import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { Link, useNavigate, useParams } from "react-router-dom";
import NewsLetterService from "../../services/NewsLetterService";
import UserService from "../../services/UserService";
import { toast } from "react-toastify";

function Compose_NewsLetter() {
  const userServ = new UserService()
  const params = useParams();
  const router = useNavigate();
  const newsLetterServ = new NewsLetterService();
  const [showLoading, setShowLoading] = useState(false);
  const [value, setValue] = useState({
    _id: "",
    subject: "",
    preview_text: "",
    send_to: "",
    from: "",
    author: "",
    banner_image: "",
    content: "",
    enable_unsubscribe_link: false,
    enable_tracking: false,
  });
  const [userList, setUserList] = useState([]);
  useEffect(() => {
    if (params?.id) getNewsLetterDetail(params?.id);
    getUserList()
  }, []);

  async function getUserList() {
    try {
      let activity = {
        filter: { role: "author" },
      };
      const response = await userServ.userList(activity);
      if (response.data) {
        setUserList(response.data);
        console.log(userList);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const getNewsLetterDetail = async (id) => {
    try {
      let response = await newsLetterServ.getDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          subject: response.data.subject,
          preview_text: response.data.preview_text,
          send_to: response.data.send_to,
          from: response.data.from,
          author: response.data.author,
          banner_image: response.data.banner_image,
          content: response.data.content,
          enable_tracking: response.data.enable_tracking,
          enable_unsubscribe_link: response.data.enable_unsubscribe_link,
        });
      } else {
        toast.error(response?.error);
      }
    } catch (err) {
      throw err;
    }
  };
  const ValidateSchema = Yup.object().shape({
    subject: Yup.string().required("Subject is required Fuild"),
    preview_text: Yup.string().required("PreviewText is a required field"),
    // send_to: Yup.string().required("Send To is a required field"),
    // form: Yup.string().required("From is a required field"),
    // author: Yup.string().required("Author is a required field"),
    // banner_image: Yup.string().required("Banner Image is a required field"),
    // setting: Yup.string().required("Setting image is required field"),
    // tracking: Yup.string().required("Tracking image is required field"),
  });
  const onSubmit = async (values, { resetForm }) => {
    setShowLoading(true);
    let obj = { ...values };
    try {
      let response;
      if (obj._id !== undefined && obj._id !== "") {
        const formData = new FormData();
        formData.append("subject", obj.subject)
        formData.append("_id", obj._id);
        formData.append("preview_text", obj.preview_text);
        formData.append("send_to", obj.send_to);
        formData.append("form", obj.form);
        formData.append("author", obj.author);
        formData.append("banner_image", obj.banner_image);
        formData.append("content", obj.content);
        formData.append("enable_unsubscribe_link", obj.enable_unsubscribe_link);
        formData.append("enable_tracking", obj.enable_tracking);
        response = await newsLetterServ.updateRecord(formData);
        if (response.data) {
          window.scroll(0, 0);
          toast.success("Letter updated successfully");
          setTimeout(() => {
            router("/newsLetter");
          }, 1500);
        }
      } else {
        const formData = new FormData();
        formData.append("subject", obj.subject)
        formData.append("preview_text", obj.preview_text);
        formData.append("send_to", obj.send_to);
        formData.append("form", obj.form);
        formData.append("author", obj.author);
        formData.append("banner_image", obj.banner_image);
        formData.append("content", obj.content);
        formData.append("enable_unsubscribe_link", obj.enable_unsubscribe_link);
        formData.append("enable_tracking", obj.enable_tracking);
        response = await newsLetterServ.addrecord(formData);
        if (response.data) {
          window.scroll(0, 0);
          toast.success("Newsletter added successfully");
          setTimeout(() => {
            router("/newsLetter");
          }, 1000);
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
    setShowLoading(false);
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="leftBox order-1">
            <div className="backIconBtn">
              <Link to="/newsletter">
                <img
                  src="/images/icons/back-icon.svg"
                  alt="backicon"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>
                <img
                  src="/images/icons/blog.svg"
                  alt="backicon"
                  className="img-fluid"
                />{" "}
                Compose Newsletter
              </h4>
            </div>
          </div>
        </div>
        <div className="userProfile_details">
          <div className="createcourseStep2 comPadding">
            <form onSubmit={formik.handleSubmit}>
              <div className="row postpageform">
                <div className="col-md-7">
                  <div className="mb-4 account_input">
                    <label htmlFor="subject" className="form-label">
                      Subject
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      placeholder="Please enter the subject"
                      name="subject"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.subject}
                    />
                    {formik.touched.subject && formik.errors.subject ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.subject}
                      </div>
                    ) : null}
                  </div>
                </div>
                {/* <div className="col-md-5">
                  <div className="mb-4 account_input">
                    <label htmlFor="from" className="form-label">
                      From
                    </label>
                    <div>
                      <div className="custom-select fa-caret-down">
                        <select id="from" 
                        className="form-select"
                        name="from"
                        >
                          <option value="MaveFi Newsletter • newsletter@mavefi.com">
                            MaveFi Newsletter • newsletter@mavefi.com
                          </option>
                          <option value="MaveFi Newsletter • newsletter@mavefi.com">
                            MaveFi Newsletter • newsletter@mavefi.com
                          </option>
                        </select>
                      </div>
                    </div>
                  </div>
                </div> */}
              </div>
              <div className="row">
                <div className="col-xl-5 border_right mb-4">
                  <div className="account_input modelFormInput pAdjust_right">
                    <label htmlFor="preview_text" className="form-label">
                      Preview Text
                    </label>
                    <textarea
                      className="form-control prevwtxt_textarea"
                      placeholder="Please enter the preview text"
                      rows={7}
                      name="preview_text"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.preview_text}
                    />
                    {formik.touched.preview_text &&
                    formik.errors.preview_text ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.preview_text}
                      </div>
                    ) : null}
                    <div className="text_description mt-2 d-flex justify-content-between">
                      <span>0 of 150 characters used</span>
                      <span className="text-end">
                        Preview text appears in the inbox after the subject
                        line.
                      </span>
                    </div>
                  </div>
                </div>
                <div className="col-xl-7">
                  <div className="modelFormInput pAdjust_left">
                    <label htmlFor="send_to" className="form-label">
                      Send To
                    </label>
                    <div className="sendradiobtn radioBtns d-flex">
                      <div className="subscri_grid mb-3">
                        <div className="form-control">
                          <input
                            type="radio"
                            id="allSubscribers"
                            name="send_to"
                            value={`${value.send_to ==1 && "All Subscribers"}`}
                            onChange={formik.handleChange}
                          />
                          <label htmlFor="allSubscribers">
                            All Subscribers
                          </label>
                        </div>
                      </div>
                      <div className="registration_grid mb-3">
                        <div className="form-control">
                          <input
                            type="radio"
                            id="registeredUsers"
                            name="send_to"
                            value={`${value.send_to ==2 && "Registered Users"}`}
                            onChange={formik.handleChange}
                          />{" "}
                          <label htmlFor="registeredUsers">
                            Registered Users
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-6">
                      <div className="mb-4 account_input">
                      <label htmlFor="Author" className="form-label">
                        Author
                      </label>
                      <div>
                        <div className="custom-select fa-caret-down">
                          <select
                            id="Author"
                            className={
                              "form-select " +
                              (formik.values.author ? "" : "color-fade")
                            }
                            name="author"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.author}
                          >
                            <option value="">Please select the author</option>
                            {userList &&
                              userList.map((user, idx) => {
                                return (
                                  <option value={user._id} key={idx}>
                                    {user.user_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {formik.touched.author && formik.errors.author ? (
                        <div className="formik-errors bg-error">
                          {formik.errors.author}
                        </div>
                      ) : null}
                    </div>
                      </div>
                      <div className="col-md-6">
                        <div className="mb-4 account_input groupsecond banner_uplod">
                          <label htmlFor="Author" className="form-label">
                            Banner Image
                          </label>
                          <div className="input-group">
                            <span className="input-group-text">
                              <img
                                src="/images/icons/uploder-icon.svg"
                                className="img-fluid"
                                alt="No Image"
                              />
                            </span>
                            <input
                              type="text"
                              id="banner_image"
                              className="form-control m-0 borderFix"
                              name="banner_image"
                              onChange={formik.handleChange}
                              value={formik.values.banner_image}
                              onBlur={formik.handleBlur}
                              placeholder="Please upload banner image"
                            />
                            <input type="file" name="banner_image" className="newsLetterFileRead" onChange={(e) => formik.setFieldValue("banner_image", e.currentTarget.files[0])}/>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="mb-4 account_input modelFormInput">
                <label htmlFor="content" className="form-label">
                  Content (HTML)
                </label>
                <textarea
                  className="form-control cotent_textarea"
                  placeholder="Please enter the content"
                  rows={7}
                  id="content"
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.content}
                />
              </div>
              <div className="settingTracking mb-4">
                <h5>Settings &amp; Trackings</h5>
                <div className="row">
                  <div className="col-sm-6 col-md-4 col-xl-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault"
                      />
                      <label
                        className="form-check-label align-text-bottom"
                        htmlFor="flexCheckDefault"
                      >
                        Track opens
                      </label>
                      <p className="settingCheck mb-0">
                        Discover who opens your campaigns by tracking the number
                        of times an invisible web beacon embedded in the
                        campaign is downloaded.
                      </p>
                    </div>
                  </div>
                  <div className="col-sm-6 col-md-4 col-xl-3">
                    <div className="form-check">
                      <input
                        className="form-check-input"
                        type="checkbox"
                        id="flexCheckDefault2"
                      />
                      <label
                        className="form-check-label align-text-bottom"
                        htmlFor="flexCheckDefault2"
                      >
                        Unscubscribe option
                      </label>
                      <p className="settingCheck mb-0">
                        Discover who opens your campaigns by tracking the number
                        of times an invisible web beacon embedded in the
                        campaign is downloaded.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="commonform_btn d-grid col-12">
                <button
                  type="submit"
                  className={
                    "btn btn-primary btn-block " +
                    (!(formik.isValid && formik.dirty) ? " disabled" : "")
                  }
                >
                  {showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Send</>
                          )}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Compose_NewsLetter;
