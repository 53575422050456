import React, { useState, useEffect } from "react";
import GiftCardService from "../../services/GiftCardService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import AddGiftCardPopup from "./AddGiftCardPopup";
import ShowHideComp from "../../shared/ShowHideComp/ShowHideComp";

const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const convertToValidDate = (value, index) => {
  return value && value.validTill ? moment(value.validTill).format("DD/MM/YYYY") : "";
};

const convertToShowHide = (value, index) => {
  return <ShowHideComp value={value.code} />;
};
const addDash = (value, index) => {
  return value.code
    ? value._id
        .replace(/(.{4})/g, "$1-")
        .toUpperCase()
        .slice(0, 19)
    : "";
};

const columns = [
  {
    label: "Valid From",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Created By", key: "createdBy" },
  { label: "Card Id", key: "cardId" },
  {
    label: "Code",
    key: "code",
    valueFunc: convertToShowHide,
    cssTd: "w170",
    cssTh: "w170",
  },
  {
    label: "Valid Till",
    key: "validTill",
    valueFunc: convertToValidDate,
  },
  { label: "Status", key: "STATUS" },
  {
    label: "Balance",
    key: "amount",
    altValue: "$00.00",
  },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "word--asc", value: "A to Z" },
  { key: "word--desc", value: "Z to A" },
];

function GiftCard() {
  const giftcardServe = new GiftCardService();
  const [totalCount, setTotalCount] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [activeCount, setActiveCount] = useState(0);
  const [monthActive, setMonthActive] = useState(0);
  const [expaireCount, setExpaireCount] = useState(0);
  const [deleteCount, setDeleteCount] = useState(0);
  const [monthExpaire, setMonthExpaire] = useState(0);
  const [extraFilter, setExtraFilter] = useState({});
  useEffect(() => {
    getGiftCardCount();
  }, []);

  const getGiftCardCount = async () => {
    try {
      let response = await giftcardServe.listCount({});
      setTotalCount(response.totalCount);
      setMonthTotal(response.monthTotal);
      setActiveCount(response.activeCount);
      setMonthActive(response.monthActive);
      setExpaireCount(response.expaireCount);
      setDeleteCount(response.deleteCount);
      setMonthExpaire(response.monthExpaire);
    } catch (error) {
      console.log(error);
    }
  };
  const cards = [
    {
      title: "Total Gift Cards",
      icon: "/images/icons/gift-black.svg",
      value: totalCount,
      footer: `+${monthTotal} this month`,
      callback: handleCallback,
    },
    {
      title: "Active Gift Cards",
      icon: "/images/icons/gift-green.svg",
      value: activeCount,
      footer: `+${monthActive} this month`,
      callback: handleCallback,
    },
    {
      title: "Expired Gift Cards",
      icon: "/images/icons/gift-red.svg",
      value: expaireCount,
      footer: `View History`,
      callback: handleCallback,
    },
    {
      title: "Deleted Gift Cards",
      icon: "/images/svg-icons/redDeleteIcon.svg",
      value: deleteCount,
      footer: `View History`,
      callback: handleCallback,
    },
  ];
  function handleCallback(value) {
    if (value == "Deleted Gift Cards") {
      setExtraFilter({ is_deleted: true });
    } else if (value == "Expired Gift Cards") {
      setExtraFilter({ expired_only: true });
    } else {
      setExtraFilter({});
    }
  }
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        hasCreatedBy: search.hasCreatedBy,
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "togiftcard"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    activity.filter = { ...activity.filter, ...extraFilter };
    try {
      let response = await giftcardServe.listAllGiftCard(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          rec.amount = rec.amount ? "$" + rec.amount : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
        getGiftCardCount();
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await giftcardServe.deleteGiftCardRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const activeToggleHandler = async (giftcard) => {
    const obj = {
      _id: giftcard._id,
      word: giftcard.word,
      definition: giftcard.definition,
      is_active: !giftcard.is_active,
    };
    try {
      const res = await giftcardServe.editGiftCardRecord(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search Gift Card"
        buttonText="Send a Gift Card"
        columns={columns}
        getListDataFunc={getListData}
        updateListFilter={extraFilter}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={AddGiftCardPopup}
        allowPopEdit={true}
      />
    </div>
  );
}

export default GiftCard;
