import React from "react";
import { toast } from "react-toastify";
import { CopyToClipboard } from "react-copy-to-clipboard";

function SucessPopUp({ onSuccess, onClose, onFail, id, link }) {
  return (
    <div
      className="modal commanModel createCustomLink show"
      id="confirmDelete"
      style={{ display: "block" }}
      onClick={() => {
        onSuccess();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/svg-icons/urlLink.svg" alt="icons" className="img-fluid" />{" "}
                  <span>Campaign URL Builder</span>
                </h4>
                <button type="button" className="btn-close" onClick={onSuccess} />
              </div>
              {/* Modal body */}
              <div className="modal-body ">
                <div className="connectWalletOuter">
                  <div className="modelFormInput">
                    <div className="row p0 margin-0 boderNone">
                      <div className="col-12 p0">
                        <p mr-bottom>
                          The link has been created successfully. You can access it below or by visiting the Custom URLs
                          section.
                        </p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="HeadingText" className="form-label">
                        Short URL
                      </label>
                      <div className="input-group">
                        {/* <input
                          type="text"
                          className="form-control"
                          id="HeadingText delConfirmText"
                          placeholder="Please enter sort Url"
                          name="name"
                          value={link.campaignShortUrl}
                        /> */}
                        <div id="HeadingText delConfirmText " className="form-control urlHeadText customUrlInpBg">
                          <span>{link.campaignShortUrl.slice(0, link.campaignShortUrl.lastIndexOf("/"))}</span>
                          {link.campaignShortUrl.slice(link.campaignShortUrl.lastIndexOf("/"))}
                        </div>
                        <CopyToClipboard text={link.campaignShortUrl}>
                          <span onClick={() => toast.success("URL Copied")} className="copyIcon hoverClickIcon ">
                            <img src="/images/svg-icons/copyIcon.svg" />
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                    <div className="marginBottom25">
                      <label htmlFor="HeadingText" className="form-label">
                        Full URL
                      </label>
                      <div className="input-group inpHeight">
                        {/* <textarea
                          type="text"
                          className="form-control"
                          id="HeadingText delConfirmText"
                          placeholder="Please enter full Url"
                          name="name"
                          value={link.campaignUrl}
                        /> */}
                        <div id="HeadingText delConfirmText " className="form-control urlHeadText customUrlInpBg">
                          <span>{link.campaignUrl.slice(0, link.campaignUrl.lastIndexOf("?"))}</span>
                          {link.campaignUrl.slice(link.campaignUrl.lastIndexOf("?"))}
                        </div>
                        <CopyToClipboard text={link.campaignUrl}>
                          <span onClick={() => toast.success("URL Copied")} className="copyIcon2 hoverClickIcon">
                            <img src="/images/svg-icons/copyIcon.svg" />
                          </span>
                        </CopyToClipboard>
                      </div>
                    </div>
                  </div>
                  <div className="">
                    <div className="d-grid">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-block disBtn hoverBtn disBtnCustom"
                        onClick={() => {
                          onSuccess();
                        }}
                      >
                        Dismiss
                      </a>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SucessPopUp;
