import { useEffect, useState } from "react";
import  moment  from "moment";
import UserService from "../services/UserService";
import {Link} from "react-router-dom"
export default function SearchBar() {
  const userServ = new UserService();
  const[searchText, setSearchText]=useState("");
  const [showResults, setShowResults] = useState(false);
  const [allOptions, setAllOptions] = useState({});
  const [userSearchArr, setUserSearchArr]=useState();
  const [courseSearchArr, setCourseSearchArr]=useState();
  const [blogSearchArr, setBlogSearchArr] =useState();
  const [glossarySearchArr, setGlossarySearchArr] =useState();
  const [supportTicketSearchArr, setSupportTicketSearchArr] = useState();
   async function searchRecords(e){
    setSearchText(e.target.value)
    if(searchText ===""){
        setUserSearchArr();
        setCourseSearchArr();
        setBlogSearchArr();
        setGlossarySearchArr()
        setSupportTicketSearchArr()
    }
    else{
      try{
        let obj ={
          start :0,
          length: 6,
          searchText : searchText
        };
        let response = await userServ.listAllSearchData(obj);
        setUserSearchArr(response.megaResult.user);
        setCourseSearchArr(response.megaResult.course);
        setBlogSearchArr(response.megaResult.blog);
        setGlossarySearchArr(response.megaResult.glossary);
        setSupportTicketSearchArr(response.megaResult.supportTicket);
        console.log(response)
        let objMap = {
          update: ["UPDATES", "word", "update", "", "/updates"],
          course: ["COURSES", "course_name", "course_desc", "cover_image", "/courses"],
          glossary: ["GLOSSARY", "word", "definition", "", "/glossary"],
          mint: ["MINTS", "name", "desc", "", "/mints"],
          newsletter: ["NEWSLETTERS", "name", "description", "", "/newsletters"],
          profile: ["PROFILES", "name", "desc", "", "/profiles"],
          user: ["ACCOUNTS", "full_name", "user_name", "file", "/users"],
          blog: ["BLOGS", "blog_title", "content", "blog_image", "/blogs"],
        };
        let allOptions ={};
        let totalMegaRecords = 0;
        if (response.megaResult) {
          for (const [key, value] of Object.entries(response.megaResult)) {
            response.megaResult[key].data = value.data.map((el) => {
              return {
                value: el._id,
                title: el[objMap[key][1]],
                content: el[objMap[key][2]],
                image: objMap[key][3] ? el[objMap[key][3]] : "",
                type: key,
              };
            });
            allOptions[key] = {
              label: objMap[key][0],
              isImage: objMap[key][3] ? true : false,
              options: response.megaResult[key].data,
              total: response.megaResult[key].total,
              viewAllLink: objMap[key][4],
            };
            totalMegaRecords = totalMegaRecords + response.megaResult[key].total;
          }
        }
        if (totalMegaRecords > 0) {
          setShowResults(true);
        } else {
          setShowResults(false);
        }
        setAllOptions(allOptions);
      }
      catch (err){
        console.log(err)
      }
    }
  }
  return (
    <div className="col-xl-7 col-lg-6 headerSearchBar ms-lg-0 ms-auto position-relative">
      <div className="mobSearch has-clear">
        <div className="input-group">
          <button className="btn" type="button" style={{ zIndex: 9 }}>
            <img
              src="/images/svg-icons/search.svg"
              alt="logo"
              className="img-fluid"
            />
          </button>
          <input
            type="text"
            className="form-control border-0"
            placeholder="Search users, files, courses, glossary, blog, support tickets & etc"
            onChange={(e)=>searchRecords(e)}
            value={searchText}
          />
          <span className="close form-control-clear hidden">
            <img
              src="/images/svg-icons/close.svg"
              alt="search"
              className="img-fluid"
            />
          </span>
        </div>
      </div>
      <div className="searchResult"
      style={{
        display:showResults ? "block" : "none"
      }}>
        <div className="searchResultInner">
          <div className="searchResultMiddleHeading searchResultHeading">
            <h4 className="mb-0">
              <img src="/images/svg-icons/search_user.svg" />
              Users
            </h4>
          </div>
          <div className="relatedSearch usergrid">
               {userSearchArr?.data?.map((v,i)=>{
                return(
                  <a>
                  <div className="relatedSearchInner d-flex align-items-center">
                    <div className="relatedSearchImage">
                      <img
                        // src="/images/svg-icons/Rectangle_shapimg.svg"
                        src={v.image==""? "/images/svg-icons/Rectangle_shapimg.svg": v.image}
                        alt="userImg"
                        className="img-fluid"
                      />
                    </div>
                    <div className="relatedSearchPara">
                      <h4 className="mb-1">{v.title}</h4>
                      <p className="mb-0">shubham1286.15014@gmail.com</p>
                    </div>
                  </div>
                </a>
                )
               })}
          </div>
          <div className="searchResultViewMore d-grid px-3">
            <button type="button" className="btn btn-primary btn-block">
             {userSearchArr?.data?.length>0? <>View all {userSearchArr?.total} results</>: <>No result found</> } 
            </button>
          </div>
          <div className="searchResultMiddleHeading searchResultHeading">
            <h4 className="mb-0">
              <img src="/images/svg-icons/file-drive.svg" />
              Files
            </h4>
          </div>
          <div className="relatedSearch relatedSearchWithoutImg files_grid">
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
            <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage files_img">
                  <img
                    src="/images/svg-icons/Rectangle_shapimg.svg"
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">Folder Name</h4>
                  <p className="mb-0">26 MB</p>
                </div>
              </div>
            </a>
          </div>
          <div className="searchResultViewMore d-grid">
            <button type="button" className="btn btn-primary btn-block">
              View all 26 results
            </button>
          </div>
          <div className="searchResultMiddleHeading searchResultHeading">
            <h4 className="mb-0">
              <img src="/images/svg-icons/E-Learning.svg" />
              Courses
            </h4>
          </div>
          <div className="relatedSearch coursegrid">
            {courseSearchArr?.data?.map((v, i)=>{
              return(
                <a href="javascript:void(0)">
                <div className="relatedSearchInner d-flex align-items-center">
                  <div className="relatedSearchImage courseSearchImg">
                    <img
                      src={v.image==""? "/images/svg-icons/Rectangle_shapimg.svg": v.image}
                      alt="courseImg"
                      className="img-fluid courseSearchImg"
                    />
                  </div>
                  <div className="relatedSearchPara">
                    <h4 className="mb-1">{v.title}</h4>
                    <p className="mb-0">
                     {v.content}
                    </p>
                  </div>
                </div>
              </a>
              )
            })}
          </div>
          <div className="searchResultViewMore d-grid">
            <button type="button" className="btn btn-primary btn-block">
            {courseSearchArr?.data?.length>0? <>View all {courseSearchArr?.total} results</>: <>No result found</> }
            </button>
          </div>
          <div className="searchResultMiddleHeading searchResultHeading">
            <h4 className="mb-0">
              <img src="/images/svg-icons/Glossary.svg" />
              Glossary
            </h4>
          </div>
          <div className="relatedSearch coursegrid">
            {glossarySearchArr?.data?.map((v, i)=>{
              return(
                <a href="javascript:void(0)">
                <div className="relatedSearchInner d-flex align-items-center">
                  <div className="relatedSearchPara">
                    <h4 className="mb-1">{v.title}</h4>
                    <p className="mb-0">
                      {v.content}
                    </p>
                  </div>
                </div>
              </a>
              )
            })}
          </div>
          <div className="searchResultViewMore d-grid">
            <button type="button" className="btn btn-primary btn-block">
            {glossarySearchArr?.data?.length>0? <>View all {glossarySearchArr?.total} results</>: <>No result found</> }
            </button>
          </div>
          <div className="searchResultMiddleHeading searchResultHeading">
            <h4 className="mb-0">
              <img src="/images/svg-icons/Blog.svg" />
              Blog
            </h4>
          </div>
          <div className="relatedSearch coursegrid">
            {blogSearchArr?.data?.map((v, i)=>{
              return(
                <a href="javascript:void(0)">
              <div className="relatedSearchInner d-flex align-items-center">
                <div className="relatedSearchImage">
                  <img
                    src={v.image==""?"/images/svg-icons/Rectangle_shapimg.svg":v.image}
                    alt="blogImg"
                    className="img-fluid"
                  />
                </div>
                <div className="relatedSearchPara">
                  <h4 className="mb-1">{v.title}</h4>
                  <p className="mb-0">
                    {v.content}
                  </p>
                </div>
              </div>
            </a>
              )
            })}
          </div>
          <div className="searchResultViewMore d-grid">
            <button type="button" className="btn btn-primary btn-block">
            {blogSearchArr?.data?.length>0? <>View all {blogSearchArr?.total} results</>: <>No result found</> }
            </button>
          </div>
          <div className="searchResultMiddleHeading searchResultHeading">
            <h4 className="mb-0">
              <img src="/images/svg-icons/support_ticket.svg" />
              Support tickets
            </h4>
          </div>
          <div className="support_ticket">
            {supportTicketSearchArr?.data?.map((v, i)=>{
              return(
                <div className="support_ticket_inner">
                <a href="javascript:void(0)">
                  <div className="relatedSearchInner">
                    <div className="relatedSearchPara">
                      <h4 className="mb-1">
                        {v.full_name}<span> ({v.email_address})</span>
                      </h4>
                    </div>
                    <div className="relatedSearchPara mt-2 gap_15 d-flex">
                      <div className="attachmentFile">
                        <p className="mb-0 lightgry">Date &amp; Time</p>
                        <p className="mb-0 dark_gray">{moment(v.createdAt).format("DD/MM/YYYY | hh:mm ")}</p>
                      </div>
                      <div className="attachmentFile">
                        <p className="mb-0 lightgry">Attachments</p>
                        <p className="mb-0 dark_gray">{v.attachments.length}</p>
                      </div>
                    </div>
                    <div className="relatedSearchPara mt-2">
                      <div className="attachmentFile">
                        <p className="mb-0 lightgry">{v.subject}</p>
                        <p className="mb-0">
                          {v.message}
                        </p>
                      </div>
                    </div>
                  </div>
                </a>
              </div>
              )
            })}
          </div>
        </div>
        <div className="searchResultViewMore d-grid">
          <button type="button" className="btn btn-primary btn-block">
          {supportTicketSearchArr?.data?.length>0? <>View all {supportTicketSearchArr?.total} results</>: <>No result found</> }
          </button>
        </div>
      </div>
      <div className="bodyOverlay" />
    </div>
  );
}
