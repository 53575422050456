import PartnerService from "../../services/PartnerService";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import PartnersForm from "./PartnersForm";
import moment from "moment";
import {useState, useEffect} from "react";
const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const convertToDate2 = (value, index) => {
  return value && value.since ? moment(value.since).format("DD/MM/YYYY") : "";
};
const columns = [
  {
    label: "Added On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Added By", key: "createdBy" },
  { label: "Name", key: "name" },
  { label: "Links", key: "social_links" },
  { label: "Category", key: "category" },
  {
    label: "Since",
    key: "since",
    valueFunc: convertToDate2,
  },
  { label: "ACTIONS", key: "ACTIONS" },
];

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "name--asc", value: "A to Z" },
  { key: "name--desc", value: "Z to A" },
];



export default function Partners() {
  const partnerServ = new PartnerService();
  const[totalCount, setTotalCount] = useState(0);
  const[monthTotal, setMonthTotal] = useState(0);
  useEffect(()=>{
    getPartnerCount()
  },[])
  const getPartnerCount = async()=>{
    try {
      let response = await partnerServ.listCount({})
       setTotalCount(response.totalCount);
       setMonthTotal(response.monthTotal);
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Total Count",
      icon: "/images/glossary/Glossary_count.svg",
      value: totalCount,
      footer: `This month: ${monthTotal}`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "partners"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await partnerServ.listAll(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await partnerServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (partnerObj) => {
    let obj = {
      _id: partnerObj._id,
      is_active: !partnerObj.is_active,
    };
    try {
      let response = await partnerServ.updateRecord(obj);
      if (response.data?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search partners"
        buttonText="Add a Partner"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={PartnersForm}
        allowPopEdit={true}
      />
    </div>
  );
}
