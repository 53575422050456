import React from "react";
import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
function ResetUserPassword({ onClose, onSucess, userId }) {
  const userService = new UserService();
  const [confirmText, setConfirmText] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showPassword2, setShowPassword2] = useState(false);
  const formValue = {
    newPassword: "",
    verifyPassword: "",
  };
  const onSubmit = (values) => {
    const obj = { ...values };
    obj.userId = userId;
    userService
      .adminChangePassword(obj)
      .then((res) => {
        if (res?.message == "Password changed successfully") {
          toast.success("Password changed successfully");
          onSucess();
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const ValidateSchema = Yup.object().shape({
    newPassword: Yup.string().required("Required"),
    verifyPassword: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: formValue,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div
        className="modal commanModel createCustomLink show"
        id="confirmDelete"
        style={{ display: "block" }}
        onClick={() => {
          onClose();
        }}
      >
        <div className="vertical-alignment-helper">
          <div className="vertical-align-center">
            <div className="modal-dialog">
              <div
                className="modal-content"
                onClick={(e) => {
                  e.stopPropagation();
                }}
              >
                {/* Modal Header */}
                <div className="modal-header">
                  <h4 className="modal-title d-flex align-items-center">
                    {/* <img src="/images/svg-icons/Delete.svg" alt="icons" className="img-fluid" />{" "} */}
                    <span>Reset Password</span>
                  </h4>
                  <button type="button" className="btn-close" onClick={onClose} />
                </div>
                {/* Modal body */}
                <div className="modal-body ">
                  <form onSubmit={formik.handleSubmit}>
                    <div className="connectWalletOuter">
                      <div className="modelFormInput">
                        {/* <div className="row p0" style={{ border: "none", margin: "0px" }}>
                      <div className="col-12 p0">
                        <p mr-bottom>
                          You are about to delete something permanently. This action can not be reversed once executed.
                        </p>
                      </div>
                    </div> */}
                        <div className="mb-3">
                          <label htmlFor="HeadingText" className="form-label">
                            Enter new Password
                          </label>
                          <div
                            className={
                              "input-group" +
                              (formik.touched.newPassword && formik.errors.newPassword ? " borderRed" : "")
                            }
                          >
                            <span className="input-group-text bg-white betaLock border-0 pe-1">
                              <img src="/images/icons/lock.svg" alt="lock" className="img-fluid" />
                            </span>
                            <input
                              type={showPassword ? "text" : "password"}
                              className="form-control passInput border-0"
                              placeholder="*************"
                              name="newPassword"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.newPassword}
                            />
                            <span className="input-group-text bg-white betaEye border-0">
                              <img
                                src="/images/icons/hide-eye.svg"
                                alt="eye-hide"
                                className="img-fluid cursor"
                                onClick={() => {
                                  formik.values.newPassword && setShowPassword(!showPassword);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                        <div className="mb-3">
                          <label htmlFor="HeadingText" className="form-label">
                            Confirm New Password
                          </label>
                          <div
                            className={
                              "input-group" +
                              (formik.touched.verifyPassword && formik.errors.verifyPassword ? " borderRed" : "")
                            }
                          >
                            <span className="input-group-text bg-white betaLock border-0 pe-1">
                              <img src="/images/icons/lock.svg" alt="lock" className="img-fluid" />
                            </span>
                            <input
                              type={showPassword2 ? "text" : "password"}
                              className="form-control passInput border-0"
                              placeholder="*************"
                              name="verifyPassword"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.verifyPassword}
                            />
                            <span className="input-group-text bg-white betaEye border-0">
                              <img
                                src="/images/icons/hide-eye.svg"
                                alt="eye-hide"
                                className="img-fluid cursor"
                                onClick={() => {
                                  formik.values.verifyPassword && setShowPassword2(!showPassword2);
                                }}
                              />
                            </span>
                          </div>
                        </div>
                      </div>
                      <div className="modelSignBtn pt-5">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className={"btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) && " disabled")}
                          >
                            Change Password
                          </button>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}

export default ResetUserPassword;
