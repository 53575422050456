import React, { useEffect, useState } from "react";
import ListNew from "../../shared/List/ListNew";
import RoleService from "../../services/RoleService";
import TeamService from "../../services/TeamService";
import AddRole from "./AddRole";
import TeamForm from "./TeamForm";
const addLogo = (value, index) => {
  return (
    <div className="d-flex justify-content-center align-items-center">
      {value?.user_id?.file ? (
        <img
          src={value.user_id.file}
          style={{ width: "24px", height: "24px", borderRadius: "50%", marginRight: "10px" }}
        />
      ) : (
        <img
          src="/images/svg-icons/newsPublisherLogo.svg"
          style={{ width: "24px", height: "24px", borderRadius: "50%", marginRight: "10px" }}
        />
      )}
      <span>{value.user_id.full_name}</span>
    </div>
  );
};
const columns = [
  {
    label: "Full Name",
    key: "full_name",
    valueFunc: addLogo,
  },
  { label: "Job Title", key: "job_title" },
  {
    label: "Type",
    key: "type",
  },
  { label: "Panel access", key: "panel_access" },
  { label: "Panel Role", key: "panel_role" },
  { label: "ACTIONS", key: "ACTIONS" },
];

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "full_name--asc", value: "A to Z" },
  { key: "full_name--desc", value: "Z to A" },
];

function TeamRoles() {
  const roleServ = new RoleService();
  const teamServ = new TeamService();
  useEffect(() => {
    getRoleList();
  }, []);
  const [value, setValue] = useState({
    title: "",
    access_to: {
      dashboard: false,
      create_content: false,
      manage_content: false,
      newsletter: false,
      support_ticket: false,
      custom_url: false,
      files_drive: false,
      analytics: false,
      coins: false,
      advertising: false,
      user_database: false,
      pro_users: false,
      gift_cards: false,
      notifications: false,
      team_roles: false,
      panel_settings: false,
    },
  });
  const [roles, setRoles] = useState([]);
  const [currRoleId, setCurrRoleId] = useState(0);
  const getRoleList = async () => {
    try {
      let obj = {};
      await roleServ.listAll(obj).then((resp) => {
        // console.log(resp);
        setRoles(resp.data);
        setCurrRoleId(currRoleId == 0 ? resp.data[0]._id : currRoleId);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const addRole = () => {
    let ls = [...roles];
    ls.push({ ...value });
    // setCurrPolicyIdx(policies.length);
    setRoles([...ls]);
  };
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "videos"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await teamServ.listAll(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.panel_role = rec.panel_role?._id ? rec.panel_role.title : "";
          return rec;
        });
        response.data = response.data.map((rec) => {
          rec.full_name = rec.user_id?._id ? rec.user_id.full_name : "";
          return rec;
        });
        response.data = response.data.map((rec) => {
          rec.panel_access = rec.panel_access ? "ON" : "OFF";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }
  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await teamServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const activeToggleHandler = async (videoObj) => {
    let obj = {
      _id: videoObj._id,
      is_active: !videoObj.is_active,
    };
    try {
      let response = await teamServ.editRecord(obj);
      if (response?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  return (
    <>
      <div className="publicSubmission">
        <div className="publicSubmissionHeader d-flex teamHeaderCustom">
          <div className="publicHeading d-flex w-100">
            <img style={{ width: "27px" }} src="/images/svg-icons/teamIcon.svg" />
            <p>Team</p>
          </div>
        </div>
        <div className="ljSectionData w-100 paddingFix clearfix teamRoleCustom" id="ljSectionData">
          <ListNew
            placeholder="Search team"
            buttonText="Add a Member"
            // buttonIcon2="/images/glossary/User_blue.svg"
            columns={columns}
            getListDataFunc={getListData}
            deleteHandlerFunc={deleteHandler}
            activeToggleHandlerFunc={activeToggleHandler}
            sortByOptions={sortByOptions}
            PopupComponent={TeamForm}
            allowPopEdit={true}
          />
        </div>
      </div>
      <div className="publicSubmission">
        <div className="publicSubmissionHeader d-flex">
          <div className="publicHeading d-flex w-100">
            <img src="/images/svg-icons/teamIcon.svg" />
            <p>Roles</p>
            <ul className="d-flex roleNav marginBottom0 nav custom-nav ms-4">
              {roles.map((item, idx) => (
                <li onClick={() => setCurrRoleId(item._id)}>
                  <a href="javascript:void(0)" className={currRoleId == item._id ? "activeNavCol active" : ""}>
                    {item.title || "Unknown"}
                  </a>
                </li>
              ))}
              <li onClick={addRole}>
                <a href="javascript:void(0)">+ Create New Role</a>
              </li>
            </ul>
          </div>
        </div>
        <div className="ljSectionData w-100 paddingFix clearfix" id="ljSectionData">
          <div className="roleContent">
            <AddRole roleId={currRoleId} />
          </div>
        </div>
      </div>
    </>
  );
}

export default TeamRoles;
