import VideoService from "../../services/VideoService";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import VideosForm from "./VideoForm";
import moment from "moment";
import { useState, useEffect } from "react";
const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const convertToDate2 = (value, index) => {
  return value && value.since ? moment(value.since).format("DD/MM/YYYY") : "";
};
const columns = [
  {
    label: "Added On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Added By", key: "createdBy" },
  {
    label: "Video Title",
    key: "video_title",
    cssTh: "white_pre",
    cssTd: "white_pre",
  },
  { label: "Channel Name", key: "channel_name" },
  { label: "Clicks", key: "clickCount" },
  { label: "bookmarks", key: "bookmarkCount" },
  { label: "Reports", key: "reportCount" },
  { label: "Status", key: "STATUS" },
  { label: "ACTIONS", key: "ACTIONS" },
];

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "name--asc", value: "A to Z" },
  { key: "name--desc", value: "Z to A" },
];

export default function Video() {
  const videoServ = new VideoService();
  const [totalCount, setTotalCount] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [totalChannelCount, setChannelTotalCount] = useState(0);
  const [monthChannel, setMonthChannel] = useState(0);
  useEffect(() => {
    getVideoCount();
  });
  const getVideoCount = async () => {
    try {
      let response = await videoServ.listCount({});
      setTotalCount(response.totalCount);
      setMonthTotal(response.monthTotal);
      setChannelTotalCount(response.totalChannelCount);
      setMonthChannel(response.monthChannel);
    } catch (error) {
      console.log(error);
    }
  };
  const cards = [
    {
      title: "Total Channels",
      icon: "/images/svg-icons/channel.svg",
      value: totalChannelCount,
      footer: `+${monthChannel} this month`,
    },
    {
      title: "Total Videos",
      icon: "/images/svg-icons/video.svg",
      value: totalCount,
      footer: `+${monthTotal} this month`,
    },
    // {
    //   title: "Public Submissions",
    //   icon: "/images/svg-icons/submission.svg",
    //   value: "000",
    //   footer: "+00 this month",
    // },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "videos"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await videoServ.listAll(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await videoServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (videoObj) => {
    let obj = {
      _id: videoObj._id,
      is_active: !videoObj.is_active,
    };
    try {
      let response = await videoServ.editRecord(obj);
      if (response?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search videos"
        buttonText="Add a Video"
        buttonLink2="/view_channel_list"
        buttonText2="View YT Channels"
        buttonIcon2="/images/svg-icons/youtubeVideoIcon.svg"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={VideosForm}
        allowPopEdit={true}
      />
    </div>
  );
}
