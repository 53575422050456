import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService";
import Dropify from "../../shared/Dropify/Dropify";
export default function BrandSetting() {
  const settingServ = new SettingService();
  const [value, setValue] = useState({
    header_logo: "",
    footer_logo: "",
    primary_color: "",
    contrasting_color: "",
    secondary_color: {
      color_1: "",
      color_2: "",
      color_3: "",
      color_4: "",
    },
    cover_image: "",
    sort_description: "",
    social_links: {
      twitter: {
        text: "",
        is_active: false,
      },
      instagram: {
        text: "",
        is_active: false,
      },
      facebook: {
        text: "",
        is_active: false,
      },
      discord: {
        text: "",
        is_active: false,
      },
      youtube: {
        text: "",
        is_active: false,
      },
      linkedin: {
        text: "",
        is_active: false,
      },
    },
  });
  useEffect(() => {
    getSettingsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingsData = async () => {
    try {
      let response = await settingServ.getDetails();
      if (response.data) {
        setValue({
          _id: response.data._id,
          header_logo: response.data?.brand?.header_logo,
          footer_logo: response.data?.brand?.footer_logo,
          primary_color: response.data?.brand?.primary_color,
          cover_image: response.data?.brand?.cover_image,
          contrasting_color: response.data?.brand?.contrasting_color,
          sort_description: response.data?.brand?.sort_description,
          secondary_color: {
            color_1: response.data?.brand?.secondary_color?.color_1,
            color_2: response.data?.brand?.secondary_color?.color_2,
            color_3: response.data?.brand?.secondary_color?.color_3,
            color_4: response.data?.brand?.secondary_color?.color_4,
          },
          social_links: {
            twitter: {
              text: response.data?.brand?.social_links?.twitter?.text,
              is_active: response.data?.brand?.social_links?.twitter?.is_active || false,
            },
            instagram: {
              text: response.data?.brand?.social_links?.instagram?.text,
              is_active: response.data?.brand?.social_links?.instagram?.is_active || false,
            },
            facebook: {
              text: response.data?.brand?.social_links?.facebook?.text,
              is_active: response.data?.brand?.social_links?.facebook?.is_active || false,
            },
            discord: {
              text: response.data?.brand?.social_links?.discord?.text,
              is_active: response.data?.brand?.social_links?.discord?.is_active || false,
            },
            youtube: {
              text: response.data?.brand?.social_links?.youtube?.text,
              is_active: response.data?.brand?.social_links?.youtube?.is_active || false,
            },
            linkedin: {
              text: response.data?.brand?.social_links?.linkedin?.text,
              is_active: response.data?.brand?.social_links?.linkedin?.is_active || false,
            },
          },
        });
      } else {
      }
    } catch (err) {}
  };

  const ValidateSchema = Yup.object().shape({
    header_logo: Yup.string().required("Required"),
    footer_logo: Yup.string().required("Required"),
    cover_image: Yup.string().required("Required"),
    sort_description: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("header_logo", values.header_logo);
    formData.append("footer_logo", values.footer_logo);
    formData.append("cover_image", values.cover_image);
    formData.append("brand", JSON.stringify(values));
    if (values._id) {
      formData.append("_id", values._id);
      settingServ.updateRecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    } else {
      settingServ.addrecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
        <div className="commonCart account_page">
          <div className="stepFormHead d-flex align-items-center">
            <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
              <div className="createCourseHeading">
                <h4>Settings | Brand</h4>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="userProfile_details">
              <div className="settingGeneral">
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Default &amp; Header Logo</h6>
                        <p>Used for the header bar &amp; other common logo applications.</p>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-4">
                      {/* <input type="file" id="input-file-uploader" className="headerLogo" /> */}
                      {/* <input
                                                    type="file"
                                                    className="headerLogo"
                                                    // placeholder="Upload Profile Picture (Size - 385X206px)"
                                                    id="input-file-uploader"
                                                    name="header_logo"
                                                    onChange={(event) => {
                                                        formik.setFieldValue(
                                                            "header_logo",
                                                            event.currentTarget.files[0]
                                                        )
                                                        // imageChange(event);
                                                    }}
                                                /> */}
                      <div className="imageUploder" style={{ width: "320px", height: "157px" }}>
                        <Dropify
                          id="input-file-uploader"
                          name="header_logo"
                          text="Please upload the image <br> <span>(Recommended Width: 512px minimum)</span>"
                          value={formik.values.cover_image}
                          changeCallBk={(event) => {
                            if (event.currentTarget.files[0]) {
                              formik.setFieldValue("header_logo", event.currentTarget.files[0]);
                            } else {
                              formik.setFieldValue("header_logo", "");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Square &amp; Footer Logo</h6>
                        <p>
                          Used in the footer &amp; other common square logo applications. (Alos used by some social
                          media channels. May be cropped into a circle.)
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-4">
                      {/* <div className="imageUploder">
                                                <input
                                                    type="file"
                                                    className="footerLogo"
                                                    id="input-file-uploader"
                                                    name="footer_logo"
                                                    onChange={(event) => {
                                                        formik.setFieldValue(
                                                            "footer_logo",
                                                            event.currentTarget.files[0]
                                                        )
                                                    }}
                                                />
                                            </div> */}
                      <div className="imageUploder" style={{ width: "157px", height: "157px" }}>
                        <Dropify
                          id="input-file-uploader-1"
                          name="footer_logo"
                          text="Please upload the image <br> <span>(Recommended: 512px x 512px minimum)</span>"
                          value={formik.values.footer_logo}
                          changeCallBk={(event) => {
                            if (event.currentTarget.files[0]) {
                              formik.setFieldValue("footer_logo", event.currentTarget.files[0]);
                            } else {
                              formik.setFieldValue("footer_logo", "");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Primary Colors</h6>
                        <p>The brand colors that appear on the platfrom, social media, and more.</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div className="bgClrBoxSec mb-3 d-flex align-items-center">
                        <div className="bgClrBox" style={{ background: "#3A86FF" }}></div>
                        <div className="bgClrBoxTxt">
                          <h6 className="mb-1">Primary Color</h6>
                          <p className="mb-0">#3A86FF</p>
                        </div>
                      </div>
                      <div className="bgClrBoxSec mb-3 d-flex align-items-center">
                        <div className="bgClrBox" style={{ background: "#FFFFFF" }}></div>
                        <div className="bgClrBoxTxt">
                          <h6 className="mb-1">Contrasting Color</h6>
                          <p className="mb-0">#FFFFFF</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Primary Colors</h6>
                        <p>The brand colors that appear on the platfrom, social media, and more.</p>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div className="bgClrBoxSec mb-3 d-flex align-items-center">
                        <div className="bgClrBox" style={{ background: "#CFD8DC" }}></div>
                        <div className="bgClrBoxTxt">
                          <h6 className="mb-1">Secondary Color 1</h6>
                          <p className="mb-0">#CFD8DC</p>
                        </div>
                      </div>
                      <div className="bgClrBoxSec mb-3 d-flex align-items-center">
                        <div className="bgClrBox" style={{ background: "#CFD8DC" }}></div>
                        <div className="bgClrBoxTxt">
                          <h6 className="mb-1">Secondary Color 2</h6>
                          <p className="mb-0">#CFD8DC</p>
                        </div>
                      </div>
                    </div>
                    <div className="col-md-4 col-xl-3">
                      <div className="bgClrBoxSec mb-3 d-flex align-items-center">
                        <div className="bgClrBox" style={{ background: "#CFD8DC" }}></div>
                        <div className="bgClrBoxTxt">
                          <h6 className="mb-1">Secondary Color 3</h6>
                          <p className="mb-0">#CFD8DC</p>
                        </div>
                      </div>
                      <div className="bgClrBoxSec mb-3 d-flex align-items-center">
                        <div className="bgClrBox" style={{ background: "#CFD8DC" }}></div>
                        <div className="bgClrBoxTxt">
                          <h6 className="mb-1">Secondary Color 4</h6>
                          <p className="mb-0">#CFD8DC</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Cover Image</h6>
                        <p>Key image that shows off our brand in apps.</p>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-4">
                      <div className="imageUploder" style={{ width: "280px", height: "157px" }}>
                        <Dropify
                          id="input-file-uploader-2"
                          name="cover_image"
                          text="Please upload the image <br> <span>(Recommended: 1920px × 1080px minimum)</span>"
                          value={formik.values.cover_image}
                          changeCallBk={(event) => {
                            if (event.currentTarget.files[0]) {
                              formik.setFieldValue("cover_image", event.currentTarget.files[0]);
                            } else {
                              formik.setFieldValue("cover_image", "");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Short description</h6>
                        <p>Description of our business for use in footer, bios and listings.</p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mb-4 account_input modelFormInput">
                        {/* <textarea className="form-control textareaCount" placeholder="Enter a short description" rows={5} maxLength={160} /> */}
                        <textarea
                          rows={4}
                          className="form-control textareaCount"
                          name="sort_description"
                          placeholder="Enter a short description"
                          value={formik.values.sort_description}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.sort_description && formik.errors.sort_description ? (
                          <div className="formik-errors bg-error">{formik.errors.sort_description}</div>
                        ) : null}
                        <div id="the-count" className="showTxtCounter">
                          <span id="current">{formik.values.sort_description.length}</span>
                          <span id="maximum">/ 160 characters</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Social links</h6>
                        <p>Social links of our business for use in footer &amp; other places.</p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="socialMediaInput mb-3">
                        <label htmlFor="email" className="form-label">
                          Twitter
                        </label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/twitter-gray.svg" alt="search" className="img-fluid" />
                          </span>
                          {/* <input type="text" className="form-control border-0" placeholder="Please enter the link" /> */}
                          <input
                            type="text"
                            className="form-control border-0"
                            id="twitter"
                            placeholder="Please enter the link"
                            name="social_links[twitter][text]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.social_links?.twitter?.text}
                          />
                          {formik.touched.social_links?.twitter?.text && formik.errors.social_links?.twitter?.text ? (
                            <div className="formik-errors bg-error">{formik.errors.social_links?.twitter?.text}</div>
                          ) : null}
                          <span className="input-group-text border-0">
                            <label className="switch">
                              {/* <input type="checkbox" defaultChecked /> */}
                              <input
                                type="checkbox"
                                id="twitter_check"
                                name="social_links[twitter][is_active]"
                                onChange={formik.handleChange}
                                checked={formik.values.social_links?.twitter?.is_active}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="socialMediaInput mb-3">
                        <label htmlFor="email" className="form-label">
                          Discord
                        </label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/discord.svg" alt="search" className="img-fluid" />
                          </span>
                          {/* <input type="text" className="form-control border-0" placeholder="Please enter the link" /> */}
                          <input
                            type="text"
                            className="form-control border-0"
                            id="discord"
                            placeholder="Please enter the link"
                            name="social_links[discord][text]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.social_links?.discord?.text}
                          />
                          {formik.touched.social_links?.discord?.text && formik.errors.social_links?.discord?.text ? (
                            <div className="formik-errors bg-error">{formik.errors.social_links?.discord?.text}</div>
                          ) : null}
                          <span className="input-group-text border-0">
                            <label className="switch">
                              {/* <input type="checkbox" /> */}
                              <input
                                type="checkbox"
                                id="discord_check"
                                name="social_links[discord][is_active]"
                                onChange={formik.handleChange}
                                checked={formik.values.social_links?.discord?.is_active}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="socialMediaInput mb-3">
                        <label htmlFor="email" className="form-label">
                          Instagram
                        </label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/instagram.svg" alt="search" className="img-fluid" />
                          </span>
                          {/* <input type="text" className="form-control border-0" placeholder="Please enter the link" /> */}
                          <input
                            type="text"
                            className="form-control border-0"
                            id="instagram"
                            placeholder="Please enter the link"
                            name="social_links[instagram][text]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.social_links?.instagram?.text}
                          />
                          {formik.touched.social_links?.instagram?.text &&
                          formik.errors.social_links?.instagram?.text ? (
                            <div className="formik-errors bg-error">{formik.errors.social_links?.instagram?.text}</div>
                          ) : null}
                          <span className="input-group-text border-0">
                            <label className="switch">
                              {/* <input type="checkbox" defaultChecked /> */}
                              <input
                                type="checkbox"
                                id="instagram_check"
                                name="social_links[instagram][is_active]"
                                onChange={formik.handleChange}
                                checked={formik.values.social_links?.instagram?.is_active}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="socialMediaInput mb-3">
                        <label htmlFor="email" className="form-label">
                          Facebook
                        </label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/facebook-gray.svg" alt="search" className="img-fluid" />
                          </span>
                          {/* <input type="text" className="form-control border-0" placeholder="Please enter the link" /> */}
                          <input
                            type="text"
                            className="form-control border-0"
                            id="facebook"
                            placeholder="Please enter the link"
                            name="social_links[facebook][text]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.social_links?.facebook?.text}
                          />
                          {formik.touched.social_links?.facebook?.text && formik.errors.social_links?.facebook?.text ? (
                            <div className="formik-errors bg-error">{formik.errors.social_links?.facebook?.text}</div>
                          ) : null}
                          <span className="input-group-text border-0">
                            <label className="switch">
                              {/* <input type="checkbox" defaultChecked /> */}
                              <input
                                type="checkbox"
                                id="facebook_check"
                                name="social_links[facebook][is_active]"
                                onChange={formik.handleChange}
                                checked={formik.values.social_links?.facebook?.is_active}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="socialMediaInput mb-3">
                        <label htmlFor="email" className="form-label">
                          Youtube
                        </label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/youtube.svg" alt="search" className="img-fluid" />
                          </span>
                          {/* <input type="text" className="form-control border-0" placeholder="Please enter the link" /> */}
                          <input
                            type="text"
                            className="form-control border-0"
                            id="youtube"
                            placeholder="Please enter the link"
                            name="social_links[youtube][text]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.social_links?.youtube?.text}
                          />
                          {formik.touched.social_links?.youtube?.text && formik.errors.social_links?.youtube?.text ? (
                            <div className="formik-errors bg-error">{formik.errors.social_links?.youtube?.text}</div>
                          ) : null}
                          <span className="input-group-text border-0">
                            <label className="switch">
                              {/* <input type="checkbox" defaultChecked /> */}
                              <input
                                type="checkbox"
                                id="youtube_check"
                                name="social_links[youtube][is_active]"
                                onChange={formik.handleChange}
                                checked={formik.values.social_links?.youtube?.is_active}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                        </div>
                      </div>
                      <div className="socialMediaInput mb-3">
                        <label htmlFor="email" className="form-label">
                          Linkedin
                        </label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/linkedin.svg" alt="search" className="img-fluid" />
                          </span>
                          {/* <input type="text" className="form-control border-0" placeholder="Please enter the link" /> */}
                          <input
                            type="text"
                            className="form-control border-0"
                            id="linkedin"
                            placeholder="Please enter the link"
                            name="social_links[linkedin][text]"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.social_links?.linkedin?.text}
                          />
                          {formik.touched.social_links?.linkedin?.text && formik.errors.social_links?.linkedin?.text ? (
                            <div className="formik-errors bg-error">{formik.errors.social_links?.linkedin?.text}</div>
                          ) : null}
                          <span className="input-group-text border-0">
                            <label className="switch">
                              {/* <input type="checkbox" defaultChecked /> */}
                              <input
                                type="checkbox"
                                id="linkedin_check"
                                name="social_links.linkedin.is_active"
                                onChange={formik.handleChange}
                                checked={formik.values.social_links.linkedin.is_active}
                              />
                              <span className="slider round" />
                            </label>
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="commonform_btn comPadding d-grid col-12">
                <button
                  type="submit"
                  className={"btn btn-primary btn-block " + (!(formik.isValid && formik.dirty) ? " disabled" : "")}
                >
                  Save Settings
                </button>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
