import React, { useState, useEffect } from "react";
import UserService from "../../services/UserService";
import RoleService from "../../services/RoleService";
import { ToastContainer, toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { useFormik } from "formik";
import * as Yup from "yup";
const userServ = new UserService();
const roleServ = new RoleService();
export default function AddRole({ roleId }) {
  const [userList, setUserList] = useState([]);
  const [value, setValue] = useState({
    title: "",
    access_to: {
      dashboard: false,
      create_content: false,
      manage_content: false,
      newsletter: false,
      support_ticket: false,
      custom_url: false,
      files_drive: false,
      analytics: false,
      coins: false,
      advertising: false,
      user_database: false,
      pro_users: false,
      gift_cards: false,
      notifications: false,
      team_roles: false,
      panel_settings: false,
    },
  });
  useEffect(() => {
    getRoleData();
  }, [roleId]);
  const getRoleData = async () => {
    try {
      if (roleId) {
        let response = await roleServ.getDetails(roleId);
        if (response) {
          setValue(response.data);
        } else {
        }
      } else {
        setValue({
          title: "",
          access_to: {
            dashboard: false,
            create_content: false,
            manage_content: false,
            newsletter: false,
            support_ticket: false,
            custom_url: false,
            files_drive: false,
            analytics: false,
            coins: false,
            advertising: false,
            user_database: false,
            pro_users: false,
            gift_cards: false,
            notifications: false,
            team_roles: false,
            panel_settings: false,
          },
        });
      }
    } catch (err) {}
  };
  const ValidateSchema = Yup.object().shape({
    policy: Yup.object().shape({
      // title: Yup.string().required("Required"),
      // author: Yup.mixed().required("Required"),
      // body: Yup.string().required("Required"),
    }),
  });
  const onSubmit = (values, { resetForm }) => {
    let obj = values;
    if (obj._id) {
      roleServ.editRecord(obj).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
          window.location.reload();
          //   getSettingsData();
        }
      });
    } else {
      roleServ.addRecord(obj).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
          window.location.reload();
          //   getSettingsData();
        }
      });
    }
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <label className="form-label marginBottom10 lineHeight16">Role Title</label>
        {/* <input className="form-control inputRole marginBottom18" /> */}
        <input
          type="text"
          className="form-control inputRole marginBottom18"
          id="title"
          placeholder="Please enter role title"
          name={"title"}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values.title}
        />
        {formik.errors && formik.errors.title ? (
          <div className="formik-errors bg-error">{formik.errors.title}</div>
        ) : null}
        <label className="form-label lineHeight16 marginBottom10">Access to</label>
        <div className="roleCheckDiv">
          <div className="row marginBottom18">
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.dashboard"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.dashboard}
                  id={"access_to_dashboard"}
                />
                <label
                  htmlFor={"access_to_dashboard"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Dashboard</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.files_drive"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.files_drive}
                  id={"access_to_files_drive"}
                />
                <label
                  htmlFor={"access_to_files_drive"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Files Drive</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.gift_cards"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.gift_cards}
                  id={"access_to_gift_cards"}
                />
                <label
                  htmlFor={"access_to_gift_cards"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Gift Cards</label>
              </div>
            </div>
          </div>
          <div className="row marginBottom18">
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.create_content"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.create_content}
                  id={"access_to_create_content"}
                />
                <label
                  htmlFor={"access_to_create_content"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Create Content</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.analytics"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.analytics}
                  id={"access_to_analytics"}
                />
                <label
                  htmlFor={"access_to_analytics"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Analytics</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.notifications"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.notifications}
                  id={"access_to_notifications"}
                />
                <label
                  htmlFor={"access_to_notifications"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Notifications</label>
              </div>
            </div>
          </div>
          <div className="row marginBottom18">
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.manage_content"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.manage_content}
                  id={"access_to_manage_content"}
                />
                <label
                  htmlFor={"access_to_manage_content"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Publish/Manage Content</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.coins"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.coins}
                  id={"access_to_coins"}
                />
                <label
                  htmlFor={"access_to_coins"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Coins</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.team_roles"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.team_roles}
                  id={"access_to_team_roles"}
                />
                <label
                  htmlFor={"access_to_team_roles"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Team & Roles</label>
              </div>
            </div>
          </div>
          <div className="row marginBottom18">
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.newsletter"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.newsletter}
                  id={"access_to_newsletter"}
                />
                <label
                  htmlFor={"access_to_newsletter"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Newsletter</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.advertising"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.advertising}
                  id={"access_to_advertising"}
                />
                <label
                  htmlFor={"access_to_advertising"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Advertising</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.panel_settings"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.panel_settings}
                  id={"access_to_panel_settings"}
                />
                <label
                  htmlFor={"access_to_panel_settings"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Panel Settings</label>
              </div>
            </div>
          </div>
          <div className="row marginBottom18">
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.support_ticket"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.support_ticket}
                  id={"access_to_support_ticket"}
                />
                <label
                  htmlFor={"access_to_support_ticket"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Support Tickets</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.user_database"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.user_database}
                  id={"access_to_user_database"}
                />
                <label
                  htmlFor={"access_to_user_database"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">User Database</label>
              </div>
            </div>
          </div>
          <div className="row ">
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.custom_url"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.custom_url}
                  id={"access_to_custom_url"}
                />
                <label
                  htmlFor={"access_to_custom_url"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Custom URLs</label>
              </div>
            </div>
            <div className="col-3 d-flex">
              <div className="d-flex optionCheck align-items-center checkBoxRead">
                <input
                  type="checkbox"
                  name="access_to.pro_users"
                  onChange={formik.handleChange}
                  checked={formik.values.access_to.pro_users}
                  id={"access_to_pro_users"}
                />
                <label
                  htmlFor={"access_to_pro_users"}
                  onClick={(e) => {
                    e.target.innerHTML = e.target.innerHTML == " " ? "" : " ";
                  }}
                ></label>
              </div>
              <div>
                <label className="labelRole">Pro Users</label>
              </div>
            </div>
          </div>
        </div>
        <div className="commonform_btn d-grid col-12 mt-3">
          <button
            type="submit"
            className={`btn btn-primary btn-block ${!(formik.isValid && formik.dirty) ? "disabled" : ""}`}
          >
            Save
          </button>
        </div>
      </form>
    </>
  );
}
