import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import moment from "moment";
import PublishService from "../../services/PublishService";

export default function UpdateForm({ onClose, onSuccess, onFail, id }) {
  const updateServe = new PublishService();
  const [showLoading, setShowLoading] = useState(true);
  const [sendDate, setSendDate] = useState(false);
  const [value, setValue] = useState({
    word: "",
    update: "",
    createdAt: "",
  });

  useEffect(() => {
    if (id) {
      getSingleUpdateData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSingleUpdateData = async () => {
    try {
      let response = await updateServe.getDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          word: response.data.word,
          createdAt: moment(response.data.createdAt).format("YYYY-MM-DD"),
          update: response.data.update,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.messsage);
    }
  };

  const ValidateSchema = Yup.object().shape({
    word: Yup.string().required("Required"),
    update: Yup.string().required("Required"),
    createdAt: sendDate ? Yup.string().required("Required") : Yup.string(),
  });

  const onSubmit = (values) => {
    setShowLoading(true);
    let obj = {
      word: values.word,
      update: values.update,
      _id: values._id,
    };
    if (sendDate) {
      obj.createdAt = values.createdAt;
    }
    if (values._id) {
      updateServe.editrecord(obj).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    } else {
      delete obj._id;
      updateServe.addrecord(obj).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    }
    setShowLoading(false);
  };
  console.log("initial", value);
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div
      className="modal commanModel addUpdate"
      id="addUpdate"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/icons/megaphone.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} an Update</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="mb-3">
                        <label htmlFor="word" className="form-label">
                          Heading Text
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="word"
                          placeholder="Please enter the heading text"
                          name="word"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.word}
                        />
                        {formik.touched.word && formik.errors.word ? (
                          <div className="formik-errors bg-error">{formik.errors.word}</div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Body Text
                        </label>
                        <div className="textareaControl">
                          <textarea
                            className="form-control"
                            rows={4}
                            id="comment"
                            placeholder="Please enter the body text"
                            maxLength={300}
                            name="update"
                            value={formik.values.update}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                        </div>
                        {formik.touched.update && formik.errors.update ? (
                          <div className="formik-errors bg-error">{formik.errors.update}</div>
                        ) : null}
                      </div>
                      {console.log("in ", formik.values)}
                      {sendDate && (
                        <div className="mb-3">
                          <label htmlFor="createdAt" className="form-label">
                            Date
                          </label>
                          <input
                            type="date"
                            className="form-control"
                            id="createdAt"
                            placeholder="Please enter the heading text"
                            name="createdAt"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.createdAt}
                          />
                          {formik.touched.createdAt && formik.errors.createdAt ? (
                            <div className="formik-errors bg-error">{formik.errors.createdAt}</div>
                          ) : null}
                        </div>
                      )}
                    </div>
                    <div className="modelSignBtn">
                      <div className="updateBtnHeader">
                        {!sendDate && (
                          <button className="calenderBtn" onClick={() => setSendDate(true)}>
                            <img src="/images/svg-icons/calenderIconDarker.svg" className="updatePopUpImg"/>
                          </button>
                        )}
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block w-100" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {!showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Publish</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
