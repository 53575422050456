import React, { useState, useEffect } from "react";
import UserService from "../../services/UserService";
import PolicyService from "../../services/PolicyService";
import { ToastContainer, toast } from "react-toastify";
import { Editor } from "@tinymce/tinymce-react";
import { useFormik } from "formik";
import * as Yup from "yup";
const userServ = new UserService();
const policyServ = new PolicyService();
export default function AddPolicy({ policyId, getSettingsData }) {
  const [userList, setUserList] = useState([]);
  const [value, setValue] = useState({
    title: "",
    author: "",
    body: "",
  });
  useEffect(() => {
    getPolicysData();
  }, [policyId]);
  useEffect(() => {
    getUserList();
  }, []);
  const getPolicysData = async () => {
    try {
      if (policyId) {
        let response = await policyServ.getDetails(policyId);
        if (response) {
          setValue(response.data);
        } else {
        }
      } else {
        setValue({
          title: "",
          author: "",
          body: "",
        });
      }
    } catch (err) { }
  };
  async function getUserList() {
    try {
      let activity = {
        filter: { role: "author" },
      };
      const response = await userServ.userList(activity);
      if (response.data) {
        setUserList(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const ValidateSchema = Yup.object().shape({
    policy: Yup.object().shape({
      // title: Yup.string().required("Required"),
      // author: Yup.mixed().required("Required"),
      // body: Yup.string().required("Required"),
    }),
  });
  const onSubmit = (values, { resetForm }) => {
    let obj = values;
    if (obj._id) {
      policyServ.editRecord(obj).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
          getSettingsData();
        }
      });
    } else {
      policyServ.addRecord(obj).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
          getSettingsData();
        }
      });
    }
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <form onSubmit={formik.handleSubmit}>
        <div className="row">
          <div className="col-8">
            <div className="mb-4 account_input">
              <label htmlFor="LessonHeading" className="form-label">
                Title
              </label>
              {/* <input type="text" className="form-control" id="LessonHeading" placeholder="Please enter the title" /> */}
              <input
                type="text"
                className="form-control"
                id="title"
                placeholder="Please enter the link here"
                name={"title"}
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.title}
              />
              {formik.errors && formik.errors.title ? (
                <div className="formik-errors bg-error">
                  {formik.errors.title}
                </div>
              ) : null}
            </div>
          </div>
          <div className="col-sm-6 col-xl-4 colSet3">
            <div className="mb-4 account_input">
              <label htmlFor="Author" className="form-label">
                Author
              </label>
              <div className="custom-select fa-caret-down">
                <select
                  id="Author"
                  className={
                    "form-select " + (formik.values.author ? "" : "color-fade")
                  }
                  name={"author"}
                  onChange={formik.handleChange}
                  onBlur={formik.handleBlur}
                  value={formik.values.author}
                >
                  <option value={""}>Please select the author</option>
                  {userList &&
                    userList.map((user, idx) => {
                      return (
                        <option value={user._id} key={idx}>
                          {user.user_name}
                        </option>
                      );
                    })}
                </select>
              </div>
              {formik.errors && formik.errors.author ? (
                <div className="formik-errors bg-error">
                  {formik.errors.author}
                </div>
              ) : null}
            </div>
          </div>
          {/* <div className="col-12">
                        <div className="mb-4 account_input modelFormInput">
                            <label htmlFor="lessonBody" className="form-label">Body</label>
                            <textarea
                                className="form-control"
                                id="body"
                                placeholder="Please enter the body text"
                                rows={7}
                                name={"body"}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.body}
                            />
                            {formik.errors &&
                                formik.errors.body ? (
                                <div className="formik-errors bg-error">
                                    {formik.errors.body}
                                </div>
                            ) : null}
                        </div>
                    </div> */}
          <div className="col-12">
            <div className="mb-4 account_input modelFormInput">
              <label htmlFor="lessonBody" className="form-label">
                Body
              </label>
              {/* <Editor
              apiKey={window.tinyAPIKEY}
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                toolbar:
                  "undo redo | bold italic underline strikethrough |fullscreen|link image media table | blocks fontfamily fontsize |  align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
              }}
              value={formik.values.lessons[lesnIdx].desc}
              onEditorChange={(e) =>
                formik.handleChange({
                  target: { name: "lessons[" + lesnIdx + "].desc", value: e },
                })
              }
            /> */}
              <Editor
                apiKey={window.tinyAPIKEY}
                init={{
                  plugins:
                    "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                  toolbar:
                    "undo redo | bold italic underline strikethrough |fullscreen|link image media table | blocks fontfamily fontsize |  align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                }}
                value={formik.values.body}
                onEditorChange={(e) =>
                  formik.handleChange({
                    target: { name: "body", value: e },
                  })
                }
              />
              {formik.touched.body && formik.errors.body ? (
                <div className="formik-errors bg-error">
                  {formik.errors.body}
                </div>
              ) : null}
            </div>
          </div>
        </div>
        <div className="commonform_btn d-grid col-12">
          <button
            type="submit"
            className={`btn btn-primary btn-block ${!(formik.isValid && formik.dirty) ? "disabled" : ""
              }`}
          >
            Publish
          </button>
        </div>
      </form>
    </>
  );
}
