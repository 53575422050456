import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService";
import Dropify from "../../shared/Dropify/Dropify";
export default function BackupSetting() {
  const settingServ = new SettingService();
  const [value, setValue] = useState({
    header_logo: "",
    footer_logo: "",
    primary_color: "",
    contrasting_color: "",
    secondary_color: {
      color_1: "",
      color_2: "",
      color_3: "",
      color_4: "",
    },
    cover_image: "",
    sort_description: "",
    social_links: {
      twitter: {
        text: "",
        is_active: false,
      },
      instagram: {
        text: "",
        is_active: false,
      },
      facebook: {
        text: "",
        is_active: false,
      },
      discord: {
        text: "",
        is_active: false,
      },
      youtube: {
        text: "",
        is_active: false,
      },
      linkedin: {
        text: "",
        is_active: false,
      },
    },
  });
  useEffect(() => {
    getSettingsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingsData = async () => {
    try {
      let response = await settingServ.getDetails();
      if (response.data) {
        setValue({
          _id: response.data._id,
          header_logo: response.data?.brand?.header_logo,
          footer_logo: response.data?.brand?.footer_logo,
          primary_color: response.data?.brand?.primary_color,
          cover_image: response.data?.brand?.cover_image,
          contrasting_color: response.data?.brand?.contrasting_color,
          sort_description: response.data?.brand?.sort_description,
          secondary_color: {
            color_1: response.data?.brand?.secondary_color?.color_1,
            color_2: response.data?.brand?.secondary_color?.color_2,
            color_3: response.data?.brand?.secondary_color?.color_3,
            color_4: response.data?.brand?.secondary_color?.color_4,
          },
          social_links: {
            twitter: {
              text: response.data?.brand?.social_links?.twitter?.text,
              is_active:
                response.data?.brand?.social_links?.twitter?.is_active || false,
            },
            instagram: {
              text: response.data?.brand?.social_links?.instagram?.text,
              is_active:
                response.data?.brand?.social_links?.instagram?.is_active ||
                false,
            },
            facebook: {
              text: response.data?.brand?.social_links?.facebook?.text,
              is_active:
                response.data?.brand?.social_links?.facebook?.is_active ||
                false,
            },
            discord: {
              text: response.data?.brand?.social_links?.discord?.text,
              is_active:
                response.data?.brand?.social_links?.discord?.is_active || false,
            },
            youtube: {
              text: response.data?.brand?.social_links?.youtube?.text,
              is_active:
                response.data?.brand?.social_links?.youtube?.is_active || false,
            },
            linkedin: {
              text: response.data?.brand?.social_links?.linkedin?.text,
              is_active:
                response.data?.brand?.social_links?.linkedin?.is_active ||
                false,
            },
          },
        });
      } else {
      }
    } catch (err) {}
  };

  const ValidateSchema = Yup.object().shape({
    // password: Yup.string().required("Required"),
    // waitList: Yup.string().required("Required"),
    // time_zone: Yup.string().required("Required"),
    // refer_reward: Yup.string().required("Required"),
    // is_protected: Yup.string().required("Required"),
    // is_refer_reward_on: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    formData.append("header_logo", values.header_logo);
    formData.append("footer_logo", values.footer_logo);
    formData.append("cover_image", values.cover_image);
    formData.append("brand", JSON.stringify(values));
    if (values._id) {
      formData.append("_id", values._id);
      settingServ.updateRecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    } else {
      settingServ.addrecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
        <div className="commonCart account_page">
          <div className="stepFormHead d-flex align-items-center">
            <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
              <div className="createCourseHeading">
                <h4>Settings | Backup</h4>
              </div>
            </div>
          </div>
          <div className="userProfile_details">
            <div className="setting_backupSec">
              <div className="row mx-0">
                <div className="col-xl-6 border_right px-0">
                  <div className="backup_platform comPadding">
                    <div className="Generalcommn_text">
                      <h6>Manually Backup Platform</h6>
                      <p>
                        Use this feature to create a manual backup of the
                        platform data.
                      </p>
                    </div>
                    <div className="backupSize">
                      <p className="mb-1">Computed Backup Size</p>
                      <span>2.50 GB</span>
                    </div>
                    <div className="mb-4 account_input">
                      <div>
                        <div className="custom-select fa-caret-down">
                          <select id="downloadzip" className="form-select">
                            <option>Download backup (.zip)</option>
                            <option>Download backup (.zip)</option>
                            <option>Download backup (.zip)</option>
                            <option>Download backup (.zip)</option>
                          </select>
                        </div>
                      </div>
                    </div>
                    <div className="commonform_btn d-grid">
                      <button
                        type="submit"
                        className="btn btn-outline-primary btn-block"
                      >
                        Prepare to download
                      </button>
                    </div>
                  </div>
                  <hr className="horiz my-0" />
                  <div className="ready_todonload comPadding">
                    <h6>Ready for download</h6>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <div className="download_link">
                        <a href="javascript:void(0)">
                          <img
                            src="/images/other-icons/download_icon.svg"
                            className="me-1 align-text-bottom"
                          />
                          Download Backup-20th-October-2020.zip
                        </a>
                        <span className="ms-1">2.35 GB</span>
                      </div>
                      <div className="delete_link">
                        <a href="javascript:void(0)">Delete</a>
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center mt-4">
                      <div className="download_link">
                        <a href="javascript:void(0)">
                          <img
                            src="/images/other-icons/download_icon.svg"
                            className="me-1 align-text-bottom"
                          />
                          Download Backup-20th-October-2020.zip
                        </a>
                        <span className="ms-1">2.35 GB</span>
                      </div>
                      <div className="delete_link">
                        <a href="javascript:void(0)">Delete</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-xl-6 px-0">
                  <div className="backup_platform comPadding">
                    <div className="Generalcommn_text">
                      <h6>Backup History</h6>
                    </div>
                    <div className="d-flex backupSizea_gap">
                      <div className="backupSize">
                        <p className="mb-1">Automatic Backup Frequency</p>
                        <span>Daily</span>
                      </div>
                      <div className="backupSize">
                        <p className="mb-1">Automatic Backup Location</p>
                        <span>Amazon AWS</span>
                      </div>
                    </div>
                    <div className="courseDtl_tbl courseTable">
                      <div className="table-responsive">
                        <table className="table commontable mb-0">
                          <tbody>
                            <tr>
                              <th>Backup Date &amp; Time</th>
                              <th> Backup Size</th>
                              <th>Backup Type</th>
                              <th>Backup Location</th>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                            <tr>
                              <td>23/10/2022 | 16:47 IST</td>
                              <td> 2.42 GB</td>
                              <td>Automatic</td>
                              <td>Amazon AWS</td>
                            </tr>
                          </tbody>
                        </table>
                      </div>
                      <div className="pagi_nation">
                        <ul className="nav justify-content-center">
                          <li className="me-1">
                            <a href="javascript:void(0)" className="disactive">
                              Prev.
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)" className="active">
                              1
                            </a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">2</a>
                          </li>
                          <li>
                            <a href="javascript:void(0)">3</a>
                          </li>
                          <li className="ms-1">
                            <a href="javascript:void(0)">Next</a>
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
