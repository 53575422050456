import React, { useState, useContext } from "react";
import FileDriveService from "../../services/FileDriveService";
import { useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import FileUpload from "../../shared/FileUpload/FileUpload";
import FileStatusContext from "../../context/FileStatusContext";
import axios from "axios";
function FilesDriveEmpty({ setListFile }) {
  const fileServ = new FileDriveService();
  const navigate = useNavigate();
  const fileCtx = useContext(FileStatusContext);
  const [status, setStatus] = fileCtx.status;
  const [btnText, setBtnText] = useState("File Uploading");
  const [showUploadingPopUp, setShowUploadingPopup] = fileCtx.showUploadingPopUp;
  const [filesDetail, setFilesDetail] = useState("");
  const [uploadProgress, setUploadProgress] = useState({});
  let uploadProgressTemp = {};
  const getType = (file) => {
    if (file["type"]?.split("/")[0] == "image") {
      return 1;
    } else if (file["type"]?.split("/")[0] == "video") {
      return 2;
    } else if (file["type"]?.split("/")[1] == "pdf") {
      return 3;
    } else {
      return 0;
    }
  };
  const getName = (file) => {
    return file["name"].split(".")[0];
  };
  const handleFileSubmit = async (e) => {
    setShowUploadingPopup(true);
    let responsePromise = [];
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      const element = e.currentTarget.files[i];
      let obj = {
        name: getName(element),
        file: element,
        type: getType(element),
        folder_id: "63f5b046bc7f1dc87efe88c1",
      };
      const formData = new FormData();
      formData.append("file", obj.file);
      formData.append("type", obj.type);
      formData.append("name", obj.name);
      formData.append("folder_id", obj.folder_id);
      const token = window.user ? window.user.token : "no-token";
      const config = {
        headers: {
          content: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (data) => {
          let _status = status;
          _status[obj.name] = Math.round((data.loaded / data.total) * 100);
          setStatus({ ..._status });
          //setStatus({ ...status, [`${obj.name}`]: Math.round((data.loaded / data.total) * 100) });
        },
      };

      try {
        responsePromise.push(axios.post(process.env.REACT_APP_API_BASEURL + "/filesdrive", formData, config));
        // if (response.err) {
        //   toast.error(response.err);
        // } else {
        //   setBtnText("File uploaded");
        //   setTimeout(() => {
        //     setListFile(true);
        //   }, 1000);
        // }
      } catch (err) {
        throw err;
      }
    }
    Promise.all(responsePromise).then((value) => {
      setListFile(true);
    });
  };
  return (
    <div className=" w-100 clearfix borderNone" id="ljSectionData">
      <div className="commonCart borderNone">
        {/* <div className="notification_head d-flex align-items-center justify-content-between comPadding pb-0">
          <div className="notif_headleft">
            <h4 className="mb-0">Files Drive</h4>
          </div>
          <div className="diskuseSpace">
            <a className="diskuseSpace_btn">Disk Space Used: 5.49 GB</a>
          </div>
        </div>
        <div className="course_header comPadding filedrive_search">
          <div className="headerSearchBar">
            <div className="mobSearch_new has-clear">
              <div className="input-group">
                <button className="btn" type="button" style={{ zIndex: 9 }}>
                  <img
                    src="/images/svg-icons/search.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </button>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder="Search files"
                />
                <span className="close form-control-clear hidden">
                  <img
                    src="images/svg-icons/close.svg"
                    alt="search"
                    className="img-fluid"
                  />
                </span>
              </div>
            </div>
          </div>
          <div className="added_filter">
            <div className="img-select2">
              <select>
                <option value="English">File Size</option>
              </select>
            </div>
          </div>
          <div className="added_filter">
            <div className="img-select2">
              <select>
                <option value="English">File Type</option>
              </select>
            </div>
          </div>
          <div className="added_filter">
            <div className="img-select2">
              <select>
                <option value="English">Recently Added</option>
              </select>
            </div>
          </div>
          <div className="create_btn">
            <button type="button">
              <img src="images/glossary/Upload.svg" /> Upload Files
            </button>
          </div>
        </div> */}
        {/* <div className="comPadding"> */}
        <div className="d-flex align-items-center justify-content-center">
          <div className="upload_filesDiv text-center">
            <img src="images/glossary/image_files.svg" className="img-fluid" />
            <h2>Upload and manage your files</h2>
            <p className="mb-4">Files can be images, videos, documents, and more.</p>
            <div className="create_btn">
              <button type="button">
                <img src="images/glossary/Upload.svg" /> Upload Files
              </button>
              <input
                type="file"
                className="fileDriveInput"
                multiple
                onChange={(e) => {
                  setFilesDetail(e);
                  handleFileSubmit(e);
                }}
              />
            </div>
          </div>
        </div>
        {/* </div> */}
        {/* <div className="notificationAlert upldfileList_top p-0">
          <div className="d-flex justify-content-between upldfileList_box">
            <div className="notificationbox">
              <span className="name ms-1">Files Uploading</span>
              <p className="remaining mb-0">2 remaining</p>
            </div>
            <div className="cancelIcon">
              <a
                href="javascript:void(0)"
                data-bs-toggle="collapse"
                data-bs-target="#demo"
              >
                {" "}
                <i className="fa fa-chevron-down" aria-hidden="true" />
              </a>
            </div>
          </div>
          <div className="upldfileList collapse show" id="demo">
            <ul className="list-unstyled mb-0">
              <li className="d-flex justify-content-between mb-2">
                <div className="notificationbox">
                  <div className="progress d-inline-block align-middle">
                    <span
                      className="title timer"
                      data-from={0}
                      data-to={85}
                      data-speed={1800}
                    />
                    <div className="overlay" />
                    <div className="left" />
                    <div className="right" />
                  </div>
                  <span className="name ms-1">Files Uploading</span>
                </div>
                <div className="cancelIcon">
                  <a href="javascript:void(0)">
                    {" "}
                    <img src="images/glossary/white_cancel.svg" />
                  </a>
                </div>
              </li>
              <li className="d-flex justify-content-between">
                <div className="notificationbox">
                  <div className="progress d-inline-block align-middle">
                    <span
                      className="title timer"
                      data-from={0}
                      data-to={85}
                      data-speed={1800}
                    />
                    <div className="overlay" />
                    <div className="left" />
                    <div className="right" />
                  </div>
                  <span className="name ms-1">Files Uploading</span>
                </div>
                <div className="cancelIcon">
                  <a href="javascript:void(0)">
                    {" "}
                    <img src="images/glossary/white_cancel.svg" />
                  </a>
                </div>
              </li>
            </ul>
          </div>
        </div> */}

        {/* {showUploadingPopUp && (
          <FileUpload setShowUploadingPopup={setShowUploadingPopup} btnText={btnText} uploadProgress={uploadProgress} />
        )} */}
      </div>
      <ToastContainer />
    </div>
  );
}

export default FilesDriveEmpty;
