import NotificationDropDown from "./NotificationDropdown";
import UserDropDown from "./UserDropDown";

export default function HeaderOptions({ handleAuthState }) {
  return (
    <div className="headerMenu ms-lg-auto">
      <div className="headerBtnSection d-flex align-items-center">
        <div className="fullScreen">
          <a href="javascript:void(0)">
            <img
              src="/images/svg-icons/Full-Screen.svg"
              className="img-fluid"
            />
          </a>
        </div>
        <NotificationDropDown />
        <UserDropDown handleAuthState={handleAuthState} />
        <div className="modeBtn d-block d-lg-none">
          <a href="javascript:void(0)" className="headerSearch">
            <img
              src="/images/svg-icons/search.svg"
              alt="search"
              className="img-fluid"
            />
          </a>
        </div>
      </div>
    </div>
  );
}
