import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import { Editor } from "@tinymce/tinymce-react";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CourseService from "../../services/CourseService";
import TagsInput from "../Courses/TagsInput";
import Steps from "./Steps";

export default function AddMeta() {
  const params = useParams();
  const router = useNavigate();
  const courseServ = new CourseService();
  const [course, setCourse] = useState({
    _id: "",
    metadata: { title: "", tags: "", description: "" },
  });
  const [defaultTag, setDefaultTag] = useState([]);
  useEffect(() => {
    if (params?.draftid && params?.id) {
      fetchDraftList();
    } else if (params?.id) {
      onLoadCourseData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onLoadCourseData = async () => {
    try {
      let response = await courseServ.getCourse(params?.id);
      if (response.data) {
        setCourse({
          metadata: { title: "", tags: "", description: "" },
          ...response.data,
        });
        let defaultTag = [];
        response.data.metadata.tags.split(",").forEach((item) => {
          let obj = { value: item, label: item };
          defaultTag = [...defaultTag, obj];
        });
        if(response.data.metadata.tags!=""){
          setDefaultTag([...defaultTag]);
        }
      } else {
        toast.error(response?.error);
      }
    } catch (err) {
      throw err;
    }
  };

  async function fetchDraftList() {
    if (params?.draftid !== "") {
      let response = await courseServ.getDraftDetails(params?.draftid);
      if (response.data) {
        let courseObj = response.data;
        let draftObj = {
          ...courseObj,
          _id: response.data.course_id,
        };
        delete draftObj.course_id;
        setCourse(draftObj);
      }
    }
  }

  const onSubmit = async (formValues) => {
    let values = { ...formValues };
    console.log(values);
    try {
      if (values._id !== undefined && values._id !== "") {
        const formData = new FormData();
        formData.append("_id", values._id);
        formData.append("metadata", JSON.stringify(values.metadata));
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        };
        axios.put(process.env.REACT_APP_API_BASEURL + "/course", formData, config).then(async (response) => {
          if (response.data) {
            toast.success("Course updated successfully");
            window.scroll(0, 0);
            setTimeout(() => {
              router("/course");
            }, 1500);
          }
        });
      } else {
        const formData = new FormData();
        formData.append("_id", values._id);
        formData.append("meta", values.meta);
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        };
        axios.post(process.env.REACT_APP_API_BASEURL + "/course", formData, config).then(async (response) => {
          if (response.data) {
            window.scroll(0, 0);
            toast.success("Course added successfully");
            setTimeout(() => {
              if (params.draftid) {
                router(`/course/create_lesson/${response.data?.data?._id}/${params.draftid}`);
              } else {
                router(`/course/create_lesson/${response.data?.data?._id}`);
              }
            }, 1000);
          }
        });
      }
    } catch (err) {
      throw err;
    }
  };
  const formik = useFormik({
    initialValues: course,
    validateOnBlur: true,
    onSubmit,
    enableReinitialize: true,
  });
  const callBackTags = (tags) => {
    let stringTag = tags.join(",")
    formik.setFieldValue("metadata.tags", stringTag);
  };
  
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="leftBox order-1">
            <div className="backIconBtn">
              <Link to="/course">
                <img src="/images/icons/back-icon.svg" alt="backicon" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div className="rightBox order-2 order-sm-3">
            <h5>Step 4</h5>
            <h6>SEO</h6>
          </div>
          <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>
                <img src="/images/icons/e-learning.svg" alt="backicon" className="img-fluid marginRight5" />{" "}
                <span>Create a Course</span>
              </h4>
            </div>
            <div className="paginationNav">
              <Steps step={4} courseId={params?.id} />
            </div>
          </div>
        </div>
        <div className="userProfile_details">
          <div className="createcourseStep2 comPadding">
            <form onSubmit={formik.handleSubmit}>
              <div className="row">
                <div className="col-12">
                  <div className="mb-4 account_input">
                    <label htmlFor="pageTitle" className="form-label marginBottom10">
                      Page Title
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="pageTitle"
                      placeholder="Please enter the page title"
                      name="metadata.title"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metadata.title}
                    />
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4 account_input">
                    <label htmlFor="pageTag" className="form-label marginBottom10">
                      Page Tags
                    </label>
                    <TagsInput
                      callBackTags={callBackTags}
                      tags={formik.values.tags}
                      formik={formik}
                      // isSearchable
                      // prevTags={tagList}
                      defualtTag={defaultTag}
                      hideMenu={true}
                    />
                    {/* <div className="pageTagSpan">
                      {pageTag?.filter((v, i)=>{
                          return(
                            v.trim().length>0
                          )
                      }).map((v, i)=>{
                        return(
                          <span>{v}<img onClick={()=>deleteTag(i)} className="crossIconPageTg" src="/images/icons/crossIcon.svg"/></span>
                        )
                      })}
                    <input
                      type="text"
                      className="pageInput"
                      id="pageTag"
                      placeholder="Please enter the page title"
                      name="metadata.tags"
                      onChange={(e)=>{
                        formik.setFieldValue("metadata.tags", formikPageTag)
                      }}
                      onBlur={formik.handleBlur}
                      onKeyUp={(e)=>addTag(e)}
                    />
                    </div> */}
                  </div>
                </div>
                <div className="col-12">
                  <div className="mb-4 account_input modelFormInput">
                    <label htmlFor="pageDescription" className="form-label marginBottom10">
                      Page Description
                    </label>
                    <textarea
                      className="form-control"
                      placeholder="Please enter the page description"
                      rows={7}
                      name="metadata.description"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.metadata.description}
                    />
                  </div>
                </div>
              </div>
              <div className="commonform_btn d-grid col-12">
                <button type="submit" className="btn btn-primary btn-block">
                  Publish
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
