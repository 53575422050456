import { useNavigate } from "react-router-dom";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import CourseService from "../../services/CourseService";

const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const columns = [
  {
    label: "Created On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Course Title", key: "course_name" },
  {
    label: "Author",
    key: "createdBy",
  },
  {
    label: "Reward",
    key: "rewards",
  },
  { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "course_name--asc", value: "A to Z" },
  { key: "course_name--desc", value: "Z to A" },
];

const cards = [
  {
    title: "Total Course",
    icon: "/images/account/TotalCourse.svg",
    value: "000",
    footer: "+00 this month",
  },
  {
    title: "Active Course",
    icon: "/images/account/ActiveCourse.svg",
    value: "000",
    footer: "+00 this month",
  },
  {
    title: "Inactive Course",
    icon: "/images/account/InactiveCourse.svg",
    value: "000",
    footer: "+00 this month",
  },
  {
    title: "Drafts",
    icon: "/images/account/TotalDrafts.svg",
    value: "000",
    footer: "+00 this month",
  },
];

function CourseDraft() {
  const navigate = useNavigate();
  const courseServ = new CourseService();

  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "course"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    try {
      let response = await courseServ.draftList(activity);
      if (response.data) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const editHandler = async (id, draftId) => {
    navigate("/course/create_course/" + id + "/" + draftId);
  };

  const viewHandler = async (id) => {
    window.open(process.env.REACT_APP_FRONTEND_BASEURL + "/courses/info/" + id, "_blank");
  };

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await courseServ.deleteCourse(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (course) => {
    try {
      let isActive = !course.is_active;
      let obj = {
        is_active: isActive,
        _id: course._id,
      };
      let res = await courseServ.activeToggle(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  return (
    <div className="publicSubmission">
      <div className="publicSubmissionHeader d-flex">
        <div className="backBtnPublic" onClick={() => navigate("/course")}>
          <img src="/images/icons/back-icon.svg" />
        </div>
        <div className="publicHeading d-flex w-100">
          <img src="/images/svg-icons/courseDraftIcon.svg" />
          <p>Courses - Drafts</p>
        </div>
      </div>
      <div className="ljSectionData w-100 paddingFix clearfix" id="ljSectionData">
        <ListNew
          // placeholder="Search glossary"
          // buttonText="Add a Word"
          columns={columns}
          getListDataFunc={getListData}
          deleteHandlerFunc={deleteHandler}
          activeToggleHandlerFunc={activeToggleHandler}
          sortByOptions={sortByOptions}
          // allowPopEdit={true}
          topRadius0={true}
          editDraftHandlerFunc={editHandler}
        />
      </div>
    </div>
  );
}

export default CourseDraft;
