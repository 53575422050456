import TagsInput from "../Courses/TagsInput";
import CreatableSelect from "react-select/creatable";
export default function AddSeoPage({ pageType, formik }) {
  return (
    <>
      <div className="mb-4 account_input">
        <label htmlFor="Title" className="form-label">
          Page Title{" "}
        </label>
        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
        <input
          type="text"
          className="form-control mb-1"
          id="Title"
          placeholder="Please enter the homepage title"
          name={`[${pageType}].title`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[pageType]?.title}
        />
        {formik.touched[pageType]?.title && formik.errors[pageType]?.title ? (
          <div className="formik-errors bg-error">
            {formik.errors[pageType]?.title}
          </div>
        ) : null}
        <div className="text_description">
          <span>0 of 70 characters used</span>
        </div>
      </div>
      <div className="mb-4 account_input">
        <label htmlFor="Title" className="form-label">
          Page Tags{" "}
        </label>
        {/* <input type="text" className="form-control" id="Title" placeholder="Please enter the homepage tags followed by a comma (Example: tag 1, tag 2, tag 3)" /> */}
        <input
          type="text"
          className="form-control mb-1"
          id="Title"
          placeholder="Please enter the homepage title"
          name={`[${pageType}].tags`}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
          value={formik.values[pageType]?.tags}
        />
        {/* <CreatableSelect
                    onChange={formik.handleChange}
                    isMulti
                    name={`[${pageType}].tags`}
                    // options={}
                    value={formik.values[pageType]?.tags}
                    className=" basic-multi-select"
                    classNamePrefix="select"
                    placeholder="Select the tags for this course"
                // styles={colourStyles}
                /> */}
        {formik.touched[pageType]?.title && formik.errors[pageType]?.tags ? (
          <div className="formik-errors bg-error">
            {formik.errors[pageType]?.tags}
          </div>
        ) : null}
      </div>
      <div className="mb-4 account_input modelFormInput">
        <label htmlFor="homepageDescription" className="form-label">
          Page meta description
        </label>
        {/* <textarea className="form-control" placeholder="Enter a description to get a better ranking on search engines like google" rows={4} /> */}
        <textarea
          rows={4}
          className="form-control textareaCount"
          name={`[${pageType}].meta_description`}
          placeholder="Please enter the page description (A good description helps to get a better ranking on search engines like google)"
          value={formik.values[pageType]?.meta_description}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />

        {formik.touched[pageType]?.meta_description &&
        formik.errors[pageType]?.meta_description ? (
          <div className="formik-errors bg-error">
            {formik.errors[pageType]?.meta_description}
          </div>
        ) : null}
        <div className="text_description mt-1">
          <span>0 of 320 characters used</span>
        </div>
      </div>
    </>
  );
}
