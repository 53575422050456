import React, { useRef, useState, useEffect } from "react";
import { Link } from "react-router-dom";

function generatePagination(current, total, handlePagingFunc) {
  let pageNums = [];
  let gap = 7;
  let max = 20;
  let start = total > max && current > gap ? current - gap : 1;
  let end = total > max && current < total - gap ? current + gap : total;
  if (total > 0) {
    pageNums.push(
      <li key="prev" className="me-1">
        <Link
          to="#"
          className={current == 0 ? "disactive" : ""}
          onClick={() => {
            handlePagingFunc(current - 1);
          }}
        >
          Prev
        </Link>
      </li>
    );
  }
  for (let i = start; i <= end; i++) {
    pageNums.push(
      <li key={i}>
        <Link
          to="#"
          className={i == current ? "active" : ""}
          onClick={() => {
            handlePagingFunc(i);
          }}
        >
          {i}
        </Link>
      </li>
    );
  }
  if (total > 0) {
    pageNums.push(
      <li key="next" className="ms-1">
        <Link
          to="#"
          className={current == total ? "disactive" : ""}
          onClick={() => {
            handlePagingFunc(current + 1);
          }}
        >
          Next
        </Link>
      </li>
    );
  }
  return pageNums;
}
export default function Table({
  thead,
  tdata,
  pagination,
  handlePaging,
  cssClassesTd,
  cssClassesTh,
  thead2,
  tdata2,
  scrollPagination,
  perPage,
}) {
  const listInnerRef = useRef();
  const d = new Date();
  const time = d.getSeconds();
  const [scrolledToBottom, setScrolledToBottom] = useState(time);
  useEffect(() => {
    if (scrollPagination) {
      paging();
    }
  }, [scrolledToBottom]);
  const onScroll = async () => {
    if (listInnerRef.current) {
      const { scrollTop, scrollHeight, clientHeight } = listInnerRef.current;
      if (scrollTop + clientHeight >= scrollHeight - 1 && pagination.current < pagination.total) {
        const d = new Date();
        let time = d.getSeconds();
        setScrolledToBottom(time);
      }
    }
  };
  const paging = async () => {
    handlePaging(pagination.current + 1);
  };
  const showHead = thead
    .map((h, i) => {
      return h !== "" ? true : false;
    })
    .includes(true);
  return (
    <>
      <div
        className={"table-responsive " + (thead2 ? "d-flex" : "") + (scrollPagination ? " userDatabaseScroll" : " ")}
        onScroll={onScroll}
        ref={listInnerRef}
      >
        <table className={"table commontable mb-0"}>
          <tbody>
            {showHead && (
              <tr>
                {thead.map((h, i) => {
                  return <TableHeadItem key={i} item={h} className={cssClassesTh[i]} />;
                })}
              </tr>
            )}
            {tdata.map((item) => {
              return <TableRow key={item.id} data={item.items} cssClassesTd={cssClassesTd} />;
            })}
          </tbody>
        </table>
        {thead2 && (
          <table className="table commontable mb-0 fix-height">
            <tbody>
              {showHead && (
                <tr>
                  {thead2.map((h, i) => {
                    return <TableHeadItem key={i} item={h} className={cssClassesTh[i]} />;
                  })}
                </tr>
              )}
              {tdata2.map((item) => {
                return <TableRow key={item.id} data={item.items} cssClassesTd={cssClassesTd} />;
              })}
            </tbody>
          </table>
        )}
      </div>

      {pagination && !scrollPagination && (
        <div className="pagi_nation">
          <ul className="nav justify-content-center">
            {generatePagination(pagination.current, pagination.total, handlePaging)}
          </ul>
        </div>
      )}
    </>
  );
}

const TableHeadItem = ({ item, className }) => {
  return (
    <th title={item} className={className}>
      {item}
    </th>
  );
};

const TableRow = ({ data, cssClassesTd }) => {
  return (
    <tr>
      {data.map((item, i) => {
        return (
          <td key={i} className={cssClassesTd[i]}>
            {item}
          </td>
        );
      })}
    </tr>
  );
};
