import React, { useState } from "react";
import CreatableSelect from "react-select/creatable";

function TagsInput({ tags, callBackTags, formik, prevTags, defualtTag , hideMenu }) {
  const [tagValue, setTagValue] = useState(null);

  const handleChange = (e) => {
    setTagValue(e);
    let tempTag = [];
    e.map((i) => {
      tempTag.push(i.value);
    });
    callBackTags([...tempTag]);
  };
  let show = "block"
  if(hideMenu){
    show = "none"
  }
  return ( 
    <div className="form-group">
      <CreatableSelect
        onChange={handleChange}
        isMulti
        name="colors"
        options={prevTags}
        value={tagValue ? tagValue : defualtTag}
        className="basic-multi-select"
        classNamePrefix="select"
        placeholder="Please enter the tags"
        styles={{
          menuList : (baseStyles, state)=>({
            ...baseStyles,
            maxHeight : "180px",
          }),
          menu : (baseStyles, state)=>({
            ...baseStyles,
            display : show
          }),
          dropdownIndicator : (baseStyles, state)=>({
            ...baseStyles,
            display : show
          }),
          indicatorSeparator : (baseStyles, state)=>({
            ...baseStyles,
            display : "none"
          }),
          clearIndicator : (baseStyles, state)=>({
            ...baseStyles,
            display : "none"
          }),
          MultiValueLabel :(baseStyles, state)=>({
            ...baseStyles,
            color:"red"

          }),
        }}
      />
    </div>
  );
}

export default TagsInput;
