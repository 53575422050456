import util from "../util/util";

export default class CourseService {
  addCourse(payload) {
    return util
      .sendApiRequest("/course", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  editCourse(payload) {
    return util
      .sendApiRequest("/course", "PUT", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  addUserImage(payload) {
    return util
      .sendApiRequest("/course/userimage", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  async courseList(data) {
    try {
      const course = Object.keys(data).reduce((object, key) => {
        if (data[key] !== "") {
          object[key] = data[key];
        }
        return object;
      }, {});
      return await util.sendApiRequest("/course/list", "POST", true, course);
    } catch (err) {
      throw err;
    }
  }

  async getCourse(courseId) {
    try {
      return await util.sendApiRequest("/course/" + courseId, "GET", true);
    } catch (err) {
      throw err;
    }
  }
  async getAnalytics(courseId) {
    try {
      return await util.sendApiRequest("/course/analytics/overview" ,  "POST", true, courseId);
    } catch (err) {
      throw err;
    }
  }
  async getUsersTypes(courseId) { 
    try {
      return await util.sendApiRequest("/course/analytics/userType" ,  "POST", true, courseId);
    } catch (err) {
      throw err;
    }
  }
  async getYearlyViews(courseId) {
    try {
      return await util.sendApiRequest("/course/analytics/view" ,  "POST", true, courseId);
    } catch (err) {
      throw err;
    }
  }
  async getYearlyUsers(courseId) {
    try {
      return await util.sendApiRequest("/course/analytics/user" ,  "POST", true, courseId);
    } catch (err) {
      throw err;
    }
  }
  deleteCourse(id) {
    return util.sendApiRequest("/course/" + id, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  activeToggle(payload) {
    return util
      .sendApiRequest("/course/active", "PUT", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  addDraft(payload) {
    return util
      .sendApiRequest("/course/draft", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  editDraft(payload) {
    return util
      .sendApiRequest("/course/draft", "PUT", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  async draftList(data) {
    try {
      const course = Object.keys(data).reduce((object, key) => {
        if (data[key] !== "") {
          object[key] = data[key];
        }
        return object;
      }, {});
      return await util.sendApiRequest(
        "/course/draft/list",
        "POST",
        true,
        course
      );
    } catch (err) {
      throw err;
    }
  }

  async getDraftDetails(id) {
    try {
      return await util.sendApiRequest("/course/draft/" + id, "GET", true);
    } catch (err) {
      throw err;
    }
  }

  deleteDraft(dataId) {
    return util.sendApiRequest("/course/draft/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  activeToggleDraft(payload) {
    return util
      .sendApiRequest("/course/draft/active", "PUT", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  listCount(condition){
    return util
    .sendApiRequest("/course/courseCount", "POST", condition)
    .then((response)=>{
      return response;
    })
    .catch((err)=>{
      throw err;
    })
  }
  async getYearlyShares(blogId) {
    try {
      return await util.sendApiRequest("/course/analytics/share" ,  "POST", true, blogId);
    } catch (err) {
      throw err;
    }
  }
}
