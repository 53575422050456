import FaqService from "../../services/FaqService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import FaqForm from "./FaqForm";
import{useState, useEffect} from "react"
const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const columns = [
  {
    label: "Published On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  {
    label: "Question",
    key: "question",
    cssTd: "white_pre",
    cssTh: "white_pre",
  },
  {
    label: "Answer",
    key: "answer",
    cssTd: "white_pre",
    cssTh: "white_pre",
    maxLength: 150,
  },
  {
    label: "Published by",
    key: "createdBy",
  },
  { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "question--asc", value: "A to Z" },
  { key: "question--desc", value: "Z to A" },
];


export default function Faqs() {
  const faqServ = new FaqService();
  const[totalCount, setTotalCount]= useState(0)
  const[monthTotal, setMonthTotal]= useState(0)
  useEffect(()=>{
    getFaqCount()
  },[])
  const getFaqCount = async()=>{
    try {
      let response = await faqServ.listCount({})
       setTotalCount(response.totalCount)
       setMonthTotal(response.monthTotal)
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Total Faqs",
      icon: "/images/glossary/ask_question.svg",
      value: totalCount,
      footer: `This month: ${monthTotal}`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "faq"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await faqServ.listAll(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await faqServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (careerObj) => {
    let obj = {
      _id: careerObj._id,
      is_active: !careerObj.is_active,
    };
    try {
      let response = await faqServ.editRecord(obj);
      if (response) {
        return response;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search faqs"
        buttonText="Add a Faq"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={FaqForm}
        allowPopEdit={true}
      />
    </div>
  );
}
