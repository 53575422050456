import React, { useEffect, useState } from "react"
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService"
import AddSlider from "./AddSlider";
export default function SliderSetting() {
    const settingServ = new SettingService();
    const [value, setValue] = useState({
        slider_image: "",
        campaighn_name: "",
        link: "",
    });
    const [settingId, setSettingId] = useState("")
    const [sliders, setSliders] = useState([{ ...value }]);
    const [currSliderIdx, setCurrSliderIdx] = useState(0);
    useEffect(() => {
        getSettingsData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSettingsData = async () => {
        try {
            let response = await settingServ.getDetails();
            if (response) {
                if (response.data.sliders.length === 0) {
                    response.data.sliders.push({ ...value });
                }
                setSliders(response.data.sliders);
                setSettingId(response.data._id)
            } else {
            }
        } catch (err) {
        }
    };
    const removeSlider = async (idx) => {
        try {
            let response = await settingServ.removeSlider(idx);
            if (response.message) {
                if (currSliderIdx == sliders.length - 1) {
                    setCurrSliderIdx(currSliderIdx - 1)
                }
                getSettingsData();
            } else {
            }
        } catch (err) {
        }
    };

    const ValidateSchema = Yup.object().shape({
        sliders: Yup.array().of(
            Yup.object().shape({
                image: Yup.string().required("Required"),
                campaighn_name: Yup.string().required("Required"),
                link: Yup.string().required("Required"),
            })
        ),
    });

    const addSlider = () => {
        let ls = [...formik.values];
        ls.push({ ...value });
        setCurrSliderIdx(formik.values.length);
        formik.setValues([...ls]);
    };

    const onSubmit = (values, { resetForm }) => {
        const formData = new FormData();
        formData.append("slider_image", values.slider_image);
        values.forEach((element, idx) => {
            if (typeof element.slider_image !== "string") {
                formData.append("slider_image_" + idx, element.slider_image)
            }
        });
        formData.append("sliders", JSON.stringify(values));
        if (settingId) {
            formData.append("_id", settingId);
            settingServ.updateRecord(formData).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("Settings added successfully");
                }
            });
        } else {
            settingServ.addrecord(formData).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("Settings added successfully");
                }
            });
        }
    };

    const formik = useFormik({
        initialValues: sliders,
        validateOnBlur: true,
        onSubmit,
        validationSchema: ValidateSchema,
        enableReinitialize: true,
    });
    return (
        <>
            <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
                <div className="commonCart account_page">
                    <div className="stepFormHead d-flex align-items-center">
                        <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
                            <div className="createCourseHeading">
                                <h4>Settings | Home Sliders</h4>
                            </div>
                        </div>
                    </div>
                    <div className="userProfile_details">
                        <div className="settingGeneral">
                            <div className="lesson_btn comPadding">
                                <ul className="nav">
                                    {formik.values.map((item, idx) => (
                                        <li onClick={() => setCurrSliderIdx(idx)}>
                                            <a href="javascript:void(0)" className={currSliderIdx === idx ? "active" : ""}>Slider {idx + 1}</a>
                                        </li>

                                    ))}
                                    {/* <li><a href="#slidertwo" data-bs-toggle="tab" role="tab">Slider 2</a></li>
                                    <li><a href="#sliderthree" data-bs-toggle="tab" role="tab">Slider 3</a></li>
                                    <li><a href="#sliderfour" data-bs-toggle="tab" role="tab">Slider 4</a></li> */}
                                    <li onClick={addSlider}>
                                        <a href="javascript:void(0)"><i className="fa fa-plus me-1" aria-hidden="true" /> Add Slider</a>
                                    </li>
                                </ul>
                            </div>
                            <div className="tab-content">
                                <form onSubmit={formik.handleSubmit}>
                                    <AddSlider sliderIdx={currSliderIdx} formik={formik} removeSlider={removeSlider} />
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <ToastContainer />
        </>
    )
} 