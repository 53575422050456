import GlossaryService from "../../services/GlossaryService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import AddGlossaryPopup from "./AddGlossaryPopup";
import { useEffect, useState } from "react";
const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const columns = [
  {
    label: "Published On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Word", key: "word" },
  {
    label: "Definition",
    key: "definition",
    cssTh: "white_pre",
    cssTd: "white_pre",
  },
  {
    label: "Published by",
    key: "createdBy",
  },
  {
    label: "Likes",
    key: "",
    altValue: "000",
  },
  { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "word--asc", value: "A to Z" },
  { key: "word--desc", value: "Z to A" },
];

function Glossary() {
  const glossaryServe = new GlossaryService();
  const[totalCount, setTotalCount] = useState(0);
  const[publiSubCount, setPublicSubCount] = useState(0);
  const[monthTotal, setMonthTotal] = useState(0);
  
const cards = [
  {
    title: "Glossary Count",
    icon: "/images/glossary/Glossary_count.svg",
    value: totalCount,
    footer: `+${monthTotal} this month`,
  },
  {
    title: "Public Submissions",
    icon: "/images/glossary/public_submission.svg",
    value: publiSubCount,
    footer: "View submissions",
    link: "/public_submission/",
  },
];
  useEffect(()=>{
    getGlossaryCount()
  })
  const getGlossaryCount = async()=>{
    try {
      let response = await glossaryServe.listCount({})
       setTotalCount(response.totalCount)
       setMonthTotal(response.monthTotal)
       setPublicSubCount(response.publicSubCount)
    } catch (error) {
      console.log(error)
    }
  }
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        hasCreatedBy: search.hasCreatedBy,
        searchText: search.searchTxt,
        approved: true,
        searchId:
          contextSearch.searchDtl &&
          contextSearch.searchDtl.type === "toglossary"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    try {
      let response = await glossaryServe.listAllGlossary(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await glossaryServe.deleteGlossaryRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  
  const activeToggleHandler = async (glossary) => {
    const obj = {
      _id: glossary._id,
      word: glossary.word,
      definition: glossary.definition,
      is_active: !glossary.is_active,
    };
    try {
      const res = await glossaryServe.editGlossaryRecord(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search glossary"
        buttonText="Add a Word"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={AddGlossaryPopup}
        allowPopEdit={true}
      />
    </div>
  );
}

export default Glossary;
