import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import NewsSubscriptionService from "../../services/NewsSubscriptionService";
import { Navigate, useNavigate } from "react-router-dom";

function SubscribePopup({ onClose, onSuccess, onFail, id }) {
  const navigate = useNavigate();
  const newsSubscriptionServ = new NewsSubscriptionService();
  const [showLoading, setShowLoading] = useState(false);
  const [value, setValue] = useState({
    _id: "",
    name: "",
    email: "",
    country: "",
  });
  useEffect(() => {
    if (id) {
      getSubscriberDetails(id);
    }
  }, []);
  const getSubscriberDetails = async (id) => {
    try {
      let response = await newsSubscriptionServ.getNewsDetails(id);
      if (response) {
        setValue({
          _id: id,
          name: response.data.name,
          email: response.data.email,
          country: response.data.country,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };
  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required("Name is a required field"),
    email: Yup.string().required("Email is a required field "),
    // country: Yup.string().required("Country a required field"),
  });

  const onSubmit = async (values, { resertForm }) => {
    setShowLoading(true);
    let obj = {
      name: values.name,
      email: values.email,
      country: values.country,
    };
    if (values._id) {
      newsSubscriptionServ.editrecord(values).then((res) => {
        if (res.err) {
          onFail(res.err[0]);
        } else {
          onSuccess();
        }
      });
    } else {
      delete values._id;
      let response;
      response = await newsSubscriptionServ.addrecord(obj);
      if (response.err) {
        onFail(response.err[0]);
      } else {
        onSuccess();
      }
    }
    setShowLoading(false);
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel newsletterSubscriber"
      id="newsletterSubscriber"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="images/icons/user.svg" alt="icons" className="img-fluid" />{" "}
                  <span>Add a Newsletter Subscriber</span>
                </h4>
                <button type="button" className="btn-close" data-bs-dismiss="modal" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Full Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Please enter the full name"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="formik-errors bg-error">{formik.errors.name}</div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Email Address
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Please enter the email address"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">{formik.errors.email}</div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="country" className="form-label">
                          Country
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="country"
                          placeholder="Please enter the country"
                          name="country"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.country}
                        />
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Add Subscriber</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SubscribePopup;
