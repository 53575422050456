import { useState, useEffect } from "react";
export default function AddSlider({ sliderIdx, formik, removeSlider }) {
  const [showLoading, setShowLoading] = useState(true);
  useEffect(() => {
    setShowLoading(true);
    console.log(sliderIdx);
    setTimeout(() => {
      setShowLoading(false);
    }, 500);
  }, [sliderIdx]);
  return (
    <>
      <div id="sliderone" className="tab-pane fade show active">
        <div className="comPadding pt-0">
          <div className="row">
            <div className={" col-md-8"}>
              {formik.values[sliderIdx].slider_image ? (
                typeof formik.values[sliderIdx].slider_image === "string" ? (
                  <>
                    {!showLoading ? (
                      <img
                        className={"sliderImg mb-4 mb-md-0"}
                        src={formik.values[sliderIdx].slider_image}
                      />
                    ) : (
                      <div className="bannerImg"><img src={"/images/icons/loading.gif"}/></div>
                    )}
                  </>
                ) : (
                  <img
                    className="sliderImg mb-4 mb-md-0"
                    src={URL.createObjectURL(formik.values[sliderIdx].slider_image)}
                  />
                )
              ) : (
                <div className="sliderImg mb-4 mb-md-0"></div>
              )}
            </div>
            <div className="col-md-4">
              <div className="sliderLinkSec">
                <div className="sliderLink">
                  <div className="mb-4 account_input">
                    <label htmlFor="name" className="form-label">
                      Campaign Name
                    </label>
                    {/* <input type="text" className="form-control" id="question" placeholder="Please enter the link here" /> */}
                    <input
                      type="text"
                      className="form-control"
                      id="name"
                      placeholder="Please enter the link here"
                      name={"[" + sliderIdx + "].campaighn_name"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[sliderIdx].campaighn_name}
                    />
                    {formik.errors && formik.errors[sliderIdx] && formik.errors[sliderIdx].campaighn_name ? (
                      <div className="formik-errors bg-error">{formik.errors[sliderIdx].campaighn_name}</div>
                    ) : null}
                  </div>
                  <div className="mb-4 account_input">
                    <label htmlFor="question" className="form-label">
                      Link to
                    </label>
                    {/* <input type="text" className="form-control" id="question" placeholder="Please enter the link here" /> */}
                    <input
                      type="text"
                      className="form-control"
                      id="question"
                      placeholder="Please enter the link here"
                      name={"[" + sliderIdx + "].link"}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values[sliderIdx].link}
                    />
                    {formik.errors && formik.errors[sliderIdx] && formik.errors[sliderIdx].link ? (
                      <div className="formik-errors bg-error">{formik.errors[sliderIdx].link}</div>
                    ) : null}
                  </div>
                </div>
                <div className="sliderBtn">
                  <div className="commonform_btn d-grid mb-3">
                    <label
                      htmlFor="sliderImage"
                      className="btn btn-outline-mute btn-block"
                      style={{ lineHeight: "30px" }}
                    >
                      Change Image
                    </label>
                    <input
                      className="hidden"
                      type="file"
                      id="sliderImage"
                      name={"[" + sliderIdx + "].slider_image"}
                      onChange={(event) => {
                        formik.setFieldValue("[" + sliderIdx + "].slider_image", event.currentTarget.files[0]);
                      }}
                    />
                  </div>
                  <div className="commonform_btn d-grid">
                    <button
                      onClick={() => removeSlider(sliderIdx)}
                      type="button"
                      className="btn btn-outline-danger btn-block"
                    >
                      Delete
                    </button>
                  </div>
                  <hr className="horiz" />
                  <div className="commonform_btn d-grid">
                    <button type="submit" className="btn btn-outline-primary btn-block">
                      Save
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}
