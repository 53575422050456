import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService";
import AddSeoPage from "./AddSeoPage";
import Dropify from "../../shared/Dropify/Dropify";
export default function NewsletterSetting() {
  const settingServ = new SettingService();
  const [pageType, setPageType] = useState("home");
  const [value, setValue] = useState({
    smtp_active: false,
    smtp_host: "",
    smtp_user_name: "",
    smtp_password: "",
    newsletter_charset: "",
    site_url: "",
    newsletter_directory: "",
    unsebscribe_link: false,
  });
  useEffect(() => {
    getSettingsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingsData = async () => {
    try {
      let response = await settingServ.getDetails();
      if (response.data) {
        setValue({
          _id: response.data._id,
          smtp_active: response.data?.newsletter?.smtp_active || false,
          smtp_host: response.data?.newsletter?.smtp_host || "",
          smtp_user_name: response.data?.newsletter?.smtp_user_name || "",
          smtp_password: response.data?.newsletter?.smtp_password || "",
          newsletter_charset:
            response.data?.newsletter?.newsletter_charset || "",
          site_url: response.data?.newsletter?.site_url || "",
          newsletter_directory:
            response.data?.newsletter?.newsletter_directory || "",
          unsebscribe_link:
            response.data?.newsletter?.unsebscribe_link || false,
        });
      } else {
      }
    } catch (err) { }
  };

  const ValidateSchema = Yup.object().shape({
    smtp_active: Yup.string().required("Required"),
    smtp_host: Yup.string().required("Required"),
    smtp_user_name: Yup.string().required("Required"),
    smtp_password: Yup.string().required("Required"),
    newsletter_charset: Yup.string().required("Required"),
    site_url: Yup.string().required("Required"),
    newsletter_directory: Yup.string().required("Required"),
    unsebscribe_link: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    const formData = new FormData();
    formData.append("newsletter", JSON.stringify(values));
    if (values._id) {
      formData.append("_id", values._id);
      settingServ.updateRecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    } else {
      settingServ.addrecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
        <div className="commonCart account_page">
          <div className="stepFormHead d-flex align-items-center">
            <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
              <div className="createCourseHeading">
                <h4>Settings | Newsletter</h4>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="userProfile_details">
              <div className="settingGeneral">
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Use SMTP Server</h6>
                        <p>If turned off, php’s mail function will be used.</p>
                      </div>
                    </div>
                    <div className=" col-md-8">
                      <div className="col-sm-2 col-xl-1">
                        <p className="mb-1 mb-xl-2 offCheckHeading">
                          {formik.values.smtp_active ? "On" : "OFF"}
                        </p>
                        <label className="switch">
                          <input
                            type="checkbox"
                            className="form-control"
                            id="smtp_active"
                            name="smtp_active"
                            onChange={formik.handleChange}
                            checked={formik.values.smtp_active}
                          />
                          <span className="slider round" />
                        </label>
                      </div>
                      <div className="mb-4 account_input">
                        <label htmlFor="smtp_host" className="form-label">
                          SMTP Host{" "}
                        </label>
                        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="smtp_host"
                          placeholder="Please enter the SMTP Host"
                          name={`smtp_host`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.smtp_host}
                        />
                        {formik.touched.smtp_host && formik.errors.smtp_host ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.smtp_host}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 account_input">
                        <label htmlFor="smtp_user_name" className="form-label">
                          SMTP Username{" "}
                        </label>
                        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="smtp_user_name"
                          placeholder="Please enter the SMTP Username"
                          name={`smtp_user_name`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.smtp_user_name}
                        />
                        {formik.touched.smtp_user_name &&
                          formik.errors.smtp_user_name ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.smtp_user_name}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 account_input">
                        <label htmlFor="smtp_password" className="form-label">
                          SMTP Password{" "}
                        </label>
                        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="smtp_password"
                          placeholder="Please enter the SMTP Password"
                          name={`smtp_password`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.smtp_password}
                        />
                        {formik.touched.smtp_password &&
                          formik.errors.smtp_password ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.smtp_password}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 account_input">
                        <label
                          htmlFor="newsletter_charset"
                          className="form-label"
                        >
                          Newsletter Charset{" "}
                        </label>
                        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="newsletter_charset"
                          placeholder="Please enter the newsletter charset"
                          name={`newsletter_charset`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.newsletter_charset}
                        />
                        {formik.touched.newsletter_charset &&
                          formik.errors.newsletter_charset ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.newsletter_charset}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 account_input">
                        <label htmlFor="site_url" className="form-label">
                          Site URL{" "}
                        </label>
                        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="smtp_host"
                          placeholder="Please enter the Site URL"
                          name={`site_url`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.site_url}
                        />
                        {formik.touched.site_url && formik.errors.site_url ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.site_url}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-4 account_input">
                        <label
                          htmlFor="newsletter_directory"
                          className="form-label"
                        >
                          Newsletter Directory
                        </label>
                        {/* <input type="text" className="form-control mb-1" id="Title" placeholder="Please enter the homepage title" /> */}
                        <input
                          type="text"
                          className="form-control mb-1"
                          id="newsletter_directory"
                          placeholder="Please enter the Newsletter directory"
                          name={`newsletter_directory`}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.newsletter_directory}
                        />
                        {formik.touched.newsletter_directory &&
                          formik.errors.newsletter_directory ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.newsletter_directory}
                          </div>
                        ) : null}
                      </div>
                      <div className="col-sm-6 col-xl-6">
                        {/* <div className="option_div">
                                                <div className="mb-4 account_input"> */}
                        <label className="form-label">
                          Send Unsubscribe Link
                        </label>
                        <br />
                        {/* <div className="d-flex optionCheck align-items-center"> */}
                        <input
                          type="checkbox"
                          name="unsebscribe_link"
                          onChange={formik.handleChange}
                          checked={formik.values.unsebscribe_link}
                        />
                        {/* <label
                                                            htmlFor="unsebscribe_link"
                                                        ></label> */}
                      </div>
                      {/* </div> */}
                      {/* </div> */}
                      {/* </div> */}
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
              </div>
              <div className="commonform_btn pt-0 comPadding d-grid col-12">
                <button type="submit" className={"btn btn-primary btn-block " +
                  (!(formik.isValid && formik.dirty)
                    ? " disabled"
                    : "")}>Save</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
