import React, { useState, useEffect, useContext } from "react";
import { toast, ToastContainer } from "react-toastify";
import GlobalContext from "../../context/GlobalContext";
import Table from "../Table/Table";
import SearchBlockNew from "../SearchBlock/SearchBlockNew";
import ConfirmDeletePopup from "../ConfirmDeletePopup/ConfirmDeletePopup";

export default function ListGrid({
  placeholder,
  buttonLink,
  buttonText,
  CardComponent,
  sortByOptions,
  getListDataFunc,
  viewHandlerFunc,
  deleteHandlerFunc,
  editHandlerFunc,
  activeToggleHandlerFunc,
  PopupComponent,
  allowPopEdit,
  perPage,
  closeBtn
}) {
  const sitectx = useContext(GlobalContext);
  const [list, setList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeletePop, setShowDeletePop] = useState(false);

  const [search, setSearch] = useState({
    start: 0,
    perPage: perPage ? perPage : 12,
    searchTxt: "",
    searchField: "",
    sortBy: { createdAt: "desc" },
  });

  const handlePaging = (pageToView) => {
    search.start = parseInt(pageToView) * search.perPage - search.perPage;
    setSearch(JSON.parse(JSON.stringify(search)));
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let pagination = { current: active, total: pages };

  function searchData(e) {
    search.searchTxt = e.target.value;
    search.start = 0;
    setSearch(JSON.parse(JSON.stringify(search)));
  }

  function setIncrementalList(list) {}

  function searchSortBy(e) {
    const [key, value] = e.target.value.split("--");
    search.sortBy = {};
    search.sortBy[key] = value;
    search.start = 0;
    setSearch(JSON.parse(JSON.stringify(search)));
  }

  useEffect(() => {
    getListDataFunc(search, sitectx, setList, setTotalCount);
  }, [search, sitectx.searchDtl && sitectx.searchDtl.value]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (PopupComponent) {
      setShowPopup(sitectx.popup.showPopup);
    }
  }, [sitectx.popup.showPopup]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteHandlerShowPopup = (id) => {
    setShowDeletePop(true);
    setDeleteId(id);
  };

  const deleteHandler = async (isConfirmed) => {
    let deleted = await deleteHandlerFunc(isConfirmed, deleteId);
    if (deleted) {
      toast.success(deleted.message);
      getListDataFunc(search, sitectx, setIncrementalList, setTotalCount); // eslint-disable-line react-hooks/exhaustive-deps
    } else {
      toast.error("Something went wrong!");
    }
    setShowDeletePop(false);
  };

  const activeToggleHandler = async (obj, index) => {
    let changed = await activeToggleHandlerFunc(obj);
    if (changed) {
      toast.success(changed.message);
      let records = JSON.parse(JSON.stringify(list));
      records[index].is_active = !records[index].is_active;
      setList(records);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const buttonClickHandler = async () => {
    if (PopupComponent) {
      setEditId(null);
      setShowPopup(true);
    }
  };

  return (
    <>
      <div className="commonCart coursePublishpage">
        <SearchBlockNew
          placeholder={placeholder}
          buttonLink={buttonLink}
          closeBtn={closeBtn}
          buttonClick={buttonClickHandler}
          buttonText={buttonText}
          searchData={searchData}
          searchSortBy={searchSortBy}
          sortByOptions={sortByOptions}
        />
        {list.length > 0 ? (
          <div className="row">
            {list.map((item) => (
              <CardComponent
                data={item}
                deleteHandlerShowPopup={deleteHandlerShowPopup}
                activeToggleHandler={activeToggleHandler}
                allowPopEdit={allowPopEdit}
                setEditId={setEditId}
                setShowPopup={setShowPopup}
                deleteHandler={deleteHandler}
                viewHandlerFunc={viewHandlerFunc}
                editHandlerFunc={editHandlerFunc}
              />
            ))}
          </div>
        ) : (
          <div className="searchingSection">
            <img src="/images/glossary/Searching.svg" />
          </div>
        )}
      </div>
      <ToastContainer />
      {showPopup && (
        <PopupComponent
          onClose={() => {
            setShowPopup(false);
          }}
          onFail={(errorMsg) => {
            toast.error(errorMsg);
          }}
          onSuccess={() => {
            setShowPopup(false);
            getListDataFunc(search, sitectx, setList, setTotalCount);
            toast.success("Record updated sucessfully");
          }}
          id={editId}
        />
      )}
      {showDeletePop && (
        <ConfirmDeletePopup
          onSucess={deleteHandler}
          onClose={() => {
            setShowDeletePop(false);
          }}
        />
      )}
    </>
  );
}
