import React from "react";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import SendNotification from "./SendNotification";
import NotificationService from "../../services/NotificationService";
import ListNew from "../../shared/List/ListNew";
import { useState } from "react";
import { useEffect } from "react";
const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const lineBreakBody = (value, index) => {
  if(value.content?.length>40){
    return(
      <p style={{whiteSpace:" break-spaces"}} className="mb-0">{value.content}</p>
    )
  }
  else{
    return(
      value.content
      )
  }
};
const columns = [
  {
    label: "Date & Time",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Heading Text", key: "title" },
  {
    label: "Body Text",
    key: "content",
    valueFunc: lineBreakBody,
    altValue: "000",
  },
  {
    label: "Published by",
    key: "createdBy",
    altValue: "000",
  },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "title--asc", value: "A to Z" },
  { key: "title--desc", value: "Z to A" },
];

function NotificationsList({ type, setNotificationTotal }) {
  // to set the no. of notification count
  // const [notificationCount, setNotificationCount] = useState(0);

  const navigate = useNavigate();
  const notificationServ = new NotificationService();

  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        type: type,
        notiPage: true,
        searchId:
          contextSearch.searchDtl &&
            contextSearch.searchDtl.type === "notification"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await notificationServ.listAll(activity);
      if (response) {
        setList(response.data);
        setNotificationTotal(response.total);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await notificationServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (careerObj) => {
    let obj = {
      _id: careerObj._id,
      is_active: !careerObj.is_active,
    };
    try {
      let response = await notificationServ.updateRecord(obj);
      if (response?.data?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  return (
    <div className="commonCart">
      <ListNew
        placeholder="Search web app aplications"
        buttonText="Send a Notification"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        PopupComponent={SendNotification}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        allowPopEdit={true}
        topRadius0={true}
        type={type}
      />
    </div>
  );
}

export default NotificationsList;
