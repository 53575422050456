import util from "../util/util";
import axios from "axios";
export default class UserService {
  login(obj) {
    return util
      .sendApiRequest("/adminlogin", "POST", true, obj)
      .then(
        (response) => {
          if (!response.error) {
            localStorage.setItem("user", JSON.stringify(response));
            window.user = response;
            return window.user;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  loginOtp(values) {
    return util
      .sendApiRequest("/login/otp", "POST", true, values)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
  logout() {
    localStorage.clear();
    window.user = null;
  }

  async forgotPassword(forgotpassword) {
    try {
      return await util.sendApiRequest("/forgot", "POST", true, forgotpassword);
    } catch (err) {
      throw err;
    }
  }

  resetPassword(payload) {
    return util
      .sendApiRequest("/reset", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }
  async adminChangePassword(payload) {
    return util
      .sendApiRequest("/adminchangepassword", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }
  async resetUser(payload) {
    return util
      .sendApiRequest("/resetuser", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((e) => {
        throw e;
      });
  }

  async changePassword(payload) {
    // return util
    //   .sendApiRequest("/changepassword", "POST", true, payload)
    //   .then((response) => {
    //     return response;
    //   })
    //   .catch((e) => {
    //     throw e;
    //   });
    const token = window.user ? window.user.token : "no-token";
    const config = {
      headers: {
        content: "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.post(process.env.REACT_APP_API_BASEURL + "/user/changepassword", payload, config);
      if (response.err) {
        throw new Error(response.err);
      } else {
        return response;
      }
    } catch (err) {
      throw err;
    }
  }
  // }

  userListApi(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  deleteUserRecordApi(dataId) {
    return util.sendApiRequest("/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  getUser(dataId) {
    return util
      .sendApiRequest("/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  async edit(payload) {
    const token = window.user ? window.user.token : "no-token";
    const config = {
      headers: {
        content: "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };
    try {
      const response = await axios.put(process.env.REACT_APP_API_BASEURL + "/user", payload, config);
      if (response.err) {
        throw new Error(response.err);
      } else {
        return response;
      }
    } catch (err) {
      throw err;
    }
  }
  listAllSearchData(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/getsearchdata", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }

  userList(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  listCount(condition) {
    return util
      .sendApiRequest("/user/userCount", "POST", condition)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  verifyForgotPasswordOtp(loginObj) {
    return util
      .sendApiRequest("/user/verifyotp", "POST", true, loginObj)
      .then(
        (response) => {
          if (!response.error) {
            return response;
          } else {
            return response;
          }
        },
        (error) => {
          throw new Error(error);
        }
      )
      .catch((e) => {
        throw e;
      });
  }
}
