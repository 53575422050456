import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService";
import PolicyService from "../../services/PolicyService";
import AddPolicy from "./AddPolicy";
import DraggableOrder from "../../shared/Draggable/DraggableOrder";
export default function PoliciesSetting() {
  const settingServ = new SettingService();
  const policyServ = new PolicyService();
  const [value, setValue] = useState({
    title: "",
    author: "",
    body: "",
  });
  const [settingId, setSettingId] = useState("");
  const [policies, setPolicies] = useState([]);
  const [currPolicyId, setCurrPolicyId] = useState(0);
  const [allowList, setAllowList] = useState(true);
  useEffect(() => {
    getSettingsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingsData = async () => {
    try {
      let response = await policyServ.listAllTitle({});
      if (response) {
        setPolicies(response.data);
        setSettingId(response.data._id);
      } else {
      }
    } catch (err) {
      console.log(err);
    }
  };
  // const removeSlider = async (idx) => {
  //     try {
  //         let response = await settingServ.removeSlider(idx);
  //         if (response.message) {
  //             getSettingsData();
  //         } else {
  //         }
  //     } catch (err) {
  //     }
  // };
  const handleChangeOrder = async (newList) => {
    try {
      setPolicies(newList);
      for (let i = 0; i < newList.length; i++) {
        const obj = newList[i];
        if (obj._id) {
          policyServ.editRecord(obj).then((res) => {
            if (res.err) {
            } else {
            }
          });
        } else {
          policyServ.addRecord(obj).then((res) => {
            if (res.err) {
            } else {
            }
          });
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const addPolicy = () => {
    setAllowList(false);
    let ls = [...policies];
    ls.push({ ...value, sort_order: ls.length });
    // setCurrPolicyIdx(policies.length);
    setPolicies([...ls]);
    setAllowList(true);
  };
  return (
    <>
      <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
        <div className="commonCart account_page">
          <div className="stepFormHead d-flex align-items-center">
            <div className="middleBox">
              <div className="createCourseHeading">
                <h4>Settings | Policies</h4>
              </div>
            </div>
          </div>
          <div className="userProfile_details">
            <div className="lesson_btn comPadding">
              <ul className="nav custom-nav">
                {policies.length !== 0 && allowList ? (
                  <DraggableOrder
                    itemList={policies}
                    setCurrId={setCurrPolicyId}
                    currId={currPolicyId}
                    handleChangeOrder={handleChangeOrder}
                  />
                ) : (
                  ""
                )}
                <li onClick={addPolicy}>
                  <a href="javascript:void(0)">
                    <i className="fa fa-plus me-1" aria-hidden="true" /> Add Policy
                  </a>
                </li>

                {/* <li><a href="#tabRisks" data-bs-toggle="tab" role="tab" className="active">Risks</a></li>
                                <li><a href="#tabDisclaimer" data-bs-toggle="tab" role="tab">Disclaimer</a></li>
                                <li><a href="#cookiesPolicy" data-bs-toggle="tab" role="tab">Cookies Policy</a></li>
                                <li><a href="#PrivacyPolicy" data-bs-toggle="tab" role="tab">Privacy Policy</a></li>
                                <li><a href="#termsofService" data-bs-toggle="tab" role="tab"> Terms of Service</a></li> */}
              </ul>
            </div>
            <div className="tab-content">
              <div id="tabRisks" className="tab-pane fade show active">
                <div className="createcourseStep2 comPadding pt-0">
                  <AddPolicy policyId={currPolicyId} getSettingsData={getSettingsData} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
