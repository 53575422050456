import { Editor } from "@tinymce/tinymce-react";

const AddLesson = ({ lesnIdx, formik }) => {
  return (
    <div className="createcourseStep2 comPadding pt-0">
      <div className="row">
        <div className="col-12">
          <div className="mb-4 account_input">
            <label htmlFor="LessonHeading" className="form-label marginBottom10">
              Lesson Heading
            </label>
            <input
              type="text"
              className="form-control"
              id="LessonHeading"
              placeholder="Please enter the heading"
              name={"lessons[" + lesnIdx + "].lesson"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lessons[lesnIdx]?.lesson}
            />
            {formik.errors.lessons &&
            formik.errors.lessons[lesnIdx] &&
            formik.errors.lessons[lesnIdx].lesson ? (
              <div className="formik-errors bg-error">
                {formik.errors.lessons[lesnIdx].lesson}
              </div>
            ) : null}
          </div>
        </div>
        <div className="col-12">
          <div className="mb-4 account_input modelFormInput">
            <label htmlFor="lessonBody" className="form-label marginBottom10">
              Lesson Body
            </label>
            {/* <textarea
              className="form-control"
              placeholder="Please enter the lesson title"
              rows={7}
              name={"lessons[" + lesnIdx + "].desc"}
              onChange={formik.handleChange}
              onBlur={formik.handleBlur}
              value={formik.values.lessons[lesnIdx].desc}
            /> */}
            <Editor
              apiKey={window.tinyAPIKEY}
              init={{
                plugins:
                  "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                toolbar:
                  "undo redo | bold italic underline strikethrough |fullscreen|link image media table | blocks fontfamily fontsize |  align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
              }}
              value={formik.values.lessons[lesnIdx]?.desc}
              onEditorChange={(e) =>
                formik.handleChange({
                  target: { name: "lessons[" + lesnIdx + "].desc", value: e },
                })
              }
            />
            {formik.errors.lessons &&
            formik.errors.lessons[lesnIdx] &&
            formik.errors.lessons[lesnIdx].desc ? (
              <div className="formik-errors bg-error">
                {formik.errors.lessons[lesnIdx].desc}
              </div>
            ) : null}
          </div>
        </div>
      </div>
      <div className="commonform_btn d-grid col-12">
        <button
          type="submit"
          className={
            "btn btn-primary btn-block" +
            ((formik.errors.lessons &&
              formik.errors.lessons[lesnIdx] &&
              Object.keys(formik.errors.lessons[lesnIdx]).length !== 0) ||
            (formik.touched.lessons &&
              formik.touched?.lessons[lesnIdx] &&
              Object.keys(formik.touched.lessons[lesnIdx]).length === 0)
              ? " disabled"
              : "")
          }
        >
          Save &amp; Continue
        </button>
      </div>
    </div>
  );
};

export default AddLesson;
