import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

export default function Login({ handleAuthState, showStep }) {
  const userService = new UserService();
  const formValue = {
    email: "",
    password: "",
  };

  const [showPassword, setShowPassword] = useState(false);
  const [showSubBtn, setShowSubBtn]=useState(true)
  const onSubmit = (values) => {
    setShowSubBtn(false)
    const enteredUserEmail = values.email;
    const enteredUserPassword = values.password;
    const formData = {
      email: enteredUserEmail,
      password: enteredUserPassword,
    };
    const loginPayload ={
      email : values.email,
      password : values.password,
      loginThrow : "admin",
    }
    userService.loginOtp(loginPayload)
    .then((res) => {
      if (res?.message == "OTP sent successfully") {
        localStorage.setItem("userTemp", JSON.stringify(formData));
        showStep(2)
        handleAuthState(true);
        setShowSubBtn(true)
      } else {
        toast.error(res.error);
      }
    })
    .catch((err) => {
        setShowSubBtn(true)
        toast.error(err.message);
      });
  };

  const ValidateSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
    password: Yup.string().required("Required"),
  });
  const formik = useFormik({
    initialValues: formValue,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="w-100 clearfix betaEnterPassword" id="betaEnterPassword">
        <div className="w-100 betaEnterPasswordInner d-flex align-items-center justify-content-between">
          <div className="card betaPasswordCard">
            <div className="card-header bg-white">
              <ul className="nav nav-pills bulletNav">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    onClick={() => {
                      showStep(1);
                    }}
                  />
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    // onClick={() => {
                    //   showStep(2);
                    // }}
                  />
                </li>
              </ul>
            </div>
            <div className="tabContent">
              <div className="tab-pane loginPanel" id="loginPanel">
                <div className="card-body">
                  <div className="betaLogo text-center">
                    <img
                      src="images/login-model-logo.svg"
                      alt="betalogo"
                      className="img-fluid"
                    />
                  </div>
                  <div className="middelContant">
                    <h4>Login to Admin Panel</h4>
                    <p className>Only for MaveFi Employees &amp; Partners</p>
                    <form action id="loginForm" onSubmit={formik.handleSubmit}>
                      <div className="mb-3 mt-3">
                        <label htmlFor="email" className="form-label">
                          Email address
                        </label>
                        <div className={"input-group" + (formik.touched.email && formik.errors.email ? " borderRed" : "")}>
                          <span className="input-group-text bg-white betaLock border-0 pe-1">
                            <img
                              src="images/icons/mail.svg"
                              alt="lock"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type="email"
                            className={"form-control passInput border-0"}
                            placeholder="john@mavefi.com"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                        </div>
                        {/* {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.email}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="pwd" className="form-label">
                          Password
                        </label>
                        <div className={"input-group" + (formik.touched.password && formik.errors.password ? " borderRed" : "")}>
                          <span className="input-group-text bg-white betaLock border-0 pe-1">
                            <img
                              src="images/icons/lock.svg"
                              alt="lock"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type={showPassword ? "test" : "password"}
                            className="form-control passInput border-0"
                            placeholder="*************"
                            name="password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                          />
                          <span className="input-group-text bg-white betaEye border-0">
                            <img
                              src="images/icons/hide-eye.svg"
                              alt="eye-hide"
                              className="img-fluid cursor"
                              onClick={() => {
                                formik.values.password &&
                                  setShowPassword(!showPassword);
                              }}
                            />
                          </span>
                        </div>
                        {/* {formik.touched.password && formik.errors.password ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.password}
                          </div>
                        ) : null} */}
                      </div>
                      <div className="mb-3 rememberFormSec d-flex justify-content-between align-items-center">
                        <div className="form-check">
                          <label className="form-check-label">
                            <input
                              className="form-check-input"
                              type="checkbox"
                              name="remember"
                            />{" "}
                            Remember me
                          </label>
                        </div>
                        <div className>
                          <Link to="/forgot_password">Forgot Password?</Link>
                        </div>
                      </div>
                      <div className="betaSubmit">
                        <div className="modelSignBtn">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary btn-block" +
                                (!(formik.isValid && formik.dirty && showSubBtn)
                                  ? " disabled"
                                  : "")
                              }
                            >
                              Login
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
  // return (
  //   <>
  //     <div className="loginFormSection">
  //       <div className="loginFormSec mx-auto">
  //         <div className="loginFormHeading text-center">
  //           <h4>LearnJPΞG ADMIN</h4>
  //         </div>
  //         <div className="logInFormInput">
  //           <form onSubmit={submitHandler}>
  //             <div className="mb-4 mt-4">
  //               <label htmlFor="" className="form-label">
  //                 Email Address
  //               </label>
  //               <input
  //                 type="email"
  //                 className="form-control"
  //                 id=""
  //                 name="new password"
  //                 ref={emailInputRef}
  //                 required
  //               />
  //             </div>
  //             <div className="mb-4">
  //               <label htmlFor="confirmpassword" className="form-label">
  //                 Password
  //               </label>
  //               <input
  //                 type="password"
  //                 className="form-control"
  //                 id="confirmpassword"
  //                 name="confirm password"
  //                 ref={passwordInputRef}
  //                 required
  //               />
  //             </div>
  //             <div className="rememberPassword d-flex mb-5">
  //               <div className="form-check">
  //                 <label className="form-check-label">
  //                   <input
  //                     className="form-check-input"
  //                     type="checkbox"
  //                     name="show password"
  //                   />{" "}
  //                   Remember Me
  //                 </label>
  //               </div>
  //               <div className="forgotPassword ms-auto">
  //                 <Link to="/forgot_password" className="textPassword">
  //                   Forgot Password?
  //                 </Link>
  //               </div>
  //             </div>
  //             <button type="submit" className="btn btnForm mt-3 mb-2">
  //               LOGIN
  //             </button>
  //           </form>
  //         </div>
  //       </div>
  //     </div>
  //     <ToastContainer />
  //   </>
  // );
}
