import React, { useState, useEffect, useContext } from "react";
import GlossaryService from "../../services/GlossaryService";
import { toast, ToastContainer } from "react-toastify";
import GlobalContext from "../../context/GlobalContext";
import FileStatusContext from "../../context/FileStatusContext";
import Table from "../../shared/Table/Table";
import SearchBlockNew from "../../shared/SearchBlock/SearchBlockNew";
import ConfirmDeletePopup from "../../shared/ConfirmDeletePopup/ConfirmDeletePopup";
import FileDriveEmpty from "../../pages/FilesDrive/FilesDriveEmpty";
import FileUpload from "../FileUpload/FileUpload";
export default function ListNew({
  placeholder,
  buttonLink,
  buttonText,
  buttonLink2,
  buttonText2,
  buttonIcon2,
  columns,
  sortByOptions,
  getListDataFunc,
  viewHandlerFunc,
  viewHandlerFuncFile,
  deleteHandlerFunc,
  editHandlerFunc,
  editDraftHandlerFunc,
  activeToggleHandlerFunc,
  PopupComponent,
  allowPopEdit,
  perPage,
  topRadius0,
  fileDriveCss,
  downloadHandlerFunc,
  analyticsHandlerFunc,
  approveHandler,
  scrollPagination,
  userDatabase,
  fileDrive,
  type,
  updateListFilter,
}) {
  const sitectx = useContext(GlobalContext);
  const statusCtx = useContext(FileStatusContext);
  const glossaryServe = new GlossaryService();
  const [list, setList] = useState([]);
  const [list2, setList2] = useState([]);
  const [showUploadingPopUp, setShowUploadingPopup] = fileDrive ? statusCtx.showUploadingPopUp : "";
  const [totalCount, setTotalCount] = useState(0);
  const [showPopup, setShowPopup] = useState(false);
  const [editId, setEditId] = useState(null);
  const [deleteId, setDeleteId] = useState(null);
  const [showDeletePop, setShowDeletePop] = useState(false);
  const [listFile, setListFile] = useState(false);
  const [search, setSearch] = useState({
    start: 0,
    perPage: perPage ? perPage : 12,
    searchTxt: "",
    searchField: "",
    sortBy: { createdAt: "desc" },
  });
  const handlePaging = async (pageToView) => {
    search.start = parseInt(pageToView) * search.perPage - search.perPage;
    setSearch(JSON.parse(JSON.stringify(search)));
  };

  let active = Math.ceil((search.start + 1) / search.perPage);
  let pages = Math.ceil(totalCount / search.perPage);

  let pagination = { current: active, total: pages };

  function searchData(e) {
    search.searchTxt = e.target.value;
    search.start = 0;
    setSearch(JSON.parse(JSON.stringify(search)));
  }

  function searchSortBy(e) {
    const [key, value] = e.target.value.split("--");
    search.sortBy = {};
    search.sortBy[key] = value;
    search.start = 0;
    setSearch(JSON.parse(JSON.stringify(search)));
  }

  useEffect(() => {
    getListDataFunc(search, sitectx, setList, setTotalCount, setList2, list);
  }, [search, type, sitectx.searchDtl && sitectx.searchDtl.value, updateListFilter]); // eslint-disable-line react-hooks/exhaustive-deps
  useEffect(() => {
    if (listFile == true) {
      getListDataFunc(search, sitectx, setList, setTotalCount, setList2, list);
    }
  }, [listFile]);
  useEffect(() => {
    if (PopupComponent) {
      setShowPopup(sitectx.popup.showPopup);
    }
  }, [sitectx.popup.showPopup]); // eslint-disable-line react-hooks/exhaustive-deps

  const deleteHandlerShowPopup = (id) => {
    setShowDeletePop(true);
    setDeleteId(id);
  };

  const deleteHandler = async (isConfirmed) => {
    let deleted = await deleteHandlerFunc(isConfirmed, deleteId);
    if (deleted) {
      toast.success(deleted.message);
      getListDataFunc(search, sitectx, setList, setTotalCount, setList2, list); // eslint-disable-line react-hooks/exhaustive-deps
    } else {
      toast.error("Something went wrong!");
    }
    setShowDeletePop(false);
  };

  const activeToggleHandler = async (obj, index) => {
    console.log(obj, index);
    let changed = await activeToggleHandlerFunc(obj);
    if (changed) {
      toast.success(changed.message);
      let records = JSON.parse(JSON.stringify(list));
      records[index].is_active = !records[index].is_active;
      setList(records);
    } else {
      toast.error("Something went wrong!");
    }
  };

  const activeButton = (data, i) => {
    return (
      <div className="tbl_status d-flex align-items-center justify-content-center">
        {activeToggleHandlerFunc && (
          <label className="switch">
            <input type="checkbox" checked={data?.is_active} onChange={() => activeToggleHandler(data, i)} />
            <span className="slider round" />
          </label>
        )}
        {data?.is_active ? <label>Active</label> : <label>Inactive</label>}
      </div>
    );
  };
  const approveHandlerFunc = async (glossary) => {
    const obj = {
      _id: glossary._id,
      approved: !glossary.approved,
    };
    try {
      const res = await glossaryServe.editGlossaryRecord(obj);
      if (res?.message?.includes("Successfully")) {
        toast.success("Record updated sucessfully");
        getListDataFunc(search, sitectx, setList, setTotalCount, setList2, list);
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  const renderButtons = (row) => {
    console.log(row)
    return (
      <div className="action_tbl">
        {approveHandler && (
          <span onClick={() => approveHandlerFunc(row)} className="approveIcon">
            {row?.approved ? "Verifyed" : "Approve"}
          </span>
        )}
        {editHandlerFunc && (
          <span className="cursor" onClick={() => editHandlerFunc(row._id)}>
            <img src="/images/svg-icons/Edit.svg" alt="" />
          </span>
        )}
        {editDraftHandlerFunc && (
          <span className="cursor" onClick={() => editDraftHandlerFunc(row.course_id, row._id)}>
            <img src="/images/svg-icons/Edit.svg" alt="" />
          </span>
        )}
        {allowPopEdit && (
          <span
            className="cursor"
            onClick={() => {
              setEditId(row._id);
              setShowPopup(true);
            }}
          >
            <img src="/images/svg-icons/Edit.svg" alt="" />
          </span>
        )}
        {viewHandlerFunc && (
          <span className="cursor" onClick={() => viewHandlerFunc(row._id)}>
            <img src="/images/svg-icons/Eye.svg" alt="" />
          </span>
        )}
        {downloadHandlerFunc && (
          <span className="cursor" onClick={() => downloadHandlerFunc(row.file)}>
            <img src="/images/glossary/download.svg" alt="" />
          </span>
        )}
        {viewHandlerFuncFile && (
          <span className="cursor">
            <a href={row?.file} target="_blank"><img src="/images/svg-icons/Eye.svg" alt="" /></a>
          </span>
        )}
        {analyticsHandlerFunc && (
          <span
            className="curser"
            onClick={() => {
              analyticsHandlerFunc(row._id);
            }}
          >
            <img src="/images/svg-icons/analyticsIcon.svg" alt="" />
          </span>
        )}
        {deleteHandlerFunc && (
          <span className="cursor" onClick={() => deleteHandlerShowPopup(row._id)}>
            <img src="/images/svg-icons/Delete.svg" alt="" />
          </span>
        )}
      </div>
    );
  };

  const socialLinkButton = (row) => {
    return (
      <div className="updates_icon p-0">
        {row.social_links?.twitter && (
          <a target="_blank" rel="noreferrer" href={row.social_links.twitter}>
            <img alt="" src="/images/Twitter.png" className="img-fluid socialicons" />
          </a>
        )}
        {row.social_links?.facebook && (
          <a target="_blank" rel="noreferrer" href={row.social_links.facebook}>
            <img alt="" src="/images/Facebook.png" className="img-fluid socialicons" />
          </a>
        )}
        {row.social_links?.instagram && (
          <a target="_blank" rel="noreferrer" href={row.social_links.instagram}>
            <img alt="" src="/images/Instagram.png" className="img-fluid socialicons" />
          </a>
        )}
        {row.social_links?.website && (
          <a target="_blank" rel="noreferrer" href={row.social_links.website}>
            <img alt="" src="/images/Website.png" className="img-fluid socialicons" />
          </a>
        )}
      </div>
    );
  };

  const buttonClickHandler = async () => {
    if (PopupComponent) {
      setEditId(null);
      setShowPopup(true);
    }
  };
  return (
    <>
      <div
        className={
          "commonCart " + (topRadius0 == true ? " borderRdiusTop" : " ") + (!fileDrive ? " coursePublishpage" : " ")
        }
      >
        <SearchBlockNew
          placeholder={placeholder}
          buttonLink={buttonLink}
          buttonClick={buttonClickHandler}
          buttonText={buttonText}
          buttonLink2={buttonLink2}
          buttonText2={buttonText2}
          buttonIcon2={buttonIcon2}
          searchData={searchData}
          searchSortBy={searchSortBy}
          sortByOptions={sortByOptions}
          fileDrive={fileDrive}
        />
        {fileDriveCss && <div className="fileDriveBorder"></div>}
        <div className={"courseDtl_tbl glossaryTable" + (fileDrive ? " borderNone margin25" : "")}>
          <div className="table-responsive">
            {list.length > 0 ? (
              <Table
                cssClassesTd={columns.map((col) => (col.cssTd ? col.cssTd : ""))}
                cssClassesTh={columns.map((col) => (col.cssTh ? col.cssTh : ""))}
                thead={columns.map((col) => col.label)}
                thead2={list2.length > 0 ? columns.map((col) => col.label) : undefined}
                scrollPagination={scrollPagination && search.searchTxt.length > 0}
                perPage={perPage}
                tdata={list.map((v, i) => ({
                  id: v._id,
                  items: [
                    ...columns.map((col) => {
                      if (typeof col.valueFunc === "function") {
                        return col.valueFunc(v, i);
                      } else if (col.key === "social_links") {
                        return socialLinkButton(v);
                      } else if (col.key === "STATUS") {
                        return activeButton(v, i);
                      } else if (col.key === "ACTIONS") {
                        return renderButtons(v);
                      } else if (!col.key) {
                        return col.altValue;
                      } else {
                        return col.maxLength && col.maxLength < v[col.key].length
                          ? v[col.key].substring(0, col.maxLength) + "..."
                          : v[col.key];
                      }
                    }),
                  ],
                }))}
                tdata2={list2.map((v, i) => ({
                  id: v._id,
                  items: [
                    ...columns.map((col) => {
                      if (typeof col.valueFunc === "function") {
                        return col.valueFunc(v, i);
                      } else if (col.key === "social_links") {
                        return socialLinkButton(v);
                      } else if (col.key === "STATUS") {
                        return activeButton(v, i);
                      } else if (col.key === "ACTIONS") {
                        return renderButtons(v);
                      } else if (!col.key) {
                        return col.altValue;
                      } else {
                        return col.maxLength && col.maxLength < v[col.key].length
                          ? v[col.key].substring(0, col.maxLength) + "..."
                          : v[col.key];
                      }
                    }),
                  ],
                }))}
                pagination={pagination}
                handlePaging={handlePaging}
              />
            ) : (
              <>
                {!fileDrive ? (
                  <div className="searchingSection">
                    <img
                      src={userDatabase ? "/images/glossary/Searching.svg" : "/images/svg-icons/emptyScreenIcon.svg"}
                    />
                  </div>
                ) : (
                  <FileDriveEmpty setListFile={setListFile} />
                )}
              </>
            )}
          </div>
        </div>
      </div>
      <ToastContainer />
      {showPopup && (
        <PopupComponent
          onClose={() => {
            setShowPopup(false);
          }}
          onFail={(errorMsg) => {
            toast.error(errorMsg);
          }}
          onSuccess={() => {
            setShowPopup(false);
            getListDataFunc(search, sitectx, setList, setTotalCount, setList2, list);
            toast.success("Record updated sucessfully");
          }}
          id={editId}
        />
      )}
      {showDeletePop && (
        <ConfirmDeletePopup
          onSucess={deleteHandler}
          onClose={() => {
            setShowDeletePop(false);
          }}
        />
      )}
      {fileDrive && showUploadingPopUp && <FileUpload setShowUploadingPopup={setShowUploadingPopup} />}
    </>
  );
}
