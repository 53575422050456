import React, { useEffect, useState } from "react"
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService"
export default function GeneralSetting() {
    const settingServ = new SettingService();
    const [value, setValue] = useState({
        password: "",
        is_protected: false,
        waitList: false,
        // time_zone: "",
        refer_reward: 0,
        is_refer_reward_on: false,
    });
    const [showPassword, setShowPassword] = useState(false)
    useEffect(() => {
        getSettingsData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSettingsData = async () => {
        try {
            let response = await settingServ.getDetails();
            if (response) {
                setValue({
                    _id: response.data._id,
                    password: response.data?.general?.password,
                    waitList: response.data?.general?.waitList,
                    // time_zone: response.data?.general?.time_zone,
                    refer_reward: response.data?.general?.refer_reward,
                    is_protected: response.data?.general?.is_protected,
                    is_refer_reward_on: response.data?.general?.is_refer_reward_on,
                });
            } else {
            }
        } catch (err) {
        }
    };

    const ValidateSchema = Yup.object().shape({
        password: Yup.string().required("Required"),
        waitList: Yup.string().required("Required"),
        // time_zone: Yup.string().required("Required"),
        refer_reward: Yup.string().required("Required"),
        is_protected: Yup.string().required("Required"),
        is_refer_reward_on: Yup.string().required("Required"),
    });
    const updatePassword = () => {
        let obj = {
            password: formik.values.password
        }
        try {
            settingServ.updatePassword(obj).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("password updated successfully");
                }
            });
        } catch (error) {
            console.log(error);
        }
    }
    const onSubmit = (values, { resetForm }) => {
        let obj = {
            _id: values._id,
            general: {
                password: values.password,
                waitList: values?.waitList,
                // time_zone: values?.time_zone,
                refer_reward: values?.refer_reward,
                is_protected: values.is_protected,
                is_refer_reward_on: values?.is_refer_reward_on,
            }
        }

        if (values._id) {
            obj.general = JSON.stringify(obj.general)
            settingServ.updateRecord(obj).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("Settings added successfully");
                }
            });
        } else {
            delete obj._id;
            obj.general = JSON.stringify(obj.general)
            settingServ.addrecord(obj).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("Settings added successfully");
                }
            });
        }
    };

    const formik = useFormik({
        initialValues: value,
        validateOnBlur: true,
        onSubmit,
        validationSchema: ValidateSchema,
        enableReinitialize: true,
    });
    return (
        <>
            <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
                <div className="commonCart account_page">
                    <div className="stepFormHead d-flex align-items-center">
                        <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
                            <div className="createCourseHeading">
                                <h4>Settings | General</h4>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="userProfile_details">
                            <div className="settingGeneral">
                                <div className="comPadding pb-0">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="Generalcommn_text">
                                                <h6>Password Protection</h6>
                                                <p>Limit who can access our platform using a password screen.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xl-1">
                                            <p className="mb-1 mb-xl-2 offCheckHeading">{formik.values.is_protected ? "On" : "OFF"}</p>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    id="is_protected"
                                                    name="is_protected"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.is_protected}
                                                />
                                                <span className="slider round" />
                                            </label>
                                        </div>
                                        <div className="col-sm-6 col-xl-7">
                                            <div className="generalForm_checks">
                                                <div className="mb-4 mt-4 mt-sm-0 account_input middelContant">
                                                    <div className="d-flex">
                                                        <div className="input-group disabled">
                                                            <span className="input-group-text bg-white betaLock border-0 pe-1"><img src="/images/icons/lock.svg" alt="lock" className="img-fluid" /></span>
                                                            {/* <input type="password" className="form-control passInput border-0" placeholder="Please enter a password" /> */}

                                                            <input
                                                                type={showPassword ? "text" : "password"}
                                                                className="form-control passInput border-0"
                                                                id="password"
                                                                placeholder="Please enter a password"
                                                                name="password"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.password}
                                                            />
                                                            {formik.touched.password && formik.errors.password ? (
                                                                <div className="formik-errors bg-error">
                                                                    {formik.errors.password}
                                                                </div>
                                                            ) : null}

                                                            <span className="input-group-text bg-white betaEye border-0">
                                                                <a href="javascript:void(0);" onClick={() => setShowPassword(!showPassword)}><img src="/images/icons/hide-eye.svg" alt="eye-hide" className="img-fluid" /></a>
                                                            </span>
                                                        </div>
                                                        <div className="commonform_btn ms-3 pt-0 d-grid">

                                                            <button onClick={updatePassword} type="button" className={"btn btn-primary btn-block px-5" +
                                                                ((formik.errors.password || formik.values.password == value.password)
                                                                    ? " disabled"
                                                                    : "")}>save</button>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div>
                                            </div>
                                        </div>
                                        <div className="col-sm-4">
                                            <div className="Generalcommn_text">
                                                <h6>Waitlist</h6>
                                                <p>Collect waitlist signups from password page visitors.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xl-1">
                                            <p className="mb-1 mb-xl-2 offCheckHeading">{formik.values.waitList ? "On" : "OFF"}</p>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    id="waitList"
                                                    name="waitList"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.waitList}
                                                />
                                                <span className="slider round" />
                                            </label>
                                        </div>
                                    </div>
                                </div>
                                <hr />
                                {/* <div className="comPadding pb-0">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="Generalcommn_text">
                                                <h6>Platform Timezone</h6>
                                                <p>This is the timezone for your platform.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-8">
                                            <div className="mb-3 account_input">
                                                <label htmlFor="userGender" className="form-label">Timezone</label>
                                                <div>
                                                    <div className="custom-select fa-caret-down">


                                                        <select
                                                            className="form-select js-Selector"
                                                            name="time_zone"
                                                            onChange={formik.handleChange}
                                                            value={formik.values.time_zone}
                                                        >
                                                            <option>Select time zone</option>
                                                            <option value="(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi">(GMT+05:30) Chennai, Kolkata, Mumbai, New Delhi</option>
                                                        </select>
                                                        {formik.touched.time_zone && formik.errors.time_zone ? (
                                                            <div className="formik-errors bg-error">
                                                                {formik.errors.time_zone}
                                                            </div>
                                                        ) : null}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <hr /> */}
                                <div className="comPadding pb-0">
                                    <div className="row">
                                        <div className="col-sm-4">
                                            <div className="Generalcommn_text">
                                                <h6>Refer &amp; Earn</h6>
                                                <p>Switch on/off the option for Refer &amp; Earn on our platforn.</p>
                                            </div>
                                        </div>
                                        <div className="col-sm-2 col-xl-1">
                                            <p className="mb-1 mb-xl-2 offCheckHeading mt-2">{formik.values.is_refer_reward_on ? "On" : "OFF"}</p>
                                            <label className="switch">
                                                <input
                                                    type="checkbox"
                                                    className="form-control"
                                                    id="is_refer_reward_on"
                                                    name="is_refer_reward_on"
                                                    onChange={formik.handleChange}
                                                    checked={formik.values.is_refer_reward_on}
                                                />
                                                <span className="slider round" />
                                            </label>
                                        </div>
                                        <div className="col-sm-6 col-md-4 col-xl-3">
                                            <div className="mb-4 account_input groupsecond mt-4 mt-sm-0">
                                                <label htmlFor="Rewards" className="form-label">Rewards</label>
                                                <div className="input-group">
                                                    {/* <input type="number" className="form-control m-0" placeholder={0} /> */}
                                                    <input
                                                        type="number"
                                                        className="form-control m-0"
                                                        id="refer_reward"
                                                        placeholder="0"
                                                        name="refer_reward"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.refer_reward}
                                                    />
                                                    {formik.touched.refer_reward && formik.errors.refer_reward ? (
                                                        <div className="formik-errors bg-error">
                                                            {formik.errors.refer_reward}
                                                        </div>
                                                    ) : null}
                                                    <span className="input-group-text"><img src="/images/other-icons/mini_logo.svg" className="img-fluid" alt="No Image" />COINS</span>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="commonform_btn comPadding pt-0 d-grid col-12">
                                <button type="submit" className={"btn btn-primary btn-block " +
                                    (!(formik.isValid && formik.dirty)
                                        ? " disabled"
                                        : "")}>Save</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />
        </>
    )
} 