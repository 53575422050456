import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import AddLesson from "./AddLesson";
import CourseService from "../../services/CourseService";
import Steps from "./Steps";

const Lesson = () => {
  const params = useParams();
  const router = useNavigate();
  const courseServ = new CourseService();
  let initialLesson = {
    lesson: "",
    desc: "",
    file_one: "",
    file_two: "",
  };
  const [course, setCourse] = useState({
    lessons: [{ ...initialLesson }],
  });
  const [currLessonIdx, setCurrLessonIdx] = useState(0);

  useEffect(() => {
    if (params?.draftid && params?.id) {
      fetchDraftList();
    } else if (params?.id) {
      getCourseDetails();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function fetchDraftList() {
    if (params?.draftid !== "") {
      let response = await courseServ.getDraftDetails(params?.draftid);
      if (response.data) {
        let courseObj = response.data;
        let draftObj = {
          ...courseObj,
          _id: response.data.course_id,
        };
        delete draftObj.course_id;
        setCourse(draftObj);
      }
    }
  }

  async function getCourseDetails() {
    try {
      let response = await courseServ.getCourse(params?.id);
      if (response) {
        if (response.data.lessons.length === 0) {
          response.data.lessons.push({ ...initialLesson });
        }
        setCourse(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }

  const ValidateSchema = Yup.object().shape({
    lessons: Yup.array().of(
      Yup.object().shape({
        lesson: Yup.string().required("Required"),
        desc: Yup.string().required("Required"),
        // file_one: Yup.string().required("Required"),
        // file_two: Yup.string().required("Required")
        // correct_answers: Yup.array().min(1, "Required").of(Yup.boolean()),
      })
    ),
  });

  const addLesson = () => {
    let ls = [...formik.values.lessons];
    ls.push({ ...initialLesson });
    setCurrLessonIdx(formik.values.lessons.length);
    formik.setValues({ ...formik.values, ...{ lessons: ls } });
  };

  const onSubmit = (values) => {
    try {
      if (params?.id) {
        const formData = new FormData();
        formData.append("_id", course && course._id);
        formData.append("course_name", course && course.course_name);
        formData.append("course_desc", course && course.course_desc);
        formData.append("course_lesson", course && course.course_lesson);
        formData.append("createdBy", course && course.createdBy);
        formData.append("tags", JSON.stringify(course && course.tags));
        formData.append("course_video", course && course.course_video);
        formData.append("cover_image", course && course.cover_image);
        formData.append(
          "read_time",
          JSON.stringify(course && course.read_time)
        );
        formData.append("rewards", JSON.stringify(course && course.rewards));
        formData.append(
          "total_coins",
          JSON.stringify(course && course.total_coins)
        );
        formData.append(
          "users_enrolled",
          JSON.stringify(course && course.users_enrolled)
        );
        formData.append("what_you_learn", course && course.what_you_learn);

        if (values.lessons.length) {
          formData.append("lessons", JSON.stringify(values.lessons));
          for (let i = 0; i < values.lessons.length; i++) {
            formData.append(
              `lessons__${i}__file_one`,
              values.lessons[i].file_one
            );
            formData.append(
              `lessons__${i}__file_two`,
              values.lessons[i].file_two
            );
          }
        }
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
        };
        axios
          .put(process.env.REACT_APP_API_BASEURL + "/course", formData, config)
          .then(async (response) => {
            if (response.data) {
              toast.success("Lesson updated successfully");
              window.scroll(0, 0);
              setTimeout(() => {
                if (params?.draftid) {
                  router(
                    `/course/create_question/${params?.id}/${params.draftid}`
                  );
                } else {
                  router(`/course/create_question/${params?.id}`);
                }
              }, 1500);
            }
          });
      }
    } catch (err) {
      console.log(err);
    }
  };

  const formik = useFormik({
    initialValues: course,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="leftBox order-1">
            <div className="backIconBtn">
              <Link to="/course">
                <img
                  src="/images/icons/back-icon.svg"
                  alt="backicon"
                  className="img-fluid"
                />
              </Link>
            </div>
          </div>
          <div className="rightBox order-2 order-sm-3">
            <h5>Step 2</h5>
            <h6>Lessons</h6>
          </div>
          <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>
                <img
                  src="/images/icons/e-learning.svg"
                  alt="backicon"
                  className="img-fluid marginRight5"
                />{" "}
                <span>Create a Course</span> 
              </h4>
            </div>
            <div className="paginationNav">
              <Steps step={2} courseId={params?.id} />
            </div>
          </div>
        </div>
        <div className="userProfile_details">
          <div className="lesson_btn comPadding">
            <ul className="nav">
              {formik.values.lessons.map((les, idx) => {
                return (
                  <li onClick={() => setCurrLessonIdx(idx)}>
                    <Link
                      className={
                        "lesson-link " + (currLessonIdx === idx ? "active" : "")
                      }
                      to={`/course/create_lesson/${params?.id}`}
                    >
                      Lesson {idx + 1}
                    </Link>
                  </li>
                );
              })}
              <li onClick={addLesson}>
                <Link
                  className="lesson-link"
                  to={`/course/create_lesson/${params?.id}`}
                >
                  <i className="fa fa-plus me-1" aria-hidden="true" /> Add
                  Lesson
                </Link>
              </li>
            </ul>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <AddLesson lesnIdx={currLessonIdx} formik={formik} />
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
};

export default Lesson;
