import React, { useState } from "react";
import LoginHeader from "../Login/LoginOTP";
import { useNavigate, useParams } from "react-router-dom";
import UserService from "../../services/UserService";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import "react-toastify/dist/ReactToastify.css";

export default function ResetPassword({ showStep }) {
  const [value, setValue] = useState({
    newPassword: "",
    confirmPassword: "",
  });
  const params = useParams();
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePassword = () => {
    setPasswordShown(!passwordShown);
  };

  const navigate = useNavigate();
  const userService = new UserService();

  const initialValues = {
    newPassword: "",
    confirmPassword: "",
  };
  const ValidateSchema = Yup.object({
    newPassword: Yup.string().required("Password is a required field"),
    confirmPassword: Yup.string()
      .oneOf([Yup.ref("newPassword"), null], "Password must match")
      .required("Confirm Password is a required field"),
  });
  const onSubmit=(values, e)=> {
    const userTemp = (JSON.parse(localStorage.getItem("userTemp")))
    try {
      let obj = {
        newPassword: values.newPassword,
        verifyPassword: values.confirmPassword,
        otp : userTemp.opt,
        email : userTemp.email
      };
      userService
        .resetPassword(obj)
        .then((result) => {
          if (result.message) {
            toast.success(result.message);
            localStorage.removeItem("userTemp")
            setTimeout(() => {
              navigate("/login");
            }, 2000);
          } else {
            toast.error(result.error);
          }
        })
        .catch((e) => {
          toast.error(e);
        });
    } catch (err) {
      console.log(err);
    }
  }
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="w-100 clearfix betaEnterPassword" id="betaEnterPassword">
      <div className="w-100 bgInner d-flex align-items-center justify-content-between">
        <div className="card betaPasswordCard">
          <div className="card-header bg-white">
            <ul className="nav nav-pills bulletNav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => {
                    showStep(1);
                    localStorage.removeItem("userTemp")
                  }}
                />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => {
                    showStep(2);
                  }}
                />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  onClick={() => {
                    showStep(3);
                  }}
                />
              </li>
            </ul>
          </div>
          <div className="h-100 tabContent">
            <div className="h-100 tab-pane loginPanel" id="loginPanel">
              <div className="card-body h-100">
                <div className="betaLogo text-center">
                  <img
                    src="images/login-model-logo.svg"
                    alt="betalogo"
                    className="img-fluid"
                  />
                </div>
                <form action id="loginForm" onSubmit={formik.handleSubmit} className="h-100">
                  <div className="middelContant middleContantPosition">
                    <h4>Change your Password</h4>
                    <p className>Only for MaveFi Employees &amp; Partners</p>
                    <div className="mb-3 mt-3">
                      <label htmlFor="pwd" className="form-label">
                        Enter New Password
                      </label>
                      <div className="input-group">
                        <span className="input-group-text bg-white betaLock border-0 pe-1">
                          <img
                            src="images/icons/lock.svg"
                            alt="lock"
                            className="img-fluid"
                          />
                        </span>
                        <input
                          type="password"
                          name="newPassword"
                          className="form-control passInput border-0"
                          placeholder="*************"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.newPassword}
                        />
                        <span className="input-group-text bg-white betaEye border-0">
                          <a href="javascript:void(0);">
                            <img
                              src="images/icons/hide-eye.svg"
                              alt="eye-hide"
                              className="img-fluid"
                            />
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="pwd" className="form-label">
                        Confirm New Password
                      </label>
                      <div className="input-group">
                        <span className="input-group-text bg-white betaLock border-0 pe-1">
                          <img
                            src="images/icons/lock.svg"
                            alt="lock"
                            className="img-fluid"
                          />
                        </span>
                        <input
                          type="password"
                          className="form-control passInput border-0"
                          placeholder="*************"
                          name="confirmPassword"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.confirmPassword}
                        />
                        <span className="input-group-text bg-white betaEye border-0">
                          <a href="javascript:void(0);">
                            <img
                              src="images/icons/hide-eye.svg"
                              alt="eye-hide"
                              className="img-fluid"
                            />
                          </a>
                        </span>
                      </div>
                    </div>
                    <div className="betaSubmit positionBtn">
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            className="btn btn-primary btn-block"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer/>
    </div>
  );

  // return (
  //   <Formik
  //     validationSchema={validationSchema}
  //     initialValues={initialValues}
  //     enableReinitialize={true}
  //     onSubmit={submitResetPassword}
  //   >
  //     {({
  //       values,
  //       errors,
  //       status,
  //       touched,
  //       handleBlur,
  //       handleChange,
  //       handleSubmit,
  //       isSubmitting,
  //     }) => (
  //       <main className="w-100 clearfix mainSection">
  //         <div className="w-100 clearfix rightSection">
  //           <div className="contantSection">
  //             {/* <LoginHeader /> */}
  //             <section className="w-100 clearfix loginFormSection">
  //               <div className="w-100 logInSection d-flex align-items-center">
  //                 {/* <InfoUpdate /> */}
  //                 <div className="loginFormSection">
  //                   <div className="loginFormSec mx-auto">
  //                     <div className="loginFormHeading text-center">
  //                       <h4>LearnJPΞG ADMIN</h4>
  //                     </div>
  //                     <div className="logInFormInput">
  //                       <form action="" onSubmit={handleSubmit}>
  //                         <div className="mb-4 mt-4">
  //                           <label htmlFor="" className="form-label">
  //                             Enter New Password
  //                           </label>
  //                           <input
  //                             type="password"
  //                             className="form-control"
  //                             name="newPassword"
  //                             value={values && values.newPassword}
  //                             onChange={handleChange}
  //                             onBlur={handleBlur}
  //                             isValid={
  //                               touched.newPassword && !errors.newPassword
  //                             }
  //                           />
  //                         </div>
  //                         <ErrorMessage name="newPassword">
  //                           {(msg) => <div className="err_below">{msg}</div>}
  //                         </ErrorMessage>
  //                         <div className="mb-4">
  //                           <label htmlFor="" className="form-label">
  //                             Confirm New Password
  //                           </label>
  //                           <input
  //                             type={passwordShown ? "text" : "password"}
  //                             className="form-control"
  //                             name="confirmPassword"
  //                             value={values && values.confirmPassword}
  //                             onChange={handleChange}
  //                             onBlur={handleBlur}
  //                             isValid={
  //                               touched.confirmPassword &&
  //                               !errors.confirmPassword
  //                             }
  //                           />
  //                         </div>
  //                         <ErrorMessage name="confirmPassword">
  //                           {(msg) => <div className="err_below">{msg}</div>}
  //                         </ErrorMessage>
  //                         <div className="rememberPassword d-flex mb-5">
  //                           <div className="form-check">
  //                             <label className="form-check-label">
  //                               <input
  //                                 className="form-check-input"
  //                                 type="checkbox"
  //                                 name="show password"
  //                                 onClick={togglePassword}
  //                               />{" "}
  //                               Show Password
  //                             </label>
  //                           </div>
  //                         </div>
  //                         <button
  //                           type="submit"
  //                           className="btn btnForm mt-3 mb-2"
  //                         >
  //                           CHANGE PASSWORD
  //                         </button>
  //                       </form>
  //                     </div>
  //                   </div>
  //                 </div>
  //               </div>
  //             </section>
  //           </div>
  //         </div>
  //         {/* <Footer />
  //         <ToastContainer /> */}
  //       </main>
  //     )}
  //   </Formik>
  // );
}
