const { useState } = require("react");

export default function ShowHideComp({ value }) {
  const [show, setShow] = useState(false);
  const showHideHandler = () => {
    setShow(!show);
  };
  return (
    <>
      {show && <span>{value}</span>}
      {!show && <span>**********</span>}

      <span
        className="cursor"
        onClick={() => showHideHandler()}
        style={{
          display: "inline-block",
          marginLeft: "5px",
          position: "relative",
          top: show ? "" : "-4px",
        }}
      >
        {!show && <img src="/images/svg-icons/Eye.svg" alt="" />}
        {show && <img src="/images/icons/hide-eye.svg" alt="" />}
      </span>
    </>
  );
}
