import PublishService from "../../services/PublishService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import UpdateForm from "./UpdateForm";
import { useState, useEffect } from "react";
const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};

const removeHTMLTags = (value, index) => {
  return value && value.update ? value.update.replace(/(<([^>]+)>)/gi, "") : "";
};
const columns = [
  {
    label: "Published On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Heading Text", key: "word" },
  {
    label: "Body Text",
    key: "update",
    cssTh: "white_pre",
    cssTd: "white_pre",
    valueFunc: removeHTMLTags,
  },
  {
    label: "Published by",
    key: "createdBy",
  },
  { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "word--asc", value: "A to Z" },
  { key: "word--desc", value: "Z to A" },
];

function Updates() {
  const publishserv = new PublishService();
  const[totalCount, setTotalCount]= useState(0)
  const[monthTotal, setMonthTotal]= useState(0)
  useEffect(()=>{
    getUpdateCount()
  })
  const getUpdateCount = async()=>{
    try {
      let response = await publishserv.listCount({})
       setTotalCount(response.totalCount)
       setMonthTotal(response.monthTotal)
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Total Updates",
      icon: "/images/glossary/megaphone.svg",
      value: totalCount,
      footer: `This month: ${monthTotal}`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "toupdate"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    try {
      let response = await publishserv.listAllUpdate(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await publishserv.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (data) => {
    const obj = {
      _id: data._id,
      word: data.word,
      update: data.update,
      is_active: !data.is_active,
    };
    try {
      const res = await publishserv.editrecord(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search updates"
        buttonText="Add an Update"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={UpdateForm}
        allowPopEdit={true}
      />
    </div>
  );
}

export default Updates;
