import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import NotificationService from "../../services/NotificationService";

function SendNotification({ onClose, onSuccess, onFail, id }) {
  const notificationServ = new NotificationService();
  const [showLoading, setShowLoading] = useState(false);
  const [value, setValue] = useState({
    title: "",
    content: "",
    type: "",
  });

  useEffect(() => {
    if (id) {
      getNotificationDetail(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getNotificationDetail = async (id) => {
    try {
      let response = await notificationServ.getDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          title: response.data.title,
          content: response.data.content,
          type: response.data.type,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object().shape({
    title: Yup.string().required("Required"),
    content: Yup.string().required("Required"),
  });

  const onSubmit = (values) => {
    setShowLoading(true);
    let obj = {
      title: values.title,
      content: values.content,
      type: values.type,
    };

    if (values._id) {
      console.log(values.id);
      notificationServ.updateRecord(values).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    } else {
      delete values._id;
      notificationServ.addrecord(obj).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    }
    setShowLoading(false);
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel createCustomLink"
      id="sendNotification"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img
                    src="images/svg-icons/bell.svg"
                    alt="icons"
                    className="img-fluid"
                  />{" "}
                  <span>Send a Notification</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />{" "}
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col">
                          <div className="row radioBtns">
                            <div className="col-12">
                              <label htmlFor className="form-label">
                                To
                              </label>{" "}
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="form-control">
                                <input
                                  type="radio"
                                  id="webApp"
                                  name="type"
                                  checked={
                                    formik.values.type == "web" ? true : false
                                  }
                                  onClick={() =>
                                    formik.setFieldValue("type", "web")
                                  }
                                />{" "}
                                <label htmlFor="webApp">Web App</label>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="form-control">
                                <input
                                  type="radio"
                                  id="mobileApp"
                                  name="type"
                                  checked={
                                    formik.values.type == "mobile"
                                      ? true
                                      : false
                                  }
                                  onClick={() =>
                                    formik.setFieldValue("type", "mobile")
                                  }
                                />{" "}
                                <label htmlFor="mobileApp">Mobile App</label>
                              </div>
                            </div>
                            <div className="col-md-6 mb-3">
                              <div className="form-control">
                                <input
                                  type="radio"
                                  id="adminPanel"
                                  name="type"
                                  checked={
                                    formik.values.type == "admin" ? true : false
                                  }
                                  onClick={() =>
                                    formik.setFieldValue("type", "admin")
                                  }
                                />{" "}
                                <label htmlFor="adminPanel">Admin Panel</label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="HeadingText" className="form-label">
                              Heading Text
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="HeadingText"
                              placeholder="Please enter the heading text"
                              name="title"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.title}
                            />
                            {formik.touched.title && formik.errors.title ? (
                              <div className="formik-errors bg-error">
                                {formik.errors.title}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="BodyText" className="form-label">
                              Body Text
                            </label>
                            <textarea
                              className="form-control"
                              rows={4}
                              id="content"
                              placeholder="Please enter the body text"
                              name="content"
                              value={formik.values.content}
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                            />
                            {formik.touched.content && formik.errors.content ? (
                              <div className="formik-errors bg-error">
                                {formik.errors.content}
                              </div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" +
                            (!(formik.isValid && formik.dirty)
                              ? " disabled"
                              : "")
                          }
                        >
                          {showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Send Notification</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SendNotification;
