import React from "react";
import moment from "moment";
import { Link } from "react-router-dom";

function Ticket({
  data,
  deleteHandlerShowPopup,
  activeToggleHandler,
  allowPopEdit,
  setEditId,
  setShowPopup,
  deleteHandler,
  viewHandlerFunc,
  editHandlerFunc,
}) {
  // const activeButton = (data, i) => {
  //   return (
  //     <div className="tbl_status d-flex align-items-center justify-content-center">
  //       {activeToggleHandlerFunc && (
  //         <label className="switch">
  //           <input
  //             type="checkbox"
  //             checked={data?.is_active}
  //             onChange={() => activeToggleHandler(data, i)}
  //           />
  //           <span className="slider round" />
  //         </label>
  //       )}
  //       {data?.is_active ? <label>Active</label> : <label>Inactive</label>}
  //     </div>
  //   );
  // };
  // const renderButtons = (row) => {
  //   return (
  //     <div className="action_tbl">
  //       {editHandlerFunc && (
  //         <span className="cursor" onClick={() => editHandlerFunc(row._id)}>
  //           <img src="/images/svg-icons/Edit.svg" alt="" />
  //         </span>
  //       )}
  //       {allowPopEdit && (
  //         <span
  //           className="cursor"
  //           onClick={() => {
  //             setEditId(row._id);
  //             setShowPopup(true);
  //           }}
  //         >
  //           <img src="/images/svg-icons/Edit.svg" alt="" />
  //         </span>
  //       )}
  //       {viewHandlerFunc && (
  //         <span className="cursor" onClick={() => viewHandlerFunc(row._id)}>
  //           <img src="/images/svg-icons/Eye.svg" alt="" />
  //         </span>
  //       )}
  //       {deleteHandlerFunc && (
  //         <span
  //           className="cursor"
  //           onClick={() => deleteHandlerShowPopup(row._id)}
  //         >
  //           <img src="/images/svg-icons/Delete.svg" alt="" />
  //         </span>
  //       )}
  //     </div>
  //   );
  // };

  return (
    <div className="col-xl-4 tickts_col">
      <div className="ticketCart">
        <div className="ticketcart_head d-flex justify-content-between">
          <div className="ticktname">
            <h6 className="mb-1">{data.full_name}</h6>
            <p className="mb-0">{data.email_address}</p>
          </div>
          <div className="ticket_no text-end">
            <p className="mb-1"> Ticket No.</p>
            <h6 className="mb-0">{data.ticketNo}</h6>
          </div>
        </div>
        <div className="social_row commonticket_cart d-flex">
          <div className="social_icon">
            <img src="images/other-icons/twitter.svg" />
            <span className="cartP">
              {data.twitter_username ? "@" + data.twitter_username : "Unknown"}
            </span>
          </div>
          <div className="social_icon">
            <img src="images/other-icons/discordneew.svg" />
            <span className="cartP">
              {data.discord_username ? data.discord_username : "Unknown"}
            </span>
          </div>
        </div>
        <div className="commonticket_cart">
          <h6 className="mb-1">Wallet Address</h6>
          <p className="mb-0">
            {data.wallet_address ? data.wallet_address : "Unknown"}
          </p>
        </div>
        <div className="commonticket_cart">
          <h6 className="mb-1">{data.subject}</h6>
          <p className="mb-0" style={{ minHeight: "30px" }}>
            {data.message.length < 100
              ? data.message
              : data.message.substr(0, 100) + " ..."}
          </p>
        </div>
        <div className="attachment_ticket commonticket_cart d-flex justify-content-between align-items-end">
          <div className="attachment_file">
            <h6 className="mb-1">Attachments</h6>
            <p className="mb-0">
              {data.attachments.length > 1
                ? `${data.attachments.length} files attached`
                : data.attachments.length == 1
                ? `${data.attachments.length} file attached`
                : "No Files Attached"}
            </p>
          </div>
          <div>
            <h6 className="mb-1">Date &amp; Time</h6>
            <p className="mb-0">
              {" "}
              {moment(data.createdAt).format("DD/MM/YYYY | HH:mm")}
            </p>
          </div>
          {editHandlerFunc && (
            <div className="zoom_cart">
              <span
                onClick={() => editHandlerFunc(data._id)}
                style={{ cursor: "pointer" }}
              >
                <img src="images/svg-icons/expandIcon.svg" className="expandIcon" />
              </span>
            </div>
          )}
        </div>
      </div>
    </div>
  );
  return (
    <div className="supportTicket">
      <div className="heading">
        <div className="alignLeft">
          <h5>{data.full_name}</h5>
          <p>{data.email_address}</p>
        </div>
        <div className="alignRight">
          <h6>Ticket No. {data.ticketNo}</h6>
          <h5 style={{ textAlign: "right" }}>Mf-1</h5>
        </div>
      </div>
      <div className="supportRow">
        <div className="alignLeft" style={{ display: "flex" }}>
          <p>
            <img src="/images/svg-icons/bird.svg" /> {data.twitter_username}
          </p>
          <p style={{ marginLeft: "18px" }}>
            <img src="/images/svg-icons/CartoonIcon.svg" /> me@shubham1286.com
          </p>
        </div>
      </div>
      <div className="supportRow">
        <div className="alignLeft">
          <h6>Wallet Address</h6>
          <p>{data.wallet_address}</p>
        </div>
      </div>
      <div className="supportRow">
        <div className="alignLeft">
          <h6>{data.subject}</h6>
          <p>{data.message}</p>
        </div>
      </div>
      <div className="bottomSection">
        <div className="alignLeft">
          <h6>Attachments</h6>
          <p>{data.attachments.length}</p>
        </div>
        <div className="middle">
          <h6>Date & Time</h6>
          <p>{data.createdAt}</p>
        </div>
        <div className="alignRight">
          <img src="/images/svg-icons/Expand.svg" />
        </div>
      </div>
    </div>
  );
}

export default Ticket;
