import CareerService from "../../services/CareerService";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import CareerForm from "./CareerForm";
import moment from "moment";
import { useState, useEffect } from "react";

const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const columns = [
  {
    label: "Posted On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Posted by", key: "createdBy" },
  { label: "Job Title", key: "role" },
  { label: "Location", key: "location" },
  { label: "Availability", key: "availability" },
  { label: "Applicants", key: "applicants" },
  { label: "Actions", key: "ACTIONS" },
];

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "role--asc", value: "A to Z" },
  { key: "role--desc", value: "Z to A" },
];


export default function Careers() {
  const careerServ = new CareerService();
  const[totalCount, setTotalCount]= useState(0);
  const[monthTotal, setMonthTotal]= useState(0);
  useEffect(()=>{
    getCareerCount()
  })
  const getCareerCount = async()=>{
    try {
      let response = await careerServ.listCount({})
       setTotalCount(response.totalCount)
       setMonthTotal(response.monthTotal)
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Total Count",
      icon: "/images/glossary/Glossary_count.svg",
      value: totalCount,
      footer: `This month: ${monthTotal}`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
    };

    try {
      let response = await careerServ.listAllCareer(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await careerServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (careerObj) => {
    let obj = {
      _id: careerObj._id,
      is_active: !careerObj.is_active,
    };
    try {
      let response = await careerServ.updateRecord(obj);
      if (response?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search careers"
        buttonText="Add an Opening"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        PopupComponent={CareerForm}
        allowPopEdit={true}
      />
    </div>
  );
}
