import util from "../util/util";

export default class VideoService {
  addRecord(payload) {
    return util
      .sendApiRequest("/video", "POST", true, payload)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  editRecord(payload) {
    return util.sendApiRequest("/video", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  listAll(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util
      .sendApiRequest("/video/list", "POST", true, activity)
      .then(
        (response) => {
          return response;
        },
        (error) => {
          throw error;
        }
      );
  }

  deleteRecord(id) {
    return util.sendApiRequest("/video/" + id, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  getDetails(id) {
    return util.sendApiRequest("/video/" + id, "Get", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
  listCount(condition){
    return util
    .sendApiRequest("/video/videoCount", "POST", condition)
    .then((response)=>{
      return response;
    })
    .catch((err)=>{
      throw err;
    })
  }
  videoTagList(){
    return util.sendApiRequest("/videotag/list", "POST", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  videoTagGetDetails(dataId) {
    return util
      .sendApiRequest("/videotag/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  videoAddTag(payload) {
    let titles = [];
    payload.map((item) => {
      return (
        titles.push({ title: item })
      )
    });
    let data = { titles: titles };
    return util.sendApiRequest("/videotag", "POST", true, data).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  videoTagDelete(dataId) {
    return util.sendApiRequest("/videotag/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }
}
