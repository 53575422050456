import React, { useEffect, useState } from "react"
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService"
export default function AdvertisingSetting() {
    const settingServ = new SettingService();
    const [value, setValue] = useState({
        header_logo: "",
        footer_logo: "",
        primary_color: "",
        contrasting_color: "",
        secondary_color: {
            color_1: "",
            color_2: "",
            color_3: "",
            color_4: "",
        },
        cover_image: "",
        sort_description: "",
        social_links: {
            twitter: {
                text: "",
                is_active: false,
            },
            instagram: {
                text: "",
                is_active: false,
            },
            facebook: {
                text: "",
                is_active: false,
            },
            discord: {
                text: "",
                is_active: false,
            },
            youtube: {
                text: "",
                is_active: false,
            },
            linkedin: {
                text: "",
                is_active: false,
            },
        }
    });
    useEffect(() => {
        getSettingsData();
    }, []); // eslint-disable-line react-hooks/exhaustive-deps

    const getSettingsData = async () => {
        try {
            let response = await settingServ.getDetails();
            if (response.data) {
                setValue({
                    _id: response.data._id,
                    header_logo: response.data?.brand?.header_logo,
                    footer_logo: response.data?.brand?.footer_logo,
                    primary_color: response.data?.brand?.primary_color,
                    cover_image: response.data?.brand?.cover_image,
                    contrasting_color: response.data?.brand?.contrasting_color,
                    sort_description: response.data?.brand?.sort_description,
                    secondary_color: {
                        color_1: response.data?.brand?.secondary_color?.color_1,
                        color_2: response.data?.brand?.secondary_color?.color_2,
                        color_3: response.data?.brand?.secondary_color?.color_3,
                        color_4: response.data?.brand?.secondary_color?.color_4,
                    },
                    social_links: {
                        twitter: {
                            text: response.data?.brand?.social_links?.twitter?.text,
                            is_active: response.data?.brand?.social_links?.twitter?.is_active || false,
                        },
                        instagram: {
                            text: response.data?.brand?.social_links?.instagram?.text,
                            is_active: response.data?.brand?.social_links?.instagram?.is_active || false,
                        },
                        facebook: {
                            text: response.data?.brand?.social_links?.facebook?.text,
                            is_active: response.data?.brand?.social_links?.facebook?.is_active || false,
                        },
                        discord: {
                            text: response.data?.brand?.social_links?.discord?.text,
                            is_active: response.data?.brand?.social_links?.discord?.is_active || false,
                        },
                        youtube: {
                            text: response.data?.brand?.social_links?.youtube?.text,
                            is_active: response.data?.brand?.social_links?.youtube?.is_active || false,
                        },
                        linkedin: {
                            text: response.data?.brand?.social_links?.linkedin?.text,
                            is_active: response.data?.brand?.social_links?.linkedin?.is_active || false,
                        },
                    }
                });
            } else {
            }
        } catch (err) {
        }
    };

    const ValidateSchema = Yup.object().shape({
        // password: Yup.string().required("Required"),
        // waitList: Yup.string().required("Required"),
        // time_zone: Yup.string().required("Required"),
        // refer_reward: Yup.string().required("Required"),
        // is_protected: Yup.string().required("Required"),
        // is_refer_reward_on: Yup.string().required("Required"),
    });

    const onSubmit = (values, { resetForm }) => {
        const formData = new FormData();

        formData.append("header_logo", values.header_logo);
        formData.append("footer_logo", values.footer_logo);
        formData.append("cover_image", values.cover_image);
        formData.append("brand", JSON.stringify(values));
        if (values._id) {
            formData.append("_id", values._id);
            settingServ.updateRecord(formData).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("Settings added successfully");
                }
            });
        } else {
            settingServ.addrecord(formData).then((res) => {
                if (res.err) {
                    toast.error(res.err)
                } else {
                    toast.success("Settings added successfully");
                }
            });
        }
    };

    const formik = useFormik({
        initialValues: value,
        validateOnBlur: true,
        onSubmit,
        validationSchema: ValidateSchema,
        enableReinitialize: true,
    });
    return (
        <>
            <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
                <div className="commonCart account_page">
                    <div className="stepFormHead d-flex align-items-center">
                        <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
                            <div className="createCourseHeading">
                                <h4>Settings | Advertising</h4>
                            </div>
                        </div>
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                        <div className="userProfile_details coming-soon-custom" style={{ height: "610px" }}>
                            Coming Soon
                        </div>
                    </form>
                </div>
            </div>
            <ToastContainer />

        </>
    )
} 