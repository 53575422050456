import React from "react";
import {useState, useEffect} from "react"
function ConfirmDeletePopup({onClose, onSucess, onFail, id}) {

  const[confirmText, setConfirmText] = useState("")
  const[isDisable, setIsDisable] = useState(true)
  const delFun=()=>{
    if(confirmText.toUpperCase()=="DELETE" ){
      onSucess(true)
    }
  }
  useEffect(()=>{
  if(confirmText.toUpperCase()==='DELETE'){
    setIsDisable(false)
  }
  else{
    setIsDisable(true)
  }
  },[confirmText])
  
  return (
    <div  className="modal commanModel createCustomLink show" id="confirmDelete" style={{ display: "block" }} onClick={() => {
      onClose();
    }}>
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div className="modal-content" onClick={(e) => {
                e.stopPropagation();
              }}>
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img
                    src="/images/svg-icons/Delete.svg"
                    alt="icons"
                    className="img-fluid"
                  />{" "}
                  <span>Are you sure?</span>
                </h4>
                <button
                  type="button"
                  className="btn-close"
                  onClick={onClose}
                />
              </div>
              {/* Modal body */}
              <div className="modal-body ">
                <div className="connectWalletOuter">
                  <div className="modelFormInput">
                    <div className="row p0" style={{ border:"none", margin:"0px"}}>
                      <div className="col-12 p0" >
                        <p mr-bottom>
                          You are about to delete something permanently. This
                          action can not be reversed once executed.
                        </p>
                      </div>
                    </div>
                    <div className="mb-3">
                      <label htmlFor="HeadingText" className="form-label">
                        Type DELETE to confirm
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="HeadingText delConfirmText"
                        placeholder="Please type DELETE"
                        name="name"
                        value={confirmText}
                        onChange={(e)=>{setConfirmText(e.target.value)}}
                      />
                    </div>
                  </div>
                  <div className="modelSignBtn pt-5">
                    <div className="d-grid marginBottom10">
                      <a
                        href="javascript:void(0);"
                        className="btn btn-block mvcBtn2"
                        onClick={()=>{onClose()}}
                      >
                        Cancel
                      </a>
                    </div>
                    <div className="d-grid" onClick={delFun}>
                      <button
                        type="submit"
                        className={"btn btn-primary btn-block" +
                        (isDisable && " disabled")
                        } 
                      >
                        Delete
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ConfirmDeletePopup;
