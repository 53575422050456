import { Link } from "react-router-dom";
import React, { useState,useContext, useEffect } from "react";
import { GlobalContext } from "../context/GlobalContext";
export default function UserDropDown({ handleAuthState }) {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user
  // console.log(user)
  const logoutHandler = () => {
    window.user = null;
    localStorage.clear();
    handleAuthState(false);
  };
  return (
    <div className="user_Profile dropdown d-none d-lg-block">
      <a
        href="javascript:void(0)"
        className="d-flex align-items-center"
        data-bs-toggle="dropdown"
      >
        <img src={user?.data?.file==""? "/images/svg-icons/doodle.svg" : user?.data?.file} className="userProfilePicHeight"/>
        <div className="user_name">
          <h5 className="mb-0" style={{ position: "relative", top: "-1px" }}>
            {user?.data?.full_name}
          </h5>
          <p
            className="mb-0 firstLetterCapital"
            style={{ position: "relative", top: "1px" }}
          >
            {user?.data?.role[0]} Role
          </p>
        </div>
        <img
          src="/images/svg-icons/Expand-Arrow.svg"
          className="img-fluid userdrop_icon"
        />
      </a>
      <div className="userprodile_droop dropdown-menu py-0">
        <div className="userInner_profileList">
          <ul className="list-unstyled mb-0">
            <li>
              <Link to="/account">
                <img
                  src="/images/svg-icons/User.svg"
                  alt="dropdownIcon"
                  className="img-fluid mr-right10"
                />{" "}
                Account{" "}
              </Link>
            </li>
            <li>
              <Link to="/support_ticket">
                <img
                  src="/images/svg-icons/bugIcon.svg"
                  alt="dropdownIcon"
                  className="img-fluid mr-right10"
                />{" "}
                Report a bug{" "}
              </Link>
            </li>
            <li>
              <Link to="/settings">
                <img
                  src="/images/svg-icons/SettingsIcon.svg"
                  alt="dropdownIcon"
                  className="img-fluid mr-right10"
                />{" "}
                Preferences{" "}
              </Link>
            </li>
            <li>
              <Link to="#" onClick={logoutHandler}>
                <img
                  src="/images/svg-icons/Shutdown.svg"
                  alt="dropdownIcon"
                  className="img-fluid mr-right10"
                />{" "}
                <span style={{ color: "#EE3124" }}>Sign out</span>{" "}
              </Link>
            </li>
          </ul>
        </div>
        <li className="checkMail  checkMailP">
          <a href="https://gmail.com" target="_blank">
            <img
              src="/images/svg-icons/GmailIcon.svg"
              alt="dropdownIcon"
              className="img-fluid  mr-right10"
            />{" "}
            Check Mailbox{" "}
            <img
              style={{ marginLeft: "1px", height: "10px" }}
              src="/images/svg-icons/RedirectIcon.svg"
              alt="dropdownIcon"
            />
          </a>
        </li>
      </div>
    </div>
  );
}
