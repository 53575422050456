import { Link, unstable_HistoryRouter } from "react-router-dom";
import UserService from "../../services/UserService";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import { useState, useEffect } from "react";

const columns = [
  { label: "", key: "col1", cssTd: "dabsSearch" },
  { label: "", key: "col2", cssTd: "dabsSearch" },
];

function UserDatabase() {
  const userServ = new UserService();
  const [total, setTotal] = useState(0);
  const [weekTotal, setWeekTotal] = useState(0);
  useEffect(() => {
    getUserCount();
  }, []);

  const getUserCount = async () => {
    try {
      let response = await userServ.listCount({});
      setTotal(response.total);
      setWeekTotal(response.weekTotal);
    } catch (error) {
      console.log(error);
    }
  };
  const cards = [
    // {
    //   title: "Online Users",
    //   icon: "/images/glossary/Activestate.svg",
    //   value: "000",
    //   footer: "Total Online Today: 000",
    // },
    {
      title: "Total Users",
      icon: "/images/glossary/User_account.svg",
      value: total,
      footer: `+${weekTotal} this week`,
    },
  ];
  let loading = false;
  async function getListData(search, contextSearch, setList, setTotalCount, setList2, list) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
    };

    try {
      if (loading == false) {
        loading = true;
        await userServ.userList(activity).then(async (response) => {
          if (response) {
            let data = response.data.map((u) => {
              return {
                col1: (
                  <>
                    <div class="dabsSearch_name d-flex">
                      <img src={u.file ? u.file : "/images/glossary/Rectangle_shapimg.svg"} className="img-fluid" />
                      <div className="dabsSearch_Text">
                        <p className="name mb-0">{u?.full_name}</p>
                        <p className="email mb-0 lineHeight15">
                          @{u?.user_name} - {u.email}
                        </p>
                      </div>
                    </div>
                  </>
                ),
                col2: (
                  <>
                    <div className="view_userUpdateBtn">
                      <Link to={"/userDatabaseProfile/" + u._id}>View User Insights</Link>
                    </div>
                  </>
                ),
              };
            });
            await setList(search.searchTxt.length > 0 && search.start > 0 ? [...list, ...data] : data);
            setTotalCount(response.total);
            loading = false;
          }
        });
      }
    } catch (err) {
      throw err;
    }
  }

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search users"
        columns={columns}
        getListDataFunc={getListData}
        perPage={8}
        scrollPagination={true}
      />
      {/* <div className="commonCart coursePublishpage">
        <div className="course_header">
          <div className="headerSearchBar">
            <div className="mobSearch_new has-clear">
              <div className="input-group">
                <button className="btn" type="button" style={{ zIndex: 9 }}>
                  <img
                    src="images/svg-icons/search.svg"
                    alt="logo"
                    className="img-fluid"
                  />
                </button>
                <input
                  type="text"
                  className="form-control border-0"
                  placeholder
                />
                <span className="close form-control-clear hidden">
                  <img
                    src="images/svg-icons/close.svg"
                    alt="search"
                    className="img-fluid"
                  />
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="noDatafound">
          <div className="d-flex align-items-center justify-content-center">
            <div className="upload_filesDiv text-center">
              <img
                src="images/glossary/searching_nodata.svg"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
      </div> */}
    </div>
  );
}

export default UserDatabase;
