import { useNavigate } from "react-router-dom";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import CourseService from "../../services/CourseService";
import { useEffect, useState } from "react";

const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const columns = [
  {
    label: "Published On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Course Title", key: "course_name" },
  {
    label: "Author",
    key: "createdBy",
  },
  {
    label: "Views",
    key: "viewCount",
  },
  {
    label: "User Count",
    key: "users_enrolled",
  },
  {
    label: "Reward",
    key: "rewards",
  },
  {
    label: "Bookmarks",
    key: "bookmarkCount",
  },
  { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "course_name--asc", value: "A to Z" },
  { key: "course_name--desc", value: "Z to A" },
];

function CourseList() {
  const[topCardData, setTopCardData]=useState()
  const cards = [
    {
      title: "Total Course",
      icon: "/images/account/TotalCourse.svg",
      value: topCardData?.totalCount,
      footer: `+${topCardData?.monthTotal} this month`,
    },
    {
      title: "Active Course",
      icon: "/images/account/ActiveCourse.svg",
      value: topCardData?.activeCount,
      footer: `+${topCardData?.monthActive} this month`,
    },
    {
      title: "Inactive Course",
      icon: "/images/account/InactiveCourse.svg",
      value: topCardData?.inactiveCount,
      footer: `+${topCardData?.monthInactive} this month`,
    },
    {
      title: "Drafts",
      icon: "/images/account/TotalDrafts.svg",
      value: topCardData?.draftCount,
      footer: `+${topCardData?.monthDraft} this month`,
    },
  ];
  useEffect(()=>{
    getCourseCount()
  },[])
  const navigate = useNavigate();
  const courseServ = new CourseService();
  const getCourseCount = async()=>{
    try {
      let response = await courseServ.listCount({})
      setTopCardData(response)
    } catch (error) {
      console.log(error)
    }
  }
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "course"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    try {
      let response = await courseServ.courseList(activity);
      if (response.data) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }
  const analyticsHandler =(id)=>{
     navigate("/course/course_analytics/" + id)
  }
  const editHandler = async (id) => {
    navigate("/course/create_course/" + id);
  };

  const viewHandler = async (id) => {
    window.open(
      process.env.REACT_APP_FRONTEND_BASEURL + "/courses/info/" + id,
      "_blank"
    );
  };

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await courseServ.deleteCourse(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (course) => {
    try {
      let isActive = !course.is_active;
      let obj = {
        is_active: isActive,
        _id: course._id,
      };
      let res = await courseServ.activeToggle(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search courses"
        buttonLink="/course/create_course"
        buttonText="Create a Course"
        buttonLink2="/draft_course"
        buttonText2="View Drafts"
        buttonIcon2="/images/svg-icons/viewDraftIcon.svg"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        editHandlerFunc={editHandler}
        viewHandlerFunc={viewHandler}
        analyticsHandlerFunc={analyticsHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
      />
    </div>
  );
}

export default CourseList;
