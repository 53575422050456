import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { v4 as uuidv4 } from "uuid";
import CustomLinkService from "../../services/CustomLinkService";
import UserService from "../../services/UserService";
import SucessPopUp from "./SucessPopUp";
import Tippy from "@tippyjs/react";
import "tippy.js/dist/tippy.css";

export default function CustomLinkForm({ onClose, onSuccess, onFail, id }) {
  const [showSucessPopup, setShowSucessPopup] = useState(true);
  // const link = {
  //   shortLink: `${process.env.REACT_APP_API_BASEURL}/${uuidv4()}`,
  //   fullLink: `${process.env.REACT_APP_API_BASEURL}/${uuidv4()}`,
  // };
  const customServe = new CustomLinkService();
  const [userList, setUserList] = useState([]);
  const [showLoading, setShowLoading] = useState(false);
  const [link, setLink] = useState({});
  const [value, setValue] = useState({
    _id: "",
    campaignId: "",
    campaignSource: "",
    campaignTerm: "",
    campaignContent: "",
    campaignMedium: "",
    campaignName: "",
    purpose: "",
  });
  const [search, setSearch] = useState({
    start: 0,
    perPage: 10,
    searchTxt: "",
    searchField: "",
  });

  useEffect(() => {
    userListApi();
    if (id) {
      getActiveLinkData(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getActiveLinkData = async (id) => {
    try {
      let response = await customServe.getCustomLinkDetails(id);
      if (response) {
        setValue({
          _id: id,
          campaignId: response.data.campaignId,
          campaignSource: response.data.campaignSource,
          campaignTerm: response.data.campaignTerm,
          campaignContent: response.data.campaignContent,
          campaignMedium: response.data.campaignMedium,
          campaignName: response.data.campaignName,
          purpose: response.data.purpose,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object().shape({
    campaignId: Yup.string().required("Required"),
    campaignSource: Yup.string().required("Required"),
    campaignMedium: Yup.string().required("Required"),
    campaignName: Yup.string().required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setShowLoading(true);
    let obj = {
      _id: values._id,
      campaignId: values.campaignId,
      campaignSource: values.campaignSource,
      campaignTerm: values.campaignTerm,
      campaignContent: values.campaignContent,
      campaignMedium: values.campaignMedium,
      campaignName: values.campaignName,
      purpose: values.purpose,
      link: `${process.env.REACT_APP_FRONTEND_BASEURL}`,
    };

    if (values._id) {
      customServe.editCustomLinkRecord(obj).then((res) => {
        setValue({
          _id: "",
          campaignId: "",
          campaignSource: "",
          campaignTerm: "",
          campaignContent: "",
          campaignMedium: "",
          campaignName: "",
          purpose: "",
        });
        resetForm();

        if (res.err) {
          onFail(res.err);
        } else {
          setLink({ campaignUrl: res.data.campaignUrl, campaignShortUrl: res.data.campaignShortUrl });
          // onSuccess();
          setShowSucessPopup(false);
        }
      });
    } else {
      delete obj._id;
      customServe.addCustomLinkRecord(obj).then((res) => {
        console.log(res);
        if (res.err) {
          onFail(res.err);
        } else {
          // onSuccess();
          setLink({ campaignUrl: res.data.campaignUrl, campaignShortUrl: res.data.campaignShortUrl });
          setShowSucessPopup(false);
        }
      });
    }
    setShowLoading(false);
  };

  let userServe = new UserService();

  async function userListApi() {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: { searchText: search.searchTxt },
    };

    try {
      let response = await userServe.userListApi(activity);
      if (response) {
        setUserList(response.data);
      }
    } catch (err) {
      throw err;
    }
  }

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      {showSucessPopup ? (
        <div
          className="modal commanModel addCareer show"
          id="addCareer"
          style={{ display: "block" }}
          onClick={() => {
            onClose();
          }}
        >
          <div className="vertical-alignment-helper">
            <div className="vertical-align-center">
              <div className="modal-dialog">
                <div
                  className="modal-content"
                  onClick={(e) => {
                    e.stopPropagation();
                  }}
                >
                  {/* Modal Header */}
                  <div className="modal-header">
                    <h4 className="modal-title d-flex align-items-center">
                      <img src="/images/icons/link.svg" alt="icons" className="img-fluid" />{" "}
                      <span>Campaign URL Builder</span>
                    </h4>
                    <button type="button" className="btn-close" onClick={onClose} />
                  </div>
                  {/* Modal body */}
                  <div className="modal-body">
                    <div className="urlFormSubHeading">
                      This tool allows us to easily add campaign parameters to URLs so we can measure Custom Campaigns
                      in Google Analytics.
                    </div>
                    <form onSubmit={formik.handleSubmit}>
                      <div className="connectWalletOuter">
                        <div className="modelFormInput">
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="campaignId" className="form-label">
                                  Campaign ID*
                                </label>
                                <Tippy content="Used to identify which ads campaign this referral references. Use Campaign ID to identify a specific ads campaign.">
                                  <label className="lableEg">
                                    (e.g. abc.123) <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="campaignId"
                                  placeholder="Please enter the ads compaign id"
                                  name="campaignId"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.campaignId}
                                />
                                {formik.touched.campaignId && formik.errors.campaignId ? (
                                  <div className="formik-errors bg-error">{formik.errors.campaignId}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="campaignSource" className="form-label">
                                  Campaign Source*
                                </label>
                                <Tippy content="Use Campaign Medium to identify a medium such as email or cost-per-click.">
                                  <label className="lableEg">
                                    (e.g. google, newsletter) <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="campaignSource"
                                  placeholder="Please enter the referrer"
                                  name="campaignSource"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.campaignSource}
                                />
                                {formik.touched.campaignSource && formik.errors.campaignSource ? (
                                  <div className="formik-errors bg-error">{formik.errors.campaignSource}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="campaignMedium" className="form-label">
                                  Campaign Medium*
                                </label>
                                <Tippy content="Use Campaign Medium to identify a medium such as email or cost-per-click.">
                                  <label className="lableEg">
                                    (e.g. cpc, banner, email) <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="campaignMedium"
                                  placeholder="Please enter the marketing medium"
                                  name="campaignMedium"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.campaignMedium}
                                />
                                {formik.touched.campaignMedium && formik.errors.campaignMedium ? (
                                  <div className="formik-errors bg-error">{formik.errors.campaignMedium}</div>
                                ) : null}
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="campaignName" className="form-label">
                                  Campaign Name*
                                </label>
                                <Tippy content="Used for keyword analysis. Use Campaign Name to identify a specific product promotion or strategic campaign.">
                                  <label className="lableEg">
                                    (e.g. spring_sale)
                                    <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="campaignName"
                                  placeholder="Please enter the compaign Name"
                                  name="campaignName"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.campaignName}
                                />
                                {formik.touched.campaignName && formik.errors.campaignName ? (
                                  <div className="formik-errors bg-error">{formik.errors.campaignName}</div>
                                ) : null}
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="campaignTerm" className="form-label">
                                  Campaign Term
                                </label>
                                <Tippy content="Used for paid search. Use Campaign Term to note the keywords for this ad.">
                                  <label className="lableEg">
                                    (e.g. running+shoes) <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="campaignTerm"
                                  placeholder="Please enter the compaign term"
                                  name="campaignTerm"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.campaignTerm}
                                />
                              </div>
                            </div>
                            <div className="col-md-6">
                              <div className="mb-3">
                                <label htmlFor="campaignContent" className="form-label">
                                  Campaign Content
                                </label>
                                <Tippy content="Used for A/B testing and content-targeted ads. Use Campaign Content to differentiate ads or links that point to the same URL.">
                                  <label className="lableEg">
                                    (e.g. logolink)
                                    <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="campaignContent"
                                  placeholder="Please enter the compaign content tag"
                                  name="campaignContent"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.campaignContent}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="purpose" className="form-label">
                                  Campaign Purpose
                                </label>
                                <Tippy content="Use for defining the reason for creating this URL.">
                                  <label className="lableEg">
                                    <img src="/images/svg-icons/exclamationIcon.svg" />{" "}
                                  </label>
                                </Tippy>
                                <textarea
                                  type="text"
                                  className="form-control"
                                  id="purpose"
                                  placeholder="Please enter the purpose"
                                  name="purpose"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.purpose}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="modelSignBtn">
                          <div className="d-grid">
                            <button
                              type="submit"
                              className={
                                "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                              }
                            >
                              {showLoading ? (
                                <>
                                  <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                                </>
                              ) : (
                                <>Create URL</>
                              )}
                            </button>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <SucessPopUp onSuccess={onSuccess} onClose={onClose} onFail={onFail} id={id} link={link} />
      )}
    </>
  );
}
