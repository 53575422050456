import {useState, useEffect} from "react";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import SearchkeywordService from "../../services/searchKeywordService";

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "keyword--asc", value: "A to Z" },
  { key: "keyword--desc", value: "Z to A" },
];
const columns = [
  {
    label: "Keyword",
    key: "keyword",
  },
  { label: "Times Searched", key: "search_count" },
];
function SearchKeyword() {
  const searchKeywordServ = new SearchkeywordService();
  const[totalCount, setTotalCount] =useState(0)
  const[monthCount, setmonthCount] =useState(0)
  useEffect(()=>{
    getSearchKewyordCount()
  },[])
  const getSearchKewyordCount = async()=>{
    try {
      let response = await searchKeywordServ.listCount({})
       setTotalCount(response.totalCount)
       setmonthCount(response.monthTotal)
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Total Search Results",
      icon: "/images/svg-icons/searchKeyCard.svg",
      value: totalCount,
      footer: `+${monthCount} this month`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount, setList2) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await searchKeywordServ.listAll(activity);
      if (response) {
        const half = Math.ceil(response.data.length / 2);
        let first = response.data.slice(0, half);
        let second = response.data.slice(half);
        second.length = first.length;
        setList(first);
        setList2(second);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search keywords"
        // buttonLink="/blogs_form"
        // buttonText="Post a Blog"
        columns={columns}
        getListDataFunc={getListData}
        // deleteHandlerFunc={deleteHandler}
        // editHandlerFunc={editHandler}
        // activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
      />
    </div>
  );
}

export default SearchKeyword;
