import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import TeamService from "../../services/TeamService";
import RoleService from "../../services/RoleService";
import Dropify from "../../shared/Dropify/Dropify";
import moment from "moment";
import axios from "axios";
import GlobalContext from "../../context/GlobalContext";
export default function TeamForm({ onClose, onSuccess, onFail, id }) {
  const teamServ = new TeamService();
  const roleServ = new RoleService();
  const [tagList, setTagList] = useState([]);
  const [value, setValue] = useState({
    full_name: "",
    job_title: "",
    email: "",
    type: "",
    panel_access: false,
    panel_role: "",
  });
  const [roleList, setRoleList] = useState([]);
  useEffect(() => {
    getRoleList();
    if (id) {
      getTeamDetail(id);
    }
  }, []);
  const getRoleList = async () => {
    try {
      let obj = {};
      await roleServ.listAll(obj).then((resp) => {
        setRoleList(resp.data);
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getTeamDetail = async (id) => {
    try {
      let response = await teamServ.getDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          full_name: response.data.user_id.full_name,
          job_title: response.data.job_title,
          email: response.data.user_id.email,
          type: response.data.type,
          panel_access: response.data.panel_access,
          panel_role: response.data.panel_role,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object({
    full_name: Yup.string().required("Required"),
    job_title: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    type: Yup.string().required("Required"),
    panel_access: Yup.string().required("Required"),
    panel_role: Yup.string().required("Required"),
  });

  const onSubmit = async (values) => {
    let obj = { ...values };
    try {
      let response;
      if (obj._id) {
        response = await teamServ.editRecord(obj);
      } else {
        delete obj._id;
        response = await teamServ.addRecord(obj);
      }
      if (response.err) {
        onFail(response.err);
      } else {
        onSuccess();
        window.location.reload();
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addCareer show"
      id="addVideo"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/svg-icons/teamIcon.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} a Team Member</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Full Name*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="full_name"
                              placeholder="Please enter the name"
                              name="full_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.full_name}
                            />
                            {formik.touched.full_name && formik.errors.full_name ? (
                              <div className="formik-errors bg-error">{formik.errors.full_name}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Email Address*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Please enter the name"
                              name="email"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email}
                            />
                            {formik.touched.email && formik.errors.email ? (
                              <div className="formik-errors bg-error">{formik.errors.email}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Job Title*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="job_title"
                              placeholder="Please enter the name"
                              name="job_title"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.job_title}
                            />
                            {formik.touched.job_title && formik.errors.job_title ? (
                              <div className="formik-errors bg-error">{formik.errors.job_title}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.website" className="form-label">
                              Type*
                            </label>
                            <div className="custom-select fa-caret-down">
                              <select
                                name="type"
                                className={"form-select " + (formik.values.type ? "" : "color-fade")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.type}
                              >
                                <option value="">Please select the Type</option>
                                <option value="founder">Founder</option>
                                <option value="employee">Employee</option>
                                <option value="contractor">Contractor</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.instagram" className="form-label">
                              Panel Access
                            </label>
                            <div className=" d-flex align-items-center">
                              <label className="switch">
                                <input
                                  type="checkbox"
                                  className="form-control"
                                  id="panel_access"
                                  name="panel_access"
                                  onChange={formik.handleChange}
                                  checked={formik.values.panel_access}
                                />
                                <span className="slider round" />
                              </label>
                              <p className="m-0   ms-2 offCheckHeading">{formik.values.panel_access ? "On" : "OFF"}</p>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="mb-3">
                          <label htmlFor="social_links.instagram" className="form-label">
                            Panel Role
                          </label>
                          <div className="role-header d-flex align-items-center">
                            {roleList &&
                              roleList.map((item, idx) => {
                                return (
                                  // <option value={item._id} key={idx}>
                                  //   {item.title}
                                  // </option>
                                  <div className="radio-btn d-flex">
                                    <input
                                      type="radio"
                                      name="panel_role"
                                      value={item._id}
                                      onChange={formik.handleChange}
                                      checked={formik.values.panel_role == item._id}
                                    />
                                    <span className="round">{item.title}</span>
                                  </div>
                                );
                              })}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          Save
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
