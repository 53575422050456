import React, { Component } from "react";
import AccountService from "../../services/AccountService";
import { Formik, ErrorMessage } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "axios";

export default class WalletAddress extends Component {
  constructor(props) {
    super(props);

    this.state = {
      user: {},
    };
    this.state.user = {
      wallet_address: "",
    };
    this.accountServ = new AccountService();
    this.schema = Yup.object({
      wallet_address: Yup.string().required("Required"),
    });
  }

  componentDidMount() {
    this.getUserDetails();
  }

  getUserDetails = async () => {
    try {
      let userId = window.user && window.user.data._id;
      let response = await this.accountServ.getUser(userId);
      if (response.data) {
        this.setState({ user: response.data });
      }
    } catch (err) {
      this.setState({
        user: [],
        errorMsg: err.error,
      });
    }
  };

  submitAddress = async (values) => {
    let tx = values;
    delete tx.redirect;
    const formData = new FormData();
    for (let prop in tx) {
      formData.append(prop, tx[prop]);
    }
    const token = window.user ? window.user.token : "no-token";
    const config = {
      headers: {
        "content-type": "multipart/form-data",
        Authorization: "Bearer " + token,
      },
    };

    try {
      const response = await axios.put(
        process.env.REACT_APP_API_BASEURL + "/",
        formData,
        config
      );
      if (response.data.message) {
        setTimeout(() => {
          toast.success("User updated successfully");
          this.setState({ redirect: true });
        }, 1000);
      }
    } catch (err) {
      toast.error(err);
    }
  };
  render() {
    return (
      <h1>wallet Address</h1>
    );
  }
}
