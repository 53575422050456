import React, { useContext } from "react";
import { CircularProgressbar, buildStyles } from "react-circular-progressbar";
import "react-circular-progressbar/dist/styles.css";
import FileStatusContext from "../../context/FileStatusContext";
import { useState } from "react";
export default function FileUpload({ setShowUploadingPopup, btnText, uploadProgress }) {
  const fileCtx = useContext(FileStatusContext);
  const [status, setStatus] = fileCtx.status;
  console.log(status);
  return (
    <>
      <div className="progressHeader">
        {status && Object.keys(status).length > 1 ? (
          <div className="notificationAlert upldfileList_top p-0 notificationAlertCustom notiTop ">
            <div className="d-flex justify-content-between upldfileList_box">
              <div className="notificationbox">
                <span className="name ms-1">Files Uploading</span>
                <p className="remaining mb-0">
                  {Object.keys(status).filter((i) => status[i] !== 100).length} remaining
                </p>
              </div>
              <div className="cancelIcon">
                <a href="javascript:void(0)" data-bs-toggle="collapse" data-bs-target="#demo">
                  {" "}
                  <i className="fa fa-chevron-down" aria-hidden="true" />
                </a>
              </div>
            </div>
            <div className="upldfileList collapse show" id="demo">
              <ul className="list-unstyled mb-0">
                {Object.keys(status).map((i) => {
                  return (
                    <li className="d-flex justify-content-between mt-2">
                      <div className="notificationbox">
                        {status[i] < 100 ? (
                          <div className="progress d-inline-block align-middle custom-progress">
                            <CircularProgressbar
                              value={status[i]}
                              styles={buildStyles({
                                pathColor: `#4A5056`,
                                trailColor: "#fff",
                              })}
                            />
                          </div>
                        ) : (
                          <>
                            <div className="succesright_img d-inline-block">
                              <img
                                src="images/svg-icons/greenTick.svg"
                                className="bg-light"
                                style={{ borderRadius: "50%" }}
                              />
                            </div>
                          </>
                        )}
                        <span className="name ms-1 word-break">
                          {status[i] < 100 ? `${i} Uploading` : `${i} Uploaded`}
                        </span>
                      </div>
                      <div
                        className="cancelIcon"
                        onClick={() => {
                          let _temp = status;
                          delete _temp[i];
                          setStatus({ ..._temp });
                        }}
                      >
                        <a href="javascript:void(0)">
                          {" "}
                          <img src="images/glossary/white_cancel.svg" />
                        </a>
                      </div>
                    </li>
                  );
                })}
              </ul>
            </div>
          </div>
        ) : (
          Object.keys(status).map((i) => {
            return (
              <div className="notificationAlert notificationAlertCustom notiTop d-flex justify-content-between">
                <div className="notificationbox">
                  {status[i] < 100 ? (
                    <div className="progress d-inline-block align-middle custom-progress">
                      <CircularProgressbar
                        value={status[i]}
                        styles={buildStyles({
                          pathColor: `#4A5056`,
                          trailColor: "#fff",
                        })}
                      />
                      {/* </div> */}
                    </div>
                  ) : (
                    <>
                      <div className="succesright_img d-inline-block">
                        <img
                          src="images/svg-icons/greenTick.svg"
                          className="bg-light"
                          style={{ borderRadius: "50%" }}
                        />
                      </div>
                    </>
                  )}

                  <span className="name ms-1"> {status[i] < 100 ? "File Uploading" : "File Uploaded"}</span>
                </div>
                <div className="cancelIcon" onClick={() => setShowUploadingPopup(false)}>
                  <a href="javascript:void(0)">
                    {" "}
                    <img src="images/glossary/white_cancel.svg" />
                  </a>
                </div>
              </div>
            );
          })
        )}
      </div>
    </>
  );
}
