import {useState, useEfeect} from "react";
import NotificationsList from "./NotificationsList";
import {Link} from "react-router-dom"
function Notifications() {
  const[type, setType]=useState("web")
  const[notificationTotal, setNotificationTotal]=useState(0)
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="notification_head  d-flex align-items-center justify-content-between coursePublishpage">
        <div className="notif_headleft">
          <img src="images/svg-icons/notificationHeaderIcon.svg" className="bellIconMargin"/>
          <h4 className="mb-0">Notifications</h4>
        </div>
      </div>
      <div className="notificationNav d-flex justify-content-between ">
        <div className="d-flex">
          <p onClick={()=>setType("web")} className={"notifyLi"+ (type=="web" ? " notifyActive" :"")}>Web app</p>
          <p onClick={()=>setType("mobile")} className={"notifyLi"+ (type=="mobile" ? " notifyActive" :"")}>Mobile app</p>
          <p onClick={()=>setType("admin")} className={"notifyLi"+ (type=="admin" ? " notifyActive" :"")}>Admin app</p>
        </div>
        <div className="totalCount">Total: {notificationTotal}</div>
      </div>
      <NotificationsList type={type} setNotificationTotal={setNotificationTotal}/>
    </div>
  );
}

export default Notifications;
