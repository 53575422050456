import { useEffect, useContext } from "react";
import GlobalContext from "../../context/GlobalContext";
import Card from "./Card";
import {Link} from "react-router-dom"
function HomePage() {
  const sitectx = useContext(GlobalContext);
  useEffect(() => {
    sitectx.popup.setShowPopup(false);
  }, []);

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="mvcDashboard">
        <h2 className="headingText1">General Shortcuts</h2>
        <div className="genralCategoriesCards d-flex">
          <Card
            image="/images/dashboard/genral/Course.svg"
            text="Create a Course"
            link="/course/create_course"
            selectNavItem="publish_manage_course"
          />
          <Card
            image="/images/dashboard/genral/Glossary.svg"
            text="Add to Glossary"
            link="/publish_glossary"
            selectNavItem="publish_manage_publish_glossary"
          />
          <Card image="/images/dashboard/genral/Video.svg" text="Add a Video" link="/video" selectNavItem="" />
          <Card
            image="/images/dashboard/genral/CryptoRecord.svg"
            text="Add a Crypto Record"
            link="/"
            selectNavItem=""
          />
          <Card
            image="/images/dashboard/genral/Update.svg"
            text="Add an Update"
            link="/publish_updates"
            selectNavItem="publish_manage_publish_updates"
          />
          <Card
            image="/images/dashboard/genral/BlogArticle.svg"
            text="Post a Blog Article"
            link="/blogs_form"
            selectNavItem="publish_manage_blogs"
          />
          <Card
            image="/images/dashboard/genral/FAQ.svg"
            text="Add a FAQ"
            link="/faq"
            selectNavItem="publish_manage_faq"
          />
          <Card
            image="/images/dashboard/genral/Newsletter.svg"
            text="Compose Newsletter"
            link="/newsletter"
            selectNavItem="newsletter"
          />
          <Card
            image="/images/dashboard/genral/NewsletterSub.svg"
            text="Compose Newsletter"
            link="/newsletter"
            selectNavItem="newsletter"
          />
          <Card
            image="/images/dashboard/genral/Tickets.svg"
            text="View Support Tickets"
            link="/support_ticket"
            selectNavItem="support_ticket"
          />
          <Card
            image="/images/dashboard/genral/CustomLink.svg"
            text="Create a Custom Link"
            link="/custom_link"
            selectNavItem="custom_link"
          />
        </div>
      </div>
      <div className="mvcDashboard borderTop">
        <h2 className="headingText1">Admin Shortcuts</h2>
        <div className="genralCategoriesCards d-flex">
          <Link to="/analytics" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/PlatformAnalytics.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>View Platform Analytics</p>
              </div>
            </div>
          </Link>
          <Link to="/userDatabase" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/UserInsights.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>View User Insights</p>
              </div>
            </div>
          </Link>
          <Link to="/proUsers" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/ProUsers.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>View Pro Users</p>
              </div>
            </div>
          </Link>
          <Link to="/team_roles" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/Employee.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>Add an Employee</p>
              </div>
            </div>
          </Link>
          <Link to="/notifications" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/Notification.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>Send a Notification</p>
              </div>
            </div>
          </Link>
          <Link to="/setting/seo" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/PlatformSEO.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>Update Platform SEO</p>
              </div>
            </div>
          </Link>
          <Link to="/setting/slider" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/ScreenSliders.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>Change Home Screen Sliders</p>
              </div>
            </div>
          </Link>
          <Link to="/setting/backup" className="genralLinkCard">
            <div className="genralCards text-center">
              <div className="cardImg d-flex">
                <img src="images/dashboard/adminshorts/PlatformBackup.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="cardImgText">
                <p>Platform Backup</p>
              </div>
            </div>
          </Link>
        </div>
      </div>
      <div className="platformStats clearfix">
        <h2 className="headingText1">Platform Stats</h2>
        <div className="onlneStats d-flex">
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Online Users</h4>
                <img src="images/dashboard/platform/ActiveState.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Total online today: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Total Users</h4>
                <img src="images/dashboard/platform/UserAccount.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+000 this week</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Pro Users</h4>
                <img src="images/dashboard/platform/LightningBolt.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">NA</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Coming soon</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Total Unique Visitors</h4>
                <img src="images/dashboard/platform/MeetingTime.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Coins Distributed</h4>
                <img src="images/dashboard/platform/CoinsDistributed.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Daily Unique Visitors</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Yesterday: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Weekly Unique Visitors</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Last week: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Monthly Unique Visitors</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Last month: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Sessions Today</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Total sessions: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Avg. Session Duration (Today)</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">00 mins</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Avg. Session Duration: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Avg. Pages/Session</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">0.00</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Pageviews today: 000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Bounce Rate</h4>
              </div>
              <div className="centerText">
                <h3 className="m-0">0.00%</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">00 per 1000</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Courses Count</h4>
                <img src="images/dashboard/platform/ELearning.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Glossary Count</h4>
                <img src="images/dashboard/platform/GlossaryCount.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Blog Articles Count</h4>
                <img src="images/dashboard/platform/Blog.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Videos Count</h4>
                <img src="images/dashboard/platform/VideoGallery.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">NA</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Coming soon</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Crypto Records Count</h4>
                <img src="images/dashboard/platform/Prize.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">NA</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Coming soon</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Profiles Count</h4>
                <img src="images/dashboard/platform/Profiles.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">NA</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Coming soon</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Newsletter Subscribers</h4>
                <img src="images/dashboard/platform/NewsletterSubscribers.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">NA</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Coming soon</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Open Support Tickets</h4>
                <img src="images/dashboard/platform/OpenSupportTickets.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">NA</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">Coming soon</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
      <div className="platformStats clearfix borderTop">
        <h2 className="headingText1">Social Stats</h2>
        <div className="onlneStats d-flex">
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Twitter Followers</h4>
                <img src="images/dashboard/platform/Twitter.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Instagram Followers</h4>
                <img src="images/dashboard/platform/Instagram.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Facebook Followers</h4>
                <img src="images/dashboard/platform/FacebookF.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Discord Members</h4>
                <img src="images/dashboard/platform/DiscordNew.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
          <a className="onUserLink">
            <div className="onlineUserCards d-flex">
              <div className="topText d-flex">
                <h4 className="m-0">Youtube Subscribers</h4>
                <img src="images/dashboard/platform/YouTube.svg" alt="icons" className="img-fluid" />
              </div>
              <div className="centerText">
                <h3 className="m-0">000</h3>
              </div>
              <div className="btmText">
                <h6 className="m-0">+00 this month</h6>
              </div>
            </div>
          </a>
        </div>
      </div>
    </div>
  );
}

export default HomePage;
