import ChannelService from "../../services/ChannelService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
// import SubscribePopup from "./SubscribePopup";

const cards = [
  {
    title: "Total Channels",
    icon: "/images/glossary/newsletter.svg",
    value: "000",
    footer: "+000 this month",
  },
  {
    title: "Total Channels",
    icon: "/images/glossary/subsriber.svg",
    value: "000",
    footer: "+000 this month",
  },
];

const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const emailStatusConversion = (value, index) => {
  return value && value.emailVerified === true ? "Verified" : "Unverified";
};
const registeredUserConversion = (value, index) => {
  return value && value.userId == "" ? "No" : "Yes";
};

const columns = [
  {
    label: "Joined On",
    key: "joined_on",
    valueFunc: convertToDate,
  },
  { label: "Name", key: "channel_name" },
  { label: "Description", key: "channel_description", cssTh: "white_pre", cssTd: "white_pre" },
  //   { label: "Registered User", key: "delivered", valueFunc: emailStatusConversion },
  //   { label: "Email Status", key: "emailVerified", valueFunc: emailStatusConversion },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "channel_name--asc", value: "A to Z" },
  { key: "channel_name--desc", value: "Z to A" },
];

function ChannelList() {
  const channelServ = new ChannelService();

  async function getListData(search, contextSearch, setList, setTotalCount) {
    try {
      let response = await channelServ.listAll({
        start: search.start,
        length: search.perPage,
        filter: { searchText: search.searchTxt },
        sortBy: search.sortBy,
      });
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await channelServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search channel"
        // buttonText="Add a Subsc"
        buttonLink2="/video"
        buttonText2="View Videos"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        sortByOptions={sortByOptions}
        // PopupComponent={SubscribePopup}
        // allowPopEdit={true}
      />
    </div>
  );
}

export default ChannelList;
