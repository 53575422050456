import CustomLinkService from "../../services/CustomLinkService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import CustomLinkForm from "./CustomLinkForm";
import { useState, useEffect } from "react";

const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const limitTitleChar = (value, index) => {
  if (value.campaignShortUrl?.length > 25) {
    return value.campaignShortUrl?.slice(0, 25) + " ...";
  } else {
    return value?.campaignShortUrl;
  }
};
const showStatus = (value, index) => {
  return value && value.is_active == true ? (
    <span className="active_color">Active</span>
  ) : (
    <span className="expired_color">Expired</span>
  );
};
const columns = [
  { label: "Link", key: "campaignShortUrl", cssTd: "white_pre_250", valueFunc: limitTitleChar },
  {
    label: "Created by",
    key: "createdBy",
  },
  {
    label: "Campaign ID",
    key: "campaignId",
  },
  {
    label: "Campaign Source",
    key: "campaignSource",
  },
  {
    label: "Campaign Medium",
    key: "campaignMedium",
  },
  {
    label: "Campaign Name",
    key: "campaignName",
  },
  { label: "Clicks", key: "clicks" },
  { label: "Actions", key: "ACTIONS" },
];

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "createdAt--asc", value: "Oldest first" },
];

export default function CustomLinks() {
  const customServe = new CustomLinkService();
  const [activeCount, setActiveCount] = useState(0);
  const [monthActive, setMonthActive] = useState(0);
  const [deleteCount, setDeleteCount] = useState(0);
  const [extraFilter, setExtraFilter] = useState({});
  useEffect(() => {
    getCustomLinkCount();
  }, []);

  const getCustomLinkCount = async () => {
    try {
      let response = await customServe.listCount({});
      setActiveCount(response.activeCount);
      setDeleteCount(response.deleteCount);
      setMonthActive(response.monthActive);
    } catch (error) {
      console.log(error);
    }
  };
  const cards = [
    {
      title: "Active Links",
      icon: "/images/glossary/active_link.svg",
      value: activeCount,
      footer: `Created this month: ${monthActive}`,
      callback: handleCallback,
    },
    // {
    //   title: "Expired Links",
    //   icon: "/images/glossary/expired_link.svg",
    //   value: inactiveCount,
    //   footer: "",
    // },
    {
      title: "Deleted Links",
      icon: "/images/glossary/delete_link.svg",
      value: deleteCount,
      footer: "View History",
      callback: handleCallback,
    },
  ];
  function handleCallback(value) {
    if (value == "Deleted Links") {
      setExtraFilter({ is_deleted: true });
    } else {
      setExtraFilter({});
    }
  }
  async function getListData(search, contextSearch, setList, setTotalCount) {
    try {
      let obj = {
        start: search.start,
        length: search.perPage,
        filter: { searchText: search.searchTxt },
        sortBy: search.sortBy,
      };
      obj.filter = { ...obj.filter, ...extraFilter };
      let response = await customServe.customLinkListApi(obj);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
        getCustomLinkCount();
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await customServe.deleteCustomLinkRecordApi(id);
        if (response) {
          getCustomLinkCount();
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search custom links"
        buttonText="Create a URL"
        columns={columns}
        updateListFilter={extraFilter}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        sortByOptions={sortByOptions}
        PopupComponent={CustomLinkForm}
        allowPopEdit={true}
      />
    </div>
  );
}
