import { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import CareerService from "../../services/CareerService";

export default function CareerForm({ onClose, onSuccess, onFail, id }) {
  const careerServ = new CareerService();
  const [value, setValue] = useState({
    _id: "",
    role: "",
    location: "",
    availability: "",
    posted_by: window.user.data._id,
    description: "",
  });
  const [showLoading, setShowLoading] = useState(false);
  useEffect(() => {
    if (id) {
      getCareerDetail(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getCareerDetail = async (id) => {
    try {
      let response = await careerServ.getDetails(id);
      if (response) {
        setValue({
          _id: id,
          role: response.data.role,
          location: response.data.location,
          availability: response.data.availability,
          posted_by: response.data.posted_by,
          description: response.data.description,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object({
    role: Yup.string().required("role is a required field"),
    location: Yup.string().required("Location is a required field "),
    availability: Yup.string().required("Availability a required field"),
    description: Yup.string().required("Description is a required field"),
  });

  const onSubmit = async (values) => {
    setShowLoading(true);
    let obj = { ...values };
    try {
      let response;
      if (obj._id) {
        response = await careerServ.updateRecord(obj);
      } else {
        delete obj._id;
        response = await careerServ.addrecord(obj);
      }
      if (response.err) {
        onFail(response.err);
      } else {
        onSuccess();
      }
    } catch (err) {
      onFail(err.message);
    }
    setShowLoading(false);
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addCareer show"
      id="addCareer"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/icons/NewJob.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} an Opening</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="role" className="form-label">
                              Job Title*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="role"
                              placeholder="Please enter the job title"
                              name="role"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.role}
                            />
                            {formik.touched.role && formik.errors.role ? (
                              <div className="formik-errors bg-error">{formik.errors.role}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="location" className="form-label">
                              Location*
                            </label>
                            <div className="custom-select fa-caret-down">
                              <select
                                name="location"
                                className={"form-select " + (formik.values.location ? "" : "color-fade")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.location}
                              >
                                <option value="">Please select the location</option>
                                <option value="Delhi">Delhi</option>
                                <option value="Bengalore">Bengalore</option>
                                <option value="Hyderabad">Hyderabad</option>
                                <option value="Chennai">Chennai</option>
                                <option value="Remote">Remote</option>
                              </select>
                            </div>
                            {formik.touched.location && formik.errors.location ? (
                              <div className="formik-errors bg-error">{formik.errors.location}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="availability" className="form-label">
                              Availability*
                            </label>
                            <div className="custom-select fa-caret-down">
                              <select
                                name="availability"
                                className={"form-select " + (formik.values.availability ? "" : "color-fade")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.availability}
                              >
                                <option value="">Please select the availability</option>
                                <option value="Full time">Full time</option>
                                <option value="Part Time">Part Time</option>
                                <option value="Remote Full Time">Remote Full Time</option>
                                <option value="Remote Part Time">Remote Part Time</option>
                              </select>
                            </div>
                            {formik.touched.availability && formik.errors.availability ? (
                              <div className="formik-errors bg-error">{formik.errors.availability}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Job Description*
                            </label>
                            <div className="textareaControl">
                              <textarea
                                className="form-control m-0"
                                name="description"
                                placeholder="Please enter the description"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ height: "200px" }}
                              />
                            </div>
                            {formik.touched.description && formik.errors.description ? (
                              <div className="formik-errors bg-error">{formik.errors.description}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Publish</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
