import { useNavigate } from "react-router-dom";
import TopCards from "../../shared/List/TopCards";
import SupportTicketService from "../../services/SupportTicketService";
import Ticket from "./Ticket";
import ListGrid from "../../shared/List/ListGrid";
import SupportTicketPopUp from "./SupportTicketPopUp";
import {useState, useEffect} from "react"

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "subject--asc", value: "A to Z" },
  { key: "subject--desc", value: "Z to A" },
];

function SupportTicket() {
  const navigate = useNavigate();
  const supportTicketServ = new SupportTicketService();
  const[openCount, setopenCount] =useState(0);
  const[monthOpen, setMonthOpen] =useState(0);
  const[closeCount, setcloseCount] =useState(0);
  const[monthClose, setMonthClose] =useState(0);
  const[dueCount, setDueCount] =useState(0);
  useEffect(()=>{
    getTicketCount()
  },[])

  const getTicketCount = async()=>{
    try {
      let response = await supportTicketServ.listCount({})
      setopenCount(response.openCount)
      setcloseCount(response.closeCount)
      setMonthOpen(response.monthOpen)
      setMonthClose(response.monthClose)
      setDueCount(response.dueCount)
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Open Tickets",
      icon: "/images/svg-icons/openTicket.svg",
      value: openCount,
      footer: `+${monthOpen} this month`,
      link: "/openTicketList/",
    },
    {
      title: "Closed Tickets",
      icon: "/images/svg-icons/closedTiket.svg",
      value: closeCount,
      footer: `+${monthClose} this month`,
      link: "/closedTicketList/",
    },
    {
      title: "Tickets due > 7days",
      icon: "/images/svg-icons/alerm.svg",
      value: dueCount,
      footer: "View Tickets",
      link: "/dueSupportTicketList/",
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "partners"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await supportTicketServ.listAll(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const editHandler = async (id) => {
    navigate("/detailedTicket/" + id);
  };
  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await supportTicketServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  const activeToggleHandler = async (partnerObj) => {
    let obj = {
      _id: partnerObj._id,
      is_active: !partnerObj.is_active,
    };
    try {
      let response = await supportTicketServ.updateRecord(obj);
      if (response.data?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListGrid
        placeholder="Search support tickets"
        buttonText="Create a Ticket"
        closeBtn={true}
        getListDataFunc={getListData}
        editHandlerFunc={editHandler}
        deleteHandlerFunc={deleteHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        CardComponent={Ticket}
        PopupComponent={SupportTicketPopUp}
      />
    </div>
  );
}

export default SupportTicket;
