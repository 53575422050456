import { useNavigate, Link } from "react-router-dom";
export default function SearchBlockNew({
  placeholder,
  buttonClick,
  buttonLink,
  buttonText,
  searchData,
  searchSortBy,
  sortByOptions,
  buttonText2,
  buttonLink2,
  buttonIcon2,
  closeBtn,
  fileDrive
}) {
  const navigate = useNavigate();
  return (
    <div className={"course_header"+(fileDrive ? " padding25" : " ")} >
      <div className="headerSearchBar">
        <div className="mobSearch_new has-clear">
          <div className="input-group">
            <button className="btn" type="button" style={{ zIndex: 9 }}>
              <img src="/images/svg-icons/search.svg" alt="logo" className="img-fluid" />
            </button>
            <input
              type="text"
              className="form-control border-0"
              placeholder={placeholder}
              onChange={(e) => {
                searchData(e);
              }}
            />
            <span className="close form-control-clear hidden">
              <img src="/images/svg-icons/close.svg" alt="search" className="img-fluid" />
            </span>
          </div>
        </div>
      </div>
      {fileDrive && <div className="fileDriveListIcon"><img src="/images/svg-icons/fileDriveListIcon.svg"/></div> }
      {sortByOptions && (
        <div className="added_filter">
          <div className="img-select2">
            <span
              className="img-option2 this-logo"
              style={{
                background: "url(images/account/sorting.svg) no-repeat",
              }}
            />
            <select onChange={searchSortBy}>
              {sortByOptions.map((elm, i) => (
                <option key={i} value={elm.key}>
                  {elm.value}
                </option>
              ))}
            </select>
          </div>
        </div>
      )}
      {buttonText2 && (
        <div class="view_btn">
          <button type="button" onClick={() => navigate(buttonLink2)}>
            <img src={buttonIcon2} className={buttonText2=="View YT Channels" ? "  marginBottom3" :" "} /> {buttonText2}
          </button>
        </div>
      )}
      {closeBtn && <button className="supportCloBtn"><img src="/images/svg-icons/closeTickBtnIcon.svg" className="marginRight5"/><Link to="/closedTicketList/">Closed Tickets</Link></button>}
      {buttonText && (
        <div className="create_btn">
          <button
            type="button"
            onClick={() => {
              if (buttonClick) {
                buttonClick();
              }
              if (buttonLink) {
                navigate(buttonLink);
              }
            }}
          >
            <img src="/images/account/add_icon.svg" /> {buttonText}
          </button>
        </div>
      )}
    </div>
  );
}
