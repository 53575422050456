import React, { useState } from "react";
import ForgotPassword from "./ForgotPassword";
import ForgotPasswordOTP from "./ForgotPasswordOTP";
import ResetPassword from "./ResetPassword";

export default function ForgotPasswordPage(props) {
  const [showStep, setShowStep] = useState(1);

  return showStep === 2 ? (
    <ForgotPasswordOTP {...props} showStep={setShowStep} />
  ) : showStep === 3 ? (
    <ResetPassword {...props} showStep={setShowStep} />
  ) : (
    <ForgotPassword {...props} showStep={setShowStep} />
  );
}
