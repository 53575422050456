import { Link } from "react-router-dom";
export default function TopCards({ cards }) {
  return (
    <div className="courseCardbox">
      <div className="onlneStats d-flex">
        {cards.map((c) => {
          return (
            <Link to={c.link ? c.link : "#"} className="onUserLink">
              <div className="onlineUserCards d-flex">
                <div className="topText d-flex">
                  <h4 className="m-0">{c.title}</h4>
                  {c.icon && <img src={c.icon} alt="icons" className="img-fluid" />}
                </div>
                <div className="centerText">
                  <h3 className="m-0">{c.value}</h3>
                </div>
                <div className="btmText" onClick={() => (c.callback ? c.callback(c.title) : "")}>
                  {c.footer == "View History" ? (
                    <h6 className="m-0">
                      <u>{c.footer}</u>
                    </h6>
                  ) : (
                    <h6 className="m-0">{c.footer}</h6>
                  )}
                </div>
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  );
}
