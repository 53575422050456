import React from "react";

const AddQuestoin = ({ queIdx, formik }) => {
  const optionsLabel = ["Option 1", "Option 2", "Option 3", "Option 4"];

  return (
    <div className="row">
      <div className="col-12">
        <div className="account_input">
          <label htmlFor="question" className="form-label marginBottom10">
            Question
          </label>
          <input
            type="text"
            className="form-control"
            id="question"
            placeholder="Please enter the question"
            name={"questions[" + queIdx + "].question"}
            onChange={formik.handleChange}
            onBlur={formik.handleBlur}
            value={formik.values.questions[queIdx].question}
          />
          {formik.errors.questions &&
          formik.errors.questions[queIdx] &&
          formik.errors.questions[queIdx].question ? (
            <div className="formik-errors bg-error">
              {formik.errors.questions[queIdx].question}
            </div>
          ) : null}
        </div>
      </div>
      <div className="col-12">
        <div className="option_group_sec">
          {["a", "b", "c", "d"].map((opt, idx) => {
            return (
              <div className="option_div">
                <div className="marginBottom20 account_input">
                  <label className="form-label marginBottom10 marginLeft46">{optionsLabel[idx]}</label>
                  <div className="d-flex optionCheck align-items-center">
                    <input
                      type="checkbox"
                      value="1"
                      checked={
                        formik.values.questions[queIdx].correct_answers[idx] ===
                        true
                          ? true
                          : false
                      }
                      onChange={() => {
                        formik.values.questions[queIdx].correct_answers.forEach(
                          (item, i) => {
                            formik.setFieldValue(
                              `questions[${queIdx}].correct_answers[${i}]`,
                              false
                            );
                          }
                        );
                        formik.setFieldValue(
                          `questions[${queIdx}].correct_answers[${idx}]`,
                          true
                        );
                      }}
                      onBlur={formik.handleBlur}
                      name={`questions[${queIdx}].correct_answers[${idx}]`}
                      id={"questioncheck" + idx}
                    />
                    <label
                      htmlFor={"questioncheck" + idx}
                      onClick={(e) => {
                        e.target.innerHTML =
                          e.target.innerHTML == " " ? "" : " ";
                      }}
                    ></label>
                    <input
                      type="text"
                      className="form-control mb-0"
                      id={"question" + idx}
                      placeholder="Please enter a choice for the question above"
                      name={`questions[${queIdx}].option[${idx}]`}
                      value={formik.values.questions[queIdx].option[idx]}
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                    />
                    {formik.errors.questions &&
                    formik.errors.questions[queIdx] &&
                    formik.errors.questions[queIdx].option &&
                    formik.errors.questions[queIdx].option[idx] ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.questions[queIdx].option[idx]}
                      </div>
                    ) : null}
                  </div>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <div className="commonform_btn d-grid col-12 marginTop5">
        <button
          type="submit"
          className={
            "btn btn-primary btn-block" +
            ((formik.errors.questions &&
              formik.errors?.questions[queIdx]?.option &&
              formik.errors.questions[queIdx].option.length !== 0) ||
            (formik.touched.questions &&
              formik.touched.questions[queIdx]?.option &&
              Object.keys(formik.touched?.questions[queIdx]?.option).length ===
                0)
              ? " disabled"
              : "")
          }
        >
          Save &amp; Continue
        </button>
      </div>
    </div>
  );
};

export default AddQuestoin;
