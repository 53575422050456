import NewsSubscriptionService from "../../services/NewsSubscriptionService";
import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import SubscribePopup from "./SubscribePopup";

const cards = [
    {
      title: "Total Newsletters",
      icon: "/images/glossary/newsletter.svg",
      value: "000",
      footer: "+000 this month",
    },
    {
      title: "Total Newsletters",
      icon: "/images/glossary/subsriber.svg",
      value: "000",
      footer: "+000 this month",
    },
  ];
 
  const convertToDate = (value, index) => {
    return value && value.joined_on
      ? moment(value.joined_on).format("DD/MM/YYYY")
      : "";
  };
  const emailStatusConversion = (value, index) => {
    return value && value.emailVerified===true
      ? "Verified" : "Unverified"
  };
  const registeredUserConversion = (value, index) => {
    return value && value.userId =="" ?
    "No" : "Yes"
  };
  
  const columns=[
    {
      label: "Joined On",
      key:"joined_on",
      valueFunc: convertToDate,
    },
    {label:"Email Address", key:"email"},
    {label:"Name", key:"name"},
    {label:"Country", key:"country"},
    {label:"Registered User", key:"delivered", valueFunc:emailStatusConversion},
    {label:"Email Status", key:"emailVerified", valueFunc: emailStatusConversion},
    { label: "Actions", key: "ACTIONS" },
  ];
  const sortByOptions = [
    { key: "joined_on--desc", value: "Recently Added" },
    { key: "email--asc", value: "A to Z" },
    { key: "email--desc", value: "Z to A" },
  ];
  
function ViewSubscriberList() {
    const newsSubscriptionServ = new NewsSubscriptionService();
    
    async function getListData(search, contextSearch, setList, setTotalCount) {
        try {
          let response = await newsSubscriptionServ.listAll({
            start: search.start,
            length: search.perPage,
            filter: { searchText: search.searchTxt },
            sortBy: search.sortBy,
          });
          if (response) {
            response.data = response.data.map((rec) => {
              rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
              return rec;
            });
            setList(response.data);
            setTotalCount(response.total);
          }
        } catch (err) {
          throw err;
        }
      }
 

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await newsSubscriptionServ.deleteEmail(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search subscriber"
        buttonText="Add a Subscriber"
        buttonLink2="/newsletter"
        buttonText2="View Newsletter"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        sortByOptions={sortByOptions}
        PopupComponent={SubscribePopup}
        allowPopEdit={true}
      />
    </div>
  )
}

export default ViewSubscriberList