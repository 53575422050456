import React, { useState } from "react";
import Login from "./Login";
import LoginOTP from "./LoginOTP";

function LoginPage(props) {
  const [showStep, setShowStep] = useState(1);

  return showStep === 2 ? (
    <LoginOTP {...props} showStep={setShowStep} />
  ) : (
    <Login {...props} showStep={setShowStep} />
  );
}

export default LoginPage;
