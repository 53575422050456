import React from "react";

function Sitemap() {
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
                <div className="commonCart account_page">
                    <div className="stepFormHead d-flex align-items-center">
                        <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
                            <div className="createCourseHeading">
                                <h4>Settings | Sitemap</h4>
                            </div>
                        </div>
                    </div>
                   
                        <div className="userProfile_details coming-soon-custom" style={{ height: "610px" }}>
                            Coming Soon
                        </div>
                   
                </div>
            </div>
  );
}

export default Sitemap;
