import React, { useState, useEffect } from "react";
import BlockListService from "../../services/BlockListService";
import { ToastContainer, toast } from "react-toastify";
import { useFormik } from "formik";
import * as Yup from "yup";
const ValidateSchema = Yup.object().shape({
  name: Yup.string().required("Required"),
  // type: Yup.string().required("Required"),
});
function BlockListSetting() {
  const serv = new BlockListService();
  const [value, setValue] = useState({
    name: "",
    type: "user",
  });
  const [type, setType] = useState("user");

  useEffect(() => {
    getDetail();
  }, [type]);
  const getDetail = async () => {
    try {
      let obj = {
        filter: {
          type: type,
        },
      };
      await serv.csvDetail(obj).then((resp) => {
        setValue({ ...value, name: resp.data.replaceAll(",", "\n") });
      });
    } catch (error) {
      console.log(error);
    }
  };
  async function onSubmit(value) {
    let obj = {
      type: type,
      name: value.name.replaceAll("\n", ","),
    };
    try {
      await serv.addRecord(obj).then((resp) => {
        console.log(resp);
        if (resp.message) {
          toast.success("User name list added successfully");
          getDetail();
        } else {
          toast.error(resp.err);
        }
      });
    } catch (error) {
      console.log(error);
    }
  }
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="middleBox padding2025 d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>Settings | Blacklisting</h4>
            </div>
          </div>
        </div>
        <div className="userProfile_details">
          <div className="settingGeneral">
            <ul className="nav userNameNav">
              <li className={type == "user" ? "activeLi" : ""}>
                <a
                  href="#stripetab"
                  data-bs-toggle="tab"
                  role="tab"
                  onClick={() => setType("user")}
                  className={type == "user" ? "activeLi_a" : ""}
                >
                  UserNames
                </a>
              </li>
              <li className={type == "email" ? "activeLi" : ""}>
                <a
                  href="#paypaltab"
                  data-bs-toggle="tab"
                  role="tab"
                  onClick={() => setType("email")}
                  className={type == "email" ? "activeLi_a" : ""}
                >
                  Emails addreses
                </a>
              </li>
              <li className={type == "domain" ? "activeLi" : ""}>
                <a
                  href="#paypaltab"
                  data-bs-toggle="tab"
                  role="tab"
                  onClick={() => setType("domain")}
                  className={type == "domain" ? "activeLi_a" : ""}
                >
                  Domain names
                </a>
              </li>
            </ul>
            <div className="userProfile_details coming-soon-custom">
              <form onSubmit={formik.handleSubmit}>
                <div className="textArea areaHead">
                  <div className="numberList">
                    {formik.values.name.split("\n").map((item, idx) => (
                      <span>{idx + 1}.</span>
                    ))}
                  </div>
                  <textarea
                    placeholder="Enter one names per line*"
                    className="textAreaCustom"
                    name="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                </div>
                <div className="textSpan">*Enter names followed by commas “,”</div>
                <div className="commonform_btn ">
                  <button
                    type="submit"
                    className={`btn btn-primary btn-block w-100 borderRadius12 ${
                      !(formik.isValid && formik.dirty) ? "disabled" : ""
                    }`}
                  >
                    Save
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default BlockListSetting;
