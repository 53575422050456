import { useEffect, useState } from "react";

export default function Dropify({ id, name, value, changeCallBk, text }) {
  function onRemove(event, element) {
    changeCallBk(event);
  }

  function initiateDropify() {
    var drEvent = window.jQuery("#" + id).dropify({
      messages: {
        default: text
          ? text
          : "Please upload the cover image <br> <span>(1048 x 564px)</span>",
        replace: "Click or drag and drop to replace",
        remove: "Remove",
        error:
          "Error. The file is either not square, larger than 2 MB or not an acceptable file type",
      },
    });
    drEvent.on("dropify.afterClear", onRemove);

    return () => {
      drEvent.off("dropify.afterClear", onRemove);
    };
  }

  useEffect(() => {
    setTimeout(() => {
      initiateDropify();
    }, 1000);
  }, []);

  return (
    <input
      type="file"
      id={id}
      className="dropify-fr"
      name={name}
      onChange={(event) => {
        changeCallBk(event);
      }}
      style={{ opacity: 0 }}
      data-default-file={value}
    />
  );
}
