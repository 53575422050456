import React from "react";
import TopCards from "../../shared/List/TopCards";

const cards = [
  {
    title: "Online Users",
    icon: "/images/glossary/Activestate.svg",
    value: "000",
    footer: "Total Online Today: 000",
  },
  {
    title: "Total Users",
    icon: "/images/glossary/User_account.svg",
    value: "000",
    footer: "+000 this week",
  },
  {
    title: "Online Pro Users",
    icon: "/images/glossary/onlineProUserIcon.png",
    value: "000",
    footer: "Online Today: 000",
  },
  {
    title: "Pro Users",
    icon: "/images/glossary/lightningBolt.svg",
    value: "000",
    footer: "Coming Soon",
  },
  {
    title: "MRP",
    icon: "/images/glossary/DollerIcon.svg",
    value: "$000",
    footer: "Coming Soon",
  },
];
function ProUsers() {
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <div className="proUserContainer">Comming Soon</div>
    </div>
  );
}

export default ProUsers;
