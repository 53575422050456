import { useState } from "react";
import SupportTicketService from "../../services/SupportTicketService";
import { useFormik } from "formik";
import * as Yup from "yup";

function SupportTicketPopUp({ onClose, onSuccess, onFail, id }) {
  const supportServ = new SupportTicketService();
  const [showLoading, setShowLoading] = useState(false);
  const [value, setValue] = useState({
    full_name: "",
    email_address: "",
    twitter_address: "",
    wallet_address: "",
    discord_address: "",
    subject: "",
    message: "",
    attachments: "",
  });
  const ValidateSchema = Yup.object({
    full_name: Yup.string().required("Name is a required field"),
    email_address: Yup.string().required("Email is a required field"),
    twitter_address: Yup.string(),
    wallet_address: Yup.string(),
    discord_address: Yup.string(),
    subject: Yup.string().required("Subject is a required field"),
    message: Yup.string().required("Message is a required field"),
    attachments: Yup.string(),
  });
  const onSubmit = async (values) => {
    setShowLoading(true);
    const formData = new FormData();
    formData.set("full_name", values.full_name);
    formData.set("email_address", values.email_address);
    formData.set("twitter_address", values.twitter_address);
    formData.set("wallet_address", values.wallet_address);
    formData.set("discord_address", values.discord_address);
    formData.set("subject", values.subject);
    formData.set("message", values.message);
    formData.set("attachments", values.attachments);
    try {
      let response = await supportServ.addSupportTicketRecord(formData);
      if (response.err) {
        onFail(response.err);
      } else {
        onSuccess();
      }
    } catch (error) {
      onFail(error.message);
    }
    setShowLoading(false);
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addCareer show"
      id="addCareer"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/svg-icons/createTicketIcon.svg" alt="icons" className="img-fluid" /> <span>Create a ticket</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Full Name*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Please enter the full name"
                              name="full_name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.full_name}
                            />
                            {formik.touched.full_name && formik.errors.full_name ? (
                              <div className="formik-errors bg-error">{formik.errors.full_name}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="job_role" className="form-label">
                              Email Address*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Please enter the email address"
                              name="email_address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.email_address}
                            />
                            {formik.touched.email_address && formik.errors.email_address ? (
                              <div className="formik-errors bg-error">{formik.errors.email_address}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.twitter" className="form-label">
                              Twitter Username
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder="Please enter the twitter username"
                              name="twitter_username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.twitter_username}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="discord_username" className="form-label">
                              Discord Username
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id=""
                              placeholder="Please enter the discord username"
                              name="discord_username"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.discord_username}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="wallet_address" className="form-label">
                              Wallet Address
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="social_links.instagram"
                              placeholder="Please enter the wallet address"
                              name="wallet_address"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.wallet_address}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.website" className="form-label">
                              Attachments
                            </label>
                            <div className="supTicReadFile"><img src="/images/svg-icons/attachmentUploadIcon.svg" className="marginRight3"/> Click here to upload</div>
                            <input
                              type="file"
                              className="attachmentInp"
                              name="attachments"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.attachments}
                              />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="since" className="form-label">
                              Subject*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              placeholder="Please enter the subject"
                              name="subject"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.subject}
                            />
                            {formik.touched.subject && formik.errors.subject ? (
                              <div className="formik-errors bg-error">{formik.errors.subject}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="banner_image" className="form-label">
                              Message*
                            </label>
                            <textarea
                              rows={4}
                              type="text"
                              className="form-control"
                              placeholder="Please enter the message"
                              name="message"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.message}
                            />      
                            {formik.touched.message && formik.errors.message ? (
                              <div className="formik-errors bg-error">{formik.errors.message}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {showLoading ? (
                        <>
                          <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                        </>
                      ) : (
                        <>Create Ticket</>
                      )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default SupportTicketPopUp;
