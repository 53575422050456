import NewsPublisherService from "../../services/NewsPublisherService";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import moment from "moment";
import { useState, useEffect } from "react";
const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const convertToDate2 = (value, index) => {
  return value && value.since ? moment(value.since).format("DD/MM/YYYY") : "";
};
const addLogo = (value, index) => {
  return (
    <div className="d-flex">
      <img src="/images/svg-icons/newsPublisherLogo.svg" className="marginRight5" />
      <span>{value.publisher_name}</span>
    </div>
  );
};
const columns = [
  {
    label: "Added On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Added By", key: "added_by" },
  {
    label: "Publisher Name",
    key: "publisher_name",
    valueFunc: addLogo,
  },
  { label: "Articles", key: "articleCount" },
  { label: "Clicks", key: "clickCount" },
  { label: "bookmarks", key: "bookmarkCount" },
  { label: "Reports", key: "reportCount" },
  { label: "Status", key: "STATUS" },
  { label: "ACTIONS", key: "ACTIONS" },
];

const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "publisher_name--asc", value: "A to Z" },
  { key: "publisher_name--desc", value: "Z to A" },
];

export default function NewsPublisher() {
  const newsServ = new NewsPublisherService();
  const [totalCount, setTotalCount] = useState(0);
  const [monthTotal, setMonthTotal] = useState(0);
  const [totalActiveCount, setTotalActiveCount] = useState(0);
  const [monthActive, setMonthActive] = useState(0);
  const [totalInactiveCount, setTotalInactiveCount] = useState(0);
  const [monthInactive, setMonthInactive] = useState(0);
  const [totalNewsCount, setTotalNewsCount] = useState(0);
  const [monthNews, setMonthNews] = useState(0);
  useEffect(() => {
    getNewsCount();
  });
  const getNewsCount = async () => {
    try {
      let response = await newsServ.listCount({});
      setTotalCount(response.totalCount);
      setMonthTotal(response.monthTotal);
      setTotalActiveCount(response.totalActiveCount);
      setMonthActive(response.monthActive);
      setTotalInactiveCount(response.totalInactiveCount);
      setMonthInactive(response.monthInactive);
      setTotalNewsCount(response.totalNewsCount);
      setMonthNews(response.monthNews);
    } catch (error) {
      console.log(error);
    }
  };
  const cards = [
    {
      title: "Total Publishers",
      icon: "/images/svg-icons/publisher-black.svg",
      value: totalCount,
      footer: `+${monthTotal} this month`,
    },
    {
      title: "Active Publishers",
      icon: "/images/svg-icons/publisher-green.svg",
      value: totalActiveCount,
      footer: `+${monthActive} this month`,
    },
    {
      title: "Inactive Publishers",
      icon: "/images/svg-icons/publisher-red.svg",
      value: totalInactiveCount,
      footer: `+${monthInactive} this month`,
    },
    {
      title: "Total News Articles",
      icon: "/images/svg-icons/news-article.svg",
      value: totalNewsCount,
      footer: `+${monthNews} this month`,
    },
  ];
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "newss"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await newsServ.listAll(activity);
      if (response) {
        // response.data = response.data.map((rec) => {
        //   rec.published_by = rec.published_by?._id ? rec.published_by.publisher_name : "";
        //   return rec;
        // });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  // const deleteHandler = async (isConfirm, id) => {
  //   try {
  //     if (isConfirm) {
  //       let response = await newsServ.deleteRecord(id);
  //       if (response) {
  //         return response;
  //       } else {
  //         return false;
  //       }
  //     }
  //   } catch (err) {
  //     return false;
  //   }
  // };
  const analyticsHandler = (id) => {
    // navigate("/course/course_analytics/" + id);
    console.log(id);
  };
  const editHandler = async (id) => {
    // navigate("/course/create_course/" + id);
    console.log(id);
  };

  const activeToggleHandler = async (newsObj) => {
    let obj = {
      _id: newsObj._id,
      is_active: !newsObj.is_active,
    };
    try {
      let response = await newsServ.editRecord(obj);
      if (response?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search news"
        // buttonText="Add a News"
        columns={columns}
        getListDataFunc={getListData}
        editHandlerFunc={editHandler}
        analyticsHandlerFunc={analyticsHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
        buttonText2="View Articles"
        buttonLink2="/news"
        buttonIcon2="/images/svg-icons/viewDraftIcon.svg"
        // PopupComponent={NewssForm}
        // allowPopEdit={true}
      />
    </div>
  );
}
