import React from "react";
import { useNavigate, useParams, Link } from "react-router-dom";
import { useState, useEffect } from "react";
import moment from "moment";
import { useFormik } from "formik";
import { ToastContainer, toast } from "react-toastify";
import * as Yup from "yup";
import SupportTicketService from "../../services/SupportTicketService";
import data from "@emoji-mart/data";
import Picker from "@emoji-mart/react";

function DetailedTicket() {
  const navigate = useNavigate();
  const supportServ = new SupportTicketService();
  const params = useParams();
  const [attachments, setAttachments]=useState("")
  const [attachmentsUrl, setAttachmentsUrl]=useState("")
  const [ticketData, setTicketData] = useState({
    _id: "",
    full_name: "",
    email_address: "",
    ticketNo: "",
    message: "",
    subject: "",
    updatedAt: "",
    createdAt: "",
    wallet_address: "",
    twitter_username: "",
    discord_username: "",
    replies: [],
    is_active: "",
    status: "",
  });
  useEffect(() => {
    getDetails(params.id);
  }, [ticketData.replies.reply, params.id]);
  useEffect(() => {
    var objDiv = document.getElementById("messages");
    objDiv.scrollTop = objDiv.scrollHeight;
  });
  const [showEmojiPicker, setShowEmojiPicker] = useState(false);
  const getDetails = async (id) => {
    try {
      let response = await supportServ.getDetails(id);
      if (response) {
        response = response.data;
        setTicketData({
          _id: response._id,
          full_name: response.full_name,
          email_address: response.email_address,
          ticketNo: response.ticketNo,
          message: response.message,
          subject: response.subject,
          updatedAt: moment(response.updatedAt).format("DD/MM/YYYY | hh:mm a"),
          createdAt: moment(response.createdAt).format("DD/MM/YYYY | hh:mm a"),
          wallet_address: response.wallet_address,
          twitter_username: response.twitter_username,
          discord_username: response.discord_username,
          replies: [...response.replies],
          is_active: response.is_active,
          status: response.status,
        });
        getListTitle(response.email_address);
      } else {
        console.log("error");
      }
    } catch (error) {
      console.log(error);
    }
  };

  const [value, setValue] = useState({
    attachments: [],
    reply: "",
    supportedId: "",
  });

  const onSubmit = async (values, { resetForm }) => {
    setShowEmojiPicker(false);
    let obj = {
      reply: values.reply,
      supportId: ticketData._id,
      attachments: values.attachments,
    };
    const formData = new FormData();
    formData.append("reply", obj.reply);
    formData.append("supportId", obj.supportId);
    formData.append("attachments", obj.attachments);
    supportServ.addChat(formData).then((res) => {
      if (res.err) {
        console.log(res.err);
      } else {
        resetForm();
        getDetails(params.id);
      }
    });
  };

  const ValidateSchema = Yup.object({
    reply: Yup.string().required("Message field should not be empty."),
  });
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  // update ticket status function
  const updateStatus = async (statusNo) => {
    const formData = new FormData();
    formData.append("_id", params.id);
    formData.append("status", statusNo);
    let response = await supportServ.updateRecord(formData);
    if (response.data) {
      toast.success("Status updated successfully");
      getDetails(params.id);
    }
  };

  // close ticket status function
  const closeTicketFun = async () => {
    const formData = new FormData();
    formData.append("_id", params.id);
    formData.append("status", 4);
    let response = await supportServ.updateRecord(formData);
    if (response.data) {
      toast.success("Status updated successfully");
      getDetails(params.id);
    }
    setTimeout(() => {
      navigate("/support_ticket");
    }, 1500);
  };
  const [prevTicketList, setPrevTicketList] = useState([]);
  const getListTitle = async (email) => {
    let activity = {
      filter: {
        email_address: email,
      },
    };
    try {
      let response = await supportServ.titleList(activity);
      if (response) {
        response = response.data;
        setPrevTicketList(response);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const previousTicketRoute = (_id) => {
    navigate("/detailedTicket/" + _id);
  };
  console.log(attachments)
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="commonCart">
        <div className="sup_tickBasicdetail">
          <div className="gridrowDetails d-flex">
            <div className="gridcolfirst">
              <div className="backIconBtn d-flex align-items-center h-100" onClick={() => navigate("/support_ticket")}>
                <Link to="/support_ticket">
                  <img src="/images/icons/back-icon.svg" alt="backicon" className="img-fluid" />
                </Link>
              </div>
            </div>
            <div className="gridcolsecond">
              <div className="user_personal_deital">
                <div className="listbox">
                  <h6 className="mb-1">{ticketData.full_name}</h6>
                  <p className="mb-0">{ticketData.email_address}</p>
                </div>
                <div className="listbox">
                  <span>Username:</span>
                  <span>&nbsp; {ticketData.discord_username}</span>
                </div>
                <div className="listbox Moderator d-flex justify-content-between align-items-center">
                  <div>
                    <span>Moderator:</span>
                    <span>&nbsp; {ticketData.full_name}</span>
                  </div>
                  <div className="change_dir_icon">
                    <img src="/images/user_database/change_dir.svg" />
                  </div>
                </div>
              </div>
            </div>
            <div className="gridcolthird">
              <div className="thirddetial_grid d-grid">
                <div className="thirddetial_col">
                  <div className="listbox">
                    <p className="mb-1">Create Date &amp; Time</p>
                    <h6 className="mb-0">{ticketData.createdAt}</h6>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox">
                    <p className="mb-1">Last Reply Date &amp; Time</p>
                    <h6 className="mb-0">{ticketData.updatedAt}</h6>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox">
                    <p className="mb-1">Wallet Address</p>
                    <h6 className="mb-0 wallet_address">{ticketData.wallet_address}</h6>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox">
                    <span>Ticket No:</span>
                    <span>&nbsp; {ticketData.ticketNo}</span>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox">
                    <img src="/images/other-icons/discordneew.svg" />
                    <span>&nbsp;{ticketData.discord_username}</span>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox">
                    <img src="/images/other-icons/twitter.svg" />
                    <span>&nbsp; {ticketData.twitter_username}</span>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox d-flex flex-wrap align-items-center">
                    <p className="mb-2">Ticket Status:</p>
                    <div className="dropdown customselectBox w-100">
                      <a
                        href="javascript:void(0)"
                        className="d-flex justify-content-between"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                      >
                        {ticketData.status == 1 && (
                          <button
                            className="task_status_btn border-0
                         "
                          >
                            Open
                          </button>
                        )}
                        {ticketData.status == 2 && (
                          <button
                            className="task_status_btn border-0
                         "
                          >
                            Replied
                          </button>
                        )}
                        {ticketData.status == 3 && (
                          <button
                            className="task_status_btn border-0
                         "
                          >
                            Not Replies
                          </button>
                        )}
                        {ticketData.status == 4 && (
                          <button
                            className="task_status_btn border-0
                         "
                          >
                            Closed
                          </button>
                        )}
                        <img src="/images/user_database/droparrow.svg" />
                      </a>
                      <ul className="dropdown-menu customselectdrop w-100">
                        <li>
                          <a className="dropdown-item" href="javascript:void(0)" onClick={() => updateStatus(1)}>
                            Open
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="javascript:void(0)" onClick={() => updateStatus(2)}>
                            Replied
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="javascript:void(0)" onClick={() => updateStatus(3)}>
                            Not Replied
                          </a>
                        </li>
                        <li>
                          <a className="dropdown-item" href="javascript:void(0)" onClick={() => updateStatus(4)}>
                            Closed
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                <div className="thirddetial_col">
                  <div className="listbox">
                    <span>Subject:</span>
                    <span>&nbsp; {ticketData.subject}</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="ticket_detaile_info ">
          <div className="ticket_detaile_infoLeft scrrolEffect chatScreenHeight" id="messages">
            {ticketData.replies.map((replyData, i) => {
              return (
                <div
                  className={
                    "uercontent_row d-flex align-items-end " +
                    ((replyData.from.role[0] == "admin" || replyData.fromBot) && "reverse")
                  }
                >
                  <div className="userPro">
                    {/* <img src={replyData.from.role[0]=='admin'? "/images/user_database/profilepic2.svg": "/images/user_database/dummyprofile.svg" } /> */}
                    <img src={replyData.fromBot ? "/images/user_database/profilepic2.svg" : replyData.from.file} />
                  </div>
                  <div className="ticket_textBox">
                    <div className="ticketContent">
                      <p className="mb-0 lineBreak">{replyData.reply}</p>
                    </div>
                    {replyData.attachments != " " > 0 && (
                      <div className="attachemnts_link border-top-0">
                        <span>Attachments:</span>
                        <a href={replyData.attachments} target="_blank">
                          {replyData?.attachments[0].split("/").slice(-1)}
                        </a>
                      </div>
                    )}
                  </div>
                  <div className="date_time">
                    {replyData.from.role[0] == "admin" &&
                      (replyData.isRead ? (
                        <img src="/images/svg-icons/doubleTick.svg" className="leftTick" />
                      ) : (
                        <img src="/images/svg-icons/singleTick.svg" className="leftTick" />
                      ))}
                    <p className="mb-0">
                      {moment(replyData.replyDate).format("DD/MM/YYYY")}
                      {replyData.from.role[0] != "admin" &&
                        (replyData.isRead ? (
                          <img src="/images/svg-icons/doubleTick.svg " className="marginLeft5" />
                        ) : (
                          <img src="/images/svg-icons/singleTick.svg" className="marginLeft5" />
                        ))}
                      <br />
                      {moment(replyData.replyDate).format("hh:mm a")}
                    </p>
                  </div>
                </div>
              );
            })}
          </div>

          <div className="ticket_detaile_infoRight">
            <div className="prec_ticket_sec">
              <select className="border-0 w-100" onChange={(e) => previousTicketRoute(e.target.value)}>
                <option>Previous Tickets</option>
                {prevTicketList.map((list, index) => {
                  return (
                    <option value={list._id} key={index}>
                      {list.subject}
                    </option>
                  );
                })}
              </select>
            </div>
            <div className="Situation_resolved">
              <h5 className="mb-1">Situation resolved?</h5>
              <p className="mb-3">Proceed with closing the ticket once the user issues have been resolved.</p>
              <a href="javascript:void(0)" className="closeBtn " onClick={closeTicketFun}>
                <img src="/images/user_database/Close.svg" className="me-1" />
                Close Ticket
              </a>
              <p className="mb-0 mt-2 pt-1">
                Ticket status needs to be updated to “Resolved” for Close Ticket button to be activated.
              </p>
            </div>
            {showEmojiPicker && (
              <div className="emojiPicker">
                <Picker
                  data={data}
                  onEmojiSelect={(e) => {
                    formik.setFieldValue("reply", formik.values.reply.concat(e.native));
                  }}
                />
              </div>
            )}
            <form onSubmit={formik.handleSubmit}>
              <div className="send_message_div">
                <div className="modelFormInput ">
                  <textarea
                    placeholder="Write a message"
                    className="form-control w-100"
                    name="reply"
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                    value={formik.values.reply}
                  />
                  {formik.touched.reply && formik.errors.reply ? (
                    <div className="formik-errors bg-error">{formik.errors.reply}</div>
                  ) : null}
                </div>
                <div className="upload_div">
                  <a href="javascript:void(0)">
                    <img
                      src={attachments==""?  "/images/user_database/upload.svg": attachmentsUrl}
                      className="me-1 size17"
                    />
                   {attachments==""? "Upload Files":  attachments["name"]}
                  </a>
                  <a
                    href="javascript:void(0)"
                    style={{ marginLeft: "5px" }}
                    onClick={() => setShowEmojiPicker(!showEmojiPicker)}
                  >
                    <img src="/images/user_database/smogy.svg" />
                  </a>
                </div>
                <input
                  type="file"
                  name="attachments"
                  className="replyFileUpload"
                  onChange={(e) =>{
                    formik.setFieldValue(
                      "attachments",
                      e.currentTarget.files[0]
                    )
                    setAttachments((e.target.files[0]))
                    setAttachmentsUrl(URL.createObjectURL(e.target.files[0]))
                  }
                  }
                />
                <div className="commonform_btn d-grid col-12">
                  <button
                    type="submit"
                    className={"btn btn-primary btn-block " + (!(formik.isValid && formik.dirty) ? " disabled" : "")}
                  >
                    <img src="/images/user_database/send_icon.svg" className="me-2" />
                    Send Message
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default DetailedTicket;
