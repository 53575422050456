import util from "../util/util";
export default class UserKycService {
  deleteUserKycApi(dataId) {
    return util.sendApiRequest("/userKyc/" + dataId, "DELETE", true).then(
      (response) => {
        return response;
      },
      (error) => {
        throw new Error(error);
      }
    );
  }

  getUserKyc(dataId) {
    return util
      .sendApiRequest("/userKyc/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }

  edit(payload) {
    return util.sendApiRequest("/userKyc", "PUT", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  updateStatus(payload) {
    return util.sendApiRequest("/userKyc/updatestatus", "POST", true, payload).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }


  userKycList(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/userKyc/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
}
