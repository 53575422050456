import React, { useState,useContext, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { GlobalContext } from "../../context/GlobalContext";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

function UserAccount() {
  const globalCtx = useContext(GlobalContext);
  const [user, setUser] = globalCtx.user
  const params = useParams();
  const userServ = new UserService();
  const router = useNavigate();
  const[imgPrev, setImgPrev] = useState()
  const [value, setValue] = useState({
    file: "",
    full_name: "",
    email: "",
    mobile_no: "",
    location: "",
  });
  const ValidateSchema = Yup.object({
    full_name: Yup.string().required("Name is a required field"),
    gender: Yup.string().required("Availability a required field"),
    email: Yup.string().required("Email is a required field"),
    mobile_no: Yup.string().required("Phone No is a required field"),
    location: Yup.string().required("Location is a required field"),
  });
  const removeProfilePic = () => {
    formik.setFieldValue("file","")
    setImgPrev("")
  };

  const onSubmit = async (values, { resetForm }) => {
    let obj = { ...values };
    try {
      let response;
      if (obj._id !== "") {
        const formData = new FormData();
        formData.append("_id", window.user.data._id);
        formData.append("file", obj.file);
        formData.append("full_name", obj.full_name);
        formData.append("gender", obj.gender);
        formData.append("email", obj.email);
        formData.append("mobile_no", obj.mobile_no);
        formData.append("location", obj.location);
        response = await userServ.edit(formData);
      }
      if (response.data) {
        toast.success("Profile updated successfully");
        window.scroll(0, 0);
        let user = JSON.parse(localStorage.getItem("user"))
        getUserDetails(window.user.data._id);
        Object.keys(user.data).forEach((item) => {
          if(item=="full_name"){
            user.data[item]=response.data.result.full_name
          }
          if(item=="gender"){
            user.data[item]=response.data.result.gender
          }
          if(item=="mobile_no"){
            user.data[item]=response.data.result.mobile_no
          }
          if(item=="location"){
            user.data[item]=response.data.result.location
          }
          if(item=="file"){
            user.data[item]=response.data.result.file
          }
        })
        // localStorage.removeItem("user")
        localStorage.setItem("user", JSON.stringify(user))
        setUser(JSON.parse(localStorage.getItem("user")))
      }
    } catch (err) {
      toast.error(err.message);
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  useEffect(() => {
    getUserDetails(window.user.data._id);
  }, []);

  const getUserDetails = async (id) => {
    try {
      let response = await userServ.getUser(id);
      if (response) {
        setValue({
          full_name: response.data.full_name,
          gender: response.data.gender,
          email: response.data.email,
          mobile_no: response.data.mobile_no,
          location: response.data.location,
          file: response.data.file,
        });
        setImgPrev(response.data.file)
      }
    } catch (err) {
      console.log(err);
    }
  };
  return (
    <div className="userProfile_details boderNone comPadding">
      <div className="row">
        <div className="col-xl-4 customgrid_first">
          <div className="profilDtl_head">
            <h6>User profile</h6>
            <p>Enter the basic details about yourself.</p>
          </div>
        </div>
        <div className="col-xl-8 customgrid_seco">
          <div className="userDetails_form">
            <form onSubmit={formik.handleSubmit} id="userDetails">
              <div className="form_inputfield row">
                <div className="col-lg-12">
                  <div className="mb-4">
                    <label htmlFor="upload_img" className="form-label">
                      Profile Picture
                    </label>
                        <input type="file" className="coverProfileInput" name="file" onChange={(e)=>{formik.setFieldValue("file", e.currentTarget.files[0])
                        setImgPrev( URL.createObjectURL(e.target.files[0]))}}/>
                    <div className="upload_file">
                      <div className="up_proImg">
                        {imgPrev!="" ? <img
                          src={imgPrev}
                          className="img-fluid"
                        />:<img
                        src="/images/svg-icons/defaultUserProfile.svg"
                        className="img-fluid"
                      />}
                      </div>
                      <div className="upload_photo">
                        <button type="button">
                          Upload photo
                        </button>
                      </div>
                      <div className="remove_photo">
                        <button type="button"
                         onClick={removeProfilePic}
                         >
                          Remove photo
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-6">
                  <div className="mb-4 account_input">
                    <label htmlFor="full_name" className="form-label">
                      Full Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="full_name"
                      name="full_name"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.full_name}
                    />
                    {formik.touched.full_name && formik.errors.full_name ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.full_name}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-sm-6 col-md-4">
                  <div className="mb-3 account_input">
                    <label htmlFor="gender" className="form-label">
                      Gender
                    </label>
                    <div>
                      <div className="custom-select fa-caret-down">
                        <select
                          id="userGender"
                          name="gender"
                          className="form-select"
                          value={formik.values.gender}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        >
                          <option>Please enter your gender</option>
                          <option value="male">Male</option>
                          <option value="female">Female</option>
                          <option value="other">Other</option>
                        </select>
                        {formik.touched.gender && formik.errors.gender ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.gender}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="col-sm-6 col-md-5">
                  <div className="mb-4 account_input">
                    <label htmlFor="email" className="form-label">
                      Email Address
                    </label>
                    <input
                      type="email"
                      className="form-control nonEditableInpBg"
                      id="email"
                      name="email"
                      readOnly
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.email}
                    />
                    {formik.touched.email && formik.errors.email ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.email}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-3 short_grid">
                  <div className="mb-4 account_input">
                    <label htmlFor="phone" className="form-label">
                      Phone No.
                    </label>
                    <input
                      type="number"
                      className="form-control"
                      id="phone"
                      name="mobile_no"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.mobile_no}
                    />
                    {formik.touched.mobile_no && formik.errors.mobile_no ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.mobile_no}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-3 short_grid">
                  <div className="mb-4 account_input">
                    <label htmlFor="location" className="form-label">
                      Location
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      id="location"
                      name="location"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.location}
                    />
                    {formik.touched.location && formik.errors.location ? (
                      <div className="formik-errors bg-error">
                        {formik.errors.location}
                      </div>
                    ) : null}
                  </div>
                </div>
                <div className="col-lg-12">
                  <div className="commonform_btn d-grid">
                    <button
                      type="submit"
                      className={
                        "btn btn-primary btn-block " +
                        (!(formik.isValid && formik.dirty) ? " disabled" : "")
                      }
                    >
                      Update profile
                    </button>
                  </div>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default UserAccount;
