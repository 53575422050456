import React, { createContext, useState, useEffect } from "react";
export const FileStatusContext = createContext();

export const FileStatus = ({ children }) => {
  const [status, setStatus] = useState({});
  const [showUploadingPopUp, setShowUploadingPopup] = useState(false);
  return (
    <FileStatusContext.Provider
      value={{
        status: [status, setStatus],
        showUploadingPopUp: [showUploadingPopUp, setShowUploadingPopup],
      }}
    >
      {children}
    </FileStatusContext.Provider>
  );
};
export default FileStatusContext;
