import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import VideoService from "../../services/VideoService";
import Dropify from "../../shared/Dropify/Dropify";
import moment from "moment";
import axios from "axios";
import TagsInput from "../Courses/TagsInput";
import GlobalContext from "../../context/GlobalContext";
export default function VideoForm({ onClose, onSuccess, onFail, id }) {
  const videoServ = new VideoService();
  const [tagList, setTagList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [defaultTagList, setDefaultTagList] = useState([]);
  const [tagFormik, setTagFormik] = useState([])
  const [value, setValue] = useState({
    video_link: "",
    video_title: "",
    tags: [],
    channel_name: "",
    language: "",
    videoLength: "",
    publishedAt: "",
    views: "",
    video_banner: "",
    is_verified: false,
    channel_id: "",
    channel_description: "",
    channel_banner: "",
  });

  useEffect(() => {
    if (id) {
      getVideoDetail(id);
    }
    getTagList();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  // useEffect(() => {
  //   getYoutubeDetails("aMep8aQbj8M")
  // }, [])
  async function getTagList() {
    try {
      const response = await videoServ.videoTagList();
      if (response.data) {
        let prTag = [];
        response.data.map((item) => {
          let obj = { value: item.title, label: item.title };
          prTag = [...prTag, obj];
        });
        setTagList(prTag);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const getYoutubeDetails = async (link) => {
    let id = link.split("v=").pop();
    id = id.substring(0, id.indexOf("&") > -1 ? id.indexOf("&") : id.length);
    let url = `https://www.googleapis.com/youtube/v3/videos?part=id%2C+snippet&key=AIzaSyCRlKNYB-6KaJ3tRrfOjHCc7WloxsxSYyY&part=contentDetails&part=statistics&id=${id}`;
    axios.get(url).then((resp) => {
      let data = resp.data.items[0];
      if (data) {
        formik.setFieldValue("video_title", data.snippet.title);
        formik.setFieldValue("channel_name", data.snippet.channelTitle);
        formik.setFieldValue(
          "videoLength",
          data.contentDetails.duration.replace("PT", "").replace("H", ":").replace("S", "").replace("M", ":")
        );
        formik.setFieldValue("publishedAt", data.snippet.publishedAt);
        formik.setFieldValue("views", data.statistics.viewCount);
        formik.setFieldValue("video_banner", data.snippet.thumbnails.medium.url);
        formik.setFieldValue("video_id", id);
        formik.setFieldValue("channel_id", data.snippet.channelId);
        // if (data.snippet.tags) {
        //   setTagList(data.snippet.tags);
        // } else {
        //   setTagList([]);
        // }
        getYoutubeChannelDetail(data.snippet.channelId);
      } else {
        formik.setFieldValue("video_title", "");
        formik.setFieldValue("channel_name", "");
        formik.setFieldValue("videoLength", "");
        formik.setFieldValue("publishedAt", "");
        formik.setFieldValue("views", "");
        formik.setFieldValue("video_banner", "");
      }
    });
  };
  console.log(value.tags)
  const getYoutubeChannelDetail = async (channelId) => {
    let url = `https://www.googleapis.com/youtube/v3/channels?part=snippet,statistics&id=${channelId}&key=AIzaSyCRlKNYB-6KaJ3tRrfOjHCc7WloxsxSYyY`;
    axios.get(url).then((resp) => {
      let data = resp.data.items[0];
      formik.setFieldValue("channel_description", data.snippet.description);
      formik.setFieldValue("channel_banner", data.snippet.thumbnails.medium.url);
    });
  };
  const getVideoDetail = async (id) => {
    try {
      let response = await videoServ.getDetails(id);
      if (response) {
        console.log(response.data);
        setValue({
          _id: response.data._id,
          video_link: response.data.video_link,
          video_title: response.data.video_title,
          createdBy: response.data.createdBy,
          tags: response.data.tags,
          channel_name: response.data.channel_name,
          language: response.data.language,
          videoLength: response.data.videoLength,
          publishedAt: response.data.publishedAt,
          views: response.data.views,
          video_banner: response.data.video_banner,
          is_verified: response.data.channel_id.is_verified,
        });
        let defaultTag = [];
        response.data.tags.forEach((item) => {
          let obj = { value: item, label: item };
          defaultTag = [...defaultTag, obj];
        });
        setDefaultTagList([...defaultTag]);
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object({
    video_link: Yup.string().required("Link is a required field"),
    video_title: Yup.string().required("video title is a required field"),
    // tags: Yup.array().of(Yup.string().required("Required")).required("video tag is a required field"),
    channel_name: Yup.string().required("channel name is a required field"),
    language: Yup.string().required("Language is a required field"),
    videoLength: Yup.string().required("Video is a required field"),
    publishedAt: Yup.string().required("Published At is a required field"),
    views: Yup.string().required("views is a required field"),
    video_banner: Yup.string().required("Video banner is a required field"),
    is_verified: Yup.string(),
  });
  const callBackTags = (tags) => {
    formik.setFieldValue("tags", tags);
    const addNewTag = async (tags) => {
      let response = await videoServ.videoAddTag(tags);
      if (response) {
        setTagFormik(tags)
      }
    };
    addNewTag(tags);
  };
  const onSubmit = async (values) => {
    setShowLoading(true);
    let obj = { ...values };
    try {
      let response;
      if (obj._id) {
        response = await videoServ.editRecord(obj);
      } else {
        delete obj._id;
        response = await videoServ.addRecord(obj);
      }
      if (response.err) {
        onFail(response.err);
      } else {
        onSuccess();
      }
    } catch (err) {
      onFail(err.message);
    }
    setShowLoading(false);
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addCareer show"
      id="addVideo"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/svg-icons/video.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} a Video</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col-md-12">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Video Link*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="video_link"
                              placeholder="Please enter the name"
                              name="video_link"
                              onChange={(e) => {
                                formik.handleChange(e);
                                getYoutubeDetails(e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.video_link}
                            />
                            {formik.touched.video_link && formik.errors.video_link ? (
                              <div className="formik-errors bg-error">{formik.errors.video_link}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-4">
                          <div className="videoBannerHead">
                            <div className="videoBanner">
                              {formik.values.video_banner && (
                                <img src={formik.values.video_banner} className="img-fluid" />
                              )}
                            </div>
                            <div className="videoBannerTextHead">
                              <div>
                                <h4>Video Length*</h4>
                                {formik.values.videoLength ? (
                                  <p style={{ color: "#000" }}>{formik.values.videoLength}</p>
                                ) : (
                                  <p>Loading</p>
                                )}
                              </div>
                              <div>
                                <h4>Uploaded On*</h4>
                                {formik.values.publishedAt ? (
                                  <p style={{ color: "#000" }}>
                                    {moment(formik.values.publishedAt).format("DD/MM/YYYY")}
                                  </p>
                                ) : (
                                  <p>Loading</p>
                                )}
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="col-md-8">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="mb-3">
                                <label htmlFor="social_links.twitter" className="form-label">
                                  Video Title*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="video_title"
                                  placeholder="Loading"
                                  name="video_title"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.video_title}
                                />
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            <div className="col-md-7">
                              <div className="mb-3 position-relative">
                                <label htmlFor="social_links.facebook" className="form-label">
                                  Channel Name*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="channel_name"
                                  placeholder="Loading"
                                  name="channel_name"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.channel_name}
                                />
                                <div className="channelVerified">
                                  {/* <span className="verifiedTick"> */}
                                  <input
                                    type={"checkbox"}
                                    name="is_verified"
                                    checked={formik.values.is_verified}
                                    onChange={formik.handleChange}
                                    onBlur={formik.handleBlur}
                                  />
                                  {/* </span> */}
                                  Verified
                                </div>
                              </div>
                            </div>
                            <div className="col-md-5">
                              <div className="mb-3">
                                <label htmlFor="social_links.facebook" className="form-label">
                                  Views on Video*
                                </label>
                                <input
                                  type="text"
                                  className="form-control"
                                  id="views"
                                  placeholder="Loading"
                                  name="views"
                                  onChange={formik.handleChange}
                                  onBlur={formik.handleBlur}
                                  value={formik.values.views}
                                />
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.instagram" className="form-label">
                              Tags*
                            </label>
                            <TagsInput
                              callBackTags={callBackTags}
                              tags={formik.values.tags}
                              formik={formik}
                              isSearchable
                              prevTags={tagList}
                              defualtTag={defaultTagList}
                            />
                            {/* <div className="custom-select fa-caret-down">
                              <select
                                name="tags"
                                className={"form-select " + (formik.values.tags ? "" : "color-fade")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.tags}
                              >
                                <option value="">Please enter the tags</option>
                                {tagList.length > 0 &&
                                  tagList.map((item) => {
                                    return <option value={item}>{item}</option>;
                                  })}
                              </select>
                            </div> */}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.website" className="form-label">
                              Language*
                            </label>
                            <div className="custom-select fa-caret-down">
                              <select
                                name="language"
                                className={"form-select " + (formik.values.language ? "" : "color-fade")}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.language}
                              >
                                <option value="">Please select the Language</option>
                                <option value="English">English</option>
                                <option value="Hindi">Hindi</option>
                              </select>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty && tagFormik.length!=0) ? " disabled" : "")
                          }
                        >
                          {!showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Publish</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="ljSectionData w-100 clearfix" id="ljSectionData">
    //   <div className="custom_link">
    //     <div className="users_bottom_part">
    //       <div className="total_updates_top ActiveLinks">
    //         <div className="custom-link_backbtn">
    //           <Link to="/partners">
    //             <img
    //               src="/assets/images/icons/leftarrow.svg"
    //               alt="arrow"
    //               className="ml-2"
    //               style={{ paddingLeft: "8px" }}
    //             />
    //           </Link>
    //         </div>
    //         <div className="walletAddressHead accountChangeHead ">
    //           <h5 className="m-0">Add a partner</h5>
    //         </div>
    //         <div className="d-flex active_link_customs">
    //           <p className="m-0 pt-2">
    //             Lorem Ipsum is simply dummy text of the printing and typesetting
    //             industry.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="custom_link_form">
    //         <div className="custom_form CreateCustomLink">
    //           <div className="update_form accountInner p-0 border-0">
    //             <form onSubmit={formik.handleSubmit}>
    //               <div
    //                 className="row"
    //                 style={{
    //                   paddingLeft: "20px",
    //                   paddingRight: "20px",
    //                   marginBottom: "20px",
    //                 }}
    //               >
    //                 <div className="col-8">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="for">Name</label>
    //                     <input
    //                       type="text"
    //                       className="form-control m-0"
    //                       placeholder="Enter the name"
    //                       name="name"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.name}
    //                     />
    //                     {formik.touched.name && formik.errors.name ? (
    //                       <div className="formik-errors bg-error">
    //                         {formik.errors.name}
    //                       </div>
    //                     ) : null}
    //                   </div>
    //                 </div>
    //                 <div className="col-4">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="bannerimg">Banner Image</label>

    //                     <div className="input-group">
    //                       <input
    //                         type="file"
    //                         className="form-control"
    //                         id="online_view_link"
    //                         placeholder="Upload an image (396 x 316px)"
    //                         name="banner_image"
    //                         onChange={(event) => {
    //                           formik.setFieldValue(
    //                             "banner_image",
    //                             event.currentTarget.files[0]
    //                           );
    //                         }}
    //                       />

    //                       <div className="input-group-append">
    //                         <Link to="#">
    //                           <span
    //                             className="input-group-text"
    //                             style={{ height: "100%" }}
    //                           >
    //                             {formik.values.banner_image &&
    //                             typeof formik.values.banner_image ==
    //                               "string" ? (
    //                               <img
    //                                 src={formik.values?.banner_image}
    //                                 alt="banner_image"
    //                                 width={30}
    //                                 height={25}
    //                               />
    //                             ) : (
    //                               <img
    //                                 src="/assets/images/icons/upload-to-cloud.svg"
    //                                 alt="upload-to-cloud"
    //                                 className="img-fluid"
    //                               />
    //                             )}
    //                           </span>
    //                         </Link>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Category</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="category"
    //                       placeholder="Enter the category of partner here"
    //                       name="category"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.category}
    //                     />
    //                     {formik.touched.category && formik.errors.category ? (
    //                       <div className="formik-errors bg-error">
    //                         {formik.errors.category}
    //                       </div>
    //                     ) : null}
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Website</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="social_links.website"
    //                       placeholder="Enter the website link here"
    //                       name="social_links.website"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.social_links.website}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Twitter</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="social_links.twitter"
    //                       placeholder="Enter the Twitter Link here"
    //                       name="social_links.twitter"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.social_links.twitter}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Facebook</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="social_links.facebook"
    //                       placeholder="Enter the Facebook Link here"
    //                       name="social_links.facebook"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.social_links.facebook}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Instagram</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="online_view_link"
    //                       placeholder="Enter the Instagram link here"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       name="social_links.instagram"
    //                       value={formik.values.social_links.instagram}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Description</label>
    //                     <textarea
    //                       className="form-control m-0"
    //                       name="description"
    //                       placeholder="Enter the definition of the above word"
    //                       value={formik.values.description}
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                     />

    //                     {formik.touched.description &&
    //                     formik.errors.description ? (
    //                       <div className="formik-errors bg-error">
    //                         {formik.errors.description}
    //                       </div>
    //                     ) : null}
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-12 p-4 custom-submitbtn">
    //                 <button type="submit" className="btn btnForm ">
    //                   Publish
    //                 </button>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <ToastContainer />
    // </div>
  );
}
