import React from "react";
import { Link } from "react-router-dom";
import ChangePassword from "./ChangePassword";
import UserAccount from "./UserAccount";
import WalletAddress from "./WalletAddress";

export default function Account() {
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
  <div className="commonCart account_page">
    <div className="mainHeading">
      <h4 className="mb-0">Account</h4>
    </div>
    <div className="userProfile_details">
      <UserAccount/>
    </div>
    <div className="userProfile_details comPadding">
      <ChangePassword/>
    </div>
    <div className="userProfile_details comPadding">
    <div className="row">
        <div className="col-xl-4 customgrid_first">
          <div className="profilDtl_head">
            <h6>Two-step authentication</h6>
            <p>After entering your password, verify your identity with an authentication method.Two-step authentication adds a layer of security to your account by using more than just your password to log in.</p>
          </div>
        </div>
        <div className="col-xl-8 customgrid_seco">
          <div className="varification_step">
            <div className="how_itworks d-flex ">
              <div className="varification_switch">
                <span className="onlabel">ON</span>
                <label className="switch">
                  <input type="checkbox" defaultChecked />
                  <span className="slider round" />
                </label> 
              </div>
              <div className="common_varifypata">
                <h6>HOW IT WORKS</h6>
                <p className="mb-0">When you log in to MaveFi Admin, you’ll need to:</p>
                <ol className="mb-0">
                  <li><p className="mb-0">Enter your email and password.</p></li>
                  <li><p className="mb-0">Complete a second step to prove that it’s you logging in. You can enter a verification code or use a security key.</p></li>
                </ol>
              </div>
            </div>
            <div className="authentication_step pt-4">
              <div className="row">
                <div className="col-md-6 pb-4 pb-md-0">
                  <div className="authentication_cart d-flex align-items-center">
                    <div className="cardimg_authen">
                      <img src="images/account/security-app.svg" className="img-fluid" />
                    </div>
                    <div className="carddata_athenty">
                      <h5>Authenticator app</h5>
                      <p className="mb-0">Use an app to generate an authentication code that you need to enter when you log in.</p>
                    </div>
                    <div className="status_btn">
                      <a href="javascript:void(0)" className="activeBtn">Active</a>
                    </div>
                  </div>
                  <div className="authenticationProces mt-4 d-flex">
                    <div className="common_varifypata">
                      <h6>1. SCAN QR CODE</h6>
                      <p className="mb-0">Scan the QR code using google authenticator app (opens a new window). After you scan the QR code, you’ll receive a six-digit confirmation code to enter below.</p>
                    </div>
                    <div className="quercode">
                      <img src="images/account/Qr_code.svg" className="img-fluid" />
                    </div>
                  </div>
                  <div className="common_varifypata mt-4">
                    <h6>2. ENTER CONFIRMATION CODE</h6>
                    <p className="mb-0">Enter the six-digit code generated by your authenticator app.</p>
                  </div>
                  <div className="mt-2 account_input">
                    <input type="text" className="form-control mb-0" placeholder="000" id="userLname" />
                  </div>
                  <div className="commonform_btn d-grid mt-4">
                    <button type="submit" className="btn btn-primary btn-block disabled">Turn on</button>
                  </div>
                </div>
                <div className="col-md-6">
                  <div className="authentication_cart d-flex align-items-center">
                    <div className="cardimg_authen">
                      <img src="images/account/security_key.svg" className="img-fluid" />
                    </div>
                    <div className="carddata_athenty">
                      <h5>Security key</h5>
                      <p className="mb-0">Use a compatible security key (like a YubiKey) that verifies your identity when you log in.</p>
                    </div>
                    <div className="status_btn">
                      <a href="javascript:void(0)" className="InactiveBtn">Inactive</a>
                    </div>
                  </div>
                  <div className="common_varifypata mt-4">
                    <h6>Security keys work with multiple devices.</h6>
                    <p>You’ll need to plug the security key into the devices you’re using to access your MaveFi Admin account.</p>
                    <p className="mb-4">If you’re using a security key, you can name it here.</p>
                  </div>
                  <div className="mt-2 account_input">
                    <input type="text" className="form-control mb-0" placeholder="MaveFi security key" id="userlname" />
                  </div>
                  <div className="commonform_btn d-grid mt-4">
                    <button type="submit" className="btn btn-primary btn-block disabled">Turn On</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

  );
}
