import GlossaryService from "../../services/GlossaryService";
import moment from "moment";
import { toast, ToastContainer } from "react-toastify";
import ListNew from "../../shared/List/ListNew";
import TopCards from "../../shared/List/TopCards";
import AddGlossaryPopup from "./AddGlossaryPopup";
import { useNavigate } from "react-router-dom";
const convertToDate = (value, index) => {
  return value && value.createdAt ? moment(value.createdAt).format("DD/MM/YYYY") : "";
};
const columns = [
  {
    label: "Submitted On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Word", key: "word" },
  {
    label: "Definition",
    key: "definition",
    cssTh: "white_pre",
    cssTd: "white_pre",
  },
  {
    label: "Submitted by",
    key: "createdBy",
  },
  // {
  //   label: "Likes",
  //   key: "",
  //   altValue: "000",
  // },
  // { label: "Status", key: "STATUS" },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "word--asc", value: "A to Z" },
  { key: "word--desc", value: "Z to A" },
];
function PublicSubmissions() {
  const navigate = useNavigate();
  const glossaryServe = new GlossaryService();

  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        hasCreatedBy: search.hasCreatedBy,
        searchText: search.searchTxt,
        createdByAdmin:false,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "toglossary"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    try {
      let response = await glossaryServe.listAllGlossary(activity);
      if (response) {
        response.data = response.data.map((rec) => {
          rec.createdBy = rec.createdBy?._id ? rec.createdBy.full_name : "";
          return rec;
        });
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }

  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await glossaryServe.deleteGlossaryRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const activeToggleHandler = async (glossary) => {
    const obj = {
      _id: glossary._id,
      word: glossary.word,
      definition: glossary.definition,
      is_active: !glossary.is_active,
    };
    try {
      const res = await glossaryServe.editGlossaryRecord(obj);
      if (res?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  return (
    <div className="publicSubmission">
      <div className="publicSubmissionHeader d-flex">
        <div className="backBtnPublic" onClick={() => navigate("/publish_glossary")}>
          <img src="/images/icons/back-icon.svg" />
        </div>
        <div className="publicHeading d-flex w-100">
          <img src="/images/svg-icons/glossarySubmissionIcon.svg" />
          <p>Glossary - Public Submissions</p>
        </div>
      </div>
      <div className="ljSectionData w-100 paddingFix clearfix" id="ljSectionData">
        <ListNew
          // placeholder="Search glossary"
          // buttonText="Add a Word"
          columns={columns}
          getListDataFunc={getListData}
          deleteHandlerFunc={deleteHandler}
          activeToggleHandlerFunc={activeToggleHandler}
          sortByOptions={sortByOptions}
          PopupComponent={AddGlossaryPopup}
          allowPopEdit={true}
          topRadius0={true}
          approveHandler={true}
        />
      </div>
      <ToastContainer />
    </div>
  );
}

export default PublicSubmissions;
