import React, { useEffect, useState, useRef } from "react";
export default function DraggableOrder({ itemList, handleChangeOrder, currId, setCurrId }) {
  const dragItem = useRef(null);
  const dragOverItem = useRef(null);
  const handleSort = () => {
    let _itemList = [...itemList];
    const draggedItemOverContent = _itemList[dragOverItem.current];
    const draggedItemContent = _itemList.splice(dragItem.current, 1)[0];
    if (
      draggedItemContent.title &&
      draggedItemContent.title !== "" &&
      draggedItemOverContent.title &&
      draggedItemOverContent.title !== ""
    ) {
      _itemList.splice(dragOverItem.current, 0, draggedItemContent);
      _itemList = _itemList.map((i, idx) => {
        i.sort_order = idx;
        return i;
      });
      handleChangeOrder(_itemList);
    }
    dragItem.current = null;
    dragOverItem.current = null;
  };
  return (
    <>
      {itemList.map((item, idx) => (
        <li
          onClick={() => setCurrId(item?._id)}
          key={idx}
          draggable
          onDragStart={() => (dragItem.current = idx)}
          onDragEnter={() => (dragOverItem.current = idx)}
          onDragEnd={handleSort}
          onDragOver={(e) => e.preventDefault()}
        >
          <a href="javascript:void(0)" className={currId === item?._id ? "active" : ""}>
            {item?.title || "Unknown"}
          </a>
        </li>
      ))}
    </>
  );
}
