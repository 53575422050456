import {useState} from "react";
import { Link } from "react-router-dom";
import * as Yup from "yup";
import OtpInput from "react18-input-otp";
import { ToastContainer, toast } from "react-toastify";
import UserService from "../../services/UserService";
import { useFormik } from "formik";
export default function ForgotPasswordOTP({ showStep }) {
  const [otp, setOtp] = useState("");
  const userService = new UserService();

  const formValue = {
    otp: "",
  };
  const onSubmit = (values) => {
    let userTemp = (localStorage.getItem("userTemp"))
    const formData = {
      email: userTemp,
      otp: otp
    };
    const newUserTemp ={
      email : userTemp,
      opt : formData.otp
    }
    localStorage.setItem("userTemp", JSON.stringify(newUserTemp))
    userService
      .verifyForgotPasswordOtp(formData)
      .then((res) => {
        if (res?.message=="OTP verified successfully") {
          showStep(3)
        } else {
          toast.error(res.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };

  const ValidateSchema = Yup.object().shape({
    // email: Yup.string().email().required("Required")
  });
  const formik = useFormik({
    initialValues: formValue,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="w-100 clearfix betaEnterPassword" id="betaEnterPassword">
      <div className="w-100 bgInner d-flex align-items-center justify-content-between">
        <div className="card betaPasswordCard">
          <div className="card-header bg-white">
            <ul className="nav nav-pills bulletNav">
              <li className="nav-item">
                <a
                  className="nav-link"
                  onClick={() => {
                    showStep(1);
                    localStorage.removeItem("userTemp")
                  }}
                />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link active"
                  onClick={() => {
                    showStep(2);
                  }}
                />
              </li>
              <li className="nav-item">
                <a
                  className="nav-link"
                  // onClick={() => {
                  //   showStep(3);
                  // }}
                />
              </li>
            </ul>
          </div>
          <div className="h-100 tabContent">
            <div className="h-100 tab-pane loginPanel" id="loginPanel">
              <div className="h-100 card-body">
                <form action id="optForm" onSubmit={formik.handleSubmit} className="h-100">
                  <div className="h-100 middelContant otp-wrapper otp-event d-flex flex-column justify-content-between">
                    <div className="mb-3 mt-3">
                      <label htmlFor="email" className="form-label">
                        Enter 2FA OTP
                      </label>
                      <div className="input-group">
                        <div className="otp-container">
                        <OtpInput
                            placeholder="_"
                            value={otp}
                            onChange={(value) => setOtp(value)}
                            numInputs={6}
                            separator={<span>&nbsp;&nbsp;</span>}
                            inputStyle={{
                              // marginLeft: "8px",
                              // marginRight: "10px",
                              width: "20px"
                            }}
                          />
                          {/* <input
                            type="tel"
                            id="otp-number-input-1"
                            className="otp-number-input"
                            maxLength={1}
                            autoComplete="off"
                            placeholder="_"
                          />
                          <input
                            type="tel"
                            id="otp-number-input-2"
                            className="otp-number-input"
                            maxLength={1}
                            autoComplete="off"
                            placeholder="_"
                          />
                          <input
                            type="tel"
                            id="otp-number-input-3"
                            className="otp-number-input"
                            maxLength={1}
                            autoComplete="off"
                            placeholder="_"
                          />
                          <input
                            type="tel"
                            id="otp-number-input-4"
                            className="otp-number-input"
                            maxLength={1}
                            autoComplete="off"
                            placeholder="_"
                          />
                          <input
                            type="tel"
                            id="otp-number-input-5"
                            className="otp-number-input"
                            maxLength={1}
                            autoComplete="off"
                            placeholder="_"
                          />
                          <input
                            type="tel"
                            id="otp-number-input-6"
                            className="otp-number-input"
                            maxLength={1}
                            autoComplete="off"
                            placeholder="_"
                          /> */}
                        </div>
                      </div>
                    </div>
                    <div className="betaSubmit">
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <a
                            href="admin-login.html"
                            className="btn btn-outline-primary btn-block mb-2"
                          >
                            Go back
                          </a>
                        </div>
                      </div>
                      <div className="modelSignBtn">
                        <div className="d-grid">
                          <button
                            type="submit"
                            id="confirm"
                            className="btn btn-primary btn-block otp-submit"
                          >
                            Submit
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
