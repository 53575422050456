import React, { useState, useEffect, useRef } from "react";
import { ToastContainer, toast } from "react-toastify";
import moment from "moment"
import NotificationService from "../services/NotificationService";
export default function NotificationDropDown() {
  const notificationServ = new NotificationService();
  const [notificationList, setNotificationList] = useState(null)
  const[showNotification, setShowNotification] = useState(false)
  useEffect(() => {
    getNotificationList()
    document.addEventListener("click", handleOutSide, true)
  }, [])
  const refOne = useRef(null)
  const handleOutSide =(e)=>{
    if(!refOne.current.contains(e.target)){
      setShowNotification(false)
    }
    else{
      setShowNotification(true)
    }
  }
  const getNotificationList = async () => {
    let activity = {
      filter: {
        type: "admin",
        // searchId:
        //   contextSearch.searchDtl &&
        //     contextSearch.searchDtl.type === "notification"
        //     ? contextSearch.searchDtl && contextSearch.searchDtl.value
        //     : "",
      },
    };

    try {
      let response = await notificationServ.listAll(activity);
      if (response.data) {
        setNotificationList(response.data)
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function handleAllRead() {
    const resp = await notificationServ.MarkNotificationAsRead({ type: "admin" });
    getNotificationList();
  }
  const handleReadById= async(id)=>{
    let response = await notificationServ.MarkById(id)
    if(response.message){
      getNotificationList()
    }
  }
  return (
    <div className="notification dropdown">
      <a href="javascript:void(0)" onClick={()=>setShowNotification(!showNotification)}>
        <svg
          width="27"
          height="27"
          viewBox="0 0 27 27"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
          className="img-fluid"

        >
          <path
            d="M13.5 2.25C12.5685 2.25 11.8125 3.006 11.8125 3.9375V4.71973C8.90233 5.46986 6.75 8.1054 6.75 11.25V18L4.5 20.25V21.375H11.5532C11.3551 21.7169 11.2505 22.1049 11.25 22.5C11.25 23.0967 11.4871 23.669 11.909 24.091C12.331 24.5129 12.9033 24.75 13.5 24.75C14.0967 24.75 14.669 24.5129 15.091 24.091C15.5129 23.669 15.75 23.0967 15.75 22.5C15.7488 22.1047 15.6434 21.7167 15.4446 21.375H22.5V20.25L20.25 18V11.25C20.25 8.1054 18.0977 5.46986 15.1875 4.71973V3.9375C15.1875 3.006 14.4315 2.25 13.5 2.25ZM13.5 6.75C15.9818 6.75 18 8.76825 18 11.25V18V18.9316L18.1934 19.125H8.80664L9 18.9316V18V11.25C9 8.76825 11.0182 6.75 13.5 6.75Z"
            fill="#7D8190"
          />
        </svg>
      </a>
      {notificationList?.filter((item)=>!item.isRead).length>0 && <div className="unreadNotCount ">{notificationList?.filter((item)=>!item.isRead).length}</div>}
      <div className={"admin_notification dropdown-menu py-0"+ (showNotification ? " show" :"")} ref={refOne} style={{ boxShadow: "2px 2px 3px rgba(0, 0, 0, 0.1), -2px -2px 3px rgba(0, 0, 0, 0.1)" }}>
        <div
          className="bellTopHead d-flex justify-content-between align-items-center"
          style={{
            height: "47px",
            borderBottom: "1px solid rgba(87, 91, 108, 0.15)",
          }}
        >
          <div className="bellTopHeading">
            <h2 className="mb-0">Notifications</h2>
          </div>
          <div className="bellCloseBtn">
            {notificationList?.filter((item) => !item.isRead)
              .length > 0 && (
                <a href="javascript:void(0)" className="mark_link" onClick={handleAllRead}>
                  <u style={{ marginRight: "18px" }}>Mark all as read</u>
                </a>
              )}
            <a href="javascript:void(0);">
              <img
                src="/images/svg-icons/close.svg"
                alt="closeIcon"
                className="img-fluid"
                onClick={()=>setShowNotification(false)}
              />
            </a>
          </div>
        </div>
        <div className="notification_box padding8-12">
          {/* {notificationList?.filter((item) => !item.isRead) */}
          {notificationList?.length > 0 ?
              (
            <div className="bellNotifyGroup ">
              {notificationList
                // .filter((item) => !item.isRead)
                .map((item) => (
                  <a
                    href="javascript:void(0);"
                    className="disBlock"
                    onClick={()=>handleReadById(item._id)}
                  >
                    <div className={"bellNotify notifyPadding notifyUiFix" +(item.isRead ? " readMessageBg" : "")}>
                      <div className="bellNotifyHead">
                        <div className="bellNotifyHeading">
                          <h5 className="mb-0 d-flex">{!item.isRead && <div className="notifyState"></div>}<div className="notifyHeading">{item.title}</div></h5>
                        </div>
                        <div className="bellNotifyTime">
                          <p className="mb-0 timeText">{(moment(item.createdAt).fromNow())}</p>
                        </div>
                      </div>
                      <div className="bellNotifyPara">
                        <p className="mb-0 lineHeight16">{item.content}</p>
                      </div>
                    </div>
                  </a>
                ))}
            </div>
          ) : (
            <div className="no_newnotification">
              <div>
                <img
                  src="/images/svg-icons/DarkNotificationIcon.svg"
                  className="img-fluid"
                />
              </div>
              <h5>No new notifications</h5>
              <p>
                Looks like there is no new notification for you! Please check
                againg later.
              </p>
            </div>
          )
          }
        </div>
      </div>
      {/* <ToastContainer/> */}
    </div>
  );
}
