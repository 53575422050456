import moment from "moment";
import ListNew from "../../shared/List/ListNew";
import SupportTicketService from "../../services/SupportTicketService";
import { useNavigate } from "react-router-dom";

const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY")
    : "";
};
const columns = [
  {
    label: "Closed On",
    key: "createdAt",
    valueFunc: convertToDate,
  },
  { label: "Ticket No.", key: "ticketNo" },
  {
    label: "Full Name",
    key: "full_name",
    altValue: "000",
  },
  {
    label: "Email Address",
    key: "email_address",
    altValue: "000",
  },
  {
    label: "Subject",
    key: "subject",
    altValue: "000",
  },
  {
    label: "Closed By",
    key: "full_name",
    altValue: "000",
  },
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "subject--asc", value: "A to Z" },
  { key: "subject--desc", value: "Z to A" },
];

function ClosedTicketList() {
  const supportTicketServ = new SupportTicketService();
  const navigate = useNavigate();
  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        status: 4,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };

    try {
      let response = await supportTicketServ.listAll(activity);
      response.data = response.data.map((rec) => {
        rec.category_id = rec.category_id?._id ? rec.category_id.name : "";
        return rec;
      });
      response.data = response.data.map((rec) => {
        rec.author = rec.author?._id ? rec.author.user_name : "";
        return rec;
      });
      if (response) {
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }
  const editHandler = async (id) => {
    navigate("/detailedTicket/" + id);
  };
  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await supportTicketServ.deleteSupportTicketRecordApi(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };

  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="supportNav">
        <div
          className="supportTicketBackIcon"
          onClick={() => navigate("/support_ticket")}
        >
          <img src="/images/icons/back-icon.svg" />
        </div>
        <div className="supportTicketIcon">
          <img src="/images/svg-icons/closedTicket.svg" />
        </div>
        <div className="navHeading">Closed Ticket</div>
      </div>
      <ListNew
        placeholder="Search closed tickets"
        columns={columns}
        editHandlerFunc={editHandler}
        deleteHandlerFunc={deleteHandler}
        getListDataFunc={getListData}
        sortByOptions={sortByOptions}
        topRadius0={true}
      />
    </div>
  );
}

export default ClosedTicketList;
