import TopCards from "../../shared/List/TopCards";
import React from "react";
import { Bar, Doughnut, Pie } from "react-chartjs-2";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend, ArcElement } from "chart.js";
ChartJS.register(CategoryScale, LinearScale, BarElement, ArcElement, Legend);
const cards = [
  {
    title: "Today so far",
    icon: "/images/svg-icons/cashIcon.svg",
    value: "$0.00",
    footer: "(-00%) vs yesterday",
  },
  {
    title: "Yesturday",
    icon: "/images/svg-icons/cashIcon.svg",
    value: "$0.00",
    footer: "(-00%) vs same day last week",
  },
  {
    title: "Last 7 days",
    icon: "/images/svg-icons/cashIcon.svg",
    value: "$0.00",
    footer: "(+00%) vs previoud 7 days",
  },
  {
    title: "This Month",
    icon: "/images/svg-icons/cashIcon.svg",
    value: "$0.00",
    footer: "(+00%) vs last month",
  },
  {
    title: "Total Ad Revenue",
    icon: "/images/svg-icons/cashIcon.svg",
    value: "$000",
    // footer: "Sub Title",
  },
  {
    title: "Total Page Views",
    icon: "/images/svg-icons/eyeIcon.svg",
    value: "000",
    footer: "(+00 this month)",
  },
  {
    title: "Total Impressions",
    icon: "/images/svg-icons/searchIcon.svg",
    value: "000",
    footer: "(+00 this month)",
  },
  {
    title: "Total Clicks",
    value: "000",
    footer: "(+00) this month",
  },
  {
    title: "Page RPM",
    icon: "/images/svg-icons/pageRpm.svg",
    value: "$0.00",
    // footer: "Sub Title",
  },
  {
    title: "CPC",
    value: "$0.00",
    // footer: "Sub Title",
  },
  {
    title: "Page CTR",
    value: "0.00%",
    footer: "Page CTR = clicks/pages views",
  },
  {
    title: "Adblocker Detected",
    icon: "/images/svg-icons/alertIcon.svg",
    value: "000",
    footer: "Total no. of unique devices",
  },
];
function Advertising() {
  const pieData = {
    datasets: [{ data: [12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5, 12.5], backgroundColor: ["#3c3c3c","#ff0000","#8338EC","#2B4EBE","#4FD939","#FB5607", "#3A86FF", " #FFBE0B"], borderWidth: 0 }],
  };
  const pieOption = {};
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <div className="commonCart padding15 marginBottom20">
        <div className="row">
          <div className="col-6 padding0">
            <div className="graphCard">
              <div className="graphCardNav">
                <p>Top Countries</p>
                <h6>This Month</h6>
              </div>
              <div className="row">
                <div className="col-4 pieChartLabel pieChartSpacing">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                </div>
                <div className="col-4 pieChartLabel">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Country Name: <span>$000.00</span>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <Doughnut data={pieData} options={pieOption} width={100} height={0} />
                </div>
              </div>
            </div>
          </div>
          <div className="col-6 padding0">
            <div className="graphCard">
              <div className="graphCardNav">
                <p>Top Cities</p>
                <h6>This Month</h6>
              </div>
              <div className="row">
                <div className="col-4 pieChartLabel pieChartSpacing">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                </div>
                <div className="col-4 pieChartLabel">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      City Name: <span>$000.00</span>
                    </div>
                  </div>
                </div>
                <div className="col-4 mb-3">
                  <Doughnut data={pieData} options={pieOption} width={100} height={0} />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-3 padding0">
            <div className="graphCard">
              <div className="graphCardNav">
                <p>Title</p>
                <h6>Sub Title</h6>
              </div>
              <div className="row">
                <div className="col-6 pieChartLabel pieChartSpacing">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Apple: <span>31%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Microsoft: <span>21%</span>
                    </div>
                  </div>
                  <div className="d-flex">
                    <div className="circle1"></div>{" "}
                    <div>
                      Samsong <span>31%</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 pieChartLabel">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      HP: <span>15%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Other: <span>7%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Text: <span>16%</span>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-3">
                  <div className="col-6">
                  <Doughnut data={pieData} options={pieOption} width={100} height={0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 padding0">
            <div className="graphCard">
              <div className="graphCardNav">
                <p>Title</p>
                <h6>Sub Title</h6>
              </div>
              <div className="row">
                <div className="col-6 pieChartLabel pieChartSpacing">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Apple: <span>31%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Microsoft: <span>21%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Samsong <span>31%</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 pieChartLabel">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      HP: <span>15%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Other: <span>7%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Text: <span>16%</span>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-3">
                  <div className="col-6">
                  <Doughnut data={pieData} options={pieOption} width={100} height={0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 padding0">
            <div className="graphCard">
              <div className="graphCardNav">
                <p>Title</p>
                <h6>Sub Title</h6>
              </div>
              <div className="row">
                <div className="col-6 pieChartLabel pieChartSpacing">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Apple: <span>31%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Microsoft: <span>21%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Samsong <span>31%</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 pieChartLabel">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      HP: <span>15%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Other: <span>7%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Text: <span>16%</span>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-3">
                  <div className="col-6">
                  <Doughnut data={pieData} options={pieOption} width={100} height={0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="col-3 padding0">
            <div className="graphCard">
              <div className="graphCardNav">
                <p>Title</p>
                <h6>Sub Title</h6>
              </div>
              <div className="row">
                <div className="col-6 pieChartLabel pieChartSpacing">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Apple: <span>31%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Microsoft: <span>21%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Samsong <span>31%</span>
                    </div>
                  </div>
                </div>
                <div className="col-6 pieChartLabel">
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      HP: <span>15%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Other: <span>7%</span>
                    </div>
                  </div>
                  <div className="d-flex mb-2">
                    <div className="circle1"></div>{" "}
                    <div>
                      Text: <span>16%</span>
                    </div>
                  </div>
                </div>
                <div className="row d-flex justify-content-center mb-3">
                  <div className="col-6">
                  <Doughnut data={pieData} options={pieOption} width={100} height={0} />
                  </div>
                </div>
              </div>
            </div>
          </div>
          
        </div>
      </div>
      <div className="commonCart">
        <div className="addHeading d-flex justify-content-between">
          <p>Settings & Preferences</p>
          <div>
            <button className="disabled btnBgadd">Update</button>
          </div>
        </div>
        <div className="addFormDiv">
          <div className="row">
            <div className="col-12">
              <div className="mb-4 account_input">
                <label htmlFor="pageTitle" className="form-label">
                  Email daily reports to
                </label>
                <input
                  type="text"
                  className="form-control"
                  id=""
                  placeholder="Please enter the email addresses"
                  name=""
                />
              </div>
            </div>
            <div className="col-12">
              <div className="mb-4 account_input">
                <label htmlFor="pageTitle" className="form-label">
                  Ads Visibility
                </label>
                <div className="d-flex">
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label>Run ads for all free users</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Run ads for all free users</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Run ads for all free users</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className="mb-4 account_input">
                <label htmlFor="pageTitle" className="form-label">
                  Display Ads on
                </label>
                <div className="d-flex ">
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label>Home Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Blog Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Blog Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Courses Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Lessons Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Profiles Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Videos Page</label>
                  </div>
                </div>
                <div className="d-flex margintop12">
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">News Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Glossary Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Crypto Records Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Rankings Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Bounties Page</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Job Board Page</label>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12">
              <div className=" account_input">
                <label htmlFor="pageTitle" className="form-label">
                  Display Ads on
                </label>
                <div className="d-flex ">
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp mb-0"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Bottom of Side Navigation bar</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp mb-0"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Between Blog Article</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp mb-0"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Between Course</label>
                  </div>
                  <div className="d-flex cheakboxDiv">
                    <input
                      type="checkbox"
                      className="checkboxInp mb-0"
                      id=""
                      placeholder="Run ads for all free users"
                      name=""
                    />
                    <label className="cheakboxPlaceholder">Legal Pages</label>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Advertising;
