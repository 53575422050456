import { useContext } from "react";
import { Link } from "react-router-dom";
import GlobalContext from "../../context/GlobalContext";

export default function Card({ image, text, link, selectNavItem }) {
  const sitectx = useContext(GlobalContext);
  return (
    <Link
      to={link}
      className="genralLinkCard"
      onClick={() => {
        sitectx.popup.setShowPopup(true);
        sitectx.navig.setSelection(selectNavItem);
      }}
    >
      <div className="genralCards text-center">
        <div className="cardImg d-flex">
          <img src={image} alt="icons" className="img-fluid" />
        </div>
        <div className="cardImgText">
          <p>{text}</p>
        </div>
      </div>
    </Link>
  );
}
