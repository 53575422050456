import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import UserService from "../../services/UserService";

export default function ForgotPassword({ handleAuthState, showStep }) {
  const userService = new UserService();
  const navigate = useNavigate();

  const formValue = {
    email: "",
  };

  const onSubmit = (values) => {
    try {
      const enteredEmailAddress = values.email;

      const formData = {
        email: enteredEmailAddress,
        flag: "admin_fogot_password",
      };
      localStorage.setItem("userTemp", values.email)
      userService
        .forgotPassword(formData)
        .then((response) => {
          if (response.message) {
            toast.success(response.message);
            setTimeout(() => {
              showStep(2)
            }, 1000);
          } else {
            toast.error(response?.error);
          }
        })
        .catch(console.log);
    } catch (err) {
      console.log(err);
    }
  };

  const ValidateSchema = Yup.object().shape({
    email: Yup.string().email().required("Required"),
  });
  const formik = useFormik({
    initialValues: formValue,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <>
      <div className="w-100 clearfix betaEnterPassword" id="betaEnterPassword">
        <div className="w-100 bgInner d-flex align-items-center justify-content-between">
          <div className="card betaPasswordCard">
            <div className="card-header bg-white">
              <ul className="nav nav-pills bulletNav">
                <li className="nav-item">
                  <a
                    className="nav-link active"
                    onClick={() => {
                      showStep(1);
                    }}
                  />
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    // onClick={() => {
                    //   showStep(2);
                    // }}
                  />
                </li>
                <li className="nav-item">
                  <a
                    className="nav-link"
                    // onClick={() => {
                    //   showStep(3);
                    // }}
                  />
                </li>
              </ul>
            </div>
            <div className="h-100 tabContent">
              <div className="h-100 tab-pane loginPanel" id="loginPanel">
                <div className="h-100 card-body">
                  <div className="betaLogo text-center">
                    <img
                      src="images/login-model-logo.svg"
                      alt="betalogo"
                      className="img-fluid"
                    />
                  </div>
                  <form
                    action
                    id="optForm"
                    className="h-100"
                    onSubmit={formik.handleSubmit}
                  >
                    <div className="middelContant middleContantPosition">
                      <div className="modelTxt">
                        <h4>Reset your Password</h4>
                        <p>Only for MaveFi Employees &amp; Partners</p>
                      </div>
                      <div className="mb-3 mt-3">
                        <label htmlFor="email" className="form-label">
                          Email address
                        </label>
                        <div className="input-group">
                          <span className="input-group-text bg-white betaLock border-0 pe-1">
                            <img
                              src="images/icons/mail.svg"
                              alt="lock"
                              className="img-fluid"
                            />
                          </span>
                          <input
                            type="type"
                            className="form-control passInput border-0"
                            placeholder="john@mavefi.com"
                            name="email"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                          />
                        </div>
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="betaSubmit positionBtn">
                        <div className="modelSignBtn">
                          <div className="d-grid">
                            <Link
                              to="/login"
                              className="btn btn-outline-primary btn-block mb-2"
                            >
                              Go back to login
                            </Link>
                          </div>
                        </div>
                        <div className="modelSignBtn">
                          <div className="d-grid">
                            <button
                              type="submit"
                              id="confirm"
                              className={
                                "btn btn-primary btn-block otp-submit" +
                                (!(formik.isValid && formik.dirty)
                                  ? " disabled"
                                  : "")
                              }
                            >
                              Submit
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <ToastContainer />
    </>
  );
}
