import React, { useState, useEffect, useContext } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FileDriveService from "../../services/FileDriveService";
import axios from "axios";
import FileStatusContext from "../../context/FileStatusContext";

function AddFile({ onClose, onSuccess, onFail, id }) {
  const fileServ = new FileDriveService();
  const fileCtx = useContext(FileStatusContext);
  const [status, setStatus] = fileCtx.status;
  const [showUploadingPopUp, setShowUploadingPopup] = fileCtx.showUploadingPopUp;
  const [value, setValue] = useState({
    name: "",
    file: "",
    type: "",
    folder_id: "",
  });
  useEffect(() => {
    if (id) {
      getFileDetail(id);
    }
  }, []);
  const getFileDetail = async (id) => {
    try {
      let response = await fileServ.getFileDetail(id);
      if (response) {
        setValue({
          name: response.data.name,
          file: response.data.file,
          type: response.data.type,
          folder_id: response.data.folder_id,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };
  const getType = (file) => {
    if (file["type"]?.split("/")[0] == "image") {
      return 1;
    } else if (file["type"]?.split("/")[0] == "video") {
      return 2;
    } else if (file["type"]?.split("/")[1] == "pdf") {
      return 3;
    } else {
      return 0;
    }
  };
  const getName = (file) => {
    return file["name"].split(".")[0];
  };
  const handleFileSubmit = async (e) => {
    setShowUploadingPopup(true);
    let responsePromise = [];
    for (let i = 0; i < e.currentTarget.files.length; i++) {
      const element = e.currentTarget.files[i];
      let obj = {
        name: getName(element),
        file: element,
        type: getType(element),
        folder_id: "63f5b046bc7f1dc87efe88c1",
      };
      const formData = new FormData();
      formData.append("file", obj.file);
      formData.append("type", obj.type);
      formData.append("name", obj.name);
      formData.append("folder_id", obj.folder_id);
      const token = window.user ? window.user.token : "no-token";
      const config = {
        headers: {
          content: "multipart/form-data",
          Authorization: "Bearer " + token,
        },
        onUploadProgress: (data) => {
          let _status = status;
          _status[obj.name] = Math.round((data.loaded / data.total) * 100);
          setStatus({ ..._status });
          // setStatus({ ...status, [`${obj.name}`]: Math.round((data.loaded / data.total) * 100) });
          // setUploadCount(Math.round((data.loaded / data.total) * 100));
        },
      };

      try {
        responsePromise.push(axios.post(process.env.REACT_APP_API_BASEURL + "/filesdrive", formData, config));
      } catch (err) {
        throw err;
      }
    }
    Promise.all(responsePromise).then((value) => {
      onSuccess();
    });
  };
  return (
    <div
      className="modal commanModel addCareer show"
      id="addCareer"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog addFilePopup">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center letterSpacing0">
                  <img src="/images/svg-icons/uploadIcon.svg" alt="icons" className="img-fluid" />{" "}
                  <span className="letterSpacing0">Upload Files</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="uploadFileBody">
                        <div className="uploadPic">
                          <img src="/images/svg-icons/uploadImgPic.svg" />
                        </div>
                        <div className="uploadPopUpContent">
                          <h5 className="letterSpacing0">Upload and manage your files</h5>
                          <p className="letterSpacing0">Files can be images, videos, documents, and more.</p>
                          <button className="btn letterSpacing0 padding14_15" type="button">
                          <img src="/images/svg-icons/uploadFiles.svg"/>  <span>Upload Files</span>
                          </button>
                          <input
                            type="file"
                            className="fileDriveInput"
                            multiple
                            onChange={(e) => {
                              handleFileSubmit(e);
                            }}
                          />
                        </div>
                        <p className="marginBottom20 letterSpacing0">
                          You can also drag & drop files in here to upload them.
                        </p>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AddFile;
