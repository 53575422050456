import React, { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import axios from "axios";
import { ToastContainer, toast } from "react-toastify";
import CourseService from "../../services/CourseService";
import UserService from "../../services/UserService";
import TagService from "../../services/TagService";
import TagsInput from "./TagsInput";
import Steps from "./Steps";
import Dropify from "../../shared/Dropify/Dropify";

export default function AddCourse() {
  const params = useParams();
  const router = useNavigate();
  const[showLoading, setShowLoading] = useState(true);
  const [uploadCount,setUploadCount] = useState(0)
  const courseServ = new CourseService();
  const userServ = new UserService();
  const tagServ = new TagService();

  let initialLesson = {
    lesson: "",
    desc: "",
    file_one: "",
    file_two: "",
  };
  let initialQuestion = {
    question: "",
    option: ["", "", "", ""],
    correct_answers: [false, false, false, false],
  };

  const [course, setCourse] = useState({
    _id: "",
    course_name: "",
    course_desc: "",
    is_publish: false,
    users_enrolled: 0,
    rewards: 0,
    total_coins: 0,
    createdBy: "",
    tags: [],
    read_time: 0,
    course_video: "",
    show_course_video: false,
    cover_image: "",
    course_lesson: "",
    what_you_learn: "",
    lessons: [{ ...initialLesson }],
    questions: [{ ...initialQuestion }],
  });
  const [userList, setUserList] = useState([]);
  const [tagList, setTagList] = useState([]);
  const [defaultTagList, setDefaultTagList] = useState([]);
  const [toggleVideo, setToggleVideo] = useState(false);
  const [cover_image, setCover_image]= useState("")

  const ValidateSchema = Yup.object().shape({
    course_name: Yup.string().required("Required"),
    course_desc: Yup.string().required("Required"),
    read_time: Yup.string().required("Required"),
    createdBy: Yup.string().required("Required"),
    course_video: Yup.string(),
    show_course_video: Yup.string(),
    course_lesson: Yup.string().required("Required"),
    cover_image: Yup.string().required("Required"),
    // cover_image: Yup.mixed().test({
    //   message:
    //     "Please set image resolution to (" +
    //     process.env.REACT_APP_COURSE_IMAGE_WIDTH +
    //     "*" +
    //     process.env.REACT_APP_COURSE_IMAGE_HEIGHT +
    //     ")",
    //   test: function (value) {
    //     return new Promise((resolve, reject) => {
    //       const image = new Image();
    //       let fr = new FileReader();
    //       fr.onload = function () {
    //         if (fr !== null && typeof fr.result === "string") {
    //           image.src = fr.result;
    //         }
    //       };
    //       image.onload = async function () {
    //         const width = image.width;
    //         const height = image.height;
    //         if (
    //           width === parseInt(process.env.REACT_APP_COURSE_IMAGE_WIDTH) &&
    //           height === parseInt(process.env.REACT_APP_COURSE_IMAGE_HEIGHT)
    //         ) {
    //           resolve(true);
    //         } else {
    //           resolve(false);
    //         }
    //       };
    //       fr.readAsDataURL(value);
    //     });
    //   },
    // }),
    // tags: Yup.array().of(Yup.string().required("Required"))
  });

  async function getInitData() {
    await getUserList();
    await getTagList();
  }
  useEffect(() => {
    getInitData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  async function getUserList() {
    try {
      let activity = {
        filter: { role: "author" },
      };
      const response = await userServ.userList(activity);
      if (response.data) {
        setUserList(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  async function getTagList() {
    try {
      const response = await tagServ.listAll();
      if (response.data) {
        let prTag = [];
        response.data.map((item) => {
          let obj = { value: item.title, label: item.title };
          prTag = [...prTag, obj];
        });
        setTagList(prTag);
      }
    } catch (err) {
      console.log(err);
    }
  }

  useEffect(() => {
    if (params?.draftid && params?.id) {
      fetchDraftList();
    } else if (params?.id) {
      onLoadCourseData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  const onLoadCourseData = async () => {
    try {
      let response = await courseServ.getCourse(params?.id);
      if (response.data) {
        response.data.show_course_video = response.data.show_course_video || false;
        setCourse(response.data);
        setCover_image(response.data.cover_image)
        if (response.data.course_video && response.data.course_video !== "") {
          setToggleVideo(true);
        }
        let defaultTag = [];
        response.data.tags.forEach((item) => {
          let obj = { value: item, label: item };
          defaultTag = [...defaultTag, obj];
        });
        setDefaultTagList([...defaultTag]);
      } else {
        toast.error(response?.error);
      }
    } catch (err) {
      throw err;
    }
  };

  async function fetchDraftList() {
    if (params?.draftid !== "") {
      let response = await courseServ.getDraftDetails(params?.draftid);
      if (response.data) {
        let courseObj = response.data;
        let draftObj = {
          ...courseObj,
          _id: response.data.course_id,
        };
        delete draftObj.course_id;
        setCourse(draftObj);
      }
    }
  }

  const callBackTags = (tags) => {
    formik.setFieldValue("tags", tags);
    const addNewTag = async(tags)=>{
      let response = await tagServ.addTag(tags);
      if(response){
        console.log(response)
      }
    }
    addNewTag(tags)
  };

  function isNumber(evt) {
    evt = evt ? evt : window.event;
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;
  }

  const onSubmit = async (formValues) => {
    setShowLoading(true)
    let values = { ...formValues };
    try {
      if (values._id !== undefined && values._id !== "") {
        const formData = new FormData();
        formData.append("_id", values._id);
        formData.append("course_name", values.course_name);
        formData.append("course_desc", values.course_desc);
        formData.append("course_lesson", values.course_lesson);
        formData.append("createdBy", values.createdBy);
        formData.append("tags", JSON.stringify(values.tags));
        formData.append("course_video", values.course_video);
        formData.append("show_course_video", values.show_course_video);
        formData.append("cover_image", values.cover_image);
        // formData.append("read_time", parseInt(values.read_time));
        formData.append("rewards", JSON.stringify(values.rewards));
        formData.append("total_coins", JSON.stringify(values.total_coins));
        formData.append("users_enrolled", JSON.stringify(values.users_enrolled));
        formData.append("what_you_learn", values.what_you_learn);
        if (values && values.lessons.length) {
          formData.append("lessons", JSON.stringify(values.lessons));
        }
        if (values && values.questions.length) {
          formData.append("questions", JSON.stringify(values.questions));
        }
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress:(data)=>{
            setUploadCount(Math.round(data.loaded/data.total*100))
          }
        };
        axios.put(process.env.REACT_APP_API_BASEURL + "/course", formData, config).then(async (response) => {
          if (response.data) {
            toast.success("Course updated successfully");
            window.scroll(0, 0);
            setTimeout(() => {
              if (params.draftid) {
                router(`/course/create_lesson/${params?.id}/${params.draftid}`);
              } else {
                router(`/course/create_lesson/${params?.id}`);
              }
            }, 1500);
          }
        });
      } else {
        const formData = new FormData();
        formData.append("course_name", values.course_name);
        formData.append("course_desc", values.course_desc);
        formData.append("course_lesson", values.course_lesson);
        formData.append("createdBy", values.createdBy);
        formData.append("tags", JSON.stringify(values.tags));
        formData.append("course_video", values.course_video);
        formData.append("show_course_video", values.show_course_video);
        formData.append("cover_image", values.cover_image);
        // formData.append("read_time", parseInt(values.read_time));
        formData.append("rewards", JSON.stringify(values.rewards));
        formData.append("total_coins", JSON.stringify(values.total_coins));
        formData.append("users_enrolled", JSON.stringify(values.users_enrolled));
        formData.append("what_you_learn", values.what_you_learn);
        const token = window.user ? window.user.token : "no-token";
        const config = {
          headers: {
            content: "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress:(data)=>{
            setUploadCount(Math.round(data.loaded/data.total*100))
          }
        };
        axios.post(process.env.REACT_APP_API_BASEURL + "/course", formData, config).then(async (response) => {
          if (response.data) {
            window.scroll(0, 0);
            toast.success("Course added successfully");
            setTimeout(() => {
              if (params.draftid) {
                router(`/course/create_lesson/${response.data?.data?._id}/${params.draftid}`);
              } else {
                router(`/course/create_lesson/${response.data?.data?._id}`);
              }
            }, 1000);
          }
        });
      }
      setShowLoading(false)
      // create tags
      // await tagServ.addTag(values.tags);
    } catch (err) {
      throw err;
    }

    // if(params?.id) {
    //   let crsDtl = {...course};
    //   crsDtl._id = params?.id;
    //   crsDtl = values;
    //   setCourses(crsDtl);
    //   router(`/course/create_lesson/${params?.id}`);
    // } else {
    //   delete values._id;
    //   setCourses(values);
    //   router("/course/create_lesson");
    // }
  };
  const formik = useFormik({
    initialValues: course,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
      <div className="commonCart account_page">
        <div className="stepFormHead d-flex align-items-center">
          <div className="leftBox order-1">
            <div className="backIconBtn">
              <Link to="/course">
                <img src="/images/icons/back-icon.svg" alt="backicon" className="img-fluid marginRight6" />
              </Link>
            </div>
          </div>
          <div className="rightBox order-2 order-sm-3">
            <h5>Step 1</h5>
            <h6>Information</h6>
          </div>
          <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
            <div className="createCourseHeading">
              <h4>
                <img src="/images/icons/e-learning.svg" alt="backicon" className="img-fluid marginRight5" /> <span>Create a Course</span>
              </h4>
            </div>
            <div className="paginationNav">
              <Steps step={1} courseId={params?.id} draftId={params?.draftid} />
            </div>
          </div>
        </div>
        <form onSubmit={formik.handleSubmit}>
          <div className="userProfile_details comPadding">
            <div className="row">
              <div className="col-xl-3 pb-3 pb-xl-0">
                <label htmlFor="input-file-uploader" className="form-label">
                  Cover Image
                </label>
                <div className="imageUploder">
                  {/* <Dropify
                    id="input-file-uploader"
                    name="cover_image"
                    value={formik.values.cover_image}
                    text="Please upload the cover image <br> <span>(1048 x 564px)</span>"
                    changeCallBk={(event) => {
                      if (event.currentTarget.files[0]) {
                        formik.setFieldValue("cover_image", event.currentTarget.files[0]);
                      } else {
                        formik.setFieldValue("cover_image", "");
                      }
                    }}
                  /> */}
                  <div className="blogImgPrev">
                      {cover_image == "" ? (
                        <>
                          <img src="/images/svg-icons/uploadDropImg.svg" className="blogUploadIcon"/>
                          <p>Please upload the cover image</p>
                          <p>(1048 x 564px)</p>
                        </>
                      ) : (
                        <img src={cover_image} className="blogPrev"/>
                      )}

                      <input
                        type="file"
                        accept="image/*"
                        onChange={(e) => {
                          formik.setFieldValue("cover_image", e.currentTarget.files[0]);
                          setCover_image(URL.createObjectURL(e.target.files[0]));
                        }}
                      />
                    </div>
                </div>
                {formik.touched.cover_image && formik.errors.cover_image ? (
                  <div className="formik-errors bg-error">{formik.errors.cover_image}</div>
                ) : null}
              </div>
              <div className="col-xl-9">
                <div className="row">
                  <div className="col-sm-6 col-xl-5 colSet1">
                    <div className="mb-4 account_input">
                      <label htmlFor="Title" className="form-label">
                        Title
                      </label>
                      <input
                        type="text"
                        className="form-control"
                        id="Title"
                        placeholder="Please enter the title"
                        name="course_name"
                        onChange={formik.handleChange}
                        onBlur={formik.handleBlur}
                        value={formik.values.course_name}
                      />
                      {formik.touched.course_name && formik.errors.course_name ? (
                        <div className="formik-errors bg-error">{formik.errors.course_name}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-5 colSet1">
                    <div className="mb-4 account_input">
                      <label htmlFor="Tags" className="form-label">
                        Tags
                      </label>
                      <div>
                        <TagsInput
                          callBackTags={callBackTags}
                          tags={formik.values.tags}
                          formik={formik}
                          isSearchable
                          prevTags={tagList}
                          defualtTag={defaultTagList}
                        />
                      </div>
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-2 colSet2">
                    <div className="mb-4 account_input groupsecond">
                      <label htmlFor="Rewards" className="form-label">
                        Rewards
                      </label>
                      <div className="input-group">
                        <input
                          type="number"
                          className="form-control m-0"
                          placeholder={0}
                          name="rewards"
                          onKeyDown={(e) => isNumber(e)}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.rewards}
                        />
                        <span className="input-group-text">
                          <img src="/images/other-icons/mini_logo.svg" className="img-fluid" alt="No Image" />
                          COINS
                        </span>
                      </div>
                      {formik.touched.rewards && formik.errors.rewards ? (
                        <div className="formik-errors bg-error">{formik.errors.rewards}</div>
                      ) : null}
                    </div>
                  </div>
                  <div className="col-sm-6 col-xl-4 colSet3">
                    <div className="mb-4 account_input">
                      <label htmlFor="Author" className="form-label">
                        Author
                      </label>
                      <div>
                        <div className="custom-select fa-caret-down">
                          <select
                            id="Author"
                            className={"form-select " + (formik.values.createdBy ? "" : "color-fade")}
                            name="createdBy"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.createdBy}
                          >
                            <option value="">Please select the author</option>
                            {userList &&
                              userList.map((user, idx) => {
                                return (
                                  <option value={user._id} key={idx}>
                                    {user.user_name}
                                  </option>
                                );
                              })}
                          </select>
                        </div>
                      </div>
                      {formik.touched.createdBy && formik.errors.createdBy ? (
                        <div className="formik-errors bg-error">{formik.errors.createdBy}</div>
                      ) : null}
                    </div>
                  </div>
                  {/* <div className="col-sm-6 col-xl-3 colSet4">
                    <div className="mb-4 account_input">
                      <label htmlFor="ReadTime" className="form-label">
                        Read Time
                      </label>
                      <div>
                        <div className="custom-select fa-caret-down zIndex0">
                          <select
                            id="ReadTime"
                            className={
                              "form-select " +
                              (formik.values.read_time ? "" : "color-fade")
                            }
                            name="read_time"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.read_time}
                          >
                            <option value="">
                              Please select the read time
                            </option>
                            <option value={5}>5 Minutes</option>
                            <option value={10}>10 Minutes</option>
                            <option value={15}>15 Minutes</option>
                            <option value={20}>20 Minutes</option>
                            <option value={25}>25 Minutes</option>
                            <option value={30}>30 Minutes</option>
                            <option value={35}>35 Minutes</option>
                            <option value={40}>40 Minutes</option>
                            <option value={45}>45 Minutes</option>
                            <option value={50}>50 Minutes</option>
                            <option value={55}>55 Minutes</option>
                            <option value={60}>1 Hour</option>
                          </select>
                        </div>
                      </div>
                      {formik.touched.read_time && formik.errors.read_time ? (
                        <div className="formik-errors bg-error">
                          {formik.errors.read_time}
                        </div>
                      ) : null}
                    </div>
                  </div> */}
                  <div className="col-sm-6 col-xl-5 colSet5">
                    <div className="mb-4 account_input groupsecond">
                      <label className="form-label">Upload Video</label>
                      <div className="d-flex align-items-center flexWithSwitch" style={{ gap: 10, height: "45px" }}>
                        <label className="switch">
                          <input
                            type="checkbox"
                            name="show_course_video"
                            checked={formik.values.show_course_video}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <span className="slider round" />
                        </label>
                        <div className={formik.values.show_course_video ? "input-group" : "hidden"}>
                          <input
                            type="file"
                            className={"form-control m-0"}
                            placeholder="Please upload the video"
                            id="UploadVideo"
                            name="course_video"
                            onChange={(event) => {
                              formik.setFieldValue("course_video", event.currentTarget.files[0]);
                            }}
                          />
                          <span className="input-group-text">
                            <img src="/images/icons/uploder-icon.svg" className="img-fluid" alt="No Image" />
                          </span>
                        </div>
                        {formik.touched.course_video && formik.errors.course_video ? (
                          <div className="formik-errors bg-error">{formik.errors.course_video}</div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-xl-4">
                <div className="mb-4 account_input modelFormInput">
                  <label htmlFor="Lessons" className="form-label">
                    Lessons
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Please enter the lesson title"
                    rows={7}
                    name="course_lesson"
                    value={formik.values.course_lesson}
                    onChange={(e) => {
                      e.target.value =
                        "👉" +
                        e.target.value
                          .split("\n")
                          .map((v) => v.replace("👉", ""))
                          .join("\n👉");
                      if (e.target.value == "👉") {
                        e.target.value = "";
                      }
                      formik.handleChange(e);
                    }}
                    onBlur={formik.handleBlur}
                  />
                  {formik.touched.course_lesson && formik.errors.course_lesson ? (
                    <div className="formik-errors bg-error">{formik.errors.course_lesson}</div>
                  ) : null}
                </div>
              </div>
              <div className="col-xl-8">
                <div className="mb-4 account_input modelFormInput">
                  <label htmlFor="Description" className="form-label">
                    Description
                  </label>
                  <textarea
                    className="form-control"
                    placeholder="Please enter the description"
                    rows={7}
                    name="course_desc"
                    value={formik.values.course_desc}
                    onChange={formik.handleChange}
                    onBlur={formik.handleBlur}
                  />
                  {/* <Editor
                    apiKey={window.tinyAPIKEY}
                    init={{
                      plugins:
                        "anchor autolink charmap codesample emoticons image link lists media searchreplace table visualblocks wordcount fullscreen",
                      toolbar:
                        "undo redo | bold italic underline strikethrough |fullscreen|  link image media table |blocks fontfamily fontsize | align lineheight | numlist bullist indent outdent | emoticons charmap | removeformat",
                    }}
                    value={formik.values.course_desc}
                    onEditorChange={(e) =>
                      formik.handleChange({
                        target: { name: "course_desc", value: e },
                      })
                    }
                  /> */}
                  {formik.touched.course_desc && formik.errors.course_desc ? (
                    <div className="formik-errors bg-error">{formik.errors.course_desc}</div>
                  ) : null}
                </div>
              </div>
              <div className="commonform_btn d-grid col-12">
                <button
                  type="submit"
                  className={"btn btn-primary btn-block " + (!(formik.isValid && formik.dirty) ? " disabled" : "")}
                >
                 {!showLoading ? <><img src="/images/icons/loading.gif" className="loadingGif"/> Uploading - {uploadCount}% </> : <>Save &amp; Continue</> } 
                </button>
              </div>
            </div>
          </div>
        </form>
      </div>
      <ToastContainer />
    </div>
  );
}
