import TopCards from "../../shared/List/TopCards";
import moment from "moment";
import { useNavigate } from "react-router-dom";
import ListNew from "../../shared/List/ListNew";
import NewsLetterService from "../../services/NewsLetterService"
import {useState, useEffect} from "react";

const convertToDate = (value, index) => {
  return value && value.createdAt
    ? moment(value.createdAt).format("DD/MM/YYYY | HH:mm")
    : "";
};

const columns=[
  {
    label: "Date & Time",
    key:"createdAt",
    valueFunc: convertToDate,
  },
  {label:"Subject", key:"subject"},
  {label:"Author", key:"author"},
  {label:"Recipient", key:"recipients"},
  {label:"Delivered", key:"delivered"},
  {label:"Opened", key:"opened"},
  {label:"Bounced", key:"bounced"},
  { label: "Actions", key: "ACTIONS" },
];
const sortByOptions = [
  { key: "createdAt--desc", value: "Recently Added" },
  { key: "subject--asc", value: "A to Z" },
  { key: "subject--desc", value: "Z to A" },
];
function NewsLetter() {
  const navigate = useNavigate()
  const newsLetterServ = new NewsLetterService();
  const[totalCount, setTotalCount] =useState(0)
  const[monthTotal, setMonthTotal] =useState(0)
  const[monthSubscriber, setMonthSubscriber] =useState(0)
  const[totalSubscriber, setTotalSubscriber] =useState(0)
  useEffect(()=>{
    getNewsLetterCount()
  },[])

  const getNewsLetterCount = async()=>{
    try {
      let response = await newsLetterServ.listCount({})
       setTotalCount(response.totalCount);
       setTotalSubscriber(response.totalSubscriber);
       setMonthTotal(response.monthTotal);
       setMonthSubscriber(response.monthSubscriber);
    } catch (error) {
      console.log(error)
    }
  }
  const cards = [
    {
      title: "Total Newsletters",
      icon: "/images/glossary/newsletter.svg",
      value: totalCount,
      footer: `+${monthTotal} this month`,
    },
    {
      title: "Total Subscribers",
      icon: "/images/glossary/subsriber.svg",
      value: totalSubscriber,
      footer: `+${monthSubscriber} this month`,
    },
  ];

  async function getListData(search, contextSearch, setList, setTotalCount) {
    let activity = {
      start: search.start,
      length: search.perPage,
      filter: {
        searchText: search.searchTxt,
        searchId:
          contextSearch.searchDtl && contextSearch.searchDtl.type === "careers"
            ? contextSearch.searchDtl && contextSearch.searchDtl.value
            : "",
      },
      sortBy: search.sortBy,
    };
    try {
      let response = await newsLetterServ.listAllNewsletter(activity);
      response.data = response.data.map((rec) => {
        rec.category_id = rec.category_id?._id ? rec.category_id.name : "";
        return rec;
      });
      response.data = response.data.map((rec) => {
        rec.author = rec.author?._id ? rec.author.user_name : "";
        return rec;
      });
      if(response.data){
        setList(response.data);
        setTotalCount(response.total);
      }
    } catch (err) {
      throw err;
    }
  }
  const editHandler = async (id) => {
    navigate("/compose_newsLetter/"+ id);
  };
  const activeToggleHandler = async (careerObj) => {
    let obj = {
      _id: careerObj._id,
      is_active: !careerObj.is_active,
    };
    try {
      let response = await newsLetterServ.updateRecord(obj);
      if (response?.data?.message?.includes("Successfully")) {
        return true;
      } else {
        return false;
      }
    } catch (err) {
      return false;
    }
  };
  const deleteHandler = async (isConfirm, id) => {
    try {
      if (isConfirm) {
        let response = await newsLetterServ.deleteRecord(id);
        if (response) {
          return response;
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <ListNew
        placeholder="Search newsletter"
        buttonLink="/compose_newsLetter"
        buttonText="Compose Newsletter"
        buttonLink2="/view_subscriber_list"
        buttonText2="View Subscriber"
        buttonIcon2="/images/glossary/User_blue.svg"
        columns={columns}
        getListDataFunc={getListData}
        deleteHandlerFunc={deleteHandler}
        editHandlerFunc={editHandler}
        activeToggleHandlerFunc={activeToggleHandler}
        sortByOptions={sortByOptions}
      />
    </div>
  );
}

export default NewsLetter;
