import { Link } from "react-router-dom";
export default function Steps({ step, courseId, draftId }) {
  if (!courseId) {
    return <></>;
  }
  return (
    <ul className="nav">
      <li className="nav-item">
        <Link
          className={"nav-link" + (step == 1 ? " active" : "")}
          to={"/course/create_course/" + courseId + (draftId ? "/" + draftId : "")}
        >
          1
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={"nav-link" + (step == 2 ? " active" : "")}
          to={"/course/create_lesson/" + courseId + (draftId ? "/" + draftId : "")}
        >
          2
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={"nav-link" + (step == 3 ? " active" : "")}
          to={"/course/create_question/" + courseId + (draftId ? "/" + draftId : "")}
        >
          3
        </Link>
      </li>
      <li className="nav-item">
        <Link
          className={"nav-link" + (step == 4 ? " active" : "")}
          to={"/course/create_meta/" + courseId + (draftId ? "/" + draftId : "")}
        >
          4
        </Link>
      </li>
    </ul>
  );
}
