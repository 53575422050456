import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GlossaryService from "../../services/GlossaryService";

export default function AddGlossaryPopup({ onClose, onSuccess, onFail, id }) {
  const glossaryServe = new GlossaryService();
  const [value, setValue] = useState({
    word: "",
    definition: "",
  });
  const [showLoading, setShowLoading] = useState(true);

  useEffect(() => {
    if (id) {
      getSingleGlossaryData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSingleGlossaryData = async () => {
    try {
      let response = await glossaryServe.getGlossaryDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          word: response.data.word,
          definition: response.data?.definition,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object().shape({
    word: Yup.string().required("Required"),
    definition: Yup.string().max(300, "Description exceed 300 characters").required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    setShowLoading(true);
    let obj = {
      _id: values._id,
      word: values.word,
      definition: values.definition,
      createdByAdmin: true,
      approved: true,
    };

    if (values._id) {
      glossaryServe.editGlossaryRecord(values).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    } else {
      delete values._id;
      glossaryServe.addGlossaryRecord(obj).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    }
    setShowLoading(false);
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addWord show"
      id="addWord"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="images/icons/a-z-icons.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} a Word</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="glossaryWord" className="form-label">
                              Word
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="glossaryWord"
                              placeholder="Please enter the word"
                              name="word"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.word}
                            />
                            {formik.touched.word && formik.errors.word ? (
                              <div className="formik-errors bg-error">{formik.errors.word}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="glossaryDefinition" className="form-label">
                              Definition
                            </label>
                            <div className="textareaControl">
                              <textarea
                                className="form-control"
                                rows={4}
                                id="glossaryDefinition"
                                name="definition"
                                placeholder="Please enter the definition"
                                maxLength={300}
                                value={formik.values.definition}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                              <div id="the-count">
                                <span id="current">{formik.values.definition?.length}</span>
                                <span id="maximum">/ 300 characters</span>
                              </div>
                            </div>
                            {formik.touched.definition && formik.errors.definition ? (
                              <div className="formik-errors bg-error">{formik.errors.definition}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {!showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Publish</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
