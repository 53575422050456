import React from "react";
import TopCards from "../../shared/List/TopCards";
const cards = [
  {
    title: "Total Coin Holders",
    icon: "images/glossary/User_account.svg",
    value: "000",
    footer: "+00 this month",
  },
  {
    title: "Total Coins Distributed",
    icon: "images/login-model-logo.svg",
    value: "000",
    footer: "View submissions",
  },
  {
    title: "Coins rewarded for Courses",
    value: "000",
    footer: "View submissions",
  },
  {
    title: "Coins rewarded for Referral",
    value: "000",
    footer: "View submissions",
  },
  {
    title: "Title",
    value: "000",
    footer: "Sub Title",
  },
  {
    title: "Highest User Balance",
    value: "000",
  },
  {
    title: "Avarage User Balance",
    value: "000",
    // footer: "View submissions",
  },
  {
    title: "Title",
    value: "000",
    footer: "Sub Title",
  },
];
function Coins() {
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <TopCards cards={cards} />
      <div className="commonCart coursePublishpage"></div>
    </div>
  );
}

export default Coins;
