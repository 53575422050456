import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import FaqService from "../../services/FaqService";

export default function FaqForm({ onClose, onSuccess, onFail, id }) {
  const faqServ = new FaqService();
  const [catList, setCatList] = useState([]);
  const [showLoading, setShowLoading] = useState(true);
  const [value, setValue] = useState({
    question: "",
    answer: "",
    category_id: "",
    category: "",
  });
  const [typeText, setTypeText] = useState(true);
  useEffect(() => {
    getCatList();
    if (id) {
      getFaqDetail(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps
  async function getCatList() {
    try {
      let activity = {};
      const response = await faqServ.listCategory(activity);
      if (response.data) {
        setCatList(response.data);
      }
    } catch (err) {
      console.log(err);
    }
  }
  const getFaqDetail = async (id) => {
    try {
      let response = await faqServ.getDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          question: response.data.question,
          answer: response.data.answer,
          category_id: response.data.category_id,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };
  const ValidateSchema = Yup.object().shape({
    question: Yup.string().required("Required"),
    answer: Yup.string().required("Required"),
    category_id: Yup.string(),
    category: Yup.string(),
  });

  const onSubmit = (values) => {
    setShowLoading(true);
    let obj = {
      question: values.question,
      answer: values.answer,
      category_id: values.category_id,
      category: values.category,
    };
    if (values._id) {
      faqServ.editRecord(values).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    } else {
      delete values._id;
      if (typeText) {
        faqServ.addRecord(obj).then((res) => {
          if (res.err) {
            onFail(res.err);
          } else {
            onSuccess();
          }
        });
      } else {
        faqServ.addFaqCat({name:obj.category}).then((res) => {
          if (res.data) {
            console.log(res.data)  
            let newobj = {
              question: values.question,
              answer: values.answer,
              category_id: res?.data._id,
            };
            faqServ.addRecord(newobj).then((res) => {
              if (res.err) {
                onFail(res.err);
              } else {
                onSuccess();
              }
            });
          } else {
            console.log(res.err);
          }
        });
      }
    }
    setShowLoading(false);
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div
      className="modal commanModel addFaq show"
      id="addFaq"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/icons/ask-question.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} a Faq</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Category
                            </label>
                            <input type="checkbox" value="" onClick={() => setTypeText(!typeText)} className="catInp" />
                            <label className="faqcheakLabel">Create New</label>
                            {typeText ? (
                              <div className="custom-select fa-caret-down">
                                <select
                                  className={"form-control " + (formik.values.category_id ? "" : "color-fade")}
                                  name="category_id"
                                  onChange={formik.handleChange}
                                  value={formik.values.category_id}
                                >
                                  <option value="">Select Category</option>
                                  {catList &&
                                    catList.map((cat, idx) => {
                                      return (
                                        <option value={cat._id} key={idx}>
                                          {cat.name}
                                        </option>
                                      );
                                    })}
                                </select>
                              </div>
                            ) : (
                              <input
                                type="text"
                                className="form-control"
                                // id="email"
                                placeholder="Please enter the categeogy"
                                name="category"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.category}
                              />
                            )}
                            {formik.touched.category_id && formik.errors.category_id ? (
                              <div className="formik-errors bg-error">{formik.errors.category_id}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div class="row">
                        <div class="col">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Question
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder="Please enter the question"
                              name="question"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.question}
                            />
                            {formik.touched.question && formik.errors.question ? (
                              <div className="formik-errors bg-error">{formik.errors.question}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col">
                          <div className="mb-3">
                            <label htmlFor="email" className="form-label">
                              Answer
                            </label>
                            <div className="textareaControl">
                              <textarea
                                className="form-control"
                                rows={4}
                                id="comment"
                                placeholder="Please enter the answer"
                                name="answer"
                                value={formik.values.answer}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                              />
                            </div>
                            {formik.touched.answer && formik.errors.answer ? (
                              <div className="formik-errors bg-error">{formik.errors.answer}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {!showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Publish</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
