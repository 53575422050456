import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import PartnerService from "../../services/PartnerService";
import Dropzone from "react-dropzone";
import moment from "moment";
export default function PartnerForm({ onClose, onSuccess, onFail, id }) {
  const partnerServ = new PartnerService();
  const [dateValue, setDateValue] = useState("");
  const [showLoading, setShowLoading] = useState(false);
  const [value, setValue] = useState({
    name: "",
    category: "",
    description: "",
    since: "",
    social_links: {
      facebook: "",
      twitter: "",
      instagram: "",
      website: "",
    },
    banner_image: "",
  });
  const [bannerPrev, setBannerPrev] = useState("");
  useEffect(() => {
    if (id) {
      getPartnerDetail(id);
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getPartnerDetail = async (id) => {
    try {
      let response = await partnerServ.getDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          name: response.data.name,
          category: response.data.category,
          since: response.data.since ? moment(response.data.since).format("YYYY-MM-DD") : "",
          description: response.data.description,
          social_links: {
            facebook: response.data.social_links.facebook,
            twitter: response.data.social_links.twitter,
            instagram: response.data.social_links.instagram,
            website: response.data.social_links.website,
          },
          banner_image: response.data.banner_image,
        });
        setDateValue(response.data.since);
        setBannerPrev(response.data.banner_image);
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object({
    name: Yup.string().required("Name is a required field"),
    category: Yup.string().required("Category is a required field"),
    since: Yup.string().required("Since is a required field"),
    banner_image: Yup.string().required("Banner is a required field"),
    description: Yup.string().required("Description is a required field"),
  });

  const onSubmit = async (values) => {
    setShowLoading(true);
    let obj = { ...values };
    try {
      let response;
      if (obj._id) {
        const formData = new FormData();
        formData.append("_id", obj._id);
        formData.append("name", obj.name);
        formData.append("category", obj.category);
        formData.append("since", obj.since);
        formData.append("description", obj.description);
        formData.append("social_links", JSON.stringify(obj.social_links));
        formData.append("banner_image", obj.banner_image);
        response = await partnerServ.updateRecord(formData);
      } else {
        delete obj._id;
        const formData = new FormData();
        formData.append("name", obj.name);
        formData.append("category", obj.category);
        formData.append("since", obj.since);
        formData.append("description", obj.description);
        formData.append("social_links", JSON.stringify(obj.social_links));
        formData.append("banner_image", obj.banner_image);
        response = await partnerServ.addrecord(formData);
      }
      if (response.err) {
        onFail(response.err);
      } else {
        onSuccess();
      }
    } catch (err) {
      onFail(err.message);
    }
    setShowLoading(false);
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addCareer show"
      id="addPartner"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img src="/images/icons/NewJob.svg" alt="icons" className="img-fluid" />{" "}
                  <span>{id ? "Edit" : "Add"} a Partner</span>
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="name" className="form-label">
                              Name*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="name"
                              placeholder="Please enter the name"
                              name="name"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.name}
                            />
                            {formik.touched.name && formik.errors.name ? (
                              <div className="formik-errors bg-error">{formik.errors.name}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="category" className="form-label">
                              Category*
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="category"
                              placeholder="Please enter the category"
                              name="category"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.category}
                            />
                            {formik.touched.category && formik.errors.category ? (
                              <div className="formik-errors bg-error">{formik.errors.category}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.twitter" className="form-label">
                              Twitter Profile URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="social_links.twitter"
                              placeholder="Please enter the twitter profile url"
                              name="social_links.twitter"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.social_links.twitter}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.facebook" className="form-label">
                              Facebook Profile URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="social_links.facebook"
                              placeholder="Please enter the facebook profile url"
                              name="social_links.facebook"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.social_links.facebook}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.instagram" className="form-label">
                              Instagram Profile Url
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="social_links.instagram"
                              placeholder="Please enter the instagram profile url"
                              name="social_links.instagram"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.social_links.instagram}
                            />
                          </div>
                        </div>
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="social_links.website" className="form-label">
                              Website URL
                            </label>
                            <input
                              type="text"
                              className="form-control"
                              id="social_links.website"
                              placeholder="Please enter the website url"
                              name="social_links.website"
                              onChange={formik.handleChange}
                              onBlur={formik.handleBlur}
                              value={formik.values.social_links.website}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-6">
                          <div className="mb-3">
                            <label htmlFor="since" className="form-label">
                              Since*
                            </label>
                            <input
                              type="date"
                              className={
                                "form-control sincePlaceholder" +
                                (dateValue != "" ? " colBlack" : " sincePlaceholderDate")
                              }
                              id="since"
                              placeholder="Please enter since date"
                              name="since"
                              onChange={(e) => {
                                formik.setFieldValue("since", e.target.value);
                                setDateValue(e.target.value);
                              }}
                              onBlur={formik.handleBlur}
                              value={formik.values.since}
                            />
                          </div>
                        </div>
                      </div>
                      <div className="row">
                        <div className="col-md-5">
                          <div className="mb-3">
                            <label htmlFor="banner_image" className="form-label">
                              Banner Image*
                            </label>
                            <div className="imageUploder">
                              {/* <Dropify
                                id="input-file-uploader"
                                name="banner_image"
                                value={formik.values.banner_image}
                                changeCallBk={(event) => {
                                  if (event.currentTarget.files[0]) {
                                    formik.setFieldValue(
                                      "banner_image",
                                      event.currentTarget.files[0]
                                    );
                                  } else {
                                    formik.setFieldValue("banner_image", "");
                                  }
                                }}
                              /> */}
                              <Dropzone onDrop={(acceptedFiles) => {
                                setBannerPrev(URL.createObjectURL(acceptedFiles[0]))
                                formik.setFieldValue("banner_image", acceptedFiles[0]);
                                }}>
                                {({ getRootProps, getInputProps }) => (
                                  <section>
                                    <div {...getRootProps()}>
                                      <input {...getInputProps()} />
                                      {bannerPrev != "" ? (
                                        <img src={bannerPrev} className="bannerPrevImg" />
                                      ) : (
                                        <div className="dropifyImgRead">
                                          <img src="/images/svg-icons/uploadDropImg.svg" />
                                          <p className="dropifyP">Click here to upload</p>
                                          <p className="dropifyP">or</p>
                                          <p className="dropifyP">Drag & drop here to upload</p>
                                          <p className="dropifyP">(594px x 330px)</p>
                                        </div>
                                      )}
                                    </div>
                                  </section>
                                )}
                              </Dropzone>
                            </div>
                            {formik.touched.banner_image && formik.errors.banner_image ? (
                              <div className="formik-errors bg-error">{formik.errors.banner_image}</div>
                            ) : null}
                          </div>
                        </div>
                        <div className="col-md-7">
                          <div className="mb-3">
                            <label htmlFor="description" className="form-label">
                              Description*
                            </label>
                            <div className="textareaControl">
                              <textarea
                                className="form-control m-0"
                                name="description"
                                placeholder="Please enter the discription"
                                value={formik.values.description}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                style={{ height: "112px" }}
                              />
                            </div>
                            {formik.touched.description && formik.errors.description ? (
                              <div className="formik-errors bg-error">{formik.errors.description}</div>
                            ) : null}
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" + (!(formik.isValid && formik.dirty) ? " disabled" : "")
                          }
                        >
                          {showLoading ? (
                            <>
                              <img src="/images/icons/loading.gif" className="loadingGif" /> Uploading
                            </>
                          ) : (
                            <>Publish</>
                          )}
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    // <div className="ljSectionData w-100 clearfix" id="ljSectionData">
    //   <div className="custom_link">
    //     <div className="users_bottom_part">
    //       <div className="total_updates_top ActiveLinks">
    //         <div className="custom-link_backbtn">
    //           <Link to="/partners">
    //             <img
    //               src="/assets/images/icons/leftarrow.svg"
    //               alt="arrow"
    //               className="ml-2"
    //               style={{ paddingLeft: "8px" }}
    //             />
    //           </Link>
    //         </div>
    //         <div className="walletAddressHead accountChangeHead ">
    //           <h5 className="m-0">Add a partner</h5>
    //         </div>
    //         <div className="d-flex active_link_customs">
    //           <p className="m-0 pt-2">
    //             Lorem Ipsum is simply dummy text of the printing and typesetting
    //             industry.
    //           </p>
    //         </div>
    //       </div>
    //       <div className="custom_link_form">
    //         <div className="custom_form CreateCustomLink">
    //           <div className="update_form accountInner p-0 border-0">
    //             <form onSubmit={formik.handleSubmit}>
    //               <div
    //                 className="row"
    //                 style={{
    //                   paddingLeft: "20px",
    //                   paddingRight: "20px",
    //                   marginBottom: "20px",
    //                 }}
    //               >
    //                 <div className="col-8">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="for">Name</label>
    //                     <input
    //                       type="text"
    //                       className="form-control m-0"
    //                       placeholder="Enter the name"
    //                       name="name"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.name}
    //                     />
    //                     {formik.touched.name && formik.errors.name ? (
    //                       <div className="formik-errors bg-error">
    //                         {formik.errors.name}
    //                       </div>
    //                     ) : null}
    //                   </div>
    //                 </div>
    //                 <div className="col-4">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="bannerimg">Banner Image</label>

    //                     <div className="input-group">
    //                       <input
    //                         type="file"
    //                         className="form-control"
    //                         id="online_view_link"
    //                         placeholder="Upload an image (396 x 316px)"
    //                         name="banner_image"
    //                         onChange={(event) => {
    //                           formik.setFieldValue(
    //                             "banner_image",
    //                             event.currentTarget.files[0]
    //                           );
    //                         }}
    //                       />

    //                       <div className="input-group-append">
    //                         <Link to="#">
    //                           <span
    //                             className="input-group-text"
    //                             style={{ height: "100%" }}
    //                           >
    //                             {formik.values.banner_image &&
    //                             typeof formik.values.banner_image ==
    //                               "string" ? (
    //                               <img
    //                                 src={formik.values?.banner_image}
    //                                 alt="banner_image"
    //                                 width={30}
    //                                 height={25}
    //                               />
    //                             ) : (
    //                               <img
    //                                 src="/assets/images/icons/upload-to-cloud.svg"
    //                                 alt="upload-to-cloud"
    //                                 className="img-fluid"
    //                               />
    //                             )}
    //                           </span>
    //                         </Link>
    //                       </div>
    //                     </div>
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Category</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="category"
    //                       placeholder="Enter the category of partner here"
    //                       name="category"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.category}
    //                     />
    //                     {formik.touched.category && formik.errors.category ? (
    //                       <div className="formik-errors bg-error">
    //                         {formik.errors.category}
    //                       </div>
    //                     ) : null}
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Website</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="social_links.website"
    //                       placeholder="Enter the website link here"
    //                       name="social_links.website"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.social_links.website}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Twitter</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="social_links.twitter"
    //                       placeholder="Enter the Twitter Link here"
    //                       name="social_links.twitter"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.social_links.twitter}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Facebook</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="social_links.facebook"
    //                       placeholder="Enter the Facebook Link here"
    //                       name="social_links.facebook"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       value={formik.values.social_links.facebook}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-md-4 col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Instagram</label>
    //                     <input
    //                       type="text"
    //                       className="form-control"
    //                       id="online_view_link"
    //                       placeholder="Enter the Instagram link here"
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                       name="social_links.instagram"
    //                       value={formik.values.social_links.instagram}
    //                     />
    //                   </div>
    //                 </div>
    //                 <div className="col-12">
    //                   <div className="form-group mb-3">
    //                     <label htmlFor="online_view_link">Description</label>
    //                     <textarea
    //                       className="form-control m-0"
    //                       name="description"
    //                       placeholder="Enter the definition of the above word"
    //                       value={formik.values.description}
    //                       onChange={formik.handleChange}
    //                       onBlur={formik.handleBlur}
    //                     />

    //                     {formik.touched.description &&
    //                     formik.errors.description ? (
    //                       <div className="formik-errors bg-error">
    //                         {formik.errors.description}
    //                       </div>
    //                     ) : null}
    //                   </div>
    //                 </div>
    //               </div>
    //               <div className="col-12 p-4 custom-submitbtn">
    //                 <button type="submit" className="btn btnForm ">
    //                   Publish
    //                 </button>
    //               </div>
    //             </form>
    //           </div>
    //         </div>
    //       </div>
    //     </div>
    //   </div>
    //   <ToastContainer />
    // </div>
  );
}
