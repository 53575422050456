import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import SettingService from "../../services/settingService";
import AddSeoPage from "./AddSeoPage";
import Dropify from "../../shared/Dropify/Dropify";
export default function SeoSetting() {
  const settingServ = new SettingService();
  const [pageType, setPageType] = useState("home");
  const [value, setValue] = useState({
    social_share_image: "",
    google_analytics_code: "",
    // google_tag_code: "",
    home: {
      title: "",
      tags: [""],
      meta_description: "",
    },
    course: {
      title: "",
      tags: [""],
      meta_description: "",
    },
    glossary: {
      title: "",
      tags: [""],
      meta_description: "",
    },
    blog: {
      title: "",
      tags: [""],
      meta_description: "",
    },
  });
  useEffect(() => {
    getSettingsData();
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSettingsData = async () => {
    try {
      let response = await settingServ.getDetails();
      if (response.data) {
        setValue({
          _id: response.data._id,
          social_share_image: response.data?.seo?.social_share_image || "",
          google_analytics_code:
          "G-2DQ3SR6PYZ",
          google_tag_code:
            response.data?.seo?.google_tag_code || "G-2DQ3SR6PYZ",
          home: {
            title: response.data?.seo?.home?.title || "",
            tags: response.data?.seo?.home?.tags || "",
            meta_description: response.data?.seo?.home?.meta_description || "",
          },
          course: {
            title: response.data?.seo?.course?.title || "",
            tags: response.data?.seo?.course?.tags || "",
            meta_description:
              response.data?.seo?.course?.meta_description || "",
          },
          glossary: {
            title: response.data?.seo?.glossary?.title || "",
            tags: response.data?.seo?.glossary?.tags || "",
            meta_description:
              response.data?.seo?.glossary?.meta_description || "",
          },
          blog: {
            title: response.data?.seo?.blog?.title || "",
            tags: response.data?.seo?.blog?.tags || "",
            meta_description: response.data?.seo?.blog?.meta_description || "",
          },
        });
      } else {
      }
    } catch (err) { }
  };

  const ValidateSchema = Yup.object().shape({
    social_share_image: Yup.string().required("Required"),
    google_analytics_code: Yup.string().required("Required"),
    // google_tag_code: Yup.string().required("Required"),
    // home: Yup.object().shape({
    //   title: Yup.string(),
    //   tags: Yup.string(),
    //   meta_description: Yup.string()
    // })
  });

  const onSubmit = (values, { resetForm }) => {
    const formData = new FormData();

    formData.append("social_share_image", values.social_share_image);
    formData.append("seo", JSON.stringify(values));
    if (values._id) {
      formData.append("_id", values._id);
      settingServ.updateRecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    } else {
      settingServ.addrecord(formData).then((res) => {
        if (res.err) {
          toast.error(res.err);
        } else {
          toast.success("Settings added successfully");
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <>
      <div className="ljSectionData w-100 clearfix mb-5" id="ljSectionData">
        <div className="commonCart account_page">
          <div className="stepFormHead d-flex align-items-center">
            <div className="middleBox d-flex align-items-center justify-content-between order-3 order-sm-2">
              <div className="createCourseHeading">
                <h4>Settings | SEO</h4>
              </div>
            </div>
          </div>
          <form onSubmit={formik.handleSubmit}>
            <div className="userProfile_details">
              <div className="settingGeneral">
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Title and meta description</h6>
                        <p>
                          The title and meta description help define how your
                          store shows up on search engines.
                        </p>
                      </div>
                    </div>
                    <div className=" col-md-8">
                      <div className="lesson_btn pb-2">
                        <ul className="nav">
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setPageType("home")}
                              className={pageType == "home" ? "active" : ""}
                            >
                              Home Page
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setPageType("course")}
                              className={pageType == "course" ? "active" : ""}
                            >
                              Course Page
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setPageType("glossary")}
                              className={pageType == "glossary" ? "active" : ""}
                            >
                              Glossary Page
                            </a>
                          </li>
                          <li>
                            <a
                              href="javascript:void(0)"
                              onClick={() => setPageType("blog")}
                              className={pageType == "blog" ? "active" : ""}
                            >
                              Blog Page
                            </a>
                          </li>
                        </ul>
                      </div>
                      <AddSeoPage pageType={pageType} formik={formik} />
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Social sharing image</h6>
                        <p>
                          When you share a link to your store on social media,
                          an image is usually shown in your post. This one will
                          be used if another relevant image can’t be found.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8 col-xl-4">
                      <label className="form-label">
                        Social sharing image preview
                      </label>

                      {/* <input type="file" id="input-file-uploader" className="socialimgPreview" /> */}
                      <div
                        className="imageUploder mb-4"
                        style={{ width: "280px", height: "157px" }}
                      >
                        <Dropify
                          id="input-file-uploader-2"
                          name="social_share_image"
                          value={formik.values.social_share_image}
                          changeCallBk={(event) => {
                            if (event.currentTarget.files[0]) {
                              formik.setFieldValue(
                                "social_share_image",
                                event.currentTarget.files[0]
                              );
                            } else {
                              formik.setFieldValue("social_share_image", "");
                            }
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                <hr className="horiz" />
                <div className="comPadding paddingTop9 pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Google Analytics</h6>
                        <p className="lineHeight17 marginBottom0">
                        Google Analytics enables us to track the visitors to our website, and generates reports that will help us with our marketing.
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mb-4 account_input modelFormInput">
                        {/* <label
                          htmlFor="homepageDescription"
                          className="form-label"
                        >
                          Google Analytics account
                        </label> */}
                        {/* <textarea className="form-control" placeholder="Enter a description to get a better ranking on search engines like google" rows={4} /> */}
                        <input
                          className="form-control textareaCount nonEditableInpBg"
                          name="google_analytics_code"
                          placeholder="UA-000000-01"
                          value={formik.values.google_analytics_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                        />

                        {formik.touched.google_analytics_code &&
                          formik.errors.google_analytics_code ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.google_analytics_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                 <div className="comPadding pb-0">
                  <div className="row">
                    <div className="col-md-4">
                      <div className="Generalcommn_text">
                        <h6>Google Tag Manager</h6>
                        <p className="lineHeight17">
                        Google Tag Manager allows us to add extra tags on our site. 
                        </p>
                      </div>
                    </div>
                    <div className="col-md-8">
                      <div className="mb-4 account_input modelFormInput">
                        <input
                          className="form-control textareaCount nonEditableInpBg"
                          name="google_tag_code"
                          placeholder="GTM-000000"
                          value={formik.values.google_tag_code}
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          readOnly
                        />

                        {formik.touched.google_tag_code &&
                          formik.errors.google_tag_code ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.google_tag_code}
                          </div>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div> 
              </div>
                <hr className="horiz margin25_0" />
              <div className="commonform_btn pt-0 comPadding d-grid col-12">
                <button type="submit" className={"btn btn-primary btn-block " +
                  (!(formik.isValid && formik.dirty)
                    ? " disabled"
                    : "")}>Save Settings</button>
              </div>
            </div>
          </form>
        </div>
      </div>

      <ToastContainer />
    </>
  );
}
