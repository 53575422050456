import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import GiftCardService from "../../services/GiftCardService";

export default function AddGiftCardPopup({ onClose, onSuccess, onFail, id }) {
  const giftcardServe = new GiftCardService();
  const [value, setValue] = useState({
    name: "",
    email: "",
    message: "",
    amount: 0,
  });

  useEffect(() => {
    if (id) {
      getSingleGiftCardData();
    }
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const getSingleGiftCardData = async () => {
    try {
      let response = await giftcardServe.getGiftCardDetails(id);
      if (response) {
        setValue({
          _id: response.data._id,
          name: response.data.name,
          email: response.data?.email,
          amount: response.data?.amount,
          message: response.data?.message,
        });
      } else {
        onFail(response?.error);
      }
    } catch (err) {
      onFail(err.message);
    }
  };

  const ValidateSchema = Yup.object().shape({
    name: Yup.string().required("Required"),
    email: Yup.string().required("Required").email(),
    amount: Yup.string().required("Required"),
    message: Yup.string()
      .max(300, "Description exceed 300 characters")
      .required("Required"),
  });

  const onSubmit = (values, { resetForm }) => {
    let obj = {
      _id: values._id,
      name: values.name,
      email: values.email,
      amount: values.amount,
      message: values.message,
    };

    if (values._id) {
      giftcardServe.editGiftCardRecord(values).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    } else {
      delete values._id;
      giftcardServe.addGiftCardRecord(obj).then((res) => {
        if (res.err) {
          onFail(res.err);
        } else {
          onSuccess();
        }
      });
    }
  };

  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div
      className="modal commanModel addWord show"
      id="addWord"
      style={{ display: "block" }}
      onClick={() => {
        onClose();
      }}
    >
      <div className="vertical-alignment-helper">
        <div className="vertical-align-center">
          <div className="modal-dialog">
            <div
              className="modal-content"
              onClick={(e) => {
                e.stopPropagation();
              }}
            >
              {/* Modal Header */}
              <div className="modal-header">
                <h4 className="modal-title d-flex align-items-center">
                  <img
                    src="images/icons/gift-black.svg"
                    alt="icons"
                    className="img-fluid"
                  />{" "}
                  <span>Send a Gift Card</span>
                  {/* <span>{id ? "Edit" : "Add"} a Word</span> */}
                </h4>
                <button type="button" className="btn-close" onClick={onClose} />
              </div>
              {/* Modal body */}
              <div className="modal-body">
                <form onSubmit={formik.handleSubmit}>
                  <div className="connectWalletOuter">
                    <div className="modelFormInput">
                      <div className="mb-3">
                        <label htmlFor="name" className="form-label">
                          Recipient Name
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="name"
                          placeholder="Please enter the recipient name"
                          name="name"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.name}
                        />
                        {formik.touched.name && formik.errors.name ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.name}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="email" className="form-label">
                          Recipient Email
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="email"
                          placeholder="Please enter the recipient email"
                          name="email"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.email}
                        />
                        {formik.touched.email && formik.errors.email ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.email}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label htmlFor="amount" className="form-label">
                          Amount
                        </label>
                        <input
                          type="text"
                          className="form-control"
                          id="amount"
                          placeholder="$0.00"
                          name="amount"
                          onChange={formik.handleChange}
                          onBlur={formik.handleBlur}
                          value={formik.values.amount}
                        />
                        {formik.touched.amount && formik.errors.amount ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.amount}
                          </div>
                        ) : null}
                      </div>
                      <div className="mb-3">
                        <label
                          htmlFor="message"
                          className="form-label"
                        >
                          Gift Message
                        </label>
                        <div className="textareaControl">
                          <textarea
                            className="form-control"
                            rows={4}
                            id="message"
                            name="message"
                            placeholder="Please enter the gift message"
                            maxLength={300}
                            value={formik.values.message}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                          />
                          <div id="the-count">
                            <span id="current">
                              {formik.values.message?.length}
                            </span>
                            <span id="maximum">/ 300 characters</span>
                          </div>
                        </div>
                        {formik.touched.message &&
                          formik.errors.message ? (
                          <div className="formik-errors bg-error">
                            {formik.errors.message}
                          </div>
                        ) : null}
                      </div>
                    </div>
                    <div className="modelSignBtn">
                      <div className="d-grid">
                        <button
                          type="submit"
                          className={
                            "btn btn-primary btn-block" +
                            (!(formik.isValid && formik.dirty)
                              ? " disabled"
                              : "")
                          }
                        >
                          Send
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
