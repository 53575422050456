import moment from "moment";
import React, { useState, useEffect, useContext } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../../services/UserService";
import UserKycService from "../../services/UserKycService";
import SupportTicketService from "../../services/SupportTicketService";
import ReferedUserService from "../../services/ReferedUserService";
import UserCourseService from "../../services/UserCourseService";
import UserLoginService from "../../services/UserLoginService";
import ConfirmDeletePopup from "../../shared/ConfirmDeletePopup/ConfirmDeletePopup";
import ResetUserPassword from "./ResetUserPassword";
import { ToastContainer, toast } from "react-toastify";

export default function UserProfile() {
  const userServe = new UserService();
  const userKycServe = new UserKycService();
  const supportServe = new SupportTicketService();
  const referedUserServe = new ReferedUserService();
  const userCourseServe = new UserCourseService();
  const userLoginServe = new UserLoginService();
  const { id } = useParams();
  const [showDeletePop, setShowDeletePop] = useState(false);
  const [deleteConfirm, setDeleteConfirm] = useState(false);
  const [userData, setUserData] = useState({});
  const [kycData, setKycData] = useState([]);
  const [kycIndex, setKycIndex] = useState(-1);
  const [supportList, setSupportList] = useState([]);
  const [referedUserList, setReferedUserList] = useState([]);
  const [userCourseList, setUserCourseList] = useState([]);
  const [userLoginList, setUserLoginList] = useState([]);
  const [showResetPassword, setShowResetPassword] = useState(false);
  const [isEditable, setIsEditable] = useState(false);
  const [formValue, setFormValue] = useState({
    full_name: "",
    user_name: "",
    bio: "",
    email: "",
    gender: "",
    website: "",
    date_of_birth: "",
    mobile_no: "",
    location: "",
  });
  const ValidateSchema = Yup.object().shape({
    full_name: Yup.string().required("Required"),
    user_name: Yup.string().required("Required"),
    bio: Yup.string().required("Required"),
    email: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
    website: Yup.string().required("Required"),
    date_of_birth: Yup.string().required("Required"),
    mobile_no: Yup.string().required("Required"),
    location: Yup.string().required("Required"),
  });
  useEffect(() => {
    getUserDetail();
    getUserKycDetail();
    getReferedUserList();
    getUserCourseList();
    getUserLoginList();
  }, [id]);
  useEffect(() => {
    getSupportList();
  }, [userData]);
  const getUserDetail = async () => {
    try {
      let resp = await userServe.getUser(id);
      if (resp.data) {
        setUserData({ ...resp.data });
        setFormValue({
          full_name: resp.data.full_name,
          user_name: resp.data.user_name,
          bio: resp.data.bio,
          email: resp.data.email,
          gender: resp.data.gender,
          website: resp.data.website,
          date_of_birth: resp.data.date_of_birth,
          mobile_no: resp.data.mobile_no,
          location: resp.data.location,
          _id: resp.data._id,
        });
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getSupportList = async () => {
    try {
      if (userData.email) {
        let obj = {
          filter: {
            email_address: userData.email,
          },
        };
        let resp = await supportServe.listAll(obj);
        if (resp.data) {
          setSupportList([...resp.data]);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getReferedUserList = async () => {
    try {
      let obj = {
        filter: {
          user_id: id,
        },
      };
      let resp = await referedUserServe.referedUserList(obj);
      if (resp.data) {
        setReferedUserList([...resp.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserLoginList = async () => {
    try {
      let obj = {
        filter: {
          user_id: id,
        },
      };
      let resp = await userLoginServe.userList(obj);
      if (resp.data) {
        setUserLoginList([...resp.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserCourseList = async () => {
    try {
      let obj = {
        filter: {
          user_id: id,
        },
      };
      let resp = await userCourseServe.userCourseList(obj);
      if (resp.data) {
        setUserCourseList([...resp.data]);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const getUserKycDetail = async () => {
    try {
      let obj = {
        filter: {
          userId: id,
        },
      };
      let resp = await userKycServe.userKycList(obj);
      if (resp.data) {
        setKycData([...resp.data]);
        if (resp.data.length > 0) {
          setKycIndex(0);
        }
      }
    } catch (error) {
      console.log(error);
    }
  };
  const changeKycStatus = async (id, status) => {
    try {
      let obj = {
        _id: id,
        kycVerified: status,
        email: userData?.email,
      };
      let resp = await userKycServe.updateStatus(obj);
      if (resp.result) {
        getUserKycDetail();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleActiveToggle = async () => {
    try {
      let obj = {
        is_active: !userData?.is_active,
        _id: userData?._id,
        email: userData?.email,
      };
      let resp = await userServe.edit(obj);
      if (resp) {
        getUserDetail();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const handleBlockUser = async () => {
    try {
      let obj = {
        is_blocked: !userData?.is_blocked,
        _id: userData?._id,
        email: userData?.email,
      };
      let resp = await userServe.edit(obj);
      if (resp) {
        getUserDetail();
      }
    } catch (error) {
      console.log(error);
    }
  };
  const resetUser = async () => {
    try {
      let obj = {
        _id: userData?._id,
      };
      let resp = await userServe.resetUser(obj);
      console.log(resp);
      if (resp.message == "Record updated successfully") {
        getUserDetail();
        toast.success("Record updated successfully");
      } else {
        toast.error(resp.err);
      }
    } catch (error) {
      console.log(error);
    }
  };
  const navigate = useNavigate();

  const deleteUserHandler = async () => {
    setShowDeletePop(true);
  };
  const deleteUser = async (deleteConfirm) => {
    try {
      if (deleteConfirm) {
        let response = await userServe.deleteUserRecordApi(id);
        if (response) {
          navigate("/userDatabase");
          setShowDeletePop(false);
        } else {
          return false;
        }
      }
    } catch (err) {
      return false;
    }
  };
  const onSubmit = async (values) => {
    const obj = { ...values };
    await userServe
      .edit(obj)
      .then((res) => {
        console.log(res);
        if (res.data?.message == "Updated Successfully") {
          setIsEditable(!isEditable);
          toast.success("User updated successfully");
          // onSucess();
        } else {
          toast.error(res.data.error);
        }
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
  const formik = useFormik({
    initialValues: formValue,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });
  return (
    <div className="ljSectionData w-100 clearfix" id="ljSectionData">
      <div className="commonCart">
        <div className="stepFormHead userdetailed_head d-flex align-items-center">
          <div className="leftBox h-100">
            <div className="backIconBtn d-flex align-items-center h-100">
              <Link to="/userDatabase">
                <img src="/images/icons/back-icon.svg" alt="backicon" className="img-fluid" />
              </Link>
            </div>
          </div>
          <div
            className="prouser_profile d-flex align-items-center h-100
               "
          >
            <div className="prouser_listbox d-flex">
              <div className="prouserDetailImg">
                <img src={userData?.file} className="img-fluid" />
                {/* <img src="/images/svg-icons/doodle.svg" className="img-fluid" /> */}
              </div>
              <div className="prouserDetail">
                <h5 className="mb-0">{userData?.full_name}</h5>
                <p className="mb-0">{userData?.email}</p>
                <p className="mb-0">@{userData?.user_name}</p>
              </div>
            </div>
            {/*  pro use pending-- */}
            <div className="prouser_listboxRight">
              <div className="pro_btn text-end">
                <a href="javascript:void(0)">
                  <img src="/images/user_database/lighting_boll.svg" className="me-1" /> Pro User
                </a>
              </div>
              <div className="pro_activeBtn mt-3">
                <div className="tbl_status d-flex align-items-center justify-content-center">
                  <span className="lable">Active</span>
                  <label className="switch">
                    <input type="checkbox" checked={userData?.is_active} onClick={handleActiveToggle} />
                    <span className="slider round" />
                  </label>
                </div>
              </div>
            </div>
          </div>
          <div className="notebox">
            <div className="account_input modelFormInput position-relative">
              <textarea className="form-control w-100" placeholder="Notes" rows={3} />
              <div className="zoomTextnotes zoom_cart">
                <a href="javascript:void(0)">
                  <img src="/images/other-icons/Expand.svg" />
                </a>
              </div>
            </div>
          </div>
          <div className="userBtnBox">
            <div className="userBtngrid">
              <a href="javascript:void(0)" className="blueBtn">
                Export Data
              </a>
              <a href="javascript:void(0)" className="orangeBtn" onClick={() => setShowResetPassword(userData?._id)}>
                Reset Pass
              </a>
              <a href="javascript:void(0)" className="redBtn" onClick={deleteUserHandler}>
                Delete User
              </a>
              <a href="javascript:void(0)" className="skyblueBtn" onClick={() => setIsEditable(!isEditable)}>
                Edit Details
              </a>
              <a href="javascript:void(0)" className="redBtn" onClick={resetUser}>
                Reset User
              </a>
              <a href="javascript:void(0)" className="redBtn" onClick={handleBlockUser}>
                {userData.is_blocked ? "Unblock User" : "Block User"}
              </a>
            </div>
          </div>
        </div>
        <div className="comPadding">
          <div className="profilesDetailForm">
            <div className="formHeading">
              <h3 className="mb-0">Profile Details</h3>
            </div>
            <form onSubmit={formik.handleSubmit}>
              <div className="profMainform">
                <div className="formcusGrid_first">
                  <div className="mb-4 userformComInput">
                    <label htmlFor="userfname" className="form-label">
                      Name
                    </label>
                    <input
                      type="text"
                      className="form-control"
                      name="full_name"
                      value={formik.values.full_name}
                      onChange={formik.handleChange}
                      disabled={!isEditable}
                      id="userfname"
                    />
                  </div>
                  <div className="mb-4 userformComInput">
                    <label className="form-label">Email Address</label>
                    <input
                      type="email"
                      className="form-control "
                      name="email"
                      value={formik.values.email}
                      onChange={formik.handleChange}
                      disabled={!isEditable}
                    />
                  </div>
                  <div className="mb-4 userformComInput">
                    <label className="form-label">Username</label>
                    <input
                      type="text"
                      className="form-control disabled"
                      name="user_name"
                      value={formik.values.user_name}
                      onChange={formik.handleChange}
                      disabled={!isEditable}
                    />
                  </div>
                  <div className="mb-4 userformComInput">
                    <label className="form-label">Mobile No.</label>
                    <input
                      type="text"
                      className="form-control disabled"
                      name="mobile_no"
                      value={formik.values.mobile_no}
                      onChange={formik.handleChange}
                      disabled={!isEditable}
                      placeholder="Not updated"
                    />
                  </div>
                  <div className="mb-4 userformComInput">
                    <label className="form-label">Gender</label>
                    <input
                      type="text"
                      className="form-control disabled"
                      name="gender"
                      value={formik.values.gender}
                      onChange={formik.handleChange}
                      disabled={!isEditable}
                      placeholder="Not updated"
                    />
                  </div>
                </div>
                <div className="formcusGrid_second">
                  <div className="formcusCol_left">
                    <div className="formcusGrid_Inner">
                      <div className="mb-4 userformComInput">
                        <label className="form-label">Birth date</label>
                        <input
                          type={!isEditable ? "text" : "date"}
                          className="form-control disabled"
                          // value={userData?.date_of_birth)}
                          name="date_of_birth"
                          value={
                            !isEditable
                              ? moment(formik.values.date_of_birth).format("DD/MM/YYYY")
                              : moment(formik.values.date_of_birth).format("YYYY-MM-DD")
                          }
                          onChange={formik.handleChange}
                          disabled={!isEditable}
                          placeholder="Not updated"
                        />
                      </div>
                      <div className="mb-4 userformComInput">
                        <label className="form-label">Location</label>
                        <input
                          type="text"
                          className="form-control disabled"
                          name="location"
                          value={formik.values.location}
                          onChange={formik.handleChange}
                          disabled={!isEditable}
                          placeholder="Not updated"
                        />
                      </div>
                      <div className="mb-4 userformComInput">
                        <label className="form-label">Website</label>
                        <input
                          type="text"
                          className="form-control disabled"
                          name="website"
                          value={formik.values.website}
                          onChange={formik.handleChange}
                          disabled={!isEditable}
                          placeholder="Not updated"
                        />
                      </div>
                      <div className="socialMediaInput userformComGroupInput mb-3">
                        <label className="form-label">Twitter Username</label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/twitter-gray.svg" alt="search" className="img-fluid" />
                          </span>
                          <input
                            type="text"
                            className="form-control border-0 disabled"
                            value={userData?.twitter_username?.user_name}
                            placeholder="Not connected"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="socialMediaInput userformComGroupInput mb-3">
                        <label className="form-label">Facebook Username</label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/facebook-gray.svg" alt="search" className="img-fluid" />
                          </span>
                          <input
                            type="text"
                            className="form-control border-0 disabled"
                            value={userData?.facebook_username?.user_name}
                            placeholder="Not connected"
                            disabled={true}
                          />
                        </div>
                      </div>
                      <div className="socialMediaInput userformComGroupInput mb-3">
                        <label className="form-label">Instagram Username</label>
                        <div className="input-group">
                          <span className="input-group-text border-0 pe-0">
                            {" "}
                            <img src="/images/icons/instagram.svg" alt="search" className="img-fluid" />
                          </span>
                          <input
                            type="text"
                            className="form-control border-0 disabled"
                            value={userData?.instagram_username?.user_name}
                            placeholder="Not connected"
                            disabled={true}
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className>
                    <div className="mb-4 userformComInput">
                      <label htmlFor="Description" className="form-label">
                        Bio
                      </label>
                      <textarea
                        className="form-control"
                        placeholder="Not updated"
                        rows={7}
                        name="bio"
                        value={formik.values.bio}
                        onChange={formik.handleChange}
                        disabled={!isEditable}
                      />
                    </div>
                  </div>
                </div>
                <div className="formcusGrid_third">
                  <div className="socialMediaInput userformComGroupInput ">
                    <label className="form-label">Discord Username</label>
                    <div className="input-group">
                      <span className="input-group-text border-0 pe-0">
                        {" "}
                        <img src="/images/icons/discord.svg" alt="search" className="img-fluid" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-0 disabled"
                        value={userData?.discord_username?.user_name}
                        placeholder="Not connected"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="socialMediaInput userformComGroupInput ">
                    <label className="form-label">Ethereum Wallet</label>
                    <div className="input-group">
                      <span className="input-group-text border-0 pe-0">
                        {" "}
                        <img src="/images/user_database/ethereum.svg" alt="search" className="img-fluid" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-0 disabled"
                        value={userData?.ethereum_wallet}
                        placeholder="Not connected"
                        disabled={true}
                      />
                    </div>
                  </div>
                  <div className="socialMediaInput userformComGroupInput ">
                    <label className="form-label">Solana Wallet</label>
                    <div className="input-group">
                      <span className="input-group-text border-0 pe-0">
                        {" "}
                        <img src="/images/user_database/solana.svg" alt="search" className="img-fluid" />
                      </span>
                      <input
                        type="text"
                        className="form-control border-0 disabled"
                        value={userData?.solana_wallet}
                        placeholder="Not connected"
                        disabled={true}
                      />
                    </div>
                  </div>
                </div>
                {isEditable && (
                  // <div>
                  //   <button
                  //     type="submit"
                  //     className={
                  //       "btn btn-primary btn-block w-100 mt-3 " + (!(formik.isValid && formik.dirty) && " disabled")
                  //     }
                  //   >
                  //     Submit
                  //   </button>
                  // </div>
                  <div className="commonform_btn d-grid col-12 mt-3">
                    <button
                      type="submit"
                      className={"btn btn-primary btn-block " + (!(formik.isValid && formik.dirty) ? " disabled" : "")}
                    >
                      Submit
                    </button>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
        <div className="comPadding">
          <div className="gerneralAlylitics_tbl_sec">
            <div className="formHeading">
              <h3 className="mb-0"> General Analytics</h3>
            </div>
            <div className="analyticsgridTbl">
              <div className="Alylitics_tblList pe-4">
                <ul className="list-unstyled mb-0">
                  <li>
                    <span>Registered On:</span>
                    <span>{moment(userData?.createdAt).format("DD[th] MMM, YYYY | hh:mm A")}</span>
                  </li>
                  <li>
                    <span>Last Active:</span>
                    <span>{moment(userData?.loginAt).format("DD[th] MMM, YYYY | hh:mm A")}</span>
                  </li>
                  <li>
                    <span>Push Notifications:</span>
                    <span>{userData?.settings?.push_notification ? "ON" : "OFF"}</span>
                  </li>
                  <li>
                    <span>Email Notifications:</span>
                    <span>{userData?.settings?.email_notification ? "ON" : "OFF"}</span>
                  </li>
                  <li>
                    <span>Discord Notifications:</span>
                    <span>{userData?.settings?.discord_notification ? "ON" : "OFF"}</span>
                  </li>
                  <li>
                    <span>Subscibed to Newsletter:</span>
                    <span>{userData?.settings?.newsletter ? "Yes" : "No"}</span>
                  </li>
                  <li>
                    <span>Default Language:</span>
                    <span>{userData?.settings?.language}</span>
                  </li>
                  <li>
                    <span>Web App Cookies:</span>
                    <span>{userData?.settings?.cookies ? "Accepted" : "Declined"}</span>
                  </li>
                  <li>
                    <span>Mobile App Activity:</span>
                    <span>{userData?.settings?.mobileApp ? "Yes" : "No"}</span>
                  </li>
                  <li>
                    <span>Total Time Spent on Platform:</span>
                    <span>{parseInt(userData?.timeSpent / 60)} hours</span>
                  </li>
                </ul>
              </div>
              <div className="Alylitics_tblList px-4">
                <ul className="list-unstyled mb-0">
                  <li>
                    <span>Course Rewards earned:</span>
                    <span>{userData?.courseReward} coins</span>
                  </li>
                  <li>
                    <span>Referral Rewards earned:</span>
                    <span>{userData?.referalReward} coins</span>
                  </li>
                  <li>
                    <span>Bonus Rewards earned:</span>
                    <span>{userData?.bonusReward} coins</span>
                  </li>
                  <li>
                    <span>Total Coins Earned:</span>
                    <span>{userData?.reward} coins</span>
                  </li>
                  <li>
                    <span>Total Coins Withdrawn:</span>
                    <span>{userData?.withdrawalReward} coins</span>
                  </li>
                  <li>
                    <span>Current Coins Balance:</span>
                    <span>{userData?.reward - userData?.withdrawalReward} coins</span>
                  </li>
                  <li>
                    <span>Courses Completed:</span>
                    <span>{userData?.courseCompleted}</span>
                  </li>
                  <li>
                    <span>Reward Tests Passed:</span>
                    <span>{userData?.testPassed}</span>
                  </li>
                  <li>
                    <span>Unsuccessful Test Attempts:</span>
                    <span>{userData?.testFailed}</span>
                  </li>
                  <li>
                    <span>Users Referred:</span>
                    <span>{userData?.referalReward / 15}</span>
                  </li>
                </ul>
              </div>
              <div className="Alylitics_tblList border-0 ps-4">
                <ul className="list-unstyled mb-0">
                  <li>
                    <span>Has Metamask Installed?</span>
                    <span>Yes</span>
                  </li>
                  <li>
                    <span>Has Coinbase Wallet Installed?</span>
                    <span>No</span>
                  </li>
                  <li>
                    <span>Has Phantom Wallet Installed?</span>
                    <span>No</span>
                  </li>
                  <li>
                    <span>Has Trust Wallet Installed?</span>
                    <span>No</span>
                  </li>
                  {/* <li>
                    <span>Browsing History - Twitter.com?</span>
                    <span>
                      <div className="text-end">
                        <p className="mb-0">Yes</p>
                        <p className="mb-0 timingstimate">(4 hours this week)</p>
                      </div>
                    </span>
                  </li>
                  <li>
                    <span>Browsing History - Binance.com?</span>
                    <span>
                      <div className="text-end">
                        <p className="mb-0">Yes</p>
                        <p className="mb-0 timingstimate">(54 mins this week)</p>
                      </div>
                    </span>
                  </li>
                  <li>
                    <span>Browsing History - Opensea.io?</span>
                    <span>
                      <div className="text-end">
                        <p className="mb-0">Yes</p>
                        <p className="mb-0 timingstimate">(4 hours this week)</p>
                      </div>
                    </span>
                  </li>
                  <li>
                    <span>Browsing History - Opensea.io?</span>
                    <span>
                      <div className="text-end">
                        <p className="mb-0">Yes</p>
                        <p className="mb-0 timingstimate">(4 hours this week)</p>
                      </div>
                    </span>
                  </li>
                  <li>
                    <span>Browsing History - Uniswap.org?</span>
                    <span>
                      <div className="text-end">
                        <p className="mb-0">Yes</p>
                        <p className="mb-0 timingstimate">(4 hours this week)</p>
                      </div>
                    </span>
                  </li> */}
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="about_sec">
          <div className="kycDetails d-flex">
            <div className="kycDetailsleft_div comPadding">
              <div className="formHeading formHeadingCustom">
                <h3 className="mb-0">
                  <img src="/images/user_database/twitter-verified-badge.svg" className="me-2" />
                  KYC Details
                </h3>
                <div className="kycCounter">
                  <img
                    src="/images/svg-icons/arrow-left.svg"
                    className="image-fluid arrow-left"
                    onClick={() => setKycIndex(kycIndex - 1 > 0 ? kycIndex - 1 : 0)}
                  />
                  <div className="kycCounterText">
                    <span>{kycIndex + 1}</span> of {kycData.length}
                  </div>
                  <img
                    src="/images/svg-icons/arrow-right.svg"
                    className="image-fluid arrow-right"
                    onClick={() => setKycIndex(kycIndex + 1 < kycData.length ? kycIndex + 1 : kycIndex)}
                  />
                </div>
              </div>
              {/* {kycData && kycData.map((item,idx)=>{
                      return(
                        
                      )
                    })} */}
              <div className="kycDetailsMain">
                <div className="kycDetails_left">
                  <ul className="list-unstyled">
                    <li>
                      <span>Full Legal Name:</span>
                      <span>{kycIndex >= 0 ? kycData[kycIndex]?.full_name : "not applied"}</span>
                    </li>
                    <li>
                      <span>Date of Birth:</span>
                      <span>
                        {kycIndex >= 0 ? moment(kycData[kycIndex]?.date_of_birth).format("DD/MM/YYYY") : "not applied"}
                      </span>
                    </li>
                    <li>
                      <span>Address:</span>
                      <span className="d-block">{kycIndex >= 0 ? kycData[kycIndex]?.address : "not applied"}</span>
                    </li>
                    <li>
                      <span>Country:</span>
                      <span>{kycIndex >= 0 ? kycData[kycIndex]?.country : "not applied"}</span>
                    </li>
                    {/* pending-- */}
                    <li>
                      <span>IP Address:</span>
                      <span>{kycIndex >= 0 ? kycData[kycIndex]?.ipAddress : "not applied"}</span>
                    </li>
                  </ul>
                </div>
                <div className="kycDetails_Right">
                  <ul className="list-unstyled">
                    <li>
                      <span>Identity Proof (Front Side):</span>
                      <span>
                        <div>
                          <a href={kycData[kycIndex]?.doc_front} target="_blank">
                            View attachment
                          </a>
                        </div>
                      </span>
                    </li>
                    <li>
                      <span>Identity Proof (Back Side):</span>
                      <span>
                        <div>
                          <a href={kycData[kycIndex]?.doc_back} target="_blank">
                            View attachment
                          </a>
                        </div>
                      </span>
                    </li>
                    <li className="kycStatus">
                      <span>Status:</span>
                      {kycIndex >= 0 ? (
                        <>
                          <span className="statusResult">
                            {" "}
                            {kycData[kycIndex]?.kycVerified
                              ? "Approved"
                              : kycData[kycIndex]?.kycVerified == false
                              ? "Rejected"
                              : "Pending"}
                          </span>
                          <div className="userBtngrid userKycFlex">
                            {!(kycData[kycIndex]?.kycVerified == false || kycData[kycIndex]?.kycVerified) && (
                              <>
                                <a
                                  href="javascript:void(0)"
                                  className="greenBtn text-decoration-none"
                                  onClick={() => changeKycStatus(kycData[kycIndex]._id, true)}
                                >
                                  Approve
                                </a>
                                <a
                                  href="javascript:void(0)"
                                  className="redBtn text-decoration-none"
                                  onClick={() => changeKycStatus(kycData[kycIndex]._id, false)}
                                >
                                  Reject
                                </a>
                              </>
                            )}
                          </div>
                        </>
                      ) : (
                        <span className="statusResult">not applied</span>
                      )}
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            <div className="membshipStatus comPadding">
              <div className="formHeading">
                <h3 className="mb-0">
                  <img src="/images/user_database/lighting_bollGray.svg" className="me-2" />
                  Pro Membership Status
                </h3>
              </div>
              <div className="membshipStatusMain">
                <p className="mb-0">No memberships found linked to this account.</p>
              </div>
            </div>
          </div>
        </div>
        <div className="walletanalysis comPadding pt-0 pb-0">
          <div className="userExtraDetails">
            <div className="profileBoxOuter">
              <div className="formHeading">
                <h3 className="mb-0">
                  <img src="/images/user_database/experience.svg" className="me-2" />
                  Work experience
                </h3>
              </div>
              <div className="">
                {userData?.work_experience?.map((item) => {
                  return (
                    <div className="profileBoxInner profileBoxInnerCustom">
                      {/* <div className="profileCards"> */}
                      <div className="profileCardHead d-flex align-items-center profileCardHeadCustom">
                        <div className="profileImgCard">
                          <img
                            src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                            alt="profile"
                            className="img-fluid"
                            style={{ borderRadius: "60px" }}
                          />
                        </div>
                        <div className="profileContantCard profileContantCardCustom">
                          <h4 className="projectLineBreak mb-0">{item?.company_name}</h4>
                          <p className="mb-0">{item?.description}</p>
                        </div>
                      </div>
                      <div className="profileCardFooter d-flex align-items-center justify-content-between w-100 profileCardFooterCustom">
                        <div className="profileCardLeft">
                          <h6 className="mb-0">{item?.position}</h6>
                        </div>
                        <div className="profileCardRight">
                          <a
                            href="javascript:void(0);"
                            className={
                              item?.present ? "btn btnLightGreen btnLightCustom" : "btn btnLightOrange btnLightCustom"
                            }
                          >
                            {item?.present ? "Present" : `${item.duration} months`}
                          </a>
                        </div>
                      </div>
                      {/* </div> */}
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="profileBoxOuter">
              <div className="formHeading">
                <h3 className="mb-0">
                  <img src="/images/user_database/project.svg" className="me-2" />
                  Projects
                </h3>
              </div>
              <div className="">
                {userData?.project?.map((item) => {
                  return (
                    <div className="profileBoxInner profileBoxInnerCustom">
                      {/* <div className="profileCards"> */}
                      <div className="profileCardHead d-flex align-items-center profileCardHeadCustom">
                        <div className="profileImgCard">
                          <img
                            src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                            alt="profile"
                            className="img-fluid"
                            style={{
                              borderRadius: "60px",
                              marginBottom: "12px",
                            }}
                          />
                        </div>
                        <div className="profileContantCard profileContantCardCustom">
                          <h4 className="projectLineBreak mb-0">{item?.project_name}</h4>
                          <p className="mb-0">{item?.description}</p>
                          <a
                            href={"//" + item?.web_link}
                            target="_blank"
                            rel="noopener noreferrer"
                            className="webLinkProf projectLineBreak"
                          >
                            {item?.web_link}
                          </a>
                        </div>
                      </div>
                      <div className="profileCardFooter d-flex align-items-center justify-content-between w-100 profileCardFooterCustom">
                        <div className="profileCardLeft">
                          <h6 className="mb-0">{item?.type}</h6>
                        </div>
                        <div className="profileCardRight">
                          <a
                            href="javascript:void(0);"
                            className={
                              item?.status == "Beta"
                                ? "btn btnLightOrange btnLightCustom"
                                : item.status == "Under Dev"
                                ? "btn btnLightGrey btnLightCustom"
                                : "btn btnLightGreen btnLightCustom"
                            }
                          >
                            {item?.status}
                          </a>
                        </div>
                      </div>
                    </div>
                    // </div>
                  );
                })}
              </div>
            </div>
            <div className="profileBoxOuter">
              <div className="formHeading">
                <h3 className="mb-0">
                  <img src="/images/user_database/skill.svg" className="me-2" /> Skills
                </h3>
              </div>
              <div className="">
                {userData?.skill?.map((item) => {
                  return (
                    <div className="profileBoxInner profileBoxInnerCustom">
                      <div className="profileCardHead profileCardHeadCustom profileSkillHead">
                        <div className="profileImgCard">
                          <img
                            src={item.logo ? item.logo : "/images/user_database/blank-small-profile.svg"}
                            alt="profile"
                            className="img-fluid"
                            style={{
                              borderRadius: "60px",
                            }}
                          />
                        </div>
                        <div className="profileContantCard profileContantCardCustom">
                          <h4 className="text-center">{item?.skill_name}</h4>
                          <p className="mb-0">{item?.level}</p>
                        </div>
                      </div>
                    </div>
                  );
                })}
              </div>
            </div>
            <div className="profileBoxOuter">
              <div className="formHeading">
                <h3 className="mb-0">
                  <img src="/images/user_database/education.svg" className="me-2" /> Education
                </h3>
              </div>
              {userData?.education?.map((item) => {
                return (
                  <div className="profileBoxInner profileBoxInnerCustom">
                    <div className="profileCardHead d-flex align-items-center justify-content-between profileCardHeadCustom w-100">
                      <div className="profileContantCard profileContantCardCustom">
                        <h4 className="projectLineBreak">{item?.school_name}</h4>
                        <p className="mb-0">{item?.degree}</p>
                      </div>
                      <div className="profileImgCard">
                        <img
                          src={item.logo ? item.logo : "/images/icons/blank-small-profile.svg"}
                          alt="profile"
                          className="img-fluid"
                          style={{
                            borderRadius: "60px",
                            marginBottom: "12px",
                          }}
                        />
                      </div>
                    </div>
                    <div className="profileCardFooter d-flex align-items-center justify-content-between profileCardFooterCustom">
                      <div className="profileCardLeft">
                        <h6 className="mb-0">{`${item?.start_month} ${item?.start_year} - ${item?.end_month} ${item?.end_year}`}</h6>
                      </div>
                      <div className="profileCardRight"></div>
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        {/* <div className="walletanalysis comPadding">
          <div className="formHeading">
            <h3 className="mb-0"> Wallet Analytics</h3>
          </div>
          <div className="commingSoon comPadding">
            <p className="mb-0">Coming Soon</p>
          </div>
        </div> */}
        {/* || course result section start || */}
        <div className="course_resultSec pb-1">
          <div className="formHeading comPadding pb-0">
            <h3 className="mb-0"> Course Results</h3>
          </div>
          <div className="course_resCardsec">
            <div className="resCardInnerSec d-flex comPadding pt-0">
              {userCourseList &&
                userCourseList.map((item, idx) => {
                  return (
                    <div className="result_card">
                      <div className="result_cardImg">
                        <img src={item?.course_id?.cover_image} className="img-fluid" />
                      </div>
                      <div className="result_cardList">
                        <ul className="list-unstyled mb-0 userDatabaseLi">
                          <li>
                            <span>Title:</span>
                            <span>{item?.course_id?.course_name}</span>
                          </li>
                          <li>
                            <span>Status:</span>
                            <span>{item?.status >= 70 ? "Completed" : "Started"}</span>
                          </li>
                          <li>
                            <span>Test Status:</span>
                            <span>{item?.score >= 60 ? "Passed" : "NA"}</span>
                          </li>
                          <li>
                            <span>Test Result:</span>
                            <span>{item?.score ? item.score : "NA"}</span>
                          </li>
                          <li>
                            <span>No. of Attempts:</span>
                            <span>{item?.attempts ? item.attempts : "NA"}</span>
                          </li>
                          <li>
                            <span>IP Address:</span>
                            <span> {item?.ipAddress}</span>
                          </li>
                          <li>
                            <span>Date & Time:</span>
                            <span> {moment(item?.updatedAt).format("DD[th] MMM, YYYY | hh:mm A")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* || course result section end || */}
        {/* || Login History section start || */}
        <div className="course_resultSec pb-1">
          <div className="formHeading comPadding pb-0">
            <h3 className="mb-0"> Login History</h3>
          </div>
          <div className="course_resCardsec">
            <div className="resCardInnerSec d-flex comPadding pt-0">
              {userLoginList &&
                userLoginList.map((item, idx) => {
                  return (
                    <div className="result_card loginHistroyCard">
                      <div className="result_cardList">
                        <ul className="list-unstyled mb-0 userDatabaseLi">
                          <li>
                            <span>Date &amp; Time:</span>
                            <span> {moment(item?.createdAt).format("DD[th] MMM, YYYY | hh:mm A")}</span>
                          </li>
                          <li>
                            <span>Location:</span>
                            <span> {item?.location}</span>
                          </li>
                          <li>
                            <span>IP: </span>
                            <span>{item?.ipAddress}</span>
                          </li>
                          <li>
                            <span>Device:</span>
                            <span> {item?.device}</span>
                          </li>
                          <li>
                            <span>Device Type:</span>
                            <span> {item?.deviceType}</span>
                          </li>
                          <li>
                            <span>Browser:</span>
                            <span> {item?.browser}</span>
                          </li>
                          <li>
                            <span>0S:</span>
                            <span> {item?.os}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* || Login History section end || */}
        {/* || Referral History section start || */}
        <div className="course_resultSec pb-1">
          <div className="formHeading comPadding pb-0">
            <h3 className="mb-0"> Referral History</h3>
          </div>
          <div className="course_resCardsec">
            <div className="resCardInnerSec d-flex comPadding pt-0">
              {referedUserList &&
                referedUserList.map((item, idx) => {
                  return (
                    <div className="result_card loginHistroyCard">
                      <div className="result_cardList">
                        <ul className="list-unstyled mb-0">
                          <li>
                            <span className="letterSpacing0">Name:</span>
                            <span className="letterSpacing0"> {item?.reffered_user_id?.full_name}</span>
                          </li>
                          <li>
                            <span className="letterSpacing0">Email:</span>
                            <span className="letterSpacing0"> {item?.reffered_user_id?.email}</span>
                          </li>
                          <li>
                            <span className="letterSpacing0">Username: </span>
                            <span className="letterSpacing0"> @{item?.reffered_user_id?.user_name}</span>
                          </li>
                          <li>
                            <span className="letterSpacing0">IP:</span>
                            <span className="letterSpacing0"> {item?.ipAddress}</span>
                          </li>
                          <li>
                            <span className="letterSpacing0">Reward:</span>
                            <span className="letterSpacing0"> {item?.reward} coins</span>
                          </li>
                          <li>
                            <span className="letterSpacing0">Date &amp; Time:</span>
                            <span className="letterSpacing0"> {moment(item?.createdAt).format("DD[th] MMM, YYYY | hh:mm A")}</span>
                          </li>
                        </ul>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* || Referral History section end || */}
        {/* || Support History section start || */}
        <div className="course_resultSec pb-1">
          <div className="formHeading comPadding pb-0">
            <h3 className="mb-0"> Support History</h3>
          </div>
          <div className="course_resCardsec">
            <div className="resCardInnerSec d-flex comPadding pt-0">
              {supportList &&
                supportList.map((item, idx) => {
                  return (
                    <div className="ticketCart supportTicket_card">
                      <div className="commonticket_cart border-top-0">
                        <h6 className="mb-1">{item?.subject}</h6>
                        <p className="mb-0">{item?.message.slice(0, 185)}</p>
                      </div>
                      <div className="attachment_ticket commonticket_cart d-flex justify-content-between align-items-end">
                        <div className="attachment_file">
                          <h6 className="mb-1">Attachments</h6>
                          <p className="mb-0">{item?.attachments ? 1 : 0} Files Attached</p>
                        </div>
                        <div>
                          <h6 className="mb-1">Date &amp; Time</h6>
                          <p className="mb-0">{moment(item?.loginAt).format("DD[th] MMM, YYYY | hh:mm A")}</p>
                        </div>
                      </div>
                      <div className="commonticket_cart">
                        <h6 className="mb-1">Wallet Address</h6>
                        <p className="mb-0">{item?.wallet_address ? item.wallet_address : "NA"}</p>
                      </div>
                      <div className="social_row commonticket_cart d-flex">
                        <div className="social_icon">
                          <img src="/images/other-icons/twitter.svg" />
                          <span className="cartP">@{item?.twitter_username}</span>
                        </div>
                        <div className="social_icon">
                          <img src="/images/other-icons/discordneew.svg" />
                          <span className="cartP">{item?.discord_username}</span>
                        </div>
                      </div>
                      <div className="ticketno_foot d-flex justify-content-between">
                        <div className="ticketno_footLeft">
                          <p className="mb-0">
                            <span>Ticket No:</span>
                            <span>{item?.ticketNo + 1}</span>
                          </p>
                        </div>
                        <div className="zoom_cart">
                          <a href="javascript:void(0)">
                            <img src="/images/other-icons/Expand.svg" />
                          </a>
                        </div>
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </div>
        {/* || Support History section end || */}
      </div>
      {showDeletePop && (
        <ConfirmDeletePopup
          onSucess={deleteUser}
          setDeleteConfirm={setDeleteConfirm}
          onClose={() => {
            setShowDeletePop(false);
          }}
        />
      )}
      {showResetPassword && (
        <ResetUserPassword
          onSucess={() => setShowResetPassword(false)}
          userId={showResetPassword}
          onClose={() => {
            setShowResetPassword(false);
          }}
        />
      )}
      <ToastContainer />
    </div>
  );
}
