import React, { useState, useEffect } from "react";
import { useFormik } from "formik";
import * as Yup from "yup";
import UserService from "../../services/UserService";
import { ToastContainer, toast } from "react-toastify";
import { Link, useNavigate, useParams } from "react-router-dom";

function ChangePassword() {
  // to maintain the toggle effect of show and hide password
  const[showCurPassword, setCurPassword] = useState(false);
  const[showNewPassword, setNewPassword] = useState(false);
  const[showVerifyPassword, setVerifyPassword] = useState(false);
  // ========
  const params = useParams();
  const userServ = new UserService();
  const router = useNavigate();

  const [value, setValue] = useState({
    currentPassword: "",
    newPassword: "",
    verifyPassword: "",
  });
  const ValidateSchema = Yup.object({
    currentPassword: Yup.string().required(
      "Current password is required field"
    ),
    newPassword: Yup.string().required("New password is required field"),
    verifyPassword: Yup.string().required("Verify password is required field"),
  });

  const onSubmit = async (values, { resetForm }) => {
    let obj = { ...values };
    try {
      let response;
      if (obj._id !== "") {
        response = await userServ.changePassword(obj);
        if (response.data.error) {
          throw new Error(response.data.error);
        } else {
          toast.success("Password updated successfully");
          formik.resetForm()
        }
      }
    } catch (err) {
      toast.error(err.message);
    }
  };
  const formik = useFormik({
    initialValues: value,
    validateOnBlur: true,
    onSubmit,
    validationSchema: ValidateSchema,
    enableReinitialize: true,
  });

  return (
    <div className="row">
      <div className="col-xl-4 customgrid_first">
        <div className="profilDtl_head">
          <h6>Change password</h6>
          <p>Forgot your password or just want to change it? Change it here.</p>
        </div>
      </div>
      <div className="col-xl-8 customgrid_seco">
        <div className="userDetails_form">
          <form onSubmit={formik.handleSubmit} id="ChangePassword">
            <div className="form_inputfield row">
              <div className="col-md-6">
                <div className="mb-4 account_input middelContant">
                  <label className="form-label">Current Password</label>
                  <div className="input-group">
                    <span className="input-group-text bg-white betaLock border-0 pe-1">
                      <img
                        src="images/icons/lock.svg"
                        alt="lock"
                        className="img-fluid"
                      />
                    </span>
                    <input
                      type={showCurPassword ? "text" : "password"}
                      placeholder="Please enter your current password"
                      className="form-control passInput border-0"
                      name="currentPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.currentPassword}
                    />
                    <span className="input-group-text bg-white betaEye border-0">
                      <a href="javascript:void(0);">
                        <img
                          src="images/icons/hide-eye.svg"
                          alt="eye-hide"
                          className="img-fluid"
                          onClick={() => {
                            formik.values.currentPassword &&
                              setCurPassword(!showCurPassword);
                          }}
                        />
                      </a>
                    </span>
                  </div>
                  {formik.touched.currentPassword &&
                  formik.errors.currentPassword ? (
                    <div className="formik-errors bg-error">
                      {formik.errors.currentPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6"></div>
              <div className="col-md-6">
                <div className="mb-4 account_input middelContant">
                  <label className="form-label">New Password</label>
                  <div className="input-group">
                    <span className="input-group-text bg-white betaLock border-0 pe-1">
                      <img
                        src="images/icons/lock.svg"
                        alt="lock"
                        className="img-fluid"
                      />
                    </span>
                    <input
                      type={showNewPassword ? "test" : "password"}
                      name="newPassword"
                      placeholder="Please enter your new password"
                      className="form-control passInput border-0"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.newPassword}
                    />
                    <span className="input-group-text bg-white betaEye border-0">
                      <a href="javascript:void(0);">
                        <img
                          src="images/icons/hide-eye.svg"
                          alt="eye-hide"
                          className="img-fluid"
                          onClick={() => {
                            formik.values.newPassword &&
                              setNewPassword(!showNewPassword);
                          }}
                        />
                      </a>
                    </span>
                  </div>
                  {formik.touched.newPassword && formik.errors.newPassword ? (
                    <div className="formik-errors bg-error">
                      {formik.errors.newPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-md-6">
                <div className="mb-4 account_input middelContant">
                  <label className="form-label">Repeat New Password</label>
                  <div className="input-group">
                    <span className="input-group-text bg-white betaLock border-0 pe-1">
                      <img
                        src="images/icons/lock.svg"
                        alt="lock"
                        className="img-fluid"
                      />
                    </span>
                    <input
                      type={showVerifyPassword ? "test" : "password"}
                      className="form-control passInput border-0"
                      placeholder="Please repeat your new password"
                      name="verifyPassword"
                      onChange={formik.handleChange}
                      onBlur={formik.handleBlur}
                      value={formik.values.verifyPassword}
                    />
                    <span className="input-group-text bg-white betaEye border-0">
                      <a href="javascript:void(0);">
                        <img
                          src="images/icons/hide-eye.svg"
                          alt="eye-hide"
                          className="img-fluid"
                          onClick={() => {
                            formik.values.verifyPassword &&
                              setVerifyPassword(!showVerifyPassword);
                          }}
                        />
                      </a>
                    </span>
                  </div>
                  {formik.touched.verifyPassword && formik.errors.verifyPassword ? (
                    <div className="formik-errors bg-error">
                      {formik.errors.verifyPassword}
                    </div>
                  ) : null}
                </div>
              </div>
              <div className="col-lg-12">
                <div className="commonform_btn d-grid">
                  <button
                    type="submit"
                    className={
                      "btn btn-primary btn-block " +
                      (!(formik.isValid && formik.dirty) ? " disabled" : "")
                    }
                  >
                    Change password
                  </button>
                </div>
              </div>
            </div>
          </form>
        </div>
      </div>
      <ToastContainer />
    </div>
  );
}

export default ChangePassword;
