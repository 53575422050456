import util from "../util/util";
import axios from "axios";
export default class SearchKeywordService {
  listAll(data) {
    const activity = Object.keys(data).reduce((object, key) => {
      if (data[key] !== "") {
        object[key] = data[key];
      }
      return object;
    }, {});

    return util.sendApiRequest("/searchkeyword/list", "POST", true, activity).then(
      (response) => {
        return response;
      },
      (error) => {
        throw error;
      }
    );
  }
  getDetails(dataId) {
    return util
      .sendApiRequest("/searchkeyword/" + dataId, "GET", true)
      .then((response) => {
        return response;
      })
      .catch((err) => {
        throw err;
      });
  }
  listCount(condition) {
    return util
      .sendApiRequest("/searchkeyword/searchKeywordCount", "POST", condition)
      .then((response) => {
        return response;
      })
      .catch((error) => {
        throw error;
      });
  }
}
